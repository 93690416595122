import { Typography, TypographyProps } from '@mui/material';

export type FreeTextProps = Pick<TypographyProps, 'variant' | 'color'> & {
  children?: React.ReactNode | React.ReactNode[];
  bold?: boolean;
  component?: React.ElementType;
  sx?: any;
};

export const FreeText = ({
  bold,
  children,
  component = 'span',
  sx = {},
  ...rest
}: FreeTextProps) => {
  if (bold) sx.fontWeight = 700;

  return (
    <Typography sx={sx} component={component} {...rest}>
      {children}
    </Typography>
  );
};

export type TextProps = Omit<FreeTextProps, 'children'> & {
  stringId: string;
};
