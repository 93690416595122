import { useTranslation } from 'react-i18next';
import TextInput from 'components/common/inputs/textInput/TextInput';
import { CountryInput } from 'components/features/dropdowns/CountryInput';
import { Grid, Typography } from '@mui/material';
import { TextCodes } from 'hooks/texts/text.codes';

interface Props {
  groupName?: string;
}

const AddressEdit = ({ groupName = 'address' }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid display='flex' flexDirection='column' container gap={2}>
      <Typography
        variant='body2'
        component='h2'
        fontWeight='bold'
        sx={{ display: 'block' }}
      >
        {t(TextCodes.WG6.Address.Domicile)}
      </Typography>
      <TextInput
        name={`${groupName}.street`}
        label={t(TextCodes.WG6.Address.Street)}
        fullWidth
        sx={{ mb: 0 }}
      />
      <Grid container justifyContent='space-between' columnSpacing={1}>
        <Grid item xs={5}>
          <TextInput
            name={`${groupName}.zipCode`}
            label={t(TextCodes.WG6.Address.ZipCode)}
            fullWidth
            sx={{ mb: 0 }}
          />
        </Grid>
        <Grid item xs={7}>
          <TextInput
            name={`${groupName}.city`}
            label={t(TextCodes.WG6.Address.City)}
            fullWidth
            sx={{ mb: 0 }}
          />
        </Grid>
      </Grid>
      <CountryInput
        name={`${groupName}.country`}
        label={t(TextCodes.WG6.Address.Country)}
        fullWidth
        sx={{ mb: 0 }}
      />
    </Grid>
  );
};

export default AddressEdit;
