import { baseApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    airFareRule: build.mutation<AirFareRuleResult, AirFareRuleArgs>({
      query: (queryArg) => ({
        url: `/api/air/fare-rule`,
        method: 'POST',
        body: queryArg.airFareRuleRequest,
      }),
    }),
    itineraryList: build.query<ItineraryListResult, ItineraryListArgs>({
      query: (queryArg) => ({
        url: `/api/itinerary/list`,
        params: {
          BookingStatus: queryArg.bookingStatus,
          BookedByMe: queryArg.bookedByMe,
          CacheKey: queryArg.cacheKey,
        },
      }),
    }),
    itineraryDetails: build.query<ItineraryDetailsResult, ItineraryDetailsArgs>(
      {
        query: (queryArg) => ({
          url: `/api/itinerary/details`,
          method: 'POST',
          body: queryArg.itineraryDetailsRequest,
        }),
      }
    ),
    itinerarySendBySms: build.query<
      ItinerarySendBySmsResult,
      ItinerarySendBySmsArgs
    >({
      query: (queryArg) => ({
        url: `/api/itinerary/send/sms`,
        method: 'POST',
        body: queryArg.sendItinerarySmsRequest,
      }),
    }),
    itinerarySendByEmail: build.query<
      ItinerarySendByEmailResult,
      ItinerarySendByEmailArgs
    >({
      query: (queryArg) => ({
        url: `/api/itinerary/send/email`,
        method: 'POST',
        body: queryArg.sendItineraryMailRequest,
      }),
    }),
    itineraryReceipt: build.query<ItineraryReceiptResult, ItineraryReceiptArgs>(
      {
        query: (queryArg) => ({
          url: `/api/itinerary/receipt`,
          method: 'POST',
          body: queryArg.itineraryReceiptRequest,
        }),
      }
    ),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as itineraryApi };
export type AirFareRuleResult =
  /** status 200 Returns the available flight rules for given flight search string */ AirFareRuleResponse[];
export type AirFareRuleArgs = {
  airFareRuleRequest: AirFareRuleRequest;
};
export type ItineraryListResult =
  /** status 200 A list of itineraries */ ItineraryListDto;
export type ItineraryListArgs = {
  bookingStatus?: BookingStatus;
  bookedByMe?: boolean;
  cacheKey?: string;
};
export type ItineraryDetailsResult =
  /** status 200 Returns an ordered list of itineraries */ ItineraryDetailsResponse;
export type ItineraryDetailsArgs = {
  itineraryDetailsRequest: ItineraryDetailsRequest;
};
export type ItinerarySendBySmsResult = unknown;
export type ItinerarySendBySmsArgs = {
  sendItinerarySmsRequest: SendItinerarySmsRequest;
};
export type ItinerarySendByEmailResult = unknown;
export type ItinerarySendByEmailArgs = {
  sendItineraryMailRequest: SendItineraryMailRequest;
};
export type ItineraryReceiptResult = unknown;
export type ItineraryReceiptArgs = {
  itineraryReceiptRequest: ItineraryReceiptRequest;
};
export type AirFareRuleResponse = {
  ruleName?: string | null;
  rules?: string[] | null;
};
export type AirFareRuleRequest = {
  searchString?: string | null;
};
export type BookingStatus = 'Past' | 'Cancelled' | 'Upcoming';
export type ItinerariesListTravellerResponse = {
  tacNumber?: string;
  firstName?: string;
  lastName?: string;
  hasReceipt?: boolean;
};
export type ItineraryListItem = {
  bookingStatus?: BookingStatus;
  orderNo?: string | null;
  outwardDate?: string | null;
  reference?: string | null;
  departureName?: string | null;
  departureCode?: string | null;
  destinationName?: string | null;
  destinationCode?: string | null;
  containsAir?: boolean;
  containsHotel?: boolean;
  containsCar?: boolean;
  containsTrain?: boolean;
  travellers?: ItinerariesListTravellerResponse[] | null;
};
export type ItineraryListDto = {
  bookingStatus?: BookingStatus;
  itineraries?: ItineraryListItem[] | null;
};
export type ItineraryTravellerOrderLabelValue = {
  label?: string;
  value?: string;
};
export type ItineraryTravellerOrder = {
  id?: string;
  firstName?: string;
  lastName?: string;
  tacNumber?: string;
  travCode?: ItineraryTravellerOrderLabelValue;
  depCode?: ItineraryTravellerOrderLabelValue;
  hasReceipt?: boolean | null;
};
export type ItineraryType = 'Flight' | 'Train' | 'Car' | 'Hotel';
export type Money = {
  amount?: number;
  currencyCode?: string | null;
};
export type GeoCords = {
  latitude?: number;
  longitude?: number;
};
export type ItineraryCarLocation = {
  date?: string | null;
  address?: string;
  phoneNo?: string;
  geoCode?: GeoCords;
};
export type CodeDescriptionPair = {
  code?: string | null;
  description?: string | null;
};
export type CodeNamePair = {
  code?: string | null;
  name?: string | null;
};
export type ItineraryInfoSegment = {
  departureCityName?: string;
  departureCityCode?: string;
  departureCityDisplayName?: string;
  arrivalCityCode?: string;
  arrivalCityName?: string;
  arrivalCityDisplayName?: string;
  flightCarrierCode?: string;
  flightCarrierName?: string;
  flightNo?: string;
  flightClassOfService?: string;
  flightBookingClass?: string;
  flightFareBasis?: string;
  flightEquipment?: string;
  flightNumberOfStops?: string;
  flightExtras?: string[];
  seatNo?: string;
  trainCarNo?: string;
  trainCarrierCode?: string;
  trainCarrierName?: string;
  departureDateTimeOriginal?: string | null;
  departureDateTimeUpdated?: string | null;
  arrivalDateTimeOriginal?: string | null;
  arrivalDateTimeUpdated?: string | null;
  segmentTime?: number;
  timeToWait?: number;
  baggageTypeCode?: string;
  baggageValue?: number;
  gateNo?: string;
  travellerId?: string;
  statusCode?: string;
  statusText?: string;
  segmentId?: string;
  flightCarrierCodeAndNo?: string;
};
export type ItineraryInfo = {
  itineraryType?: ItineraryType;
  reference?: string;
  statusCode?: string;
  statusText?: string;
  departureDateTime?: string | null;
  departureCityCode?: string;
  departureCityName?: string;
  arrivalCityCode?: string;
  arrivalCityName?: string;
  arrivalDateTime?: string | null;
  totalTime?: number | null;
  name?: string;
  search?: string;
  edit?: string;
  delete?: string;
  checkInDate?: string | null;
  checkOutDate?: string | null;
  address?: string;
  phoneNo?: string;
  cancellationPolicy?: string[];
  roomType?: string;
  price?: Money;
  nights?: number;
  hotelCoordinates?: GeoCords;
  carPickupLocation?: ItineraryCarLocation;
  carDropOffLocation?: ItineraryCarLocation;
  carClass?: CodeDescriptionPair;
  carType?: CodeDescriptionPair;
  carTransmission?: CodeDescriptionPair;
  carAirCondition?: CodeDescriptionPair;
  carCompany?: CodeNamePair;
  carModel?: string;
  segments?: ItineraryInfoSegment[];
  travellerIds?: string[];
  departureSegmentId?: string;
  arrivalSegmentId?: string;
  arrivalDateTimeUpdated?: string | null;
  departureDateTimeUpdated?: string | null;
};
export type ItineraryDetails = {
  date?: string;
  itineraryTypes?: ItineraryType[];
  itineraries?: ItineraryInfo[];
};
export type Location = {
  code?: string;
  name?: string;
  geoCode?: GeoCords;
};
export type LocationInfo = {
  location?: Location;
  city?: Location;
  country?: Location;
  displayName?: string;
};
export type ItineraryDetailsResponse = {
  orderNo?: string;
  travellers?: ItineraryTravellerOrder[];
  itineraryDetails?: ItineraryDetails[];
  pointOfTurnaround?: LocationInfo;
};
export type ItineraryDetailsRequest = {
  orderNumber?: string | null;
};
export type ItinerarySms = {
  phoneNumber: string;
  language: string;
};
export type SendItinerarySmsRequest = {
  orderNo?: string | null;
  travellerTacNo?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumbers?: ItinerarySms[] | null;
};
export type ItineraryMail = {
  mail: string;
  language: string;
};
export type SendItineraryMailRequest = {
  orderNo?: string | null;
  travellerTacNo?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  mails?: ItineraryMail[] | null;
};
export type ItineraryReceiptRequest = {
  orderNumber: string;
  firstName: string;
  lastName: string;
};
