import { Box, Container } from '@mui/material';
import Header from 'components/features/header/Header';
import MainMenu from 'components/features/header/MainMenu';
import ProfileMenu from 'components/features/header/profileMenu/ProfileMenu';
import BreadcrumbList from 'components/features/breadcrumbs/BreadcrumbList';

export interface StandardHeaderProps {
  amountOfBreadcrumbs?: number;
  isLoading?: boolean;
  finalCrumbLabel?: string;
  showBreadcrumbs?: boolean;
}

/**
 * @param amountOfBreadcrumbs - number of breadcrumbs to show
 * @param finalCrumbLabel - label for the final breadcrumb
 * @param isLoading - Will show a loading effect on the final crumb if true
 */
const StandardHeader = ({
  amountOfBreadcrumbs,
  isLoading,
  finalCrumbLabel,
  showBreadcrumbs = true,
}: StandardHeaderProps) => {
  return (
    <Box component='nav'>
      <Header />
      <MainMenu />
      <ProfileMenu />
      <Container>
        {showBreadcrumbs && (
          <BreadcrumbList
            finalCrumbLabel={finalCrumbLabel}
            amountOfBreadcrumbs={amountOfBreadcrumbs}
            isLoading={isLoading}
          />
        )}
      </Container>
    </Box>
  );
};

export default StandardHeader;
