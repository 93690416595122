import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { TextCodes } from 'hooks/texts/text.codes';
import { useTranslation } from 'react-i18next';
import {
  setShowNotImplementedModal,
  showNotImplementedModal,
} from 'store/app/app.slice';
import { useAppDispatch, useAppSelector } from 'store/store';

const NotImplementedModal = () => {
  const { t } = useTranslation();
  const open = useAppSelector(showNotImplementedModal);
  const dispatch = useAppDispatch();

  return (
    <Dialog open={open} onClose={() => {}}>
      <DialogContent sx={{ px: 3, pb: 1, pt: 2 }}>
        {t(TextCodes.WG6.Generic.FeatureNotImplemented)}
      </DialogContent>
      <DialogActions>
        <Button
          variant='text'
          size='medium'
          onClick={() => {
            dispatch(setShowNotImplementedModal(false));
          }}
        >
          {t(TextCodes.WG6.Navigation.Close)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotImplementedModal;

interface NotImplementedButtonProps {
  children: React.ReactNode;
}

export const NotImplementedButton = ({
  children,
}: NotImplementedButtonProps) => {
  const dispatch = useAppDispatch();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(setShowNotImplementedModal(true));
  };

  return (
    <Box position='relative'>
      <Box
        position={'absolute'}
        top={0}
        left={0}
        width={'100%'}
        height={'100%'}
        zIndex={2}
        onClick={handleClick}
      ></Box>
      {children}
    </Box>
  );
};
