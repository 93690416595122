import { PaletteOptions } from '@mui/material';
import { opacity } from 'theme/theme.utils';

export const lightColorPalette: PaletteOptions = {
  mode: 'light',
  background: {
    default: '#F3F3F3',
    paper: '#FFFFFF',
  },
  backgroundColors: {
    default: '#F3F3F3',
    paper01: '#FFFFFF',
    paper02: '#F7F7F7',
    selectedCard: '#F4F6FD',
  },
  common: {
    black: '#000000',
    white: '#ffffff',
  },
  grey: {
    '50': '#FAFAFA',
    '100': '#F5F5F5',
    '200': '#EEEEEE',
    '300': '#E0E0E0',
    '400': '#BDBDBD',
    '500': '#9E9E9E',
    '600': '#757575',
    '700': '#616161',
    '800': '#424242',
    '900': '#212121',
    A100: '#D5D5D5',
    A200: '#AAAAAA',
    A400: '#616161',
    A700: '#303030',
  },
  divider: `#000000${opacity[12]}`,
  action: {
    active: `#000000${opacity[54]}`,
    hover: `#000000${opacity[4]}`,
    selected: `#000000${opacity[8]}`,
    disabled: `#000000${opacity[26]}`,
    disabledBackground: `#000000${opacity[12]}`,
    focus: `#000000${opacity[12]}`,
  },
  other: {
    divider: `#000000${opacity[12]}`,
    outlineBorder: `#000000${opacity[23]}`,
    standardInputLine: `#000000${opacity[42]}`,
    backdropOverlay: `#000000${opacity[50]}`,
    ratingActive: '#FFB400',
    snackbarBackground: '#323232',
  },
  primary: {
    main: '#3057E4',
    light: '#768FE8',
    dark: '#112E96',
    contrastText: '#ffffff',
    containedHover: '#2343B8',
    outlinedHover: `#3057E4${opacity[10]}`,
    outlinedBorder: `#3057E4${opacity[50]}`,
  },
  secondary: {
    main: '#68D8EA',
    light: '#B0E6EE',
    dark: '#51AAB8',
    contrastText: '#000000',
    containedHover: '#B6F5FF',
    outlinedHover: `#68D8EA${opacity[10]}`,
    outlinedBorder: `#68D8EA${opacity[50]}`,
  },
  error: {
    main: '#B40516',
    light: '#C1404D',
    dark: '#820411',
    background: '#F8E7E7',
    textColor: '#480209',
    contrastText: '#ffffff',
    containedHover: '#7E040F',
    outlinedHover: `#B40516${opacity[8]}`,
    outlinedBorder: `#B40516${opacity[50]}`,
  },
  info: {
    main: '#2094D5',
    light: '#64B6F7',
    dark: '#0B79B8',
    background: '#E9F5FE',
    textColor: `#0D3C${opacity[38]}`,
    contrastText: '#ffffff',
    containedHover: '#1769AA',
    outlinedHover: `#2196F3${opacity[8]}`,
    outlinedBorder: `#2196F3${opacity[50]}`,
  },
  warning: {
    main: '#ED6C02',
    light: '#FFB547',
    dark: '#C77700',
    background: '#FDF0E6',
    textColor: '#5F2B01',
    contrastText: '#ffffff',
    containedHover: '#A64C01',
    outlinedHover: `#ED6C02${opacity[8]}`,
    outlinedBorder: `#ED6C02${opacity[50]}`,
  },
  success: {
    main: '#399C3D',
    light: '#3D9134',
    dark: '#225C24',
    background: '#E1F4D9',
    textColor: '#083103',
    contrastText: '#ffffff',
    containedHover: '#0D5605',
    outlinedHover: `#137B07${opacity[8]}`,
    outlinedBorder: `#137B07${opacity[50]}`,
  },
  text: {
    primary: `#000000${opacity[87]}`,
    secondary: `#000000${opacity[60]}`,
    disabled: `#000000${opacity[38]}`,
  },
};

export const darkColorPalette: PaletteOptions = {
  mode: 'dark',
  background: {
    default: '#1A2033',
    paper: '#212840',
  },
  backgroundColors: {
    default: '#1A2033',
    paper01: '#212840',
    paper02: '#242C49',
    selectedCard: '#2F4D64',
  },
  common: {
    black: '#000000',
    white: '#ffffff',
  },
  grey: {
    '50': '#FAFAFA',
    '100': '#F5F5F5',
    '200': '#EEEEEE',
    '300': '#E0E0E0',
    '400': '#BDBDBD',
    '500': '#9E9E9E',
    '600': '#757575',
    '700': '#616161',
    '800': '#424242',
    '900': '#212121',
    A100: '#D5D5D5',
    A200: '#AAAAAA',
    A400: '#616161',
    A700: '#303030',
  },
  action: {
    active: '#FFFFFF',
    hover: `#FFFFFF${opacity[8]}`,
    selected: `#FFFFFF${opacity[16]}`,
    disabled: `#FFFFFF${opacity[30]}`,
    disabledBackground: `#FFFFFF${opacity[12]}`,
    focus: `#FFFFFF${opacity[12]}`,
  },
  other: {
    divider: `#FFFFFF${opacity[12]}`,
    outlineBorder: `#FFFFFF${opacity[23]}`,
    standardInputLine: `#FFFFFF${opacity[42]}`,
    backdropOverlay: `#FFFFFF${opacity[50]}`,
    ratingActive: '#FAAF00',
    snackbarBackground: '#FAFAFA',
  },
  primary: {
    main: '#68D8EA',
    light: '#B0E6EE',
    dark: '#51AAB8',
    contrastText: '#000000',
    containedHover: '#B6F5FF',
    outlinedHover: `#68D8EA${opacity[10]}`,
    outlinedBorder: `#68D8EA${opacity[50]}`,
  },
  secondary: {
    main: '#3057E4',
    light: '#768FE8',
    dark: '#112E96 ',
    contrastText: '#FFFFFF',
    containedHover: '#2343B8',
    outlinedHover: `#3057E4${opacity[10]}`,
    outlinedBorder: `#3057E4${opacity[50]}`,
  },
  error: {
    main: '#FF5A6A',
    dark: '#B32D3A',
    light: '#FF7381',
    contrastText: '#FFFFFF',
    textColor: '#FFEFF0',
    background: '#211010',
    containedHover: '#7D1F29',
    outlinedHover: `#FF5A6A${opacity[20]}`,
    outlinedBorder: `#FF5A6A${opacity[50]}`,
  },
  info: {
    main: '#29B6F6',
    dark: '#0288D1',
    light: '#4FC3F7',
    textColor: '#E9F5FE',
    background: '#0A1323',
    contrastText: '#ffffff',
    containedHover: '#1D7FAC',
    outlinedHover: `#29B6F6${opacity[20]}`,
    outlinedBorder: `#29B6F6${opacity[50]}`,
  },
  warning: {
    main: '#FFA726',
    dark: '#F57C00',
    light: '#FFB74D',
    textColor: '#F0ECE7',
    background: '#1F2012',
    containedHover: '#AC5700',
    outlinedHover: `#FFA726${opacity[20]}`,
    outlinedBorder: `#FFA726${opacity[50]}`,
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#4CAF50',
    dark: '#225C24',
    light: '#3D9134',
    contrastText: '#FFFFFF',
    textColor: '#E1F4D9',
    background: '#0C130C',
    containedHover: '#0D5605',
    outlinedHover: `#137B07${opacity[20]}`,
    outlinedBorder: `#137B07${opacity[50]}`,
  },
  text: {
    primary: '#FFFFFF',
    secondary: `#FFFFFF${opacity[17]}`,
    disabled: `#FFFFFF${opacity[50]}`,
  },
};
