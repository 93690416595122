import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextInput from 'components/common/inputs/textInput/TextInput';
import { DictionaryInput } from 'components/features/dropdowns/DictionaryInput';
import { TextCodes } from 'hooks';

const FlightAndHotelPreferences = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography component='h2' variant='body2' fontWeight='bold'>
        {t(TextCodes.WG6.Preferences.FlightAndHotel)}
      </Typography>
      <DictionaryInput
        name='airSeatCode'
        dictionary='planeSeats'
        label={t(TextCodes.WG6.Preferences.SeatType)}
        fullWidth
        sx={{ mb: 0 }}
      />
      <TextInput
        name='airSeatNumber'
        label={t(TextCodes.WG6.Preferences.SeatNumber)}
        fullWidth
        sx={{ mb: 0 }}
      />
      <DictionaryInput
        name='airMealCode'
        dictionary='planeMeals'
        label={t(TextCodes.WG6.Preferences.MealType)}
        fullWidth
        sx={{ mb: 0 }}
      />
      <DictionaryInput
        name='hotelRoomSmoking'
        dictionary='hotelSmokeTypes'
        label={t(TextCodes.WG6.Preferences.RoomType)}
        fullWidth
        sx={{ mb: 0 }}
      />
    </>
  );
};

export default FlightAndHotelPreferences;
