import { Theme } from '@mui/material/styles/createTheme';

export enum ThemeIds {
  Light,
  Dark,
  DefaultLight,
  DefaultDark
}

export type ThemeConfig = {
  value: ThemeIds;
  title: string;
  right?: string;
  theme: Theme;
};

