import { useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

// TODO: maybe in the future we can allow custom variants for more animation types.

/**
 * @param {string} listName - The name of the field array.
 *
 * @remarks
 * Do not forget to include the variants, itemControls and custom prop, or else the animations will not work.
 *
 * @example
 *
 * const {fields, append, remove, itemControls, variants} = useAnimatedFieldArray("yourArrayName")
 *
 * // Example custom handler. You can use remove directly if you do not want to perform any side actions.
 * const handleDelete = (index: number) => {
 * //
 * // Some logic you want to do before removing the item.
 * //
 *  remove(number)
 * }
 *
 * return (
 * <>
 * {fields?.map((f,i) => (
 * <Box
 * component={motion.div} // can be any motion component
 * variants={variants} // Don't forget these!
 * animate={itemControls} // Controls the animations.
 * custom={i} // Should always be the index of the item
 * key={f.id}
 * >
 * <h1>Hello number {i}</h1>
 * <Button onClick={() =>
 * handleDelete(i)}>Delete</Button>
 * </Box>
 * ))}
 * </>
 * )
 */
const useAnimatedFieldArray = (listName: string) => {
  const animation = useAnimation();
  const { control, setValue, formState } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: listName,
  });
  // Keeps track of if we should animate the last item in the list.
  const [previousLength, setPreviousLength] = useState(fields.length);
  // Animations
  const variants = {
    animate: {
      opacity: [0, 0.5, 1],
      y: [-20, 0],
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: [1, 0.5, 0],
      y: [0, -10, 20],
      transition: {
        duration: 0.3,
      },
    },
  };

  const addItem = (item: any) => {
    setPreviousLength(fields.length);
    append(item);
  };

  // This ensures that the last item only gets animated if there was a new one added.
  useEffect(() => {
    if (fields.length > previousLength) {
      animation.start((currentIndex) => {
        if (fields.length - 1 === currentIndex) return 'animate';
        return { opacity: 1 };
      });
    }
  }, [fields.length]);

  const removeItem = async (index: number) => {
    await animation.start((currentIndex) => {
      if (index === currentIndex) return 'exit';
      return { opacity: 1 };
    });
    setPreviousLength(fields.length);
    remove(index);
  };

  return {
    fields,
    append: addItem,
    remove: removeItem,
    itemControls: animation,
    variants,
    setValue,
    formState,
  };
};

export default useAnimatedFieldArray;
