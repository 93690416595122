import { Box } from '@mui/material';
import StandardHeader from 'components/common/headers/StandardHeader';
import { ContactDetailsForm } from 'components/features/user/personalInformation/ContactDetailsForm';

const ContactDetailsPage = () => (
  <Box sx={{ mt: 7 }} aria-live='polite'>
    <StandardHeader showBreadcrumbs={false} />
    <ContactDetailsForm />
  </Box>
);

export default ContactDetailsPage;
