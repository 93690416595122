import { Accordion, AccordionSummary, Box, Skeleton } from '@mui/material';

const AccordionSkeleton = () => {
  return (
    <Accordion expanded={false}>
      <AccordionSummary>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Skeleton width={150} />
          <Skeleton width={20} />
        </Box>
      </AccordionSummary>
    </Accordion>
  );
};

export default AccordionSkeleton;
