import { Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/common/pageTitle/PageTitle';
import StandardHeader from 'components/common/headers/StandardHeader';
import { TextCodes } from 'hooks';
import { UserFamily } from 'components/features/user/UserFamily';

const FamilyAndFriendsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <StandardHeader />
      <Container>
        <PageTitle title={t(TextCodes.WG6.FamilyAndFriends.Title)} />
        <Grid aria-live='polite'>
          <UserFamily />
        </Grid>
      </Container>
    </>
  );
};

export default FamilyAndFriendsPage;
