import { Stack, Skeleton, Box, useTheme } from '@mui/material';
import HeaderCard from 'components/common/containers/HeaderCard';
import { FC } from 'react';

const OrderCardSkeleton = () => {
  const theme = useTheme();

  return (
    <HeaderCard
      headerContent={
        <Skeleton sx={{ ml: 'auto' }} variant='text' width='35%' />
      }
    >
      <Stack sx={{ p: 2, pb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
            <Skeleton
              sx={{ mr: 0.5 }}
              variant='circular'
              width={20}
              height={20}
            />
            <Skeleton
              sx={{ mr: 0.5 }}
              variant='circular'
              width={20}
              height={20}
            />
            <Skeleton
              sx={{ mr: 0.5 }}
              variant='circular'
              width={20}
              height={20}
            />
          </Box>
          <Box>
            <Skeleton variant='text' width={40} height={20} />
          </Box>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Skeleton variant='text' width='30%' />
          <Skeleton variant='text' width='40%' />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            backgroundColor: theme.palette.background.default,
            px: 1,
          }}
        >
          <Skeleton variant='text' sx={{ width: '30%' }} />
          <Skeleton variant='text' sx={{ width: '30%' }} />
          <Skeleton variant='text' sx={{ width: '30%' }} />
        </Box>
      </Stack>
    </HeaderCard>
  );
};

export const OrderCardSkeletonList: FC<{ amount: number }> = ({ amount }) => {
  const cards = Array.from(Array(amount).keys());

  return (
    <>
      {cards.map((number) => (
        <Box sx={{ mb: 2 }} key={number}>
          <OrderCardSkeleton />
        </Box>
      ))}
    </>
  );
};

export default OrderCardSkeleton;
