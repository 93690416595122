import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PlainCard } from 'components/common/containers/Card';
import FormPanel from 'components/common/forms/FormPanel';
import TextInput from 'components/common/inputs/textInput/TextInput';
import yup from 'components/validators/yup';
import useSiteNavigation from 'hooks/navigation/useSiteNavigation';
import { TextCodes } from 'hooks/texts/text.codes';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { routeConfig } from 'routes/config/route.config';
import { userApi } from 'services/api/user';
import { useAppSelector } from 'store/store';
import { getProfile } from 'store/user/user.slice';

type FormData = {
  username: string;
};

const UsernameForm = () => {
  // Hooks 🎣
  const { t } = useTranslation();
  const navigate = useSiteNavigation();

  // Api⚡
  const [updateProfile, { isLoading, data }] =
    userApi.useUserPersonalDataSaveMutation();

  // Selectors 🤏
  const profile = useAppSelector(getProfile);

  // Form 📝
  const formMethods = useForm<FormData>({
    resolver: yupResolver(
      yup.object().shape({
        username: yup
          .string()
          .required(TextCodes.WG6.Validation.Required)
          .min(5, TextCodes.WG6.BasicInformation.UsernameHelperText),
      })
    ),
    defaultValues: {
      username: profile.userName!,
    },
  });

  const onSubmit = (data: FormData) => {
    // We don't have an endpoint for username, so we just use the one for basicProfile
    const payload = {
      userName: data.username,
      dateOfBirth: profile.dateOfBirth,
      gender: profile.gender,
    };
    updateProfile({ saveUserPersonalDataDto: payload });
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate(routeConfig.User.path, {
        replace: true,
        scrollToTop: true,
        toast: {
          severity: 'success',
          message: t(TextCodes.WG6.Status.ChangesSavedSuccessfully),
        },
      });
    }
  }, [data]);

  return (
    <PlainCard>
      <FormProvider {...formMethods}>
        <Stack component='form' gap={2} sx={{ width: '100%' }}>
          <Typography variant='body2'>
            {t(TextCodes.WG6.User.UsernameDescription)}
          </Typography>
          <TextInput
            sx={{ mb: 0 }}
            name='username'
            label={t(TextCodes.WG6.Profile.Username)}
          />
          <FormPanel
            isLoading={isLoading}
            onSubmit={formMethods.handleSubmit(onSubmit)}
            shouldShowCancelWarning={formMethods.formState.isDirty}
            errorMessage={data?.message || ''}
          />
        </Stack>
      </FormProvider>
    </PlainCard>
  );
};

export default UsernameForm;
