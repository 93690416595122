import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import HotelIcon from '@mui/icons-material/Hotel';
import Typography from '@mui/material/Typography';
import { capitalize, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import HeaderCard, {
  HeaderCardReference,
} from 'components/common/containers/HeaderCard';
import LocationDetails from 'components/common/location/LocationDetails';
import OrderDetailsCardHeader from 'components/features/orderDetails/OrderDetailsCardHeader';
import OrderDetailsStatusBar from 'components/features/orderDetails/OrderDetailsStatusBar';
import PriceDetails from 'components/common/price/PriceDetails';
import { DropdownMenuOptions } from 'components/common/dropdownMenu/DropdownMenu';
import { ItineraryInfo } from 'services/api/itinerary';
import { TextCodes } from 'hooks';
import { dayOfMonth, timeOfDate } from 'services/text/datetime-format';
import { setShowNotImplementedModal } from 'store/app/app.slice';
import { useAppDispatch } from 'store/store';

const HotelDetails = ({
  address,
  cancellationPolicy,
  checkInDate,
  checkOutDate,
  delete: cancel,
  edit,
  hotelCoordinates,
  name,
  nights,
  phoneNo,
  price,
  reference,
  roomType,
  statusCode,
  statusText,
}: ItineraryInfo) => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const options: DropdownMenuOptions[] = [
    {
      label: !!edit
        ? TextCodes.WG6.Hotel.EditHotel
        : TextCodes.WG6.Base.EditNotPossible,
      disabled: isEmpty(edit),
      action: () => {
        dispatch(setShowNotImplementedModal(true));
      },
    },
    {
      label: !!cancel
        ? TextCodes.WG6.Hotel.CancelHotel
        : TextCodes.WG6.Base.CancelNotPossible,
      disabled: isEmpty(cancel),
      action: () => dispatch(setShowNotImplementedModal(true)),
    },
  ];

  // Formatted data
  const nightsToStay = `${nights!!} ${t(TextCodes.WG6.Hotel.Nights)}`;
  const details: string[] = [
    nightsToStay,
    roomType!!,
    `${t(TextCodes.WG6.Hotel.CheckInFrom)} ${timeOfDate(checkInDate)}`,
    `${t(TextCodes.WG6.Hotel.CheckOutBefore)} ${timeOfDate(checkOutDate)}`,
  ];

  return (
    <HeaderCard
      sx={{ width: '100%' }}
      headerContent={<HeaderCardReference reference={reference} />}
    >
      <Grid
        container
        component={Container}
        paddingTop={2}
        paddingBottom={2}
        gap={2}
      >
        <OrderDetailsCardHeader
          options={options}
          id={name!!}
          icon={<HotelIcon />}
          content={
            <Typography variant='body1' component='h2' fontWeight='bold'>
              {capitalize(name!!)}
            </Typography>
          }
        />
        <OrderDetailsStatusBar
          statusCode={statusCode!!}
          statusText={statusText!!}
          additionalInfo={nightsToStay}
        />
        <Grid container gap={2}>
          <LocationDetails
            address={address!!}
            phoneNumber={phoneNo!!}
            coords={hotelCoordinates!!}
            headerText={
              !isEmpty(checkInDate) && !isEmpty(checkOutDate)
                ? `${dayOfMonth(checkInDate!!)} - ${dayOfMonth(checkOutDate!!)}`
                : ''
            }
          />
          {!isEmpty(cancellationPolicy) && (
            <Grid container gap={1}>
              <Typography component='h3' fontWeight='bold' variant='body2'>
                {t(TextCodes.WG6.Orders.CancellationPolicy)}
              </Typography>
              <Typography variant='body2'>{cancellationPolicy}</Typography>
            </Grid>
          )}
          {!isEmpty(details) && (
            <Grid container gap={1}>
              <Typography component='h3' fontWeight='bold' variant='body2'>
                {t(TextCodes.WG6.OrderDetails.SelectedAccessories)}
              </Typography>
              <Grid
                container
                direction='row'
                flexWrap='wrap'
                rowSpacing={0}
                columnSpacing={1}
                component='ul'
              >
                {details.map((detail, i) => (
                  <Grid item key={i} component='li'>
                    <Typography variant='body2' sx={{ fontSize: 14 }}>
                      {i === 0 ? detail : detail.toLowerCase()}
                      {i !== details.length - 1 ? ',' : ''}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
          <Button
            sx={{ mr: 'auto', pl: 2 }}
            endIcon={<ArrowForwardIcon />}
            variant='text'
            size='small'
            onClick={() => dispatch(setShowNotImplementedModal(true))}
          >
            {roomType}
          </Button>
          <Grid item xs={12}>
            <PriceDetails
              currency={price?.currencyCode!!}
              price={price?.amount!!}
              disclaimer={t(TextCodes.WG6.Hotel.Disclaimer)}
              text={t(TextCodes.WG6.Hotel.TotalPrice)}
            >
              <Box display='flex' justifyContent='space-between'>
                <Typography variant='caption'>23/10/22</Typography>
                <Box display='flex' gap={1} alignItems='center'>
                  <Typography variant='body2' fontWeight='bold'>
                    2230
                  </Typography>
                  <Typography variant='caption'>NOK</Typography>
                </Box>
              </Box>
              <Divider sx={{ width: '100%', borderStyle: 'dashed' }} />
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography variant='caption' fontWeight='bold'>
                  {t(TextCodes.WG6.Generic.Total)}
                </Typography>
                <Box display='flex' gap={1} alignItems='center'>
                  <Typography fontWeight='bold' variant='body2'>
                    {price?.amount!!}
                  </Typography>
                  <Typography variant='body2'>
                    {price?.currencyCode!!}
                  </Typography>
                </Box>
              </Box>
            </PriceDetails>
          </Grid>
        </Grid>
      </Grid>
    </HeaderCard>
  );
};

export default HotelDetails;
