import { useRef, useState } from 'react';
import { TextField, Tooltip } from '@mui/material';
import { TextCodes } from 'hooks/texts/text.codes';
import { useFormContext, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useEventListener from 'hooks/event/useEventListener';
import PasswordVisibilityIcon from 'components/common/inputs/password/PasswordVisibilityIcon';
import { TextInputProps } from 'components/common/inputs/textInput/TextInput';
import ClearInputIcon from 'components/common/inputs/ClearInputIcon';

const PasswordInput = ({ name, sx, ...rest }: TextInputProps) => {
  // Hooks 🎣
  const { t } = useTranslation();

  // Local state 🏠
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [capsLock, setCapsLock] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);

  // Form ✍️
  const { register, formState, resetField, watch } = useFormContext();
  const error = get(formState.errors, name);
  const value = watch(name);

  // Listen for caps lock toggle
  useEventListener(
    ['keydown', 'click'],
    (e: KeyboardEvent) => {
      if (e?.getModifierState) setCapsLock(e?.getModifierState('CapsLock'));
    },
    ref.current
  );

  return (
    <Tooltip
      title={t(TextCodes.WG6.Validation.CapsLockWarning)}
      open={capsLock && focus}
      arrow
      placement='bottom-start'
    >
      <TextField
        type={showPassword ? 'text' : 'password'}
        inputRef={ref}
        {...rest}
        sx={{ mb: 2, ...sx }}
        {...register(name)}
        error={error && true}
        helperText={t(error?.message)}
        aria-errormessage={t(error?.message)}
        focused={focus}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        InputProps={{
          endAdornment: (
            <>
              <ClearInputIcon
                isVisible={value && (focus || hover)}
                onClick={() => {
                  resetField(name);
                  ref?.current?.focus();
                }}
              />
              <PasswordVisibilityIcon
                showPassword={showPassword}
                onClick={() => setShowPassword(!showPassword)}
              />
            </>
          ),
        }}
      />
    </Tooltip>
  );
};

export default PasswordInput;
