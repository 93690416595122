import { Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Document } from 'services/api/user';
import { TextCodes, useDateFormatting } from 'hooks';
import { getDictionary } from 'store/user/user.slice';
import { useAppSelector } from 'store/store';

type DocumentsListProps = {
  list?: Document[] | null;
  /**
   * Display's a message saying nation id card is not supported
   */
  isNationIdCard?: boolean;
};

const DocumentsList = ({
  list,
  isNationIdCard = false,
}: DocumentsListProps) => {
  const { t } = useTranslation();
  const { toShortDate } = useDateFormatting();
  const countries = useAppSelector(getDictionary('countries'));

  const isEmpty = !list || list.length === 0;

  const getCountry = (countryCode: string) =>
    countries.map((country) =>
      countryCode === country.value ? country.text : ''
    );

  return (
    <Stack spacing={2} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
      {isEmpty && !isNationIdCard && (
        <Typography variant='body2' color='text.secondary'>
          {t(TextCodes.WG6.Documents.EmptyList)}
        </Typography>
      )}

      {!isNationIdCard &&
        list?.map((document) => {
          const expirationDate = new Date(document.expiryDate || '');
          const isExpired = expirationDate < new Date();
          const color = isExpired ? 'warning.main' : 'text.primary';

          return (
            <Typography
              key={document.number}
              paragraph
              variant='body2'
              sx={{ color, mb: 0 }}
            >
              {document.number}, {toShortDate(document.expiryDate)},{' '}
              {document.countryCode && getCountry(document.countryCode)}{' '}
              <Typography variant='body2' fontWeight={700}>
                {isExpired && t(TextCodes.WG6.Documents.Expired)}
              </Typography>
            </Typography>
          );
        })}

      {isNationIdCard && (
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{ wordBreak: 'break-all' }}
        >
          {t(TextCodes.WG6.Documents.NationalIdCardNotSupported)}
        </Typography>
      )}
    </Stack>
  );
};

export default DocumentsList;
