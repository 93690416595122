import { Container } from '@mui/system';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DocumentsList from 'components/features/user/documents/DocumentsList';
import PageTitle from 'components/common/pageTitle/PageTitle';
import StandardHeader from 'components/common/headers/StandardHeader';
import { EditableCard } from 'components/common';
import { NotImplementedButton } from 'components/common/not-implemented/NotImplementedModal';
import { TextCodes } from 'hooks';
import {
  getVisas,
  getPassports,
  getIsReadOnlyProfile,
} from 'store/user/user.slice';
import { setShowNotImplementedModal } from 'store/app/app.slice';
import { useAppDispatch, useAppSelector } from 'store/store';

const DocumentsPage = () => {
  // Hooks 🎣
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Selectors 🤏
  const passports = useAppSelector(getPassports);
  const visas = useAppSelector(getVisas);
  const isReadOnly = useAppSelector(getIsReadOnlyProfile);

  return (
    <>
      <StandardHeader />
      <Container aria-live='polite'>
        <PageTitle title={t(TextCodes.WG6.User.DocumentsTitle)} />

        <Stack spacing={2}>
          <EditableCard
            isEditable={!isReadOnly}
            routeId='Passport'
            title={t(TextCodes.WG6.Passport.Title)}
          >
            <DocumentsList list={passports} />
          </EditableCard>
          <EditableCard
            isEditable={!isReadOnly}
            routeId='UserVisa'
            title={t(TextCodes.WG6.Visa.Title)}
          >
            <DocumentsList list={visas} />
          </EditableCard>
          <NotImplementedButton>
            <EditableCard
              isEditable={!isReadOnly}
              // routeId='UserId'
              editAction={() => dispatch(setShowNotImplementedModal(true))}
              title={t(TextCodes.WG6.NationalId.Title)}
            >
              <DocumentsList isNationIdCard />
            </EditableCard>
          </NotImplementedButton>
        </Stack>
      </Container>
    </>
  );
};

export default DocumentsPage;
