import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import {
  Payments,
  userApi,
  UserPaymentsCreditCard,
  UserPaymentsInvoice,
} from 'services/api/user';
import { RootState } from 'store';

export type PaymentOption = {
  displayText?: string;
  expiration?: Date | null;
  number?: string;
  code?: string;
  type: 'invoice' | 'creditCard';
  isLodge?: boolean;
};

export type PaymentState = {
  invoices: UserPaymentsInvoice[];
  creditCards: UserPaymentsCreditCard[];
  paymentMethods: PaymentOption[];
  paymentPreferences: Payments;
  isLoadingPayments: boolean;
  didLoadInitialData: boolean;
};

const initialState: PaymentState = {
  invoices: [],
  creditCards: [],
  paymentMethods: [],
  paymentPreferences: {
    businessTripsCardCode: '',
    hotelsAndCarsCardCode: '',
    privateTravelsCardCode: '',
    methodOfPayment: '',
  },
  isLoadingPayments: false,
  didLoadInitialData: false,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setInvoices: (state, action: PayloadAction<UserPaymentsInvoice[]>) => {
      state.invoices = action.payload;
    },
    setCreditCards: (
      state,
      action: PayloadAction<UserPaymentsCreditCard[]>
    ) => {
      state.creditCards = action.payload;
    },
    setIsLoadingPayments: (state, action: PayloadAction<boolean>) => {
      state.isLoadingPayments = action.payload;
    },
    setDidLoadInitialPaymentData: (state, action: PayloadAction<boolean>) => {
      state.didLoadInitialData = action.payload;
    },
    resetPayments: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.userPaymentMethods.matchFulfilled,
      (state, { payload }) => {
        const paymentMethods = mergePaymentMethods(
          payload.invoices,
          payload.creditCards
        );

        const paymentPreferences = setPaymentPreferences(
          payload.invoices,
          payload.creditCards
        );

        state.paymentMethods = paymentMethods;
        state.paymentPreferences = paymentPreferences;
        state.isLoadingPayments = false;
      }
    );
    builder.addMatcher(
      userApi.endpoints.userPaymentAndCreditCardsSettings.matchFulfilled,
      (state, { payload }) => {
        if (payload.creditCards) state.creditCards = payload.creditCards;

        if (payload.invoices) state.invoices = payload.invoices;

        state.paymentMethods = mergePaymentMethods(
          payload.invoices,
          payload.creditCards
        );

        state.paymentPreferences = setPaymentPreferences(
          payload.invoices,
          payload.creditCards
        );
      }
    );
  },
});
export default paymentSlice.reducer;

// ACTIONS
export const {
  setInvoices,
  setCreditCards,
  setIsLoadingPayments,
  setDidLoadInitialPaymentData,
  resetPayments,
} = paymentSlice.actions;

// SELECTORs

export const getInvoices = (state: RootState) => state.payment.invoices;

export const getCreditCards = (state: RootState) => state.payment.creditCards;

export const getDidLoadInitialPaymentData = (state: RootState) =>
  state.payment.didLoadInitialData;

export const getIsLoadingPayments = (state: RootState) =>
  state.payment.isLoadingPayments;

export const getPaymentMethods = (state: RootState) =>
  state.payment.paymentMethods;

export const getPaymentPreferences = (state: RootState) =>
  state.payment.paymentPreferences;

/*  
🤝 Helper methods 🤝
*/
const mergePaymentMethods = (
  invoices?: UserPaymentsInvoice[],
  creditCards?: UserPaymentsCreditCard[]
) => {
  let paymentOptions: PaymentOption[] = [];

  if (invoices) {
    paymentOptions = [
      ...paymentOptions,
      ...invoices.map((inv) => {
        return { ...inv, type: 'invoice' } as PaymentOption;
      }),
    ];
  }
  if (creditCards) {
    paymentOptions = [
      ...paymentOptions,
      ...creditCards.map((card) => {
        return { ...card, type: 'creditCard' } as PaymentOption;
      }),
    ];
  }

  return paymentOptions;
};

const setPaymentPreferences = (
  invoices?: UserPaymentsInvoice[],
  creditCards?: UserPaymentsCreditCard[]
) => {
  let paymentPreferences: Payments = {
    businessTripsCardCode: '',
    hotelsAndCarsCardCode: '',
    privateTravelsCardCode: '',
    methodOfPayment: '',
  };

  if (creditCards) {
    for (const card of creditCards) {
      if (card.isBusiness && !card.isLodge)
        paymentPreferences.businessTripsCardCode = card.number;

      if (card.isHotel) paymentPreferences.hotelsAndCarsCardCode = card.number;

      if (card.isPrivate)
        paymentPreferences.privateTravelsCardCode = card.number;
    }
  }

  // Invoices can only be business, so we can do a simple find
  if (invoices && paymentPreferences.businessTripsCardCode === '')
    paymentPreferences.businessTripsCardCode =
      invoices.find((invoice) => invoice.isDefault)?.code ?? '';

  // If we have no selected business card, and no invoice as default, we check if there's a lodge card.
  if (isEmpty(paymentPreferences.businessTripsCardCode))
    paymentPreferences.businessTripsCardCode =
      creditCards?.find((card) => card?.isBusiness)?.number ?? '';

  return paymentPreferences;
};
