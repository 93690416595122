import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { ToastItem } from 'store/toast/toast.slice';
import { useLayoutEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Button from '@mui/material/Button';
import { Severity } from 'components/common/messages/Alert';
import { useToast } from 'hooks/toast/toast.hooks';
import { TextCodes } from 'hooks/texts/text.codes';

export const ToastProvider = () => {
  const theme = useTheme();
  const { toastList, hideToast } = useToast();
  const currentToast: ToastItem = toastList[toastList.length - 1];

  useLayoutEffect(() => {
    if (currentToast) {
      setTimeout(
        () => hideToast(currentToast?.id),
        currentToast?.autoHideDuration
      );
    }
  }, [toastList]);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: { xs: 62, md: 80 },
        left: 0,
        width: '100%',
        minHeight: '48px',
        pointerEvents: currentToast?.isVisible ? 'auto' : 'none',
        alignItems: 'center',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Collapse
        in={currentToast?.isVisible}
        sx={{
          backgroundColor: theme.palette[currentToast?.severity]?.background,
          px: 2,
          '& .MuiCollapse-wrapper': {
            minHeight: '48px',
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <Grid container spacing={1.5}>
          <Grid display='flex' alignItems='center' item>
            <SeverityIcon severity={currentToast?.severity} />
          </Grid>
          <Grid
            display='flex'
            alignItems='center'
            item
            xs={7}
            sx={{ mr: 'auto' }}
          >
            <Typography
              variant='body2'
              color={theme.palette[currentToast?.severity]?.textColor}
            >
              {currentToast?.message}
            </Typography>
          </Grid>
          <Grid item display='flex' alignItems='center'>
            <CloseButton
              severity={currentToast?.severity}
              onClick={() => hideToast(currentToast?.id)}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};

const CloseButton = ({
  severity,
  onClick,
}: {
  severity: Severity;
  onClick: () => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick}
      type='button'
      size='small'
      sx={{
        ml: 'auto',
        color: theme.palette[severity]?.textColor,
      }}
      variant='text'
      endIcon={<CloseIcon height='11.67px' width='11.67px' />}
    >
      {t(TextCodes.WG6.Navigation.Close)}
    </Button>
  );
};

const SeverityIcon = ({ severity }: { severity: string }) => {
  const theme = useTheme();

  switch (severity) {
    case 'success':
      return (
        <CheckCircleOutlineIcon
          sx={{
            width: 18.33,
            height: 18.33,
            fill: theme.palette.success.main,
          }}
        />
      );
    case 'error':
      return (
        <ErrorOutlineIcon
          sx={{
            width: 18.33,
            height: 18.33,
            fill: theme.palette.error.main,
          }}
        />
      );
    case 'warning':
      return (
        <WarningAmberOutlinedIcon
          sx={{
            width: 22,
            height: 19,
            fill: theme.palette.warning.main,
          }}
        />
      );
    case 'info':
      return (
        <InfoOutlinedIcon
          sx={{
            width: 18.33,
            height: 18.33,
            fill: theme.palette.info.main,
          }}
        />
      );
    default:
      return (
        <InfoOutlinedIcon
          sx={{
            width: 18.33,
            height: 18.33,
            fill: theme.palette.info.main,
          }}
        />
      );
  }
};
