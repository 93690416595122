import { Container, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import BonusCardsList from 'components/features/user/benefitCardsAndPreferences/benefitCards/BonusCardsList';
import InfoButtonWithDialog from 'components/common/modals/InfoButtonWithDialog';
import PageTitle from 'components/common/pageTitle/PageTitle';
import useSiteNavigation from 'hooks/navigation/useSiteNavigation';
import { BonusCardsData, userApi } from 'services/api/user';
import { PlainCard } from 'components/common/containers/Card';
import { TextCodes } from 'hooks/texts/text.codes';
import { getBonusCards } from 'store/user/user.slice';
import { routeConfig } from 'routes/config/route.config';
import { useAppSelector } from 'store/store';
import yup from 'components/validators/yup';
import FormPanel from 'components/common/forms/FormPanel';

interface BonusCardsFormFields extends BonusCardsData {
  didDelete: boolean;
}

const groupDefaultValues = {
  companyCode: '',
  cardNumber: '',
};

const BonusCardsForm = () => {
  // Hooks
  const { t } = useTranslation();
  const navigate = useSiteNavigation();

  // Selectors
  const cards = useAppSelector(getBonusCards);

  // Form
  const formMethods = useForm<BonusCardsFormFields>({
    defaultValues: {
      airlineCards: cards?.airlineCards?.length
        ? cards.airlineCards
        : [groupDefaultValues],
      hotelCards: cards?.hotelCards?.length
        ? cards.hotelCards
        : [groupDefaultValues],
      carCards: cards?.carCards?.length ? cards.carCards : [groupDefaultValues],
      didDelete: false,
    },
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  });
  const didDelete = formMethods.watch('didDelete');

  // Api
  const [post, { data, isLoading }] = userApi.useUserBonusCardsSaveMutation();

  const onSubmit = (data: BonusCardsData) => {
    const formattedData: BonusCardsData = {
      airlineCards: data?.airlineCards?.filter((ac) => ac != null),
      hotelCards: data?.hotelCards?.filter((hc) => hc != null),
      carCards: data?.carCards?.filter((cc) => cc != null),
    };
    post({
      bonusCardsData: formattedData,
    });
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate(routeConfig.BenefitCardsAndPreferences.path, {
        replace: true,
        scrollToTop: true,
        toast: {
          severity: 'success',
          message: t(TextCodes.WG6.Status.ChangesSavedSuccessfully),
        },
      });
    }
  }, [data]);

  return (
    <Container>
      <PageTitle
        sx={{ mb: 1, mt: 7 }}
        title={t(TextCodes.WG6.BonusCards.Title)}
        button={
          <InfoButtonWithDialog popupText={t(TextCodes.WG6.BonusCards.Popup)} />
        }
      />
      <PlainCard>
        <FormProvider {...formMethods}>
          <Grid container component='form' gap={4}>
            <Grid container gap={2}>
              <BonusCardsList
                dictionaryLabel={t(TextCodes.WG6.BonusCards.AirLabel)}
                textFieldLabel={t(TextCodes.WG6.BonusCards.CardNumber)}
                addPluralButtonText={t(TextCodes.WG6.BonusCards.AddAirPlural)}
                addSingularButtonText={t(
                  TextCodes.WG6.BonusCards.AddAirSingular
                )}
                groupName='airlineCards'
                title={t(TextCodes.WG6.BonusCards.AirTitle)}
                dictionaryType='frequentFlyerAirlines'
              />
              <BonusCardsList
                dictionaryLabel={t(TextCodes.WG6.BonusCards.HotelLabel)}
                textFieldLabel={t(TextCodes.WG6.BonusCards.CardNumber)}
                addPluralButtonText={t(TextCodes.WG6.BonusCards.AddHotelPlural)}
                addSingularButtonText={t(
                  TextCodes.WG6.BonusCards.AddHotelSingular
                )}
                groupName='hotelCards'
                title={t(TextCodes.WG6.BonusCards.HotelLabel)}
                dictionaryType='frequentFlyerHotels'
              />
              <BonusCardsList
                dictionaryLabel={t(TextCodes.WG6.BonusCards.CarLabel)}
                textFieldLabel={t(TextCodes.WG6.BonusCards.CardNumber)}
                addPluralButtonText={t(TextCodes.WG6.BonusCards.AddCarPlural)}
                addSingularButtonText={t(
                  TextCodes.WG6.BonusCards.AddCarSingular
                )}
                groupName='carCards'
                title={t(TextCodes.WG6.BonusCards.CarTitle)}
                dictionaryType='frequentFlyerCars'
              />
            </Grid>
            <FormPanel
              isLoading={isLoading}
              errorMessage={data?.message || ''}
              onSubmit={formMethods.handleSubmit(onSubmit)}
              shouldShowCancelWarning={
                didDelete || formMethods.formState.isDirty
              }
              shouldShowSaveWarning={didDelete}
              hideDivider
            />
          </Grid>
        </FormProvider>
      </PlainCard>
    </Container>
  );
};

export default BonusCardsForm;

const validationSchema = yup.object().shape({
  airlineCards: yup.array().of(
    yup
      .object()
      .shape({
        companyCode: yup.string().required(TextCodes.WG6.Validation.Required),
        cardNumber: yup.string().required(TextCodes.WG6.Validation.Required),
      })
      .allowEmpty()
  ),
  hotelCards: yup.array().of(
    yup
      .object()
      .shape({
        companyCode: yup.string().required(TextCodes.WG6.Validation.Required),
        cardNumber: yup.string().required(TextCodes.WG6.Validation.Required),
      })
      .allowEmpty()
  ),
  carCards: yup.array().of(
    yup
      .object()
      .shape({
        companyCode: yup.string().required(TextCodes.WG6.Validation.Required),
        cardNumber: yup.string().required(TextCodes.WG6.Validation.Required),
      })
      .allowEmpty()
  ),
});
