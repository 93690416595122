import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormGroup,
  CheckboxProps as MuiCheckboxProps,
  FormHelperText,
  FormControl,
  Stack,
} from '@mui/material';

export interface CheckboxProps extends MuiCheckboxProps {
  name: string;
  defaultValue?: any;
  displayBlock?: boolean;
  error?: boolean;
  errorMessage?: string;
  label?: string;
  tooltipText?: string;
}

/**
 * https://mui.com/material-ui/react-checkbox/
 *
 * Customized checkbox from MUI
 */
export const Checkbox = ({
  name,
  onChange,
  onBlur,
  value,
  label = '',
  error,
  errorMessage,
  displayBlock,
  disabled,
  tooltipText,
  ...rest
}: CheckboxProps) => {
  const innerProps = { onChange, onBlur, name, value, disabled, ...rest };

  const checkbox = (
    <Stack direction='row' alignItems='center' sx={{ flexWrap: 'wrap' }}>
      <MuiCheckbox {...innerProps} checked={!!value} />
      {/* {tooltipText && <Tooltip title={tooltipText || ''} sx={{ float: 'right' }} />} */}
    </Stack>
  );

  return (
    <FormControl error={error} sx={{ display: displayBlock ? 'block' : '' }}>
      <FormGroup>
        <FormControlLabel sx={{ m: 0 }} control={checkbox} label={label} />
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormGroup>
    </FormControl>
  );
};

Checkbox.isInput = true;
