const DinersClub = () => {
  return (
    <svg width='34' height='24' viewBox='0 0 34 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='33' height='23' rx='3.5' fill='white' stroke='#D9D9D9' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.8382 19.3H18.4343C22.4868 19.3 25.716 16.085 25.8427 12.1135V12.0505C25.7794 8.01596 22.4868 4.7379 18.4343 4.80094H15.5216C11.6592 4.92702 8.61986 8.20508 8.74649 12.1135C8.74649 16.022 11.9125 19.237 15.8382 19.3ZM10.0129 12.0505C10.0129 8.77241 12.7356 6.06171 16.0282 6.06171C19.3208 6.06171 22.0435 8.77241 22.0435 12.0505C22.0435 15.3285 19.3208 18.0392 16.0282 18.0392C12.7356 18.0392 10.0129 15.3285 10.0129 12.0505ZM16.978 16.085V8.07898C19.1942 8.64634 20.5872 10.8527 20.0173 13.0591C19.6374 14.5721 18.4977 15.7068 16.978 16.085ZM12.1024 11.0418C11.5325 13.2482 12.8622 15.5177 15.0784 16.085V8.07898C13.6221 8.45722 12.4823 9.59193 12.1024 11.0418Z'
        fill='#0079BE'
      />
    </svg>
  );
};

export default DinersClub;
