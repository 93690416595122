import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

import NotRegistered from 'components/common/text/NotRegistered';
import TextBlock from 'components/common/text/TextBlock';
import { EditableCard } from 'components/common';
import { TextCodes } from 'hooks';
import {
  getIsReadOnlyProfile,
  getPreferenceValues,
} from 'store/user/user.slice';
import { useAppSelector } from 'store/store';

const PreferencesSection = () => {
  const values = useAppSelector(getPreferenceValues);
  const { t } = useTranslation();
  const isReadOnly = useAppSelector(getIsReadOnlyProfile);

  return (
    <EditableCard
      isEditable={!isReadOnly}
      routeId='Preferences'
      title={t(TextCodes.WG6.Preferences.Title)}
    >
      <Stack spacing={2} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
        {/* Seat preference */}
        <TextBlock removeDivider title={t(TextCodes.WG6.Preferences.SeatType)}>
          {values?.airSeatCode!! || <NotRegistered />}
        </TextBlock>

        {/* Seat number */}
        <TextBlock
          removeDivider
          title={t(TextCodes.WG6.Preferences.SeatNumber)}
        >
          {values?.airSeatNumber!! || <NotRegistered />}
        </TextBlock>

        {/* Meal type */}
        <TextBlock removeDivider title={t(TextCodes.WG6.Preferences.MealType)}>
          {values?.airMealCode!! || <NotRegistered />}
        </TextBlock>

        {/* Room type */}
        <TextBlock removeDivider title={t(TextCodes.WG6.Preferences.RoomType)}>
          {values?.hotelRoomSmoking!! || <NotRegistered />}
        </TextBlock>

        {/* Departure airport */}
        <TextBlock
          removeDivider
          title={t(TextCodes.WG6.Preferences.DepartureAirport)}
        >
          {values?.defaultDepartureCity!! || <NotRegistered />}
        </TextBlock>

        {/* Destination airport */}
        <TextBlock
          removeDivider
          title={t(TextCodes.WG6.Preferences.DestinationAirport)}
        >
          {values?.defaultDestinationCity!! || <NotRegistered />}
        </TextBlock>

        {/* Language */}
        <TextBlock removeDivider title={t(TextCodes.WG6.Preferences.Language)}>
          {values?.languageCode!! || <NotRegistered />}
        </TextBlock>

        {/* Currency */}
        <TextBlock removeDivider title={t(TextCodes.WG6.Preferences.Currency)}>
          {values?.currencyCode!! || <NotRegistered />}
        </TextBlock>
      </Stack>
    </EditableCard>
  );
};

export default PreferencesSection;
