import { startCase, toLower } from 'lodash';

/**
 * @param name String or array
 * @returns Returns a name with the first letters capitalized
 */
export const capitalizeName = (name: string | string[]): string => {
  if (typeof name === 'string') {
    return startCase(toLower(name));
  } else {
    const fullName = toLower(name.join(' ')); // Join all the array separated names with a space
    return startCase(fullName);
  }
};

/**
 *
 * @param string
 * @returns "A string with the first letter capitalized"
 */
export const capitalizeFirstLetterInAString = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);
