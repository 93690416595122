import { Link, useTheme } from '@mui/material';
import { useInternalRoutes } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

type BreadcrumbLinkProps = {
  breadcrumb?: string;
  to: string;
  isLast: boolean;
};

const BreadcrumbLink = ({ breadcrumb, to, isLast }: BreadcrumbLinkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { getRouteDataByPath } = useInternalRoutes();
  const data = getRouteDataByPath(to);
  const label = breadcrumb ? breadcrumb : t(data?.breadcrumb!!);

  const color = isLast
    ? theme.palette.text.primary
    : theme.palette.text.disabled;

  return (
    <Link
      component={RouterLink}
      to={to}
      sx={{
        color: color,
        p: 0,
        pointerEvents: isLast ? 'none' : 'all',
        ':visited': { color: color },
      }}
      variant='body2'
      underline='hover'
    >
      {label}
    </Link>
  );
};

export default BreadcrumbLink;
