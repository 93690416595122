import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PaymentPreferenceSelect from 'components/features/user/payment-settings/components/PaymentPreferenceSelect';
import { TextCodes } from 'hooks';
import { useTranslation } from 'react-i18next';

const PaymentPreferences = () => {
  // Hooks
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <Typography variant='body2' component='h2' fontWeight='bold'>
        {t(TextCodes.WG6.Payment.Method)}
      </Typography>
      <Stack gap={2}>
        <PaymentPreferenceSelect
          label={t(TextCodes.WG6.Payment.PaymentBusiness)}
          name='payments.businessTripsCardCode'
          type='business'
        />
        <PaymentPreferenceSelect
          label={t(TextCodes.WG6.Payment.PaymentPrivate)}
          name='payments.privateTravelsCardCode'
          type='private'
        />
        <PaymentPreferenceSelect
          label={t(TextCodes.WG6.Payment.PaymentRent)}
          name='payments.hotelsAndCarsCardCode'
          type='guarantee'
        />
      </Stack>
    </Stack>
  );
};

export default PaymentPreferences;
