import AMEX from 'components/common/icons/svg/AMEX';
import DinersClub from 'components/common/icons/svg/DinersClub';
import Mastercard from 'components/common/icons/svg/Mastercard';
import Visa from 'components/common/icons/svg/Visa';
import { BankCardLogoProps } from 'components/features/user/payment-settings/payment.types';
import Icon from '../components/common/icons/Icon';

const BankCardLogoIcon = ({ type }: BankCardLogoProps): JSX.Element => {
  switch (type) {
    case 'visa':
      return (
        <Icon width={34}>
          <Visa />
        </Icon>
      );
    case 'mastercard':
      return (
        <Icon width={34}>
          <Mastercard />
        </Icon>
      );
    case 'american-express':
      return (
        <Icon width={34}>
          <AMEX />
        </Icon>
      );
    case 'diners-club':
      return (
        <Icon width={34}>
          <DinersClub />
        </Icon>
      );
    default:
      return <span></span>;
  }
};

export default BankCardLogoIcon;
