import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useFormContext, useWatch, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProfileDictionaries } from 'services/api/user';
import { TextInputProps } from 'components/common/inputs/textInput/TextInput';
import { getDictionary } from 'store/user/user.slice';
import { useAppSelector } from 'store/store';

export type DictionaryInputProps = TextInputProps & {
  dictionary: keyof ProfileDictionaries;
};

export const DictionaryInput = ({
  name,
  label,
  dictionary,
  sx,
}: DictionaryInputProps) => {
  // Hooks 🎣
  const { t } = useTranslation();

  // Global store 🏪
  const items = useAppSelector(getDictionary(dictionary));

  // Form methods 📋
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext();

  const value = useWatch({
    name: name,
    control,
  });

  const error = get(errors, name);

  return (
    <FormControl fullWidth error={!!error?.message} size='small'>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        {...register(name)}
        SelectDisplayProps={{
          style: { display: 'flex', alignItems: 'center' },
        }}
        labelId={name}
        id={name}
        value={value}
        label={label}
        sx={{ mb: 2, ...sx }}
        inputProps={{ id: name }}
      >
        {items.map((i) => (
          <MenuItem value={i.value!!} key={i.value}>
            <Typography variant='body1'>{i.text}</Typography>
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText>
          {/* ⚠️ INFO: ⚠️ When getting the error message like we do here, we only get the TextCode no matter how its added in the schema. So we need to wrap it in the translation hook to get it translated. */}
          {t(error.message)}
        </FormHelperText>
      )}
    </FormControl>
  );
};
