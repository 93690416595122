import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import { useRef, useState } from 'react';
import { useFormContext, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ClearInputIcon from 'components/common/inputs/ClearInputIcon';

export interface TextInputProps
  extends Omit<BaseTextFieldProps, 'onChange' | 'onBlur' | 'onFocus'> {
  name: string;
  id?: string;
  autoFocus?: boolean;
  showHelperTextOnFocus?: boolean;
  showClearButton?: boolean;
  helperText?: string | null | undefined;
  /**
   * @deprecated Set error in the field using FormContext instead.
   */
  errorMessage?: string;
  maxLength?: number;
}

export const TextInput = ({
  disabled,
  helperText,
  id,
  name,
  showHelperTextOnFocus = false,
  sx,
  maxLength = undefined,
  showClearButton = true,
  size = 'small',
  variant = 'outlined',
  ...rest
}: TextInputProps) => {
  // Hooks 🎣
  const { t } = useTranslation();

  // Local state 🏠
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);

  // Form ✍️
  const { register, resetField, formState, watch } = useFormContext();
  const error = get(formState?.errors, name);
  const value = watch(name);

  /* 
    Priority list for "helper text"
    1. Display error message if any error
    2. if showHelperTextOnFocus equals true display helper text only if focus === true
    3. Display helper text if helperText is provided
    4. Display empty string
  */
  const priorityHelperText = error?.message
    ? t(error?.message)
    : showHelperTextOnFocus
    ? focus && helperText
    : helperText
    ? helperText
    : '';

  return (
    <TextField
      {...register(name)}
      {...rest}
      aria-errormessage={t(error?.message)}
      disabled={disabled}
      error={error ? true : false}
      helperText={priorityHelperText}
      id={id ?? name}
      InputProps={{
        endAdornment: (
          <ClearInputIcon
            isVisible={value && (focus || hover)}
            disabled={disabled}
            onClick={() => {
              resetField(name, {
                defaultValue: '',
              });
              ref?.current?.focus();
            }}
          />
        ),
      }}
      focused={focus}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      onMouseLeave={() => setHover(false)}
      onMouseOver={() => setHover(true)}
      size={size}
      sx={sx}
      variant='outlined'
      inputRef={ref}
      inputProps={{
        maxLength: maxLength,
      }}
    />
  );
};

export default TextInput;
