import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import {
  ItineraryListItem,
  ItinerariesListTravellerResponse,
  itineraryApi,
} from 'services/api/itinerary';
import { capitalizeName } from 'utils/text.utils';
import { RootState } from '..';

export interface TripsState {
  upcomingTrips: ItineraryListItem[];
  pastTrips: ItineraryListItem[];
  cancelledTrips: ItineraryListItem[];
}

const initialState = {
  upcomingTrips: [],
  pastTrips: [],
  cancelledTrips: [],
};

// REDUCERS
const tripsSlice = createSlice({
  name: 'trips',
  initialState: initialState,
  reducers: {
    saveTrips: (
      state: TripsState,
      action: PayloadAction<{
        bookingStatus: any;
        itineraries: ItineraryListItem[];
      }>
    ) => {
      const status = action.payload?.bookingStatus;
      let trips: ItineraryListItem[] =
        action.payload?.itineraries?.map((t: ItineraryListItem) => ({
          ...t,
          bookingStatus: status,
        })) ?? [];
      if (status === 'Upcoming') {
        state.upcomingTrips = trips;
      }
      if (status === 'Past') {
        state.pastTrips = trips;
      }
      if (status === 'Cancelled') {
        state.cancelledTrips = trips;
      }
    },
    resetTrips: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      itineraryApi.endpoints.itineraryList.matchFulfilled,
      (state: TripsState, { payload }) => {
        const trips = payload?.itineraries ?? [];
        if (payload?.bookingStatus === 'Upcoming') {
          state.upcomingTrips = trips;
        }
        if (payload?.bookingStatus === 'Past') {
          state.pastTrips = trips;
        }
        if (payload?.bookingStatus === 'Cancelled') {
          state.cancelledTrips = trips;
        }
      }
    );
  },
});
// export default userSlice.reducer;
export default tripsSlice.reducer;
export const { saveTrips, resetTrips } = tripsSlice.actions;

export const getUpcomingTrips = createSelector(
  (state: RootState) => state.trips.upcomingTrips,
  (data: ItineraryListItem[]) => data
);

export const getPastTrips = createSelector(
  (state: TripsState) => state.pastTrips,
  (data: ItineraryListItem[]) => data
);

export const getCancelledTrips = createSelector(
  (state: TripsState) => state.cancelledTrips,
  (data: ItineraryListItem[]) => data
);

export const getAllTrips = createSelector(
  ({ trips }: RootState) => [
    ...trips.upcomingTrips,
    ...trips.pastTrips,
    ...trips.cancelledTrips,
  ],

  (data: ItineraryListItem[]) => data
);

export const getAllTravellers = createSelector(
  ({ trips }: RootState) => [
    ...trips.upcomingTrips,
    ...trips.pastTrips,
    ...trips.cancelledTrips,
  ],

  (data: ItineraryListItem[]) => mapTravellers(data)
);

const mapTravellers = (data: ItineraryListItem[]) => {
  let travellers: ItinerariesListTravellerResponse[] = [];
  for (var trip of data) {
    if (trip.travellers)
      travellers = [
        ...travellers,
        ...trip.travellers.map((t) => ({
          ...t,
          firstName: capitalizeName(t.firstName!),
          lastName: capitalizeName(t.lastName!),
        })),
      ]; // map the tripId to the traveller
  }
  return uniqBy(travellers, 'tacNumber');
};
