import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  useTheme,
  Stack,
  Box,
} from '@mui/material';
import OrderCard from 'components/features/order/OrderCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSearchParams } from 'react-router-dom';
import { OrderCardSkeletonList } from './OrderCardSkeleton';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Progress } from 'components/common';
import { ItineraryListItem } from 'services/api/itinerary';

interface Props {
  title: string;
  tabName: string;
  loading?: boolean;
  hasFilters?: boolean;
  filteredTrips: ItineraryListItem[];
}

const OrdersAccordion = ({
  title,
  tabName,
  loading,
  hasFilters,
  filteredTrips,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [count, setCount] = useState(10);
  const paginatedTrips = filteredTrips.slice(0, count);
  const theme = useTheme();
  const currentTab = searchParams.get('type');
  const handleExpand = () => {
    tabName === currentTab
      ? searchParams.delete('type')
      : searchParams.set('type', tabName);
    setSearchParams(searchParams);
  };

  const loadMore = () => {
    setCount(count + 10);
  };

  useEffect(() => {
    setCount(10);
  }, [filteredTrips, loading]);

  const isDisabled = currentTab !== tabName && hasFilters;

  return (
    <Accordion
      disabled={isDisabled}
      expanded={currentTab === tabName}
      onChange={handleExpand}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              fill: isDisabled
                ? theme.palette.text.disabled
                : theme.palette.primary.main,
            }}
          />
        }
      >
        <Typography
          variant='body1'
          fontWeight='bold'
          sx={{
            color: isDisabled
              ? theme.palette.text.disabled
              : theme.palette.primary.main,
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack>
          {!loading && paginatedTrips.length > 0 && (
            <InfiniteScroll
              loader={
                <Box sx={{ display: 'flex', justifyContent: 'center' }} key={0}>
                  <Progress />
                </Box>
              }
              loadMore={loadMore}
              hasMore={filteredTrips && filteredTrips.length > count}
            >
              {paginatedTrips?.map((t) => (
                <Box key={t.reference} sx={{ mb: 2 }}>
                  <OrderCard
                    reference={t.reference!!}
                    departureDate={t.outwardDate!!}
                    title={t.destinationName!!}
                    travellers={t.travellers ?? []}
                    containsAir={t.containsAir ?? false}
                    containsTrain={t.containsTrain ?? false}
                    containsHotel={t.containsHotel ?? false}
                    orderNumber={t.orderNo!!}
                    containsCar={t.containsCar ?? false}
                    bookingStatus={t.bookingStatus!!.toLowerCase()}
                  />
                </Box>
              ))}
            </InfiniteScroll>
          )}
          {loading && <OrderCardSkeletonList amount={5} />}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrdersAccordion;
