import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  fallbackComponent: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO: Maybe we should save the errors and collect them somewhere? When they are happening in production or other environments so we can track how often we get an error or where the error happened?
    console.error('Uncaught error:', errorInfo, error);
  }

  public render() {
    if (this.state.hasError) return this.props.fallbackComponent;

    return this.props.children;
  }
}

export default ErrorBoundary;
