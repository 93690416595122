import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';

import EditBasicInformationForm from 'components/features/user/personalInformation/basicInformation/EditBasicInformationForm';
import { Card } from 'components/common';
import { TextCodes } from 'hooks';
import StandardHeader from 'components/common/headers/StandardHeader';
import PageTitle from 'components/common/pageTitle/PageTitle';
import InfoButtonWithDialog from 'components/common/modals/InfoButtonWithDialog';

const EditBasicInformationPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <StandardHeader showBreadcrumbs={false} />
      <Container>
        <PageTitle
          title={t(TextCodes.WG6.Profile.BaseInfo)}
          sx={{
            mt: 7,
          }}
          button={
            <InfoButtonWithDialog
              popupText={t(TextCodes.WG6.Profile.PersonalPopup)}
            />
          }
        />
        <Card aria-live='polite' sx={{ pt: 1 }}>
          <EditBasicInformationForm />
        </Card>
      </Container>
    </>
  );
};

export default EditBasicInformationPage;
