import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from 'services/api/authentication';
import { RootState } from '../store';

// INITIAL STATE
export interface AuthState {
  token: string;
  tac: string;
}

const initialState: AuthState = {
  token: '',
  tac: '',
};

// REDUCERS
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setTac: (state, action: PayloadAction<string>) => {
      state.tac = action.payload;
    },
    resetAuth: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload?.value?.accessToken) {
          state.token = payload.value.accessToken;
          window.localStorage.setItem('token', payload.value.accessToken);
        }
      }
    );
  },
});

// ACTIONS
export const { setToken, setTac, resetAuth } = authSlice.actions;

// SELECTORS
export const getToken = createSelector(
  (state: RootState) => state.auth.token,
  (token) => token
);

export const getTac = createSelector(
  (state: RootState) => state.auth.tac,
  (tac) => tac
);

export default authSlice.reducer;
