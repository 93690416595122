import * as Yup from 'yup';
import { Alert, Box, Collapse, Grid, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import PasswordInput from 'components/common/inputs/password/PasswordInput';
import useSiteNavigation from 'hooks/navigation/useSiteNavigation';
import { ButtonPanel } from 'components/common/buttons/buttonPanel/ButtonPanel';
import { PlainCard } from 'components/common/containers/Card';
import { ProgressButton } from 'components/common/buttons/ProgressButton';
import { TextCodes } from 'hooks/texts/text.codes';
import { authApi } from 'services/api/authentication';
import { routeConfig } from 'routes/config/route.config';
import { useToast } from 'hooks/toast/toast.hooks';

export const SetPasswordForm = () => {
  const { t } = useTranslation();
  const navigate = useSiteNavigation();
  const { toast } = useToast();
  const [setNewPassword, { isLoading, data, isError }] =
    authApi.useResetPasswordMutation();
  const [searchParams] = useSearchParams();

  const formMethods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        password: Yup.string()
          .required(TextCodes.ValidationErrors.Required)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d$@.,!%*#?&(){}_+-=[\]/':;"|`~^<>\\])[a-zA-Z\\d$@.,!%*#?&(){}_+-=[\]/':;"|`~^<> ]{8,}$/,
            TextCodes.WG6.Validation.PasswordFormat
          ),
        passwordRepeat: Yup.string()
          .required(TextCodes.ValidationErrors.Required)
          .equals(
            [Yup.ref('password')],
            TextCodes.WG6.Validation.PasswordMatch
          ),
      })
    ),
    defaultValues: { password: '', passwordRepeat: '' },
  });

  const onSubmit: SubmitHandler<any> = (data) => {
    setNewPassword({
      passwordResetRequest: {
        loginId: searchParams.get('loginId') || '',
        newPassword: data.password,
      },
    });
  };

  if (data?.isSuccess) {
    navigate(routeConfig.Login.path);
    toast({
      severity: 'success',
      autoHideDuration: 3000,
      message: data?.message,
    });
  }

  let errorMessage: string | null = null;
  if (isError) errorMessage = t(TextCodes.WG6.Generic.UnknownError);
  if (data?.message) errorMessage = data.message;

  return (
    <PlainCard container gap={3.5}>
      <Grid container gap={3}>
        <Typography variant='subtitle1' component='h1'>
          {t(TextCodes.WG6.ForgottenPassword.ChangePasswordTitle)}
        </Typography>
        <Typography variant='body1' component='p'>
          {t(TextCodes.WG6.ForgottenPassword.ChangePasswordDescription)}
        </Typography>
      </Grid>
      <Grid container component='form' gap={5}>
        <FormProvider {...formMethods}>
          <Grid container gap={2}>
            <PasswordInput
              fullWidth
              label={t(TextCodes.WG6.Password.NewPasswordField)}
              name='password'
              autoFocus
              size='medium'
              sx={{ mb: 0 }}
            />
            <PasswordInput
              fullWidth
              label={t(TextCodes.WG6.Password.ConfirmPasswordField)}
              name='passwordRepeat'
              size='medium'
              sx={{ mb: 0 }}
            />
          </Grid>
          <Grid
            component={Collapse}
            in={errorMessage !== null}
            collapsedSize={0}
            item
            xs={12}
            unmountOnExit
          >
            <Box>
              <Alert severity='error'>{errorMessage}</Alert>
            </Box>
          </Grid>
          <ButtonPanel align='right'>
            <ProgressButton
              variant='contained'
              type='submit'
              disabled={isLoading}
              busy={isLoading}
              onClick={formMethods.handleSubmit(onSubmit)}
            >
              <Typography variant='buttonLarge'>
                {t(TextCodes.WG6.Navigation.Save)}
              </Typography>
            </ProgressButton>
          </ButtonPanel>
        </FormProvider>
      </Grid>
    </PlainCard>
  );
};
