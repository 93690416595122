import Avatar from '@mui/material/Avatar';
import { IconButton, useTheme } from '@mui/material';
import { useProfileMenuDrawer } from 'hooks/menu/menu.hooks';
import { useAppSelector } from 'store/store';
import { getProfile } from 'store/user/user.slice';
import { UserProfileResponse } from 'services/api/user';

const ProfileIcon = () => {
  const { palette } = useTheme();
  const { toggleProfileMenuDrawer } = useProfileMenuDrawer();

  const userProfile: UserProfileResponse = useAppSelector(getProfile);

  return (
    <IconButton
      size='medium'
      edge='end'
      aria-label='menu'
      onClick={toggleProfileMenuDrawer}
      sx={{ marginRight: '0px' }}
      aria-live='polite'
    >
      <Avatar
        sx={{
          width: 24,
          height: 24,
          backgroundColor: palette.primary.light,
          fontSize: 14,
          fontFamiliy: 'Inter',
        }}
        color='primary.light'
      >
        {userProfile?.firstName?.charAt(0).toUpperCase()}
      </Avatar>
    </IconButton>
  );
};

export default ProfileIcon;
