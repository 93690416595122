import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { ThemeIds } from 'theme/theme.types';
import { themes } from 'theme/theme';
import { RootState } from 'store';

interface FlightNumberModal {
  isOpen: boolean;
  flightBookingClass?: string | undefined;
  flightCarrierCode?: string | undefined;
  flightNo?: string | undefined;
  flightClassOfService?: string | undefined;
  flightFareBasis?: string | undefined;
  flightCarrierName?: string | undefined;
  flightEquipment?: string | undefined;
}

interface BaggageInfoDrawer {
  isOpen: boolean;
  departureCityName: string;
  arrivalCityName: string;
  baggageValue: number;
  baggageCode: string;
}

interface AirFareRulesDrawer {
  isOpen: boolean;
  searchString: string;
  departureCityName: string;
  arrivalCityName: string;
}

// INITIAL STATE
export interface AppState {
  theme: ThemeIds;
  isMainMenuDrawerExpanded: boolean;
  isProfileMenuDrawerExpanded: boolean;
  isLoading: boolean;
  showNotImplementedModal: boolean;
  flightNumberModal: FlightNumberModal;
  baggageInfoDrawer: BaggageInfoDrawer;
  airFareRulesDrawer: AirFareRulesDrawer;
}

export const initialState: AppState = {
  theme: ThemeIds.Light,
  isMainMenuDrawerExpanded: false,
  isProfileMenuDrawerExpanded: false,
  isLoading: false,
  showNotImplementedModal: false,
  flightNumberModal: {
    isOpen: false,
    flightBookingClass: '',
    flightCarrierCode: '',
    flightNo: '',
    flightClassOfService: '',
    flightFareBasis: '',
    flightCarrierName: '',
    flightEquipment: '',
  },
  baggageInfoDrawer: {
    isOpen: false,
    departureCityName: '',
    arrivalCityName: '',
    baggageValue: 0,
    baggageCode: '',
  },
  airFareRulesDrawer: {
    isOpen: false,
    searchString: '',
    departureCityName: '',
    arrivalCityName: '',
  },
};

// REDUCERS
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCurrentThemeId: (state, action: PayloadAction<ThemeIds>) => {
      state.theme = action.payload;
    },
    setMainMenuDrawer: (state, action: PayloadAction<boolean>) => {
      state.isMainMenuDrawerExpanded = action.payload;
    },
    setProfileMenuDrawer: (state, action: PayloadAction<boolean>) => {
      state.isProfileMenuDrawerExpanded = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShowNotImplementedModal: (state, action: PayloadAction<boolean>) => {
      state.showNotImplementedModal = action.payload;
    },
    setFlightNumberModal: (state, action: PayloadAction<FlightNumberModal>) => {
      state.flightNumberModal = action.payload;
    },
    setBaggageInfoDrawer: (state, action: PayloadAction<BaggageInfoDrawer>) => {
      state.baggageInfoDrawer = action.payload;
    },
    setAirFareRulesDrawer: (
      state,
      action: PayloadAction<AirFareRulesDrawer>
    ) => {
      state.airFareRulesDrawer = action.payload;
    },
    resetApp: () => initialState,
  },
});

// ACTIONS
export const {
  setCurrentThemeId,
  setMainMenuDrawer,
  setProfileMenuDrawer,
  setLoading,
  setShowNotImplementedModal,
  setFlightNumberModal,
  resetApp,
  setBaggageInfoDrawer,
  setAirFareRulesDrawer,
} = appSlice.actions;

// SELECTORS
export const getCurrentThemeId = createSelector(
  (state: RootState) => state.app.theme,
  (theme) => theme
);

export const getCurrentTheme = createSelector(
  (state: RootState) => state.app.theme,
  (themeId) =>
    themes.find((theme) => theme.value === themeId)?.theme || themes[0].theme
);

export const getMainMenuDrawerExpandedFlag = createSelector(
  (state: RootState) => state.app.isMainMenuDrawerExpanded,
  (flag) => flag
);

export const getProfileMenuDrawerExpandedFlag = createSelector(
  (state: RootState) => state.app.isProfileMenuDrawerExpanded,
  (flag) => flag
);

export const isLoading = createSelector(
  (state: RootState) => state.app.isLoading,
  (flag) => flag
);

export const isDarkMode = createSelector(
  (state: RootState) => state.app.theme,
  (theme) => theme === ThemeIds.Dark
);

export const showNotImplementedModal = createSelector(
  (state: RootState) => state.app.showNotImplementedModal,
  (flag) => flag
);

export const flightNumberModal = createSelector(
  (state: RootState) => state.app.flightNumberModal,
  (flag) => flag
);

export const baggageInfoDrawer = createSelector(
  (state: RootState) => state.app.baggageInfoDrawer,
  (flag) => flag
);

export const getAirFareRulesDrawer = createSelector(
  (state: RootState) => state.app.airFareRulesDrawer,
  (flag) => flag
);

export default appSlice.reducer;
