import InputAdornment from '@mui/material/InputAdornment';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { get, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressButton } from 'components/common/buttons/ProgressButton';
import { TextCodes } from 'hooks';
import { pxToRem } from 'theme/theme.utils';

interface TravelArrangersSearchProps {
  onSearch: () => void;
  isSearching?: boolean;
  name: string;
}

const TravelArrangersSearch = ({
  onSearch,
  name,
  isSearching,
}: TravelArrangersSearchProps) => {
  // Local state 🏡
  const [searchText, setSearchText] = useState('');

  // Hooks 🎣
  const { t } = useTranslation();

  // Form methods 📋✍️
  const { register, formState, setValue } = useFormContext();
  const error = get(formState?.errors, name);

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='flex-start'
      spacing={1}
    >
      <TextField
        {...register(name)}
        aria-errormessage={t(error?.message)}
        helperText={!!error ? t(error?.message) : ''}
        error={!!error}
        fullWidth
        label={t(TextCodes.WG6.Base.Search)}
        onChange={(event) => {
          setValue(name, event.target.value);
          setSearchText(event.target.value);
        }}
        size='small'
        variant='outlined'
        type='text'
        InputProps={{
          // FIXME: 😠💢 (╯°□°）╯︵ ┻━┻ The label is always shrinked when using the startAdornment prop - https://dev.azure.com/Berg-Hansen/Berg-Hansen/_sprints/taskboard/WebGate%20Team%20Six/Berg-Hansen/Webgate6/M1-A?workitem=35967
          startAdornment: (
            <InputAdornment position='start'>
              <SearchOutlined />
            </InputAdornment>
          ),
        }}
        sx={{
          mb: 0,
          '& > div': {
            paddingInline: pxToRem[12],
          },
          '& > div > input': {
            paddingLeft: 0,
          },
        }}
      />
      <ProgressButton
        color='primary'
        size='medium'
        type='submit'
        variant='text'
        busy={isSearching}
        disabled={isSearching || !searchText}
        onClick={onSearch}
      >
        {t(TextCodes.WG6.Base.Search)}
      </ProgressButton>
    </Stack>
  );
};

export default TravelArrangersSearch;
