import { Box, Container, Skeleton, useTheme } from '@mui/material';
import AccordionSkeleton from 'components/common/accordions/AccordionSkeleton';
import PageTitleSkeleton from 'components/common/pageTitle/PageTitleSkeleton';

const OrderDetailsSkeleton = () => {
  const theme = useTheme();
  return (
    <Box>
      <PageTitleSkeleton hasButton />
      <Container sx={{ mb: 2 }}>
        <Skeleton width={150} />
        <Skeleton width={250} />
      </Container>
      <AccordionSkeleton />
      <AccordionSkeleton />
      <AccordionSkeleton />
      <Container
        sx={{
          backgroundColor: theme.palette.background.paper,
          position: 'fixed',
          bottom: 0,
          height: 72,
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Skeleton width={100} height={50} />
      </Container>
    </Box>
  );
};

export default OrderDetailsSkeleton;
