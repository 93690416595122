import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { LinkExternal, LinkInternal } from 'components/common';
import { TextCodes } from 'hooks';
import { getRoles, getShortcuts } from 'store/user/user.slice';
import { setShowNotImplementedModal } from 'store/app/app.slice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useAuthorization, useLogoutAction } from 'hooks/auth/auth.hooks';

export const MainMenuItems = () => {
  // Store 🏘️
  const roles = useAppSelector(getRoles);
  const shortcuts = useAppSelector(getShortcuts);

  // Hooks 🎣
  const dispatch = useAppDispatch();
  const { isAuthorized } = useAuthorization();
  const { logout } = useLogoutAction();
  const { palette } = useTheme();
  const { t } = useTranslation();

  const showNotImplementedModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(setShowNotImplementedModal(true));
  };

  const getListItem = (title: string, isLogOutButton?: boolean) => (
    <ListItem
      sx={{
        color: palette.text.primary,
        textDecoration: 'none',
        backgroundColor: isLogOutButton
          ? 'transparent'
          : palette.background.paper,
        py: isLogOutButton ? 2 : 3,
      }}
    >
      <Typography variant='body1' fontWeight='bold'>
        {title}
      </Typography>
    </ListItem>
  );

  const getNotImplementedListItem = (title: string) => (
    <Link
      underline='none'
      sx={{ width: '100%' }}
      component='button'
      onClick={showNotImplementedModal}
    >
      {getListItem(title)}
    </Link>
  );

  return (
    <List sx={{ paddingBlock: 0 }}>
      <Stack divider={<Divider />}>
        {/* Start booking: */}
        {getNotImplementedListItem(t(TextCodes.WG6.Menu.Order))}

        {/* Orders: */}
        <LinkInternal
          routeId='Orders'
          sx={{
            textDecoration: 'none',
          }}
        >
          {getListItem(t(TextCodes.WG6.Menu.Orders))}
        </LinkInternal>

        {/* Information From Employer - ⚠️ This field is dynamic 🚨🚨 This is removed for now - see this comment from Anne Siri: https://dev.azure.com/Berg-Hansen/Berg-Hansen/_workitems/edit/35965/#7135680 🚨🚨 */}
        {/* {getNotImplementedListItem(
          t(TextCodes.WG6.Menu.InformationFromEmployer)
        )} */}

        {/* Profile */}
        <LinkInternal
          routeId='User'
          sx={{
            textDecoration: 'none',
          }}
        >
          {getListItem(t(TextCodes.WG6.Menu.Profile))}
        </LinkInternal>

        {/* ERM - ⚠️ This field is dynamic - based on the shortcuts in the profile */}
        {shortcuts?.some((shortcut) => shortcut.type === 'ERM') &&
          getNotImplementedListItem(t(TextCodes.WG6.Menu.ERM))}

        {/* Arrangements - ⚠️ This field is dynamic - based on the roles (groupEventArranger) in the profile*/}
        {roles?.groupEventArranger &&
          getNotImplementedListItem(t(TextCodes.WG6.Menu.Arrangements))}

        {/* Travel Expenses - ⚠️ This field is dynamic - based on the shortcuts in the profile */}
        {shortcuts?.some((shortcut) => shortcut.type === 'Expense') && (
          <LinkExternal
            href='https://er.berg-hansen.no/#/dashboard'
            sx={{ textDecoration: 'none' }}
          >
            {getListItem(t(TextCodes.WG6.Menu.TravelExpenses))}
          </LinkExternal>
        )}

        {/* Contact us */}
        {getNotImplementedListItem(t(TextCodes.WG6.Menu.ContactUs))}
      </Stack>

      {/* Logout - Only visible if you're logged in */}
      {isAuthorized && (
        <Link component='button' underline='none' onClick={logout}>
          {getListItem(t(TextCodes.WG6.Menu.LogOut), true)}
        </Link>
      )}
    </List>
  );
};
