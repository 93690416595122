import { Box } from '@mui/material';
import StandardHeader from 'components/common/headers/StandardHeader';
import { ChangePassword } from 'components/features/user/password/ChangePassword';
import { TextCodes } from 'hooks';
import { useTranslation } from 'react-i18next';

const ChangePasswordPage = () => {
  const { t } = useTranslation();

  return (
    <Box aria-live='polite' marginTop={7}>
      <StandardHeader
        finalCrumbLabel={t(TextCodes.WG6.Profile.Contact)}
        amountOfBreadcrumbs={2}
        showBreadcrumbs={false}
      />
      <ChangePassword />
    </Box>
  );
};

export default ChangePasswordPage;
