const WorkProfile = () => (
  <svg
    width='93'
    height='122'
    viewBox='0 0 93 122'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M71.75 30.75V20.5H51.25V30.75H71.75ZM20.5 41V97.375H102.5V41H20.5ZM102.5 30.75C108.189 30.75 112.75 35.3112 112.75 41V97.375C112.75 103.064 108.189 107.625 102.5 107.625H20.5C14.8112 107.625 10.25 103.064 10.25 97.375L10.3012 41C10.3012 35.3112 14.8112 30.75 20.5 30.75H41V20.5C41 14.8112 45.5612 10.25 51.25 10.25H71.75C77.4388 10.25 82 14.8112 82 20.5V30.75H102.5Z'
      fill='#3057E4'
      fillOpacity='0.1'
    />
  </svg>
);

export default WorkProfile;
