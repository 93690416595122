import { Components, Palette } from '@mui/material';

export const getHeaderStyles = (palette: Palette): Components => ({
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: palette?.background?.default,
        backgroundImage: 'unset',
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundImage: 'unset',
      },
    },
  },
});
