import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Theme,
  SxProps,
} from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { DictionaryItem } from 'services/api/user';

export interface RadioListProps {
  label?: string;
  options: DictionaryItem[];
  name: string;
  row?: boolean;
  sx?: SxProps<Theme>;
}

const RadioList = ({ label, options, name, sx, ...rest }: RadioListProps) => {
  const { register, control, setValue } = useFormContext();
  const value = useWatch({ control, name });

  return (
    <FormControl>
      <FormLabel sx={{ pb: 2 }} id={name}>
        <Typography fontWeight='bold' component='span' variant='body1'>
          {label}
        </Typography>
      </FormLabel>
      <RadioGroup value={value} sx={sx} {...rest}>
        {options.map((o) => (
          <FormControlLabel
            label={o.text!!}
            onClick={() => setValue(name, o.value)}
            key={o.value}
            control={<Radio {...register(name)} value={o.value} />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioList;
