import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

import NotRegistered from 'components/common/text/NotRegistered';
import TextBlock from 'components/common/text/TextBlock';
import { EditableCard } from 'components/common';
import { TextCodes, useDateFormatting } from 'hooks';
import { getIsReadOnlyProfile, getProfile } from 'store/user/user.slice';
import { useAppSelector } from 'store/store';

const BasicInformationCard = () => {
  const { t } = useTranslation();
  const { toShortDate } = useDateFormatting();
  const profile = useAppSelector(getProfile);
  const isReadOnly = useAppSelector(getIsReadOnlyProfile);

  return (
    <EditableCard
      isEditable={!isReadOnly}
      routeId='BasicInformation'
      title={t(TextCodes.WG6.Profile.Personal)}
    >
      <Stack spacing={2} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
        <TextBlock removeDivider title={t(TextCodes.WG6.Profile.Name)}>
          {profile.firstName} {profile.lastName}
        </TextBlock>
        <TextBlock removeDivider title={t(TextCodes.WG6.Profile.Gender)}>
          {profile.gender === 'MR'
            ? t(TextCodes.WG6.Profile.Male)
            : t(TextCodes.WG6.Profile.Female)}
        </TextBlock>
        <TextBlock removeDivider title={t(TextCodes.WG6.Profile.BirthDate)}>
          {toShortDate(profile.dateOfBirth) || <NotRegistered />}
        </TextBlock>
      </Stack>
    </EditableCard>
  );
};

export default BasicInformationCard;
