import { Card } from '../../components/common';
import { ExampleApiCall } from '../../components/features/example/ExampleApiCall';
import { CenteredLayout } from '../../components/layouts/CenteredLayout';

export const ExampleApiPage = () => {
  return (
    <CenteredLayout>
      <Card title='Example API page'>
        <ExampleApiCall />
      </Card>
    </CenteredLayout>
  );
};
