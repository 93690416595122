import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FlightIcon from '@mui/icons-material/Flight';
import Grid from '@mui/material/Grid';
import HotelIcon from '@mui/icons-material/Hotel';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import TrainIcon from '@mui/icons-material/Train';
import { useTheme } from '@mui/material/styles';

import { ItineraryType } from 'services/api/itinerary';

interface OrderDetailsIconRowProps {
  types: ItineraryType[];
}

const OrderDetailsIconRow = ({ types }: OrderDetailsIconRowProps) => {
  const theme = useTheme();

  const icons = types as string[];
  return (
    <Grid container flexDirection='row' justifyContent='flex-end' gap={2}>
      {icons.map((icon, i) => {
        switch (icon) {
          case 'Flight':
            return (
              <FlightIcon
                sx={{
                  width: 16,
                  height: 16,
                  fill: theme.palette.action.active,
                }}
                key={i}
              />
            );
          case 'Car':
            return (
              <DirectionsCarIcon
                sx={{
                  width: 14,
                  height: 14,
                  fill: theme.palette.action.active,
                }}
                key={i}
              />
            );
          case 'Hotel':
            return (
              <HotelIcon
                sx={{
                  width: 14,
                  height: 14,
                  fill: theme.palette.action.active,
                }}
                key={i}
              />
            );
          case 'Taxi':
            return (
              <LocalTaxiIcon
                sx={{
                  width: 14,
                  height: 14,
                  fill: theme.palette.action.active,
                }}
                key={i}
              />
            );
          case 'Train':
            return (
              <TrainIcon
                sx={{
                  width: 14,
                  height: 14,
                  fill: theme.palette.action.active,
                }}
                key={i}
              />
            );
          default:
            return '';
        }
      })}
    </Grid>
  );
};

export default OrderDetailsIconRow;
