const Mastercard = () => {
  return (
    <svg width='34' height='24' viewBox='0 0 34 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='33' height='23' rx='3.5' fill='white' stroke='#D9D9D9' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.179 16.8295C15.9949 17.8275 14.459 18.43 12.7807 18.43C9.03582 18.43 6 15.4303 6 11.73C6 8.02972 9.03582 5.03003 12.7807 5.03003C14.459 5.03003 15.9949 5.63253 17.179 6.63057C18.363 5.63253 19.8989 5.03003 21.5773 5.03003C25.3221 5.03003 28.358 8.02972 28.358 11.73C28.358 15.4303 25.3221 18.43 21.5773 18.43C19.8989 18.43 18.363 17.8275 17.179 16.8295Z'
        fill='#ED0006'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.1792 16.8295C18.6371 15.6006 19.5616 13.772 19.5616 11.73C19.5616 9.68807 18.6371 7.85947 17.1792 6.63057C18.3632 5.63253 19.8992 5.03003 21.5775 5.03003C25.3224 5.03003 28.3582 8.02972 28.3582 11.73C28.3582 15.4303 25.3224 18.43 21.5775 18.43C19.8992 18.43 18.3632 17.8275 17.1792 16.8295Z'
        fill='#F9A000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.1788 16.8295C18.6367 15.6006 19.5611 13.772 19.5611 11.7301C19.5611 9.68811 18.6367 7.85952 17.1788 6.63062C15.7208 7.85952 14.7964 9.68811 14.7964 11.7301C14.7964 13.772 15.7208 15.6006 17.1788 16.8295Z'
        fill='#FF5E00'
      />
    </svg>
  );
};

export default Mastercard;
