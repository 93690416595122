import { Box, SxProps } from '@mui/material';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

interface CollapsableBoxProps {
  isCollapsed: boolean;
  children: ReactNode | ReactNode[];
  sx?: SxProps;
  speed?: number;
}

const CollapsableBox = ({
  isCollapsed,
  children,
  sx,
  speed = 0.3,
}: CollapsableBoxProps) => {
  return (
    <Box
      sx={{ overflow: 'hidden', ...sx }}
      component={motion.div}
      animate={{
        height: isCollapsed ? 0 : 'auto',
        transition: { duration: speed, type: 'spring' },
      }}
    >
      {children}
    </Box>
  );
};

export default CollapsableBox;
