import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { TextCodes } from 'hooks/texts/text.codes';
import { useTranslation } from 'react-i18next';

type FormPanelModalProps = {
  isVisible: boolean;
  text?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const FormPanelModal = ({
  isVisible,
  text,
  onConfirm,
  onCancel,
}: FormPanelModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isVisible}>
      <DialogContentText
        variant='body1'
        sx={{
          minWidth: '311px',
          paddingRight: 3,
          paddingLeft: 3,
          paddingTop: 2,
        }}
      >
        {text}
      </DialogContentText>
      <DialogActions>
        <Button variant='text' onClick={onCancel} color='primary'>
          {t(TextCodes.WG6.Navigation.No)}
        </Button>
        <Button variant='text' onClick={onConfirm} color='primary'>
          {t(TextCodes.WG6.Navigation.Yes)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormPanelModal;
