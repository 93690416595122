import { baseApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchArrangers: build.mutation<SearchArrangersResult, SearchArrangersArgs>(
      {
        query: (queryArg) => ({
          url: `/api/arrangers`,
          method: 'POST',
          body: queryArg.searchArrangersRequest,
        }),
      }
    ),
    userProfileDetails: build.query<
      UserProfileDetailsResult,
      UserProfileDetailsArgs
    >({
      query: () => ({ url: `/api/user/profile`, method: 'POST' }),
    }),
    userPaymentMethods: build.query<
      UserPaymentMethodsResult,
      UserPaymentMethodsArgs
    >({
      query: (queryArg) => ({
        url: `/api/user/paymentmethods`,
        params: { LacNumber: queryArg.lacNumber },
      }),
    }),
    userPasswordSave: build.mutation<
      UserPasswordSaveResult,
      UserPasswordSaveArgs
    >({
      query: (queryArg) => ({
        url: `/api/user/profile/save-password`,
        method: 'POST',
        body: queryArg.passwordChangeRequest,
      }),
    }),
    userTravellersSave: build.mutation<
      UserTravellersSaveResult,
      UserTravellersSaveArgs
    >({
      query: (queryArg) => ({
        url: `/api/user/profile/save-travellers`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    userPreferencesSave: build.mutation<
      UserPreferencesSaveResult,
      UserPreferencesSaveArgs
    >({
      query: (queryArg) => ({
        url: `/api/user/profile/save-preferences`,
        method: 'POST',
        body: queryArg.preferences,
      }),
    }),
    userPassportsSave: build.mutation<
      UserPassportsSaveResult,
      UserPassportsSaveArgs
    >({
      query: (queryArg) => ({
        url: `/api/user/profile/save-passports`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    userVisasSave: build.mutation<UserVisasSaveResult, UserVisasSaveArgs>({
      query: (queryArg) => ({
        url: `/api/user/profile/save-visas`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    userPaymentAndCreditCardsSettings: build.mutation<
      UserPaymentAndCreditCardsSettingsResult,
      UserPaymentAndCreditCardsSettingsArgs
    >({
      query: (queryArg) => ({
        url: `/api/user/profile/save-payment-and-credit-cards-settings`,
        method: 'POST',
        body: queryArg.savePaymentAndCreditCardsSettingsDto,
      }),
    }),
    userBonusCardsSave: build.mutation<
      UserBonusCardsSaveResult,
      UserBonusCardsSaveArgs
    >({
      query: (queryArg) => ({
        url: `/api/user/profile/save-bonus-cards`,
        method: 'POST',
        body: queryArg.bonusCardsData,
      }),
    }),
    userContactDataSave: build.mutation<
      UserContactDataSaveResult,
      UserContactDataSaveArgs
    >({
      query: (queryArg) => ({
        url: `/api/user/profile/save-contact-data`,
        method: 'POST',
        body: queryArg.saveContactDetailsDto,
      }),
    }),
    userPersonalDataSave: build.mutation<
      UserPersonalDataSaveResult,
      UserPersonalDataSaveArgs
    >({
      query: (queryArg) => ({
        url: `/api/user/profile/save-personal-data`,
        method: 'POST',
        body: queryArg.saveUserPersonalDataDto,
      }),
    }),
    userClientReferencesSave: build.mutation<
      UserClientReferencesSaveResult,
      UserClientReferencesSaveArgs
    >({
      query: (queryArg) => ({
        url: `/api/user/profile/save-client-references`,
        method: 'POST',
        body: queryArg.clientReferences,
      }),
    }),
    userArrangersSave: build.mutation<
      UserArrangersSaveResult,
      UserArrangersSaveArgs
    >({
      query: (queryArg) => ({
        url: `/api/user/profile/save-arrangers`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    userTravelPassCorporateSave: build.mutation<
      UserTravelPassCorporateSaveResult,
      UserTravelPassCorporateSaveArgs
    >({
      query: (queryArg) => ({
        url: `/api/user/profile/save-travel-pass-corporate`,
        method: 'POST',
        body: queryArg.travelPassCorporateUpdateModel,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as userApi };
export type SearchArrangersResult =
  /** status 200 Success */ IResultIListArrangerData;
export type SearchArrangersArgs = {
  searchArrangersRequest: SearchArrangersRequest;
};
export type UserProfileDetailsResult =
  /** status 200 Success */ IResultGetUserProfileDto;
export type UserProfileDetailsArgs = void;
export type UserPaymentMethodsResult =
  /** status 200 Returns the available method of payments for the authorized user and the provided lac */ UserPaymentsResponse;
export type UserPaymentMethodsArgs = {
  lacNumber?: string;
};
export type UserPasswordSaveResult =
  /** status 200 Success */ IResultPasswordChangeResponse;
export type UserPasswordSaveArgs = {
  passwordChangeRequest: PasswordChangeRequest;
};
export type UserTravellersSaveResult =
  /** status 200 Success */ IResultIListFrequentTraveller;
export type UserTravellersSaveArgs = {
  body: FrequentTraveller[];
};
export type UserPreferencesSaveResult =
  /** status 200 Success */ IResultUserProfileResponse;
export type UserPreferencesSaveArgs = {
  preferences: Preferences;
};
export type UserPassportsSaveResult =
  /** status 200 Success */ IResultUserProfileResponse;
export type UserPassportsSaveArgs = {
  body: Document[];
};
export type UserVisasSaveResult =
  /** status 200 Success */ IResultUserProfileResponse;
export type UserVisasSaveArgs = {
  body: Document[];
};
export type UserPaymentAndCreditCardsSettingsResult =
  /** status 200 Credit cards and paymentsettings for authorized user was successfully saved. Returns the users form of payments. */ UserPaymentsResponse;
export type UserPaymentAndCreditCardsSettingsArgs = {
  savePaymentAndCreditCardsSettingsDto: SavePaymentAndCreditCardsSettingsDto;
};
export type UserBonusCardsSaveResult =
  /** status 200 Success */ IResultUserProfileResponse;
export type UserBonusCardsSaveArgs = {
  bonusCardsData: BonusCardsData;
};
export type UserContactDataSaveResult =
  /** status 200 Success */ IResultUserProfileResponse;
export type UserContactDataSaveArgs = {
  saveContactDetailsDto: SaveContactDetailsDto;
};
export type UserPersonalDataSaveResult =
  /** status 200 Success */ IResultUserProfileResponse;
export type UserPersonalDataSaveArgs = {
  saveUserPersonalDataDto: SaveUserPersonalDataDto;
};
export type UserClientReferencesSaveResult =
  /** status 200 Success */ IResultUserProfileResponse;
export type UserClientReferencesSaveArgs = {
  clientReferences: ClientReferences;
};
export type UserArrangersSaveResult =
  /** status 200 Success */ IResultUserProfileResponse;
export type UserArrangersSaveArgs = {
  body: ArrangerData[];
};
export type UserTravelPassCorporateSaveResult =
  /** status 200 Success */ IResultUserProfileResponse;
export type UserTravelPassCorporateSaveArgs = {
  travelPassCorporateUpdateModel: TravelPassCorporateUpdateModel;
};
export type ArrangerData = {
  tac?: string | null;
  firstName?: string | null;
  lastName?: string | null;
};
export type IResultIListArrangerData = {
  value?: ArrangerData[] | null;
  errorCode?: string | null;
  message?: string | null;
  isSuccess?: boolean;
};
export type SearchArrangersRequest = {
  name?: string | null;
};
export type Address = {
  street?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
};
export type BonusCardDetails = {
  companyCode?: string | null;
  cardNumber?: string | null;
};
export type BonusCardsData = {
  canAdd?: boolean;
  airlineCards?: BonusCardDetails[] | null;
  hotelCards?: BonusCardDetails[] | null;
  carCards?: BonusCardDetails[] | null;
};
export type CodeDescriptionPair = {
  code?: string | null;
  description?: string | null;
};
export type Preferences = {
  airSeatNumber?: string | null;
  airSeatCode?: string | null;
  airMealCode?: string | null;
  hotelRoomSmoking?: string | null;
  languageCode?: string | null;
  currencyCode?: string | null;
  defaultDepartureCity?: CodeDescriptionPair;
  defaultDestinationCity?: CodeDescriptionPair;
};
export type Payments = {
  businessTripsCardCode?: string | null;
  privateTravelsCardCode?: string | null;
  hotelsAndCarsCardCode?: string | null;
  methodOfPayment?: string | null;
};
export type Roles = {
  travelArranger?: boolean | null;
  tacAdmin?: boolean | null;
  norwegianUser?: boolean | null;
  clientAdminUser?: boolean | null;
  cwtUser?: boolean | null;
  traveller?: boolean | null;
  travelFolder?: boolean | null;
  leisureArranger?: boolean | null;
  groupEventArranger?: boolean | null;
  creditsAdmin?: boolean | null;
  reportUser?: boolean | null;
  easyMember?: boolean | null;
  intercom?: boolean | null;
};
export type TravelPassRouting = {
  originCode?: string | null;
  destinationCode?: string | null;
  carrierCode?: string | null;
  dateFrom?: string | null;
  dateTo?: string | null;
  class?: string | null;
};
export type TravelPass = {
  number?: string | null;
  routings?: TravelPassRouting[] | null;
};
export type ClientReferenceType =
  | 'Employer'
  | 'Reference'
  | 'Department'
  | 'Project'
  | 'SubProject';
export type ClientReference = {
  type?: ClientReferenceType;
  readonly?: boolean;
  label?: string | null;
  description?: string | null;
  value?: string | null;
};
export type Document = {
  number?: string | null;
  issuedDate?: string | null;
  expiryDate?: string | null;
  countryCode?: string | null;
};
export type CreditCardData = {
  code?: string | null;
  number?: string | null;
  expirationMonth?: number;
  expirationYear?: number;
  isLodge?: boolean;
  displayText?: string | null;
};
export type PhoneData = {
  readonly?: boolean;
  type?: string | null;
  number?: string | null;
};
export type EmailData = {
  readonly?: boolean;
  address?: string | null;
  consentUsernameAndPassword?: boolean;
  consentItinerary?: boolean;
  consentReceipt?: boolean;
  consentETicket?: boolean;
};
export type Shortcut = {
  type?: string | null;
  description?: string | null;
};
export type UserProfileResponse = {
  tac?: string | null;
  lac?: string | null;
  lacName?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  employerName?: string | null;
  canEditCredentials?: boolean;
  dateOfBirth?: string | null;
  address?: Address;
  bonusCards?: BonusCardsData;
  preferences?: Preferences;
  payments?: Payments;
  roles?: Roles;
  travelPassRegular?: TravelPass;
  travelPassCorporate?: TravelPass;
  clientReferences?: ClientReference[] | null;
  passports?: Document[] | null;
  visas?: Document[] | null;
  creditCards?: CreditCardData[] | null;
  phoneNumbers?: PhoneData[] | null;
  emails?: EmailData[] | null;
  arrangers?: ArrangerData[] | null;
  shortcuts?: Shortcut[] | null;
};
export type DictionaryItem = {
  text?: string | null;
  value?: string | null;
};
export type ProfileDictionaries = {
  documents?: DictionaryItem[] | null;
  phones?: DictionaryItem[] | null;
  creditCardProviders?: DictionaryItem[] | null;
  planeSeats?: DictionaryItem[] | null;
  hotelSmokeTypes?: DictionaryItem[] | null;
  planeMeals?: DictionaryItem[] | null;
  languages?: DictionaryItem[] | null;
  currencies?: DictionaryItem[] | null;
  frequentFlyerCardTypes?: DictionaryItem[] | null;
  frequentFlyerAirlines?: DictionaryItem[] | null;
  frequentFlyerHotels?: DictionaryItem[] | null;
  frequentFlyerCars?: DictionaryItem[] | null;
  sktpZones?: DictionaryItem[] | null;
  countries?: DictionaryItem[] | null;
  contactEmails?: DictionaryItem[] | null;
};
export type Gender = 'Female' | 'Male';
export type FrequentTraveller = {
  id?: string | null;
  gender?: Gender;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
};
export type GetUserProfileDto = {
  profileData?: UserProfileResponse;
  dictionaries?: ProfileDictionaries;
  frequentFlyers?: FrequentTraveller[] | null;
};
export type IResultGetUserProfileDto = {
  value?: GetUserProfileDto;
  errorCode?: string | null;
  message?: string | null;
  isSuccess?: boolean;
};
export type UserPaymentsCreditCard = {
  isDefault?: boolean;
  code?: string;
  number?: string;
  displayText?: string;
  expirationYear?: number;
  expirationMonth?: number;
  isBusiness?: boolean;
  isPrivate?: boolean;
  isHotel?: boolean;
  isFoid?: boolean;
  isLodge?: boolean;
  cardType?: string;
  countryCode?: string;
  isReadOnly?: boolean;
};
export type UserPaymentsInvoice = {
  isDefault?: boolean;
  code?: string | null;
  displayText?: string | null;
};
export type UserPaymentsResponse = {
  creditCards?: UserPaymentsCreditCard[];
  invoices?: UserPaymentsInvoice[];
};
export type PasswordChangeResponse = {
  loginID?: string | null;
  url?: string | null;
};
export type IResultPasswordChangeResponse = {
  value?: PasswordChangeResponse;
  errorCode?: string | null;
  message?: string | null;
  isSuccess?: boolean;
};
export type PasswordChangeRequest = {
  currentPassword?: string | null;
  newPassword?: string | null;
  newPasswordRepeat?: string | null;
};
export type IResultIListFrequentTraveller = {
  value?: FrequentTraveller[] | null;
  errorCode?: string | null;
  message?: string | null;
  isSuccess?: boolean;
};
export type IResultUserProfileResponse = {
  value?: UserProfileResponse;
  errorCode?: string | null;
  message?: string | null;
  isSuccess?: boolean;
};
export type SavePaymentAndCreditCardsSettingsDto = {
  payments?: Payments;
  cards?: CreditCardData[] | null;
};
export type SaveContactDetailsDto = {
  address?: Address;
  emails?: EmailData[] | null;
  phoneNumbers?: PhoneData[] | null;
};
export type SaveUserPersonalDataDto = {
  userName?: string | null;
  gender?: string | null;
  dateOfBirth?: string | null;
};
export type ClientReferences = {
  employer?: string | null;
  reference?: string | null;
  department?: string | null;
  project?: string | null;
  subProject?: string | null;
};
export type TravelPassCorporateUpdateModel = {
  number?: string | null;
};
