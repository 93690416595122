import * as Yup from 'yup';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import PhoneNumber from 'awesome-phonenumber';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import AddressEdit from 'components/features/user/personalInformation/AddressEdit';
import EmailContactList from 'components/features/user/EmailContactList';
import FormPanel from 'components/common/forms/FormPanel';
import InfoButtonWithDialog from 'components/common/modals/InfoButtonWithDialog';
import PageTitle from 'components/common/pageTitle/PageTitle';
import PhoneNumbers from 'components/features/user/PhoneNumbers';
import useSiteNavigation from 'hooks/navigation/useSiteNavigation';
import { ContactDetails } from 'components/features/user/personalInformation/profile.types';
import { PlainCard } from 'components/common/containers/Card';
import { TextCodes } from 'hooks/texts/text.codes';
import { getEmails, getPhoneNumbers, getProfile } from 'store/user/user.slice';
import { routeConfig } from 'routes/config/route.config';
import { useAppSelector } from 'store/store';
import { useDictionary } from 'hooks';
import { userApi } from 'services/api/user';

export const ContactDetailsForm = () => {
  // Selectors
  const profile = useAppSelector(getProfile);
  const phoneNumbers = useAppSelector(getPhoneNumbers);
  const emails = useAppSelector(getEmails);

  //Hooks
  const navigate = useSiteNavigation();
  const { t } = useTranslation();
  const [updateContactDetails, { isLoading, data }] =
    userApi.useUserContactDataSaveMutation();
  const [_, getCountryCode] = useDictionary('countries');

  // Form definition
  const formMethods = useForm<ContactDetails>({
    resolver: yupResolver(contactDetailsValidationSchema),
    defaultValues: {
      address: {
        ...profile.address,
        country: getCountryCode(profile.address?.country),
      },
      phoneNumbers:
        phoneNumbers.length > 0
          ? phoneNumbers
          : [
              ...phoneNumbers,
              {
                type: '5GS',
                number: '',
                readonly: false,
              },
            ],
      emails:
        emails.length > 0
          ? emails
          : [
              ...emails,
              {
                address: '',
                consentUsernameAndPassword: false,
                consentItinerary: false,
                consentReceipt: false,
                consentETicket: false,
              },
            ],
      didDelete: false,
    },
  });
  const didDelete = formMethods.watch('didDelete');
  const formEmails = formMethods.watch('emails');
  const formPhoneNumbers = formMethods.watch('phoneNumbers');

  // Handles successful submit
  useEffect(() => {
    if (data?.isSuccess) {
      navigate(routeConfig.PersonalInformation.path, {
        replace: true,
        scrollToTop: true,
        toast: {
          severity: 'success',
          autoHideDuration: 3000,
          message: t(TextCodes.WG6.Status.ChangesSavedSuccessfully),
        },
      });
    }
  }, [data, navigate, t]);

  const onSubmit = (data: ContactDetails) => {
    const formData = {
      address: data.address,
      emails: data.emails.filter((email) => !isEmpty(email.address)),
      phoneNumbers: data.phoneNumbers.filter((phone) => !isEmpty(phone.number)),
    };
    updateContactDetails({ saveContactDetailsDto: formData });
  };

  // Show validation error first, then API error if any.
  const priorityError = useMemo(() => {
    if (!isEmpty(formMethods.formState.errors))
      return t(TextCodes.WG6.Validation.HighlightedFieldsRequired);

    if (data?.message) return data?.message;
  }, [data?.message, formMethods.formState.errors]);

  let saveMessage = '';
  if (formEmails.length === 0 && formPhoneNumbers.length === 0)
    saveMessage = t(TextCodes.WG6.Contact.NoContactInfo);
  else if (formEmails.length === 0)
    saveMessage = t(TextCodes.WG6.Contact.AtLeastOneEmail);

  return (
    <Container>
      <PageTitle
        sx={{ mb: 1 }}
        title={t(TextCodes.WG6.Profile.Contact)}
        button={
          <InfoButtonWithDialog popupText={t(TextCodes.WG6.Contact.Popup)} />
        }
      />
      <PlainCard>
        <FormProvider {...formMethods}>
          <Grid component='form' container gap={2}>
            <PhoneNumbers />
            <EmailContactList />
            <AddressEdit />
            <Grid item xs={12}>
              <FormPanel
                errorMessage={priorityError || ''}
                isLoading={isLoading}
                onSubmit={formMethods.handleSubmit(onSubmit)}
                shouldShowCancelWarning={
                  didDelete || formMethods.formState.isDirty
                }
                shouldShowSaveWarning={didDelete}
                saveMessage={saveMessage}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </PlainCard>
    </Container>
  );
};

// Validation schema
export const contactDetailsValidationSchema = Yup.object().shape({
  address: Yup.object().shape({
    street: Yup.string().optional(),
    zipCode: Yup.string().optional(),
    city: Yup.string().optional(),
    country: Yup.string().optional(),
  }),
  phoneNumbers: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required(TextCodes.WG6.Validation.Required),
      number: Yup.string().test(
        'phoneNumber',
        TextCodes.WG6.Validation.PhoneNumberFormat,
        function (value) {
          // If field is empty, no need to validate
          if (value === undefined || value === null || value === '')
            return true;

          const number = value || '';
          const result = PhoneNumber(number);

          return result.isValid();
        }
      ),
    })
  ),
  emails: Yup.array().of(
    Yup.object()
      .shape({
        address: Yup.string()
          .email(TextCodes.WG6.Validation.EmailFormat)
          .required(TextCodes.WG6.Validation.Required)
          .optional(),
        emailConsentMessage: Yup.string().test(
          'emailConsentMessage',
          TextCodes.WG6.Validation.OneCheckRequired,
          (_: any, context: any) => {
            if (!context.parent.address) return true;
            return (
              context?.parent?.consentETicket ||
              context?.parent?.consentItinerary ||
              context?.parent?.consentReceipt ||
              context?.parent?.consentUsernameAndPassword
            );
          }
        ),
      })
      .optional()
  ),
});
