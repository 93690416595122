import { Breadcrumbs, Skeleton } from '@mui/material';
import { StandardHeaderProps } from 'components/common/headers/StandardHeader';
import BreadcrumbLink from 'components/features/breadcrumbs/BreadcrumbLink';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @param amountOfBreadcrumbs - number of breadcrumbs to show
 * @param finalCrumbLabel - label for the final breadcrumb
 * @param isLoading - Will show a loading effect on the final crumb if ture
 */
const BreadcrumbList = ({
  amountOfBreadcrumbs,
  finalCrumbLabel,
  isLoading,
}: StandardHeaderProps) => {
  const location = useLocation();
  const paths = useMemo(() => {
    const pathNames = location.pathname.split('/');
    let pathBuilder: string[] = [];
    for (const path of pathNames) {
      if (!path) continue;
      pathBuilder.push(pathBuilder.join('') + `/${path}`);
    }
    pathBuilder.unshift('/');
    return pathBuilder;
  }, [location]);

  return (
    <Breadcrumbs sx={{ mt: 1.5 }} maxItems={amountOfBreadcrumbs ?? 3}>
      {paths.map((path, index) =>
        isLoading && index === paths.length - 1 ? (
          <Skeleton key={path} width={60} height={16} />
        ) : (
          <BreadcrumbLink
            breadcrumb={
              index === paths.length - 1 ? finalCrumbLabel : undefined
            }
            isLast={index === paths.length - 1}
            key={path}
            to={path}
          />
        )
      )}
    </Breadcrumbs>
  );
};

export default BreadcrumbList;
