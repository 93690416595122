import { Grid, Typography, useTheme } from '@mui/material';
import {
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineDot,
  TimelineSeparator,
} from '@mui/lab';
import { useTranslation } from 'react-i18next';

import HighlightedText from 'components/common/highlightedText/HighlightedText';
import { TextCodes } from 'hooks';
import { punctualDate, timeOfDate } from 'services/text/datetime-format';

interface ArrivalFlightPointProps {
  city: string | undefined;
  dateDifference: number;
  originalDate: string | undefined;
  updatedDate: string | undefined;
}

const ArrivalFlightPoint = ({
  city,
  dateDifference,
  originalDate,
  updatedDate,
}: ArrivalFlightPointProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <TimelineItem sx={{ mt: '2.5px', minHeight: 'unset' }}>
      <Separator />
      <TimelineContent sx={{ py: 0, marginTop: 'auto' }}>
        <Grid container alignItems='center'>
          <Typography variant='caption' fontSize={12}>
            {punctualDate(updatedDate || originalDate)}
          </Typography>
        </Grid>
        <Typography
          fontWeight='bold'
          variant='body2'
          component='p'
          sx={{
            mt: 0.5,
            display: updatedDate ? 'flex' : '',
            alignItems: 'flex-start',
          }}
        >
          {updatedDate ? (
            <Typography
              fontWeight='bold'
              variant='body2'
              sx={{
                mr: 1,
                color: theme?.palette?.warning?.main,
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              {timeOfDate(updatedDate)}
              <Typography
                variant='caption'
                color='text.secondary'
                sx={{ textDecoration: 'line-through' }}
              >
                {timeOfDate(originalDate)}
              </Typography>
            </Typography>
          ) : (
            <Typography
              sx={{ mr: 1 }}
              fontWeight='bold'
              variant='body2'
              fontSize={14}
            >
              {timeOfDate(originalDate)}
            </Typography>
          )}
          {dateDifference > 0 && (
            <HighlightedText type='warning'>
              {`+${dateDifference} ${t(TextCodes.WG6.Base.Day)}`}
            </HighlightedText>
          )}
          {city}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ArrivalFlightPoint;

const Separator = () => {
  return (
    <>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineConnector />
        <TimelineConnector />
        <TimelineConnector className='small' />
        <TimelineDot sx={{ padding: '1px', my: 0 }} />
      </TimelineSeparator>
    </>
  );
};
