import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FlightIcon from '@mui/icons-material/Flight';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import FlightTimeline from 'components/common/timeline/flight/FlightTimeline';
import OrderDetailsStatusBar from 'components/features/orderDetails/OrderDetailsStatusBar';
import { ItineraryInfo } from 'services/api/itinerary';
import { TextCodes } from 'hooks';
import { Warning } from '@mui/icons-material';
import { durationToString } from 'services/text/datetime-format';
import { getAirlineLogo } from 'utils/asset.utils';
import {
  setAirFareRulesDrawer,
  setFlightNumberModal,
  setShowNotImplementedModal,
} from 'store/app/app.slice';
import { useAppDispatch } from 'store/store';
import BaggageInfoButton from 'components/common/flight/BaggageInfoButton';

interface FlightSegmentDetailsProps extends ItineraryInfo {
  searchString: string | undefined;
}

const FlightSegmentDetails = ({
  segments,
  searchString,
}: FlightSegmentDetailsProps) => {
  // Hooks 🎣
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const openNotImplementedModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(setShowNotImplementedModal(true));
  };

  // This is just a temporarily hack until we get all the logos
  const logosWeCurrentlyHave = ['DY', 'KL', 'SK'];

  return (
    <Stack>
      {segments?.map((segment, index) => {
        const flightName = `${segment?.flightCarrierCode} ${segment?.flightNo}`;
        const doesNextFlightDepartureFromArrivalAirport =
          segments[index + 1]?.departureCityCode === segment?.arrivalCityCode ||
          segments[index + 1]?.departureCityCode === undefined;

        const onClick = () => {
          dispatch(
            setFlightNumberModal({
              isOpen: true,
              flightBookingClass: segment?.flightBookingClass,
              flightCarrierCode: segment?.flightCarrierCode,
              flightNo: segment?.flightNo,
              flightClassOfService: segment?.flightClassOfService,
              flightFareBasis: segment?.flightFareBasis,
              flightCarrierName: segment?.flightCarrierName,
              flightEquipment: segment?.flightEquipment,
            })
          );
        };

        return (
          <Fragment key={`${segment.segmentId}-${index}`}>
            <Card
              sx={{
                backgroundColor: theme.palette.backgroundColors?.selectedCard,
                border: `1px solid ${theme.palette.other?.divider}`,
                boxShadow: 0,
                borderRadius:
                  index === 0 && segments?.length !== 1
                    ? '4px 4px 0px 0px'
                    : index + 1 !== segments?.length && segments?.length !== 1
                    ? '0px'
                    : index + 1 === segments?.length && segments?.length !== 1
                    ? '0px 0px 4px 4px'
                    : '4px',
              }}
            >
              <Box sx={{ pt: 2 }}>
                <Stack sx={{ px: 1 }}>
                  <OrderDetailsStatusBar
                    statusText={segment?.statusText}
                    statusCode={segment?.statusCode}
                    justifyContent='flex-end'
                  />
                </Stack>
                <Stack direction='column'>
                  <Container
                    sx={{ display: 'flex', alignItems: 'center', px: 1 }}
                  >
                    <Box>
                      <>
                        {/* TODO: Add logos of low and high res */}
                        {logosWeCurrentlyHave.includes(
                          segment?.flightCarrierCode!!
                        ) ? (
                          <img
                            src={getAirlineLogo(segment?.flightCarrierCode!!)}
                            height={'26px'}
                            width={'28px'}
                            alt={segment?.flightCarrierName}
                          />
                        ) : (
                          <FlightIcon sx={{ height: '20px', width: '20px' }} />
                        )}
                      </>
                    </Box>
                    <Button sx={{ pl: 2 }} variant='text' onClick={onClick}>
                      <Typography fontSize={13} variant='button'>
                        {flightName}
                      </Typography>
                      <ArrowForwardIcon
                        sx={{ ml: 1.5, width: '16px', height: '16px' }}
                      />
                    </Button>
                  </Container>
                  <FlightTimeline
                    arrival={segment?.arrivalCityDisplayName}
                    departure={segment?.departureCityDisplayName}
                    duration={segment?.segmentTime || 0}
                    gate={segment?.gateNo}
                    originalArrivalDate={segment?.arrivalDateTimeOriginal!!}
                    originalDepartureDate={segment?.departureDateTimeOriginal!!}
                    seat={segment?.seatNo}
                    updatedArrivalDate={segment?.arrivalDateTimeUpdated!!}
                    updatedDepartureDate={segment?.departureDateTimeUpdated!!}
                    flightNumberOfStops={segment?.flightNumberOfStops}
                  />
                  <Divider sx={{ mt: 2 }} />
                  <Button
                    onClick={openNotImplementedModal}
                    variant='text'
                    size='small'
                    sx={{ py: 1.5, px: 1 }}
                  >
                    <Typography>
                      {segment?.seatNo
                        ? `${t(TextCodes.WG6.OrderDetails.ChangeSeat)} (${
                            segment?.seatNo
                          })`
                        : t(TextCodes.WG6.OrderDetails.ChooseSeat)}
                    </Typography>
                    <KeyboardArrowRightIcon sx={{ ml: 'auto' }} />
                  </Button>
                  <Divider />

                  {/* Baggage Information 💼 */}
                  <BaggageInfoButton
                    isOpen={true}
                    departureCityName={segment?.departureCityName ?? ''}
                    arrivalCityName={segment?.arrivalCityName ?? ''}
                    baggageValue={segment?.baggageValue ?? 0}
                    baggageCode={segment?.baggageTypeCode ?? ''}
                  />

                  <Divider />

                  {/* Air Fare Rules 📋✈️ */}
                  <Button
                    variant='text'
                    sx={{ py: 1.5 }}
                    onClick={() =>
                      dispatch(
                        setAirFareRulesDrawer({
                          isOpen: true,
                          departureCityName: segment?.departureCityName ?? '',
                          arrivalCityName: segment?.arrivalCityName ?? '',
                          searchString: searchString ?? '',
                        })
                      )
                    }
                  >
                    <Typography>{t(TextCodes.WG6.Flight.Rules)}</Typography>
                    <KeyboardArrowRightIcon sx={{ ml: 'auto' }} />
                  </Button>
                </Stack>
              </Box>
            </Card>
            {!!segments[index + 1]?.timeToWait &&
              doesNextFlightDepartureFromArrivalAirport && (
                <>
                  <Typography
                    variant='body2'
                    sx={{
                      py: 2,
                      px: 1,
                      borderInline: `1px solid ${theme.palette.other?.divider}`,
                    }}
                  >
                    <Typography fontWeight='bold'>
                      {`${t(TextCodes.WG6.OrderDetails.FlightTransfer)}: `}{' '}
                    </Typography>

                    {`${t(
                      TextCodes.WG6.OrderDetails.TimeToNextFlight
                    )} ${durationToString(segments[index + 1]?.timeToWait)}`}
                  </Typography>
                </>
              )}
            {!!segments[index + 1]?.timeToWait &&
              !doesNextFlightDepartureFromArrivalAirport && (
                <Stack
                  direction='row'
                  alignItems='center'
                  spacing={2}
                  sx={{
                    backgroundColor: theme?.palette?.warning?.background,
                    p: 2,
                    borderInline: `1px solid ${theme.palette.other?.divider}`,
                  }}
                >
                  <Warning sx={{ color: theme?.palette?.warning?.main }} />
                  <Typography
                    variant='body2'
                    sx={{
                      color: theme?.palette?.warning?.textColor,
                    }}
                  >
                    {t(TextCodes.WG6.Flight.ChangeAirportWarning)}
                    {` ${t(
                      TextCodes.WG6.OrderDetails.TimeToNextFlight
                    )} ${durationToString(segments[index + 1]?.timeToWait)}`}
                  </Typography>
                </Stack>
              )}
          </Fragment>
        );
      })}
    </Stack>
  );
};

export default FlightSegmentDetails;
