import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useTheme } from '../../../hooks/theme/theme.hooks';

export const ThemeSwitch = () => {
  const { isDarkTheme, switchTheme } = useTheme();
  return (
    <FormGroup sx={{ ml: 2, mt: 1 }}>
      <FormControlLabel control={<Switch checked={isDarkTheme} onClick={switchTheme} />} label='Switch theme' />
    </FormGroup>
  );
};
