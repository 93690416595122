import Collapse from '@mui/material/Collapse';
import dayjs from 'dayjs';
import validateCard from 'card-validator';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import FormPanel from 'components/common/forms/FormPanel';
import NewCreditCards from 'components/features/user/payment-settings/components/NewCreditCards';
import PaymentPreferences from 'components/features/user/payment-settings/components/PaymentPreferences';
import RegisteredCreditCards from 'components/features/user/payment-settings/components/RegisteredCreditCards.';
import usePayment from 'hooks/payment/usePayment';
import useSiteNavigation from 'hooks/navigation/useSiteNavigation';
import yup from 'components/validators/yup';
import { CreditCardData } from 'services/api/user';
import { PaymentOption } from 'store/payment/payment.slice';
import { PaymentSettingsFields } from 'components/features/user/payment-settings/payment.types';
import { PlainCard } from 'components/common/containers/Card';
import { TextCodes } from 'hooks/texts/text.codes';
import { routeConfig } from 'routes/config/route.config';

export const newCreditCardDefaultValues: PaymentOption = {
  displayText: '',
  expiration: null,
  code: '',
  type: 'creditCard',
};

const PaymentSettingsForm = () => {
  // Hooks 🎣
  const { t } = useTranslation();
  const {
    isSavingPaymentDetails,
    savePaymentError,
    savePaymentDetails,
    didSavePaymentDetails,
    paymentPreferences,
    paymentMethods,
    invoices,
  } = usePayment();
  const navigate = useSiteNavigation();

  // Form
  const formMethods = useForm<PaymentSettingsFields>({
    resolver: yupResolver(bankCardValidationSchema),
    defaultValues: {
      cards:
        paymentMethods?.filter(
          (mthd) => mthd.type === 'creditCard' && !mthd.isLodge
        ) ?? [],
      lodgeCards: paymentMethods?.filter((mthd) => mthd.isLodge) ?? [],
      newCards:
        paymentMethods?.length === 0 ? [newCreditCardDefaultValues] : [],
      payments: {
        businessTripsCardCode: paymentPreferences?.businessTripsCardCode,
        hotelsAndCarsCardCode: paymentPreferences?.hotelsAndCarsCardCode,
        privateTravelsCardCode: paymentPreferences?.privateTravelsCardCode,
        methodOfPayment: paymentPreferences?.methodOfPayment,
      },
      didDeleteCards: false,
    },
  });
  const didDelete = formMethods.watch('didDeleteCards');
  const registeredCards: PaymentOption[] = formMethods.watch('cards');
  const newCards: PaymentOption[] = formMethods.watch('newCards');
  const lodgeCards: PaymentOption[] = formMethods.watch('lodgeCards');

  useEffect(() => {
    if (didSavePaymentDetails)
      navigate(routeConfig.User.path, {
        replace: true,
        scrollToTop: true,
        toast: {
          message: t(TextCodes.WG6.Status.ChangesSavedSuccessfully),
          severity: 'success',
        },
      });
  }, [didSavePaymentDetails]);

  // If there are no valid cards in registeredCards or newCards, hide payment selects.
  let shouldHidePaymentSelect = true;
  if (isEmpty(registeredCards) && isEmpty(lodgeCards) && isEmpty(invoices)) {
    for (const card of newCards) {
      if (validateCard.number(card.number).isValid) {
        shouldHidePaymentSelect = false;
        break;
      }
    }
  } else {
    shouldHidePaymentSelect = false;
  }

  const onSubmit = (data: PaymentSettingsFields) => {
    const existingCards = data.cards.filter((card) => !card.isLodge);
    let cardsToSubmit: CreditCardData[] = existingCards ?? [];

    for (const card of data.newCards) {
      // Since we can accept an empty default value, we have to check for it.
      if (isEmpty(card.displayText)) continue;
      const formattedCard = {
        ...card,
        number: '',
        expirationMonth: dayjs(card.expiration).month() + 1,
        expirationYear: dayjs(card.expiration).year(),
        isLodge: false,
      };

      cardsToSubmit.push(formattedCard);
    }

    savePaymentDetails({
      savePaymentAndCreditCardsSettingsDto: {
        cards: cardsToSubmit,
        payments: data.payments,
      },
    });
  };

  return (
    <PlainCard sx={{ gap: 2 }}>
      <FormProvider {...formMethods}>
        <RegisteredCreditCards />
        <NewCreditCards />
        <Collapse
          sx={{ width: '100%' }}
          in={!shouldHidePaymentSelect}
          unmountOnExit
        >
          <PaymentPreferences />
        </Collapse>
        <FormPanel
          hideDivider={shouldHidePaymentSelect}
          isLoading={isSavingPaymentDetails}
          errorMessage={savePaymentError}
          shouldShowCancelWarning={didDelete || formMethods.formState.isDirty}
          shouldShowSaveWarning={didDelete}
          onSubmit={formMethods.handleSubmit(onSubmit)}
        />
      </FormProvider>
    </PlainCard>
  );
};

// Validation form
export const bankCardValidationSchema = yup.object().shape({
  newCards: yup.array().of(
    yup.object().shape({
      displayText: yup
        .string()
        .required(TextCodes.WG6.Validation.Required)
        .test(
          'validation',
          TextCodes.WG6.Validation.BankCardFormat,
          (value) => {
            const cardValidationResult = validateCard.number(value);
            if (cardValidationResult.isValid) return true;

            return false;
          }
        ),
      expiration: yup
        .string()
        .disallowPastDate(
          TextCodes.WG6.Validation.DateCannotBeInPast,
          TextCodes.WG6.Validation.InvalidExpirationDateForBankCards
        ),
    })
  ),
});

export default PaymentSettingsForm;
