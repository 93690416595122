import { Button, Text } from '..';
import { Dialog as MdDialog, ContainerProps, DialogContent, DialogContentText, DialogTitle, DialogActions } from '@mui/material';
import { TextCodes } from '../../../hooks';
import { useTranslation } from 'react-i18next';

export type ModalProps = Pick<ContainerProps, 'maxWidth'> & {
  open?: boolean;
  title?: string;
  children?: React.ReactNode;
  onClose?: Function;
  buttons?: React.ReactNode | React.ReactNode[];
};

export const Modal = ({ children, buttons, onClose, title, maxWidth = false, open = true }: ModalProps) => {
  const closeHandler = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };
  return (
    <MdDialog
      open={open}
      onClose={closeHandler}
      maxWidth={maxWidth}
      fullWidth
      sx={{
        zIndex: 99999,
      }}
    >
      {title && (
        <DialogTitle>
          <Text component='span' variant='h6' bold>
            {title}
          </Text>
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      {buttons && <DialogActions>{buttons}</DialogActions>}
    </MdDialog>
  );
};

export type DialogProps = ModalProps & {
  confirmLabel?: string;
  onConfirm?: Function;
  cancelLabel?: string;
  onCancel?: Function;
};

export const Dialog = (props: DialogProps) => {
  const { t } = useTranslation();

  const defaultConfirmLabel = t(TextCodes.WG6.Navigation.Save);
  const defaultCancelLabel = t(TextCodes.WG6.Navigation.Cancel);
  const { confirmLabel = defaultConfirmLabel, onConfirm, cancelLabel = defaultCancelLabel, onCancel, ...rest } = props;
  let buttons = [];
  if (typeof onCancel === 'function') {
    buttons.push(
      <Button key='cancelButton' onClick={() => onCancel()} variant='text'>
        {cancelLabel}
      </Button>
    );
  }
  if (typeof onConfirm === 'function') {
    buttons.push(
      <Button key='confirmButton' onClick={() => onConfirm()} variant='text'>
        {confirmLabel}
      </Button>
    );
  }
  return <Modal buttons={buttons} {...rest} />;
};
