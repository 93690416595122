import { Components } from '@mui/material';
import { pxToRem } from 'theme/theme.utils';

export const fabStyles: Components = {
  MuiFab: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        lineHeight: '150%',
        letterSpacing: '0.15px',
        textTransform: 'unset',
        fontSize: pxToRem[16],
        boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
      },
      extended: {
        display: 'flex',
        gap: '8px',
      },
    },
    variants: [
      {
        props: { variant: 'extended', size: 'small' },
        style: { padding: '5px 8px' },
      },
      {
        props: { variant: 'extended', size: 'medium' },
        style: { padding: '8px 16px' },
      },
      {
        props: { variant: 'extended', size: 'large' },
        style: { padding: '12px 16px' },
      },

      {
        props: { variant: 'circular', size: 'small' },
        style: { padding: '8px' },
      },
      {
        props: { variant: 'circular', size: 'medium' },
        style: { padding: '12px' },
      },
      {
        props: { variant: 'circular', size: 'large' },
        style: { padding: '16px' },
      },
    ],
  },
};
