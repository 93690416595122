import IconWrapper from '@mui/material/Icon';

interface IconsProps {
  children: React.ReactNode;
  color?: string;
  height?: number;
  width?: number;
}

const Icon = ({ children, width = 24, height = 24, color = '#000' }: IconsProps) => {
  return <IconWrapper sx={{ fill: color, height, width, display: 'flex' }}>{children}</IconWrapper>;
};

export default Icon;
