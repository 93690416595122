import { Close } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

interface ClearInputIconProps {
  isVisible: boolean;
  onClick: () => void;
  disabled?: boolean | undefined;
}

const ClearInputIcon = ({
  onClick,
  isVisible,
  disabled,
}: ClearInputIconProps) => {
  const show = isVisible && !disabled;

  return (
    <InputAdornment
      position='end'
      sx={{ mr: -1, visibility: show ? 'visible' : 'hidden' }}
    >
      <IconButton aria-label='clear' onClick={onClick}>
        <Close />
      </IconButton>
    </InputAdornment>
  );
};

export default ClearInputIcon;
