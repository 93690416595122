import { Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/common/pageTitle/PageTitle';
import StandardHeader from 'components/common/headers/StandardHeader';
import { TextCodes } from 'hooks';
import { UserIndex } from 'components/features/user/UserIndex';

// Generic wrapper for the user section subpages
export default function UserPage() {
  const { t } = useTranslation();

  return (
    <>
      <StandardHeader />
      <Container>
        <PageTitle title={t(TextCodes.WG6.User.SectionTitle)} />
        <Grid aria-live='polite'>
          <UserIndex />
        </Grid>
      </Container>
    </>
  );
}
