import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface PageTitleProps {
  title: string;
  button?: ReactNode;
  sx?: SxProps<Theme>;
}

/**
 *
 * @param button - Here you can add IconButtons like info icon with modal as a property. You can see an example in EditVisaPage.tsx
 */
const PageTitle = ({ title, button, sx }: PageTitleProps) => (
  <Stack
    direction='row'
    justifyContent='space-between'
    alignItems='center'
    spacing={2}
    sx={{
      mt: 3,
      mb: 1,
      wordBreak: 'break-word',
      ...sx,
    }}
  >
    <Typography variant='h4' component='h1' fontWeight='bold'>
      {title}
    </Typography>
    {button && button}
  </Stack>
);

export default PageTitle;
