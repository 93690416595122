import { useTranslation } from 'react-i18next';
import { ActionResultMessage } from '../../components/common';
import { CenteredLayout } from '../../components/layouts';
import { TextCodes } from '../../hooks';

export const SetPasswordSuccessPage = () => {
  const { t } = useTranslation();

  return (
    <CenteredLayout maxWidth='xs'>
      <ActionResultMessage severity='success' title={t(TextCodes.WG6.ForgottenPassword.SetPasswordSuccessTitle)}>
        {t(TextCodes.WG6.ForgottenPassword.SetPasswordSuccessDescription)}
      </ActionResultMessage>
    </CenteredLayout>
  );
};
