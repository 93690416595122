export const useLocalStorage = (key: string, initialValue: any) => {
  let storedValue;
  try {
    const item = window?.localStorage?.getItem(key);
    storedValue = item ? JSON.parse(item) : initialValue;
  } catch (error) {
    storedValue = initialValue;
  }

  const setValue = (newValue: any) => {
    window?.localStorage?.setItem(key, JSON.stringify(newValue));
  };

  return [storedValue, setValue];
};
