import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Password from '@mui/icons-material/Password';
import Payment from '@mui/icons-material/Payment';
import SupportAgent from '@mui/icons-material/SupportAgent';
import Typography from '@mui/material/Typography';

import { LinkInternal } from 'components/common';
import { NotImplementedButton } from 'components/common/not-implemented/NotImplementedModal';
import { useProfileMenuDrawer } from 'hooks/menu/menu.hooks';
import { useTheme } from 'hooks/theme/theme.hooks';
import { useAppSelector } from 'store/store';
import { getProfile } from 'store/user/user.slice';

const ProfileInformationAndShortCuts = () => {
  const profile = useAppSelector(getProfile);
  const { currentTheme } = useTheme();
  const { toggleProfileMenuDrawer } = useProfileMenuDrawer();

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Avatar
          sx={{
            width: 40,
            height: 40,
            backgroundColor: currentTheme.palette.primary.light,
            fontSize: 18,
            fontFamiliy: 'Inter',
          }}
          color='primary.light'
        >
          {profile?.firstName?.charAt(0).toUpperCase()}
        </Avatar>
        <Box>
          <Typography
            variant='h4'
            align='center'
            fontWeight={400}
          >{`${profile?.firstName} ${profile?.lastName}`}</Typography>
          {profile?.emails?.[0]?.address && (
            <Typography variant='body1' paragraph align='center'>
              {profile?.emails?.[0]?.address}
            </Typography>
          )}
        </Box>
      </Box>

      <List
        sx={{
          display: 'flex',
          gap: '32px',
          justifyContent: 'center',
          paddingBottom: 2,
        }}
      >
        <ListItem>
          <LinkInternal
            routeId='UserPassword'
            onClick={toggleProfileMenuDrawer}
          >
            <Password color='primary' sx={{ width: '32px', height: '32px' }} />
          </LinkInternal>
        </ListItem>
        <ListItem>
          <LinkInternal routeId='UserPayment' onClick={toggleProfileMenuDrawer}>
            <Payment color='primary' sx={{ width: '32px', height: '32px' }} />
          </LinkInternal>
        </ListItem>
        <ListItem>
          <NotImplementedButton>
            <SupportAgent
              color='primary'
              sx={{ width: '32px', height: '32px' }}
            />
          </NotImplementedButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default ProfileInformationAndShortCuts;
