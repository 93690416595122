import { Alert, List, ListItem, Typography, Stack, Box } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrangerData, IResultIListArrangerData } from 'services/api/user';
import { TextCodes } from 'hooks';
import { Checkbox } from 'components/common';

interface SearchResultProps {
  isSearching: boolean;
  isUninitialized: boolean;
  searchResult: IResultIListArrangerData;
  searchValue: string;
  selectedTravelArrangers: ArrangerData[];
  updateSelectedTravelArrangers: (arranger: ArrangerData) => void;
}

const TravelArrangersSearchResult = ({
  isSearching,
  isUninitialized,
  searchResult,
  searchValue,
  selectedTravelArrangers,
  updateSelectedTravelArrangers,
}: SearchResultProps) => {
  // Hooks/Variables
  const { t } = useTranslation();
  const isError: boolean = searchResult?.isSuccess === false;
  const message: string = searchResult?.message || '';
  const travelArrangers: ArrangerData[] = searchResult?.value || [];

  // Sorts travel arrangers by last name alphabetically
  const sortedTravelArrangers = [...travelArrangers]?.sort((a, b) =>
    (a?.lastName || '') > (b?.lastName || '') ? 1 : -1
  );

  return (
    <Box sx={{ mt: 2 }}>
      {/* Search result */}
      {sortedTravelArrangers?.length > 0 && (
        <>
          <Typography variant='body2' color='text.secondary' paragraph>
            {t(TextCodes.WG6.TravelArrangers.ArrangersFoundLabel)}
            &nbsp;{searchValue} ({sortedTravelArrangers?.length}{' '}
            {t(TextCodes.WG6.TravelArrangers.ArrangersHitsLabel)})
          </Typography>
          <List
            sx={{
              p: 0,
            }}
            component={Stack}
            spacing={2}
          >
            {sortedTravelArrangers?.map((arranger) => (
              <Fragment key={arranger.tac}>
                <ListItem
                  sx={{
                    p: 0,
                  }}
                >
                  <Checkbox
                    onChange={() => updateSelectedTravelArrangers(arranger)}
                    label={`${arranger.firstName} ${arranger.lastName}`}
                    name={arranger.tac || ''}
                    value={selectedTravelArrangers.some(
                      (selectedArranger) =>
                        selectedArranger.tac === arranger.tac
                    )}
                  />
                </ListItem>
              </Fragment>
            ))}
          </List>
        </>
      )}

      {/* No search result */}
      {!sortedTravelArrangers?.length &&
        !isUninitialized &&
        !isError &&
        !isSearching && (
          <Typography variant='body2' fontWeight={700} paragraph>
            {t(TextCodes.WG6.TravelArrangers.NoSearchResults)} {searchValue}
          </Typography>
        )}

      {/* Error */}
      {isError && <Alert severity='error'>{message}</Alert>}
    </Box>
  );
};

export default TravelArrangersSearchResult;
