import {
  Card,
  Typography,
  Divider,
  useTheme,
  IconButton,
  Box,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { motion } from 'framer-motion';
import CollapsableBox from 'components/layouts/CollapsableBox';
import { useAppDispatch } from 'store/store';
import { setShowNotImplementedModal } from 'store/app/app.slice';
import { visuallyHidden } from '@mui/utils';
import { TextCodes } from 'hooks';
import { useTranslation } from 'react-i18next';
const _animDur = 0.3;

interface PriceDetailsProps {
  price: number;
  currency: string;
  text?: string;
  disclaimer: string;
  children?: ReactNode | ReactNode[];
}

const PriceDetails = ({
  price,
  currency,
  text,
  disclaimer,
  children,
}: PriceDetailsProps) => {
  // Hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Local state
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card elevation={0}>
      <Box
        display='flex'
        flexDirection='column'
        sx={{
          backgroundColor: theme.palette.backgroundColors?.selectedCard,
          p: 2,
        }}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          gap={1}
          sx={{ width: '100%', marginTop: '-8px' }}
        >
          <IconButton
            component={motion.button}
            animate={{
              rotate: isOpen ? 180 : 0,
              transition: { duration: _animDur },
            }}
            style={{ marginRight: '-8px' }}
            onClick={() => dispatch(setShowNotImplementedModal(true))}
          >
            <Typography sx={visuallyHidden}>
              {t(TextCodes.WG6.Hidden.ExpandPriceDetails)}
            </Typography>
            <ExpandCircleDownOutlinedIcon
              sx={{ fill: theme.palette.primary.main, height: 16, width: 16 }}
            />
          </IconButton>
          <Typography fontWeight='bold' variant='h4'>
            {price}
          </Typography>
          <Typography variant='body2'>{currency}</Typography>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          sx={{ width: '100%' }}
        >
          <Typography variant='body2'>{text}</Typography>
        </Box>
        <CollapsableBox
          isCollapsed={!isOpen}
          sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
        >
          <Divider sx={{ width: '100%', borderStyle: 'dashed' }} />
          {children}
        </CollapsableBox>
        <Divider sx={{ my: 1 }} />
        <Typography variant='caption'>{disclaimer}</Typography>
      </Box>
    </Card>
  );
};

export default PriceDetails;
