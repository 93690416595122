
/**
 * Adds one or more eventlisteners to the provided element.
 * @param events - The events to listen to.
 * @param handler - the handler to call when the events are fired.
 * @param element - element to attach the event to.
 */
export const addEventListeners = (events: string | string[], handler: any, element: Element | Document | Window) => {
    if (Array.isArray(events)) {
        events.forEach(event => element.addEventListener(event, handler));
    }
    else {
        element.addEventListener(events, handler);
    }
}

/**
 * Removes all of the provided eventlisteners from the provided element.
 * @param events - The events to remove.
 * @param handler - the handler signature to remove.
 * @param element - element the events are attached to.
 */
export const removeEventListeners = (events: string | string[], handler: any, element: Element | Document | Window) => {
    if (Array.isArray(events)) {
        events.forEach(event => element.removeEventListener(event, handler));
    }
    else {
        element.removeEventListener(events, handler);
    }
}