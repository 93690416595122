import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import PrivateProfile from 'components/common/icons/svg/PrivateProfile';
import WorkProfile from 'components/common/icons/svg/WorkProfile';
import { LinkCard } from 'components/common';
import { TextCodes } from 'hooks';
import { routeConfig } from 'routes/config/route.config';

const NewProfile = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <LinkCard
        title={t(TextCodes.WG6.NewProfile.BusinessProfile)}
        externalLink={process.env.REACT_APP_CREATE_PROFILE_BUSINESS_URL}
        backgroundIcon={<WorkProfile />}
      >
        <Box sx={{ pr: 10 }}>
          {t(TextCodes.WG6.NewProfile.BusinessProfileDescription)}
        </Box>
      </LinkCard>
      <LinkCard
        title={t(TextCodes.WG6.NewProfile.PersonalProfile)}
        externalLink={process.env.REACT_APP_CREATE_PROFILE_PRIVATE_URL}
        backgroundIcon={<PrivateProfile />}
      >
        <Box sx={{ pr: 10 }}>
          {t(TextCodes.WG6.NewProfile.PersonalProfileDescription)}
        </Box>
      </LinkCard>
      <Box sx={{ textAlign: 'center', pt: 2 }}>
        <Typography
          variant='body2link'
          component={Link}
          to={routeConfig.Login.path}
          sx={{
            ':visited': { color: theme.palette.primary.main },
          }}
        >
          {t(TextCodes.WG6.NewProfile.BackToLogin)}
        </Typography>
      </Box>
    </>
  );
};

export default NewProfile;
