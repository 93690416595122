import { DeleteOutline } from '@mui/icons-material';
import { Grid, IconButton, Divider, Typography } from '@mui/material';
import CreditCardExpirationInput from './CreditCardExpirationInput';
import CreditCardInput from 'components/common/inputs/CreditCardInput';
import { motion } from 'framer-motion';
import useAnimatedFieldArray from 'hooks/animation/useAnimatedFieldArray';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { useTranslation } from 'react-i18next';
import { TextCodes } from 'hooks/texts/text.codes';
import { useAppSelector } from 'store/store';
import { getIsReadOnlyProfile } from 'store/user/user.slice';

const RegisteredCreditCards = () => {
  // Hooks 🎣
  const { t } = useTranslation();

  // Selectors 🤏
  const isReadOnly = useAppSelector(getIsReadOnlyProfile);

  // Form 📋
  const { fields, remove, setValue, itemControls, variants } =
    useAnimatedFieldArray('cards');

  const handleRemove = (index: number) => {
    remove(index);
    setValue('didDeleteCards', true);
  };

  return (
    <Grid component='ul' container gap={2}>
      {fields?.map((f, i) => (
        <Grid
          container
          gap={2}
          component={motion.li}
          custom={i}
          variants={variants}
          animate={itemControls}
          key={f.id}
        >
          {!isReadOnly && (
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <IconButton
                color='primary'
                sx={{ pt: 0 }}
                onClick={() => handleRemove(i)}
              >
                <DeleteOutline />
                <Typography sx={visuallyHidden}>
                  {t(TextCodes.Common.Delete)}
                </Typography>
              </IconButton>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <CreditCardInput disabled name={`cards.${i}`} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CreditCardExpirationInput disabled name={`cards.${i}`} />
          </Grid>
          {!isReadOnly && (
            <Grid item xs={12}>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default RegisteredCreditCards;
