import { Components } from '@mui/material';
import { lightColorPalette } from 'theme/colorPalette/colorPalette';

export const lightAccordionStyles: Components = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        margin: '0px!important',
        '& .Mui-expanded': {
          backgroundColor: lightColorPalette.backgroundColors?.selectedCard,
        },
        '& .Mui-disabled': {
          backgroundColor: lightColorPalette.backgroundColors?.paper01,
          opacity: '1!important',
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      content: {
        margin: 0,
        paddingTop: 20,
        paddingBottom: 20,
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        backgroundColor: lightColorPalette.backgroundColors?.selectedCard,
      },
    },
  },
};
