import { useTranslation } from 'react-i18next';

import DropdownMenu from 'components/common/dropdownMenu/DropdownMenu';
import useOrder from 'hooks/orders/useOrder';
import { TextCodes } from 'hooks/texts/text.codes';
import { getSelectedOrderDetailsTraveller } from 'store/orders/orderDetails.slice';
import { setShowNotImplementedModal } from 'store/app/app.slice';
import { useAppDispatch, useAppSelector } from 'store/store';

interface OrderDetailsMenuProps {
  orderNumber: string;
}

const OrderDetailsMenu = ({ orderNumber }: OrderDetailsMenuProps) => {
  // Hooks
  const { showReceipt, handleSetOrderReceipt } = useOrder();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const traveller = useAppSelector(getSelectedOrderDetailsTraveller);

  return (
    <DropdownMenu
      maxHeight='initial'
      label='Order details menu'
      options={[
        {
          label: t(TextCodes.WG6.Orders.SendTravelDetailsSms),
          action: async () => {
            handleSetOrderReceipt(orderNumber!!, 'sms');
          },
        },
        {
          label: t(TextCodes.WG6.Orders.SendTravelDetailsEmail),
          action: async () => {
            handleSetOrderReceipt(orderNumber!!, 'email');
          },
        },
        {
          label: t(TextCodes.WG6.Orders.ShowReceipt),
          action: async () => {
            await showReceipt(orderNumber!!);
          },
          disabled: !traveller.hasReceipt,
        },
        {
          label: t(TextCodes.WG6.Generic.AddToCalendar),
          action: () => dispatch(setShowNotImplementedModal(true)),
        },
        {
          label: t(TextCodes.WG6.OrderDetails.CancelForAll),
          action: () => dispatch(setShowNotImplementedModal(true)),
        },
        {
          label: t(TextCodes.WG6.Orders.NewOrder),
          action: () => dispatch(setShowNotImplementedModal(true)),
        },
      ]}
      id={'orderDetailsMenu'}
    />
  );
};

export default OrderDetailsMenu;
