import { Container, Box, Skeleton } from '@mui/material';

const LoginSkeleton = () => {
  return (
    <Container>
      <Box display='flex' alignItems='center' gap={2} paddingTop={2}>
        <Skeleton variant='circular' width={40} height={40} />
        <Skeleton height={25} sx={{ flex: 1 }} />
      </Box>
      <Box sx={{ px: 3, mt: 10 }}>
        <Skeleton height={80} width={'100%'} />
        <Skeleton sx={{ mt: -2 }} height={80} width={'100%'} />
        <Skeleton height={25} width={'100%'} />
        <Skeleton sx={{ mt: 2, mb: 4 }} height={60} width={'100%'} />
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Skeleton height={25} width={220} />
          <Skeleton height={25} width={195} sx={{ mt: -0.5 }} />
        </Box>
      </Box>
    </Container>
  );
};

export default LoginSkeleton;
