import * as Yup from 'yup';
import { Divider, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import FlightAndHotelPreferences from './FlightAndHotelPreferences';
import OrderPreferences from './OrderPreferences';
import { AutocompleteOption } from 'components/common/inputs/input.types';
import { CodeDescriptionPair } from 'services/api/locations';
import { TextCodes, useToast } from 'hooks';
import { getPreferences, getPreferencesForEdit } from 'store/user/user.slice';
import { routeConfig } from 'routes/config/route.config';
import { useAppSelector } from 'store/store';
import { userApi } from 'services/api/user';
import FormPanel from 'components/common/forms/FormPanel';

interface FormFields {
  airSeatCode: string;
  airSeatNumber: string;
  airMealCode: string;
  hotelRoomSmoking: string;
  currencyCode: string;
  defaultDepartureCity: string;
  defaultDestinationCity: string;
  languageCode: string;
}

const EditPreferencesForm = () => {
  // Local state
  const [hasDataBeenDeleted, setHasDataBeenDeleted] = useState(false);

  // Global state
  const defaultValues = useAppSelector(getPreferencesForEdit) || {};
  const preferences = useAppSelector(getPreferences) || {};

  // maps the departure and destination object
  const mapToAutocompleteOption = (
    object: CodeDescriptionPair | null | undefined
  ): AutocompleteOption => ({
    value: object?.code || '',
    text: object?.description || '',
  });

  // Hooks/Variables
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toast } = useToast();

  const defaultDepartureCity = mapToAutocompleteOption(
    preferences?.defaultDepartureCity
  );

  const defaultDestinationCity = mapToAutocompleteOption(
    preferences?.defaultDestinationCity
  );

  // API
  const [updatePreferences, { isLoading, data }] =
    userApi.useUserPreferencesSaveMutation();

  // Form methods
  const formMethods = useForm<FormFields>({
    defaultValues: defaultValues as FormFields,
    resolver: yupResolver(
      Yup.object().shape({
        airSeatCode: Yup.string().optional(),
        airSeatNumber: Yup.string().matches(/^[0-9]+[a-zA-Z]+$/, {
          message: TextCodes.WG6.Validation.SeatNumberFormat,
          excludeEmptyString: true,
        }),
        airMealCode: Yup.string().optional(),
        hotelRoomSmoking: Yup.string().optional(),
        defaultDepartureCityCode: Yup.string().optional(),
        defaultDestinationCityCode: Yup.string().optional(),
        languageCode: Yup.string().optional(),
        currencyCode: Yup.string().optional(),
      })
    ),
  });

  const watch = formMethods.watch();

  // Submit
  const onSubmit = (formFieldsData: FormFields) => {
    updatePreferences({
      preferences: {
        ...formFieldsData,
        defaultDepartureCity: {
          code: formFieldsData?.defaultDepartureCity,
        },
        defaultDestinationCity: {
          code: formFieldsData?.defaultDestinationCity,
        },
      },
    });
  };

  useEffect(() => {
    if (
      (defaultValues?.airSeatNumber && watch?.airSeatNumber === '') ||
      (defaultValues?.defaultDepartureCity &&
        watch?.defaultDepartureCity === '') ||
      (defaultValues?.defaultDestinationCity &&
        watch?.defaultDestinationCity === '')
    ) {
      setHasDataBeenDeleted(true);
    } else {
      setHasDataBeenDeleted(false);
    }
  }, [watch]);

  // Success handler
  useEffect(() => {
    if (data?.isSuccess) {
      navigate(routeConfig.BenefitCardsAndPreferences.path);
      toast({
        severity: 'success',
        autoHideDuration: 3000,
        message: t(TextCodes.WG6.Status.ChangesSavedSuccessfully),
      });
    }
  }, [data]);

  return (
    <FormProvider {...formMethods}>
      <form>
        <Stack spacing={2} divider={<Divider />} sx={{ mb: 2 }}>
          <FlightAndHotelPreferences />
          <OrderPreferences
            defaultDepartureCity={defaultDepartureCity}
            defaultDestinationCity={defaultDestinationCity}
            preferences={preferences}
          />
        </Stack>
        <FormPanel
          isLoading={isLoading}
          errorMessage={data?.message || ''}
          onSubmit={formMethods.handleSubmit(onSubmit)}
          shouldShowCancelWarning={
            hasDataBeenDeleted || formMethods.formState.isDirty
          }
          shouldShowSaveWarning={hasDataBeenDeleted}
        />
      </form>
    </FormProvider>
  );
};

export default EditPreferencesForm;
