import { Components, CSSInterpolation, Palette } from '@mui/material';

interface TimelineComponent extends Components {
    MuiTimeline?: {
        styleOverrides: {
            root: CSSInterpolation
        }
    },
    MuiTimelineItem?: {
        styleOverrides: {
            root: CSSInterpolation
        }
    },
    MuiTimelineDot?: {
        styleOverrides: {
            root: CSSInterpolation
        }
    },
    MuiTimelineSeparator?: {
        styleOverrides: {
            root: CSSInterpolation
        }
    }
    MuiTimelineConnector?: {
        styleOverrides: {
            root: CSSInterpolation
        }
    }
}

export const timelineStyles = (palette: Palette): TimelineComponent => ({
    MuiTimeline: {
        styleOverrides: {
            root: {
                margin: "0"
            }
        }
    },
    MuiTimelineItem: {
        styleOverrides: {
            root: {
                "&:before": {
                    display: "none"
                }
            }
        }
    },
    MuiTimelineDot: {
        styleOverrides: {
            root: {
                backgroundColor: palette.common.black
            }
        }
    },
    MuiTimelineSeparator: {
        styleOverrides: {
            root: {
                gap: "5px"
            }
        }
    },
    MuiTimelineConnector: {
        styleOverrides: {
            root: {
                flexGrow: 2,
                boxSizing: "border-box",
                backgroundColor: palette.common.black,
                height: "6px",
                '&.small': {
                    flexGrow: 0,
                    height: "3px"
                }
            }
        }
    }
})

