import ArrowForward from '@mui/icons-material/ArrowForward';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import useTheme from '@mui/material/styles/createTheme';
import { useTranslation } from 'react-i18next';

import HighlightedText from 'components/common/highlightedText/HighlightedText';
import { TextCodes } from 'hooks';
import { timeOfDate } from 'services/text/datetime-format';

interface FlightDetailsHeaderProps {
  arrivalCityCode: string | undefined;
  arrivalDateTime: string | null | undefined;
  departureCityCode: string | undefined;
  departureDateTime: string | null | undefined;
  departureDateTimeUpdated?: string | null | undefined;
  arrivalDateTimeUpdated?: string | null | undefined;
}

const FlightDetailsHeader = ({
  arrivalCityCode,
  arrivalDateTime,
  arrivalDateTimeUpdated,
  departureCityCode,
  departureDateTime,
  departureDateTimeUpdated,
}: FlightDetailsHeaderProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const isDelayed =
    arrivalDateTimeUpdated || departureDateTimeUpdated ? true : false;

  const startTime = departureDateTimeUpdated ?? departureDateTime;
  const endTime = arrivalDateTimeUpdated ?? arrivalDateTime;

  const dateDifference = dayjs(endTime)
    .startOf('day')
    .diff(dayjs(startTime).startOf('day'), 'days');

  const dateDifferenceText =
    dateDifference === 1
      ? t(TextCodes.WG6.Base.Day)
      : t(TextCodes.WG6.Base.Days);

  return (
    <Box>
      <Typography
        variant='body1'
        fontWeight='bold'
        component='h3'
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          flexWrap: 'wrap',
          color: isDelayed ? palette?.warning?.main : palette?.text?.primary,
        }}
      >
        {`${departureCityCode} ${timeOfDate(startTime)}`}
        <ArrowForward fontSize='small' aria-label={t(TextCodes.WG6.Base.To)} />
        {`${arrivalCityCode} ${timeOfDate(endTime)}`}
        {dateDifference > 0 && !isDelayed && (
          <HighlightedText type='warning'>
            {`+${dateDifference} ${dateDifferenceText}`}
          </HighlightedText>
        )}
      </Typography>
      {isDelayed && (
        <Typography
          variant='body1'
          sx={{
            color: palette?.text?.secondary,
            textDecoration: 'line-through',
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            flexWrap: 'wrap',
          }}
        >
          {`${departureCityCode} ${timeOfDate(departureDateTime)}`}
          <ArrowForward
            fontSize='small'
            aria-label={t(TextCodes.WG6.Base.To)}
          />
          {`${arrivalCityCode} ${timeOfDate(arrivalDateTime)}`}
        </Typography>
      )}
      {dateDifference > 0 && isDelayed && (
        <HighlightedText type='warning'>
          {`+${dateDifference} ${dateDifferenceText}`}
        </HighlightedText>
      )}
    </Box>
  );
};

export default FlightDetailsHeader;
