import { Container, Skeleton } from '@mui/material';

interface PageTitleSkeletonProps {
  hasButton?: boolean;
}

const PageTitleSkeleton = ({ hasButton = false }: PageTitleSkeletonProps) => {
  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pb: 2,
        mt: 4,
      }}
    >
      <Skeleton width={175} height={20} />
      {hasButton && <Skeleton width={40} height={20} />}
    </Container>
  );
};

export default PageTitleSkeleton;
