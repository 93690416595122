import { Link } from '@mui/material';

export type LinkPhoneProps = {
  phone: string;
};

export const LinkPhone = ({ phone }: LinkPhoneProps) => {
  return (
    <Link
      sx={{
        display: 'inline-block',
      }}
      href={'tel:' + phone}
    >
      {phone}
    </Link>
  );
};
