import i18n from 'i18n';

import ArrangementsPage from 'components/pages/ArrangementsPage';
import BenefitCardsAndPreferencesOverviewPage from 'routes/user/benefitCardsAndPreferences/BenefitCardsAndPreferencesOverviewPage';
import BonusCardsPage from 'routes/bonus-cards/BonusCardsPage';
import ChangePasswordPage from 'routes/change-password/ChangePasswordPage';
import ContactDetailsPage from 'routes/contact-details/ContactDetailsPage';
import ContactUsPage from 'components/pages/ContactUsPage';
import DocumentsPage from 'components/features/user/documents/DocumentsPage';
import ERMPage from 'components/pages/ERMPage';
import EditBasicInformationPage from 'components/features/user/personalInformation/basicInformation/EditBasicInformationPage';
import EditEmployerAndReportingReferencesPage from 'components/features/user/personalInformation/employerAndReportingReferences/EditEmployerAndReportingReferencesPage';
import EditNationalIdPage from 'components/features/user/documents/nationalId/EditNationalIdPage';
import EditPassportPage from 'components/features/user/documents/passport/EditPassportPage';
import EditTravelArrangersPage from 'components/features/user/personalInformation/travelArrangers/EditTravelArrangersPage';
import EditVisaPage from 'components/features/user/documents/visa/EditVisaPage';
import FamilyAndFriendsPage from 'routes/user/familyAndFriends/FamilyAndFriendsPage';
import InformationFromEmployerPage from 'components/pages/InformationFromEmployer';
import Insight from 'routes/Insight';
import LandingPage from 'routes/landingPage/LandingPage';
import NewProfilePage from 'routes/new-profile/NewProfilePage';
import OrderDetailsPage from 'routes/OrderDetails/OrderDetailsPage';
import OrderPage from 'components/pages/Order';
import OrdersPage from 'routes/orders/OrdersPage';
import PaymentSettingsPage from 'routes/payment-settings/PaymentSettingsPage';
import PersonalInformationPage from 'components/features/user/personalInformation/PersonalInformationPage';
import PreferencesPage from 'routes/user/benefitCardsAndPreferences/PreferencesPage';
import Receipts from 'routes/Receipts';
import SasTravelPassPage from 'routes/user/benefitCardsAndPreferences/SasTravelPassPage';
import UserPage from 'routes/user/UserPage';

import { ExampleApiPage } from 'routes/example/ExampleApiPage';
import { ExampleOpenPage } from 'routes/example/ExampleOpenPage';
import { ForgotPasswordPage } from 'routes/forgot-password/ForgotPasswordPage';
import { LoginPage } from 'routes/login/LoginPage';
import { SetPasswordPage } from 'routes/set-password/SetPasswordPage';
import { SetPasswordSuccessPage } from 'routes/set-password/SetPasswordSuccessPage';
import { TextCodes } from 'hooks/texts/text.codes';
import UsernamePage from 'routes/user/username/UsernamePage';
import PageNotFound from './../../components/common/errors/errorHandlers/PageNotFound';

export type RouteItem = {
  path: string;
  element: JSX.Element;
  skipLogin?: boolean;
  breadcrumb?: string;
};

// Small helper function for Typescript to infer correct types
// This way it is possible to have explicit type for RouteItems
// And also typed keys in the form of RouteId below
// https://stackoverflow.com/questions/54598322/how-to-make-typescript-infer-the-keys-of-an-object-but-define-type-of-its-value
const asRouteConfig = <T,>(config: { [K in keyof T]: RouteItem }) => config;

export const routeConfig = asRouteConfig({
  // Login page
  Login: {
    path: '/login',
    element: <LoginPage />,
    skipLogin: true,
  },

  // User section
  User: {
    path: '/user',
    element: <UserPage />,
    breadcrumb: i18n.t(TextCodes.WG6.User.SectionTitle),
  },
  Username: {
    path: '/user/change-username',
    element: <UsernamePage />,
    breadcrumb: i18n.t(TextCodes.WG6.User.ChangeUsername),
  },
  UserPayment: {
    path: '/user/payment',
    element: <PaymentSettingsPage />,
    breadcrumb: i18n.t(TextCodes.WG6.User.PaymentTitle),
  },
  UserPassword: {
    path: '/user/password',
    element: <ChangePasswordPage />,
    breadcrumb: i18n.t(TextCodes.WG6.User.PasswordTitle),
  },
  UserFamily: {
    path: '/user/family',
    element: <FamilyAndFriendsPage />,
    breadcrumb: i18n.t(TextCodes.WG6.User.FamilyTitle),
  },

  // Personal Information
  PersonalInformation: {
    path: '/user/personal-information',
    element: <PersonalInformationPage />,
    breadcrumb: i18n.t(TextCodes.WG6.User.PersonalTitle),
  },
  BasicInformation: {
    path: '/user/personal-information/basic-information',
    element: <EditBasicInformationPage />,
    breadcrumb: i18n.t(TextCodes.WG6.Profile.BaseInfo),
  },
  UserContact: {
    path: '/user/contact-details',
    element: <ContactDetailsPage />,
    breadcrumb: i18n.t(TextCodes.WG6.Profile.Contact),
  },
  EmployerAndReportingReferences: {
    path: '/user/personal-information/employer-and-reporting-references',
    element: <EditEmployerAndReportingReferencesPage />,
    breadcrumb: i18n.t(TextCodes.WG6.EmployerAndReportingReferences.Title),
  },
  TravelArrangers: {
    path: '/user/personal-information/travel-arrangers',
    element: <EditTravelArrangersPage />,
    breadcrumb: i18n.t(TextCodes.WG6.TravelArrangers.Title),
  },

  // Passport, Visas and National ID
  Documents: {
    path: '/user/documents',
    element: <DocumentsPage />,
    breadcrumb: i18n.t(TextCodes.WG6.User.DocumentsTitle),
  },
  Passport: {
    path: '/user/documents/passport',
    element: <EditPassportPage />,
    breadcrumb: i18n.t(TextCodes.WG6.Passport.Title),
  },
  UserVisa: {
    path: '/user/documents/visa',
    element: <EditVisaPage />,
    breadcrumb: i18n.t(TextCodes.WG6.Visa.Title),
  },
  UserId: {
    path: '/user/documents/national-id',
    element: <EditNationalIdPage />,
    breadcrumb: i18n.t(TextCodes.WG6.NationalId.Title),
  },

  // Bonus cards and preferences
  BenefitCardsAndPreferences: {
    path: '/user/benefit-cards-and-preferences',
    element: <BenefitCardsAndPreferencesOverviewPage />,
    breadcrumb: i18n.t(TextCodes.WG6.User.BonusTitle),
  },
  SasTravelPass: {
    path: '/user/benefit-cards-and-preferences/sas-travel',
    element: <SasTravelPassPage />,
    breadcrumb: i18n.t(TextCodes.WG6.SasTravel.Title),
  },
  Preferences: {
    path: '/user/benefit-cards-and-preferences/preferences',
    element: <PreferencesPage />,
    breadcrumb: i18n.t(TextCodes.WG6.Preferences.Title),
  },
  BenefitCards: {
    path: '/user/benefit-cards-and-preferences/cards',
    element: <BonusCardsPage />,
    breadcrumb: i18n.t(TextCodes.WG6.BonusCards.Title),
  },

  // Forgotten password procedure
  ForgotPassword: {
    path: '/user/forgot-password',
    element: <ForgotPasswordPage />,
    skipLogin: true,
  },
  SetNewPassword: {
    path: '/user/set-password',
    element: <SetPasswordPage />,
    skipLogin: true,
  },
  SetNewPasswordSuccess: {
    path: '/user/set-password-success',
    element: <SetPasswordSuccessPage />,
    skipLogin: true,
  },

  // New profile creation screen
  NewProfile: {
    path: '/new-profile',
    element: <NewProfilePage />,
    skipLogin: true,
    breadcrumb: i18n.t(TextCodes.WG6.NewProfile.Title),
  },

  // Example pages
  ExampleOpenPage: {
    path: '/example/open',
    element: <ExampleOpenPage />,
    skipLogin: true,
  },
  ExampleApiPage: {
    path: '/example/api',
    element: <ExampleApiPage />,
    skipLogin: true,
  },

  // Navigation/Menu
  Order: {
    path: '/order',
    element: <OrderPage />,
    breadcrumb: i18n.t(TextCodes.WG6.Menu.Order),
  },
  Orders: {
    path: '/orders',
    element: <OrdersPage />,
    breadcrumb: i18n.t(TextCodes.WG6.Menu.Orders),
  },
  OrderDetails: {
    path: '/orders/details',
    element: <OrderDetailsPage />,
    // TODO: Remove this when we have created a breadcrumb hook and component
    breadcrumb: 'Replace with trip name',
  },
  InformationFromEmployer: {
    path: '/information-from-employer',
    element: <InformationFromEmployerPage />,
    breadcrumb: i18n.t(TextCodes.WG6.Menu.InformationFromEmployer),
  },
  ERM: {
    path: '/erm',
    element: <ERMPage />,
    breadcrumb: i18n.t(TextCodes.WG6.Menu.ERM),
  },
  Arrangements: {
    path: '/arrangements',
    element: <ArrangementsPage />,
    breadcrumb: i18n.t(TextCodes.WG6.Menu.Arrangements),
  },
  ContactUs: {
    path: '/contact-us',
    element: <ContactUsPage />,
    breadcrumb: i18n.t(TextCodes.WG6.Menu.ContactUs),
  },
  Receipts: {
    path: '/receipts',
    element: <Receipts />,
    breadcrumb: i18n.t(TextCodes.WG6.LandingPage.Receipts),
  },
  Insight: {
    path: '/insight',
    element: <Insight />,
    breadcrumb: i18n.t(TextCodes.WG6.LandingPage.Insight),
  },

  // Fallback - home
  Home: {
    path: '/',
    element: <LandingPage />,
    breadcrumb: i18n.t(TextCodes.WG6.Home.Title),
  },
  Blank: {
    path: '#',
    element: <></>,
  },
  NotFound: {
    path: '/PageNotFound',
    element: <PageNotFound></PageNotFound>,
  },
});

// Export type that has all the possible routes form route config keys
// This way ensures type safety with LinkInternal
// Only linking to existing route is possible
export type RouteId = keyof typeof routeConfig;
