import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

import AddressBlock from 'components/features/user/personalInformation/AddressBlock';
import EmailBlock from 'components/features/user/personalInformation/EmailBlock';
import PhoneBlock from 'components/features/user/personalInformation/PhoneBlock';
import { EditableCard } from 'components/common';
import { TextCodes } from 'hooks';
import { getIsReadOnlyProfile, getProfile } from 'store/user/user.slice';
import { useAppSelector } from 'store/store';

const ContactInformationCard = () => {
  const profile = useAppSelector(getProfile);
  const { t } = useTranslation();
  const isReadOnly = useAppSelector(getIsReadOnlyProfile);

  return (
    <EditableCard
      isEditable={!isReadOnly}
      routeId='UserContact'
      title={t(TextCodes.WG6.Profile.Contact)}
    >
      <Stack spacing={2} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
        <PhoneBlock list={profile?.phoneNumbers} />
        <EmailBlock list={profile?.emails} />
        <AddressBlock address={profile?.address || {}} />
      </Stack>
    </EditableCard>
  );
};

export default ContactInformationCard;
