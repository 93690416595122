import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EditPreferencesForm from 'components/features/user/benefitCardsAndPreferences/preferences/EditPreferencesForm';
import InfoButtonWithDialog from 'components/common/modals/InfoButtonWithDialog';
import PageTitle from 'components/common/pageTitle/PageTitle';
import StandardHeader from 'components/common/headers/StandardHeader';
import { Card } from 'components/common';
import { TextCodes } from 'hooks';

const PreferencesPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <StandardHeader showBreadcrumbs={false} />
      <Container>
        <PageTitle
          title={t(TextCodes.WG6.Preferences.Title)}
          sx={{ mt: 7 }}
          button={
            <InfoButtonWithDialog
              ariaLabel={t(TextCodes.WG6.Preferences.InfoButtonAriaLabel)}
              popupText={t(TextCodes.WG6.Preferences.Popup)}
            />
          }
        />
        <Card aria-live='polite'>
          <EditPreferencesForm />
        </Card>
      </Container>
    </>
  );
};

export default PreferencesPage;
