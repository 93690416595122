import { Card } from '../../components/common';
import { CenteredLayout } from '../../components/layouts/CenteredLayout';

export const ExampleOpenPage = () => {
  return (
    <CenteredLayout>
      <Card title='Example open page'>Login not reqired</Card>
    </CenteredLayout>
  );
};
