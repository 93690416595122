import {
  Box,
  Card,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { TextCodes } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface HeaderCardProps {
  headerContent?: React.ReactNode | React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
  sx?: SxProps;
}

const HeaderCard = ({ headerContent, sx, children }: HeaderCardProps) => {
  const theme = useTheme();

  return (
    <Card sx={sx}>
      <Stack>
        <Box
          sx={{
            backgroundColor: theme.palette.action.selected,
            py: 1.5,
            px: 2,
          }}
        >
          {headerContent}
        </Box>
        {children}
      </Stack>
    </Card>
  );
};

export default HeaderCard;

interface HeaderCardReferenceProps {
  reference?: string | null | undefined;
}

export const HeaderCardReference = ({
  reference,
}: HeaderCardReferenceProps) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const { t } = useTranslation();

  const copyReference = () => {
    if (reference) {
      setOpenTooltip(true);
      navigator.clipboard.writeText(reference);
      setTimeout(() => setOpenTooltip(false), 3000);
    }
  };

  return (
    <Box textAlign='right'>
      <Tooltip
        open={openTooltip}
        title={t(TextCodes.WG6.Generic.Copied)}
        arrow
        placement='top'
        onClick={copyReference}
      >
        <Box display='inline-block' sx={{ cursor: 'pointer' }}>
          <Typography variant='body2'>
            {t(TextCodes.WG6.Generic.Reference)}:{' '}
          </Typography>
          <Typography variant='body2' fontWeight='bold'>
            {reference}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};
