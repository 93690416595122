import { Components, Palette } from '@mui/material';
import { pxToRem } from 'theme/theme.utils';

export const getTextInputStyles = (palette: Palette): Components => ({
  MuiFormControl: {
    styleOverrides: {
      root: {
        '& > .Mui-focused * svg': {
          fill: palette?.primary?.main,
        },
        '& > .Mui-error svg': {
          fill: palette?.error?.main,
        },
        '&:hover .MuiInputLabel-shrink': {
          color: palette?.primary?.main,
        },
        '&:hover .Mui-disabled > fieldset': {
          borderColor: `${palette?.action?.disabled} !important`,
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: false,
    },
    styleOverrides: {
      root: {
        marginBottom: '16px', // TODO: We shouldn't use margins in these kind of components, but rather control the spacing in the parent component were we decide the layout. I will fix this later as if I do this now I have to change a lot of files, which again is not a priority atm.
        '&:hover .Mui-disabled': {
          color: palette?.text?.disabled,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&:hover:not([class*="MuiDisabled"]) .MuiOutlinedInput-notchedOutline': {
          borderColor: palette?.primary?.main,
        },
      },
      input: {
        padding: '12px 16px',
        lineHeight: 1.5,
      },
      inputSizeSmall: {
        padding: '8px 12px',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: pxToRem[16], // When focusing on the input or having text in the text field, the label is shrunk by 75%. 16 x 0,75 = 12. So when having 16px here we will get 12px when it's small. 12px is currently whats being defined in the Design Manual
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.15px',
        transform: 'translate(14px, 13px) scale(1)',
        '&.Mui-focused, &.Mui-focused ~ .MuiOutlinedInput-root > fieldset > legend > span': {
          fontWeight: 700,
        },
      },
      shrink: {
        transform: 'translate(14px, -7px) scale(0.75)',
      },
      sizeSmall: {
        transform: 'translate(14px, 9px) scale(1)',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: pxToRem[12],
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.15px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        fontSize: pxToRem[16],
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.15px',
      },
    },
  },
});
