import { isLoading, setLoading } from '../../store/app/app.slice';
import { useAppDispatch, useAppSelector } from '../../store/store';

export type ApiResponseBase<Type> = {
  isSuccess?: boolean;
  message?: string;
  value?: Type;
};

export const useAppLoadingState = () => {
  const dispatch = useAppDispatch();
  const setAppLoadingState = (state: boolean) => {
    dispatch(setLoading(state));
  };
  const isAppLoading = useAppSelector((state: any) => isLoading(state));
  return { isAppLoading, setAppLoadingState };
};
