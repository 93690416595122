import { Box } from '@mui/material';
import { ThemeSwitch } from '../theme/ThemeSwitch';
import { DevLanguageSwitch } from './DevLanguageSwitch';
import { DevTextLabelSwitch } from './TextLabelSwitch';

export const DevPanel = () => {
  return (
    <Box
      sx={{
        display: { xs: 'none', md: 'block' },
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: { xs: 0, md: 'auto' },
        background: 'rgba(0,0,0,0.25)',
        color: 'common.white',
      }}
    >
      <ThemeSwitch />
      <DevTextLabelSwitch />
      <DevLanguageSwitch />
    </Box>
  );
};
