import React from 'react';
import {
  Box,
  Card as MdCard,
  CardProps as MdCardProps,
  CardContent,
  Stack,
  ContainerProps,
  Container,
  GridProps,
  Grid,
} from '@mui/material';
import { CardTitle } from './CardTitle';

export type CardProps = Pick<MdCardProps, 'sx'> & {
  children?: React.ReactNode;
  side?: React.ReactNode;
  title?: React.ReactNode;
};

export const Card = ({ children, title, side, sx }: CardProps) => {
  return (
    <MdCard sx={{ boxShadow: 2, ...sx }}>
      <CardContent sx={{ width: '100%', ':last-child': { pb: 2 } }}>
        {(title || side) && (
          <Stack
            direction='row'
            sx={{
              pt: 3,
              pb: 5,
              alignItems: 'center',
              height: 20,
            }}
          >
            {title && <CardTitle>{title}</CardTitle>}
            {side && <Box sx={{ marginLeft: 'auto' }}>{side}</Box>}
          </Stack>
        )}
        {children}
      </CardContent>
    </MdCard>
  );
};

export type ContainedCardProps = Pick<ContainerProps, 'maxWidth'> & CardProps;

export const ContainedCard = (props: ContainedCardProps) => {
  const { maxWidth, ...rest } = props;
  return (
    <Container maxWidth={maxWidth}>
      <Card {...rest} />
    </Container>
  );
};

/**
 * A plain grid card with the standard padding applied from the Figma Design System.
 * Can overwrite any property apart from changing component type to something else
 * than Grid.
 */
export const PlainCard = ({ children, sx, ...rest }: GridProps) => {
  return (
    <Grid
      container
      component={MdCard}
      sx={{ wordBreak: 'break-word', px: 2, pt: 3, pb: 2, ...sx }}
      {...rest}
    >
      {children}
    </Grid>
  );
};
