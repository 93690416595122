const Instagram = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 2.162C15.204 2.162 15.584 2.174 16.849 2.232C18.215 2.294 19.482 2.568 20.457 3.543C21.432 4.518 21.706 5.785 21.768 7.151C21.826 8.416 21.838 8.796 21.838 12C21.838 15.204 21.826 15.584 21.768 16.849C21.706 18.215 21.432 19.482 20.457 20.457C19.482 21.432 18.215 21.706 16.849 21.768C15.584 21.826 15.204 21.838 12 21.838C8.796 21.838 8.416 21.826 7.151 21.768C5.785 21.706 4.518 21.432 3.543 20.457C2.568 19.482 2.294 18.215 2.232 16.849C2.174 15.584 2.162 15.204 2.162 12C2.162 8.796 2.174 8.416 2.232 7.151C2.294 5.785 2.568 4.518 3.543 3.543C4.518 2.568 5.785 2.294 7.151 2.232C8.416 2.174 8.796 2.162 12 2.162ZM12 0C8.741 0 8.332 0.014 7.052 0.072C5.102 0.161 3.389 0.639 2.014 2.014C0.639 3.389 0.161 5.102 0.072 7.052C0.014 8.332 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.161 18.898 0.639 20.611 2.014 21.986C3.389 23.361 5.102 23.839 7.052 23.928C8.332 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C18.898 23.839 20.611 23.361 21.986 21.986C23.361 20.611 23.839 18.898 23.928 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.332 23.928 7.052C23.839 5.102 23.361 3.389 21.986 2.014C20.611 0.639 18.898 0.161 16.948 0.072C15.668 0.014 15.259 0 12 0Z' />
      <path d='M12.0001 5.83801C8.59714 5.83801 5.83813 8.59701 5.83813 12C5.83813 15.403 8.59714 18.162 12.0001 18.162C15.4031 18.162 18.1621 15.403 18.1621 12C18.1621 8.59701 15.4031 5.83801 12.0001 5.83801ZM12.0001 16C9.79114 16 8.00014 14.209 8.00014 12C8.00014 9.79101 9.79114 8.00001 12.0001 8.00001C14.2091 8.00001 16.0001 9.79101 16.0001 12C16.0001 14.209 14.2091 16 12.0001 16Z' />
      <path d='M18.4058 7.03399C19.2011 7.03399 19.8458 6.38928 19.8458 5.59399C19.8458 4.7987 19.2011 4.15399 18.4058 4.15399C17.6105 4.15399 16.9658 4.7987 16.9658 5.59399C16.9658 6.38928 17.6105 7.03399 18.4058 7.03399Z' />
    </svg>
  );
};

export default Instagram;
