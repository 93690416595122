import { InfoOutlined } from '@mui/icons-material';
import { ClickAwayListener, IconButton, Tooltip, TooltipProps } from '@mui/material';
import { useState } from 'react';

interface TooltipButtonProps extends Pick<TooltipProps, 'arrow' | 'title' | 'placement' | "sx"> { }

// TODO: Remove margin on MuiTooltip-tooltipArrow when theme rework is done

const TooltipButton = (props: TooltipButtonProps) => {
  const [open, setOpen] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip {...props} sx={{ mb: 0 }} open={open} onClick={() => setOpen(!open)}>
        <IconButton>
          <InfoOutlined color='primary' />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default TooltipButton;
