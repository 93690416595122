import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import SocialMediaLinks from 'components/features/header/SocialMediaLinks';
import { DevLanguageSwitch } from 'components/features/dev/DevLanguageSwitch';
import { DevTextLabelSwitch } from 'components/features/dev/TextLabelSwitch';
import { MainMenuItems } from 'components/features/header/MainMenuItems';
import { ThemeSwitch } from 'components/features/theme/ThemeSwitch';
import { useMainMenuDrawer } from 'hooks/menu/menu.hooks';

const MainMenu = () => {
  const { mainMenuExpanded, toggleMainMenuDrawer } = useMainMenuDrawer();
  const { palette } = useTheme();

  return (
    <Drawer
      anchor='right'
      open={mainMenuExpanded}
      onClick={toggleMainMenuDrawer}
      hideBackdrop
      sx={{
        minHeight: '100vh',
        height: '100%',
        flexShrink: 0,
        backgroundColor: palette.background.default,
        zIndex: 2,
      }}
    >
      <Grid
        container
        alignContent='space-between'
        sx={{
          backgroundColor: palette.background.default,
          minHeight: '100vh',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ width: '100vw', marginTop: '61px' }}
          role='presentation'
        >
          <MainMenuItems />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: palette.primary.outlinedHover,
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: palette.primary.outlinedBorder,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '1rem',
            marginBlock: '32px',
          }}
        >
          <div>
            <ThemeSwitch />
            <DevTextLabelSwitch />
            <DevLanguageSwitch />
          </div>
        </Grid>
        <SocialMediaLinks color={palette.primary.main} />
      </Grid>
    </Drawer>
  );
};

export default MainMenu;
