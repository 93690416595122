import CheckIcon from '@mui/icons-material/Check';
import Container from '@mui/material/Container';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import HeaderCard, {
  HeaderCardReference,
} from 'components/common/containers/HeaderCard';
import HorizontalList from 'components/common/lists/HorizontalList';
import LocationDetails from 'components/common/location/LocationDetails';
import OrderDetailsCardHeader from 'components/features/orderDetails/OrderDetailsCardHeader';
import OrderDetailsStatusBar from 'components/features/orderDetails/OrderDetailsStatusBar';
import { DropdownMenuOptions } from 'components/common/dropdownMenu/DropdownMenu';
import { ItineraryInfo } from 'services/api/itinerary';
import { TextCodes } from 'hooks';
import { getDateAndTime } from 'services/text/datetime-format';
import { setShowNotImplementedModal } from 'store/app/app.slice';
import { useAppDispatch } from 'store/store';

const CarDetails = ({
  carAirCondition,
  carClass,
  carCompany,
  carDropOffLocation,
  carPickupLocation,
  carTransmission,
  carType,
  delete: cancel,
  edit,
  itineraryType,
  reference,
  statusCode,
  statusText,
}: ItineraryInfo) => {
  // Hooks
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Feature formatting
  const features: string[] = [
    carAirCondition?.description!,
    carTransmission?.description!,
    carType?.description!,
    `${carClass?.code} ${carClass?.description}`,
  ];

  // "..." menu options
  const options: DropdownMenuOptions[] = [
    {
      label: !!edit
        ? TextCodes.WG6.Car.EditCar
        : TextCodes.WG6.Base.EditNotPossible,
      disabled: !edit,
      action: () => {
        dispatch(setShowNotImplementedModal(true));
      },
    },
    {
      label: !!cancel
        ? TextCodes.WG6.Car.CancelCar
        : TextCodes.WG6.Base.CancelNotPossible,
      disabled: !cancel,
      action: () => dispatch(setShowNotImplementedModal(true)),
    },
  ];

  return (
    <HeaderCard headerContent={<HeaderCardReference reference={reference} />}>
      <Grid container sx={{ py: 2 }} gap={2} component={Container}>
        <Grid item xs={12}>
          <OrderDetailsCardHeader
            options={options}
            id={carPickupLocation?.date!!}
            icon={<DirectionsCarIcon />}
            content={
              <Typography variant='body1' fontWeight='bold' sx={{ ml: 1.5 }}>
                {carCompany?.name}
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <OrderDetailsStatusBar
            statusCode={statusCode!!}
            statusText={statusText!!}
          />
        </Grid>

        <LocationDetails
          address={carPickupLocation?.address!!}
          phoneNumber={carPickupLocation?.phoneNo}
          headerText={`${t(TextCodes.WG6.Generic.Pickup)}: ${getDateAndTime(
            carPickupLocation?.date!!
          )}`}
          coords={carPickupLocation?.geoCode!}
        />
        <LocationDetails
          address={carDropOffLocation?.address!!}
          phoneNumber={carDropOffLocation?.phoneNo}
          headerText={`${t(TextCodes.WG6.Generic.Dropoff)}: ${getDateAndTime(
            carDropOffLocation?.date!!
          )}`}
          coords={carDropOffLocation?.geoCode!}
        />
        <Grid item xs={12}>
          <Typography fontWeight='bold' variant='body2'>
            {t(TextCodes.WG6.Car.CarInfo)}
          </Typography>
        </Grid>
        <Grid item xs={12} display='flex'>
          <CheckIcon
            sx={{
              fill: theme.palette.success.main,
              width: 16,
              height: 16,
              mr: 1,
            }}
          />
          <HorizontalList>
            {features.map((feature) => (
              <Typography key={feature} variant='caption' sx={{ fontSize: 12 }}>
                {feature}
              </Typography>
            ))}
          </HorizontalList>
        </Grid>
        {/* ❓ We are currently missing data for this ❓ */}
        {/* <Grid item xs={12} gap={1}>
          <Typography fontWeight='bold' variant='body2'>
            {t(TextCodes.WG6.OrderDetails.SelectedAccessories)}
          </Typography>
          <HorizontalList iconPosition='leading'>
            {additionalEquipment.map((equipment) => (
              <Fragment key={equipment}>
                <Typography variant='body2' sx={{ fontSize: 14 }}>
                  {equipment}
                  <Typography variant='caption' sx={{ ml: 1, fontSize: 12 }}>
                    ({t(TextCodes.WG6.OrderDetails.ByRequest)})
                  </Typography>
                </Typography>
              </Fragment>
            ))}
          </HorizontalList>
        </Grid> */}
        {/* ❓ We are currently missing data for this ❓ */}
        {/* <Grid item xs={12}>
          <PriceDetails
            currency={price?.currencyCode!!}
            price={price?.amount!!}
            disclaimer={t(TextCodes.WG6.Car.PriceDisclaimer)}
            text={t(TextCodes.WG6.Car.TotalCost)}
          >
            <Typography variant='caption' fontWeight='bold'>
              {t(TextCodes.WG6.Generic.IncludedInEstimatedPrice)}
            </Typography>
          </PriceDetails>
        </Grid> */}
      </Grid>
    </HeaderCard>
  );
};

export default CarDetails;
