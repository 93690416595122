import { InputAdornment, TextField } from '@mui/material';
import { get, useFormContext } from 'react-hook-form';
import validateCard from 'card-validator';
import BankCardLogoIcon from 'utils/BankCardLogoIcon.util';
import { CreditCardTypeCardBrandId } from 'credit-card-type/dist/types';
import { TextCodes } from 'hooks';
import { useTranslation } from 'react-i18next';
import {
  convertFromM3Type,
  convertToM3Type,
  M3CardType,
} from 'utils/card.utils';
import { useEffect } from 'react';

interface CreditCardInputProps {
  name: string;
  disabled?: boolean;
}

const CreditCardInput = ({ name, disabled }: CreditCardInputProps) => {
  // Hooks
  const { t } = useTranslation();

  // Form
  const {
    register,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const fieldName = `${name}.displayText`;
  const value = getValues(fieldName);
  const error = get(errors, fieldName);

  // If the card is already registered, it has an m3Type, if not we have to get it from the number.
  let m3Type: M3CardType | undefined = getValues(`${name}.code`);
  const cardType = convertFromM3Type(m3Type);

  const logo = (
    <InputAdornment position='start'>
      {<BankCardLogoIcon type={cardType as CreditCardTypeCardBrandId} />}
    </InputAdornment>
  );

  const handleChange = (newValue: string) => {
    const validatedCard = validateCard.number(newValue);
    setValue(
      `${name}.code`,
      convertToM3Type(validatedCard.card?.type as M3CardType)
    );
    setValue(fieldName, newValue.replace(/\s/g, ''));
  };

  // Trigger validation if there are errors.
  useEffect(() => {
    if (error) trigger(fieldName);
  }, [value]);

  return (
    <TextField
      {...register(fieldName)}
      disabled={disabled}
      InputProps={{ startAdornment: value && logo }}
      label={t(TextCodes.WG6.Payment.CardNumber)}
      size='small'
      fullWidth
      sx={{ mb: 0 }}
      error={error ? true : false}
      helperText={t(error?.message)}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

export default CreditCardInput;
