import { Button, ButtonProps, Box, useTheme } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Progress } from '..';

export interface ProgressButtonProps extends ButtonProps {
  title?: string;
  busy?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  disabledAppearance?: boolean;
  hookForm?: UseFormReturn<any, object>;
  progressColor?: string;
}

export const ProgressButton = ({
  children,
  disabled,
  disabledAppearance,
  color = 'primary',
  sx,
  href,
  variant = 'contained',
  hookForm,
  busy,
  progressColor,
  ...rest
}: ProgressButtonProps) => {
  const theme = useTheme();
  color = disabled ? 'secondary' : 'primary';
  let buttonProps: any = {
    ...rest,
    variant,
    color,
    sx: { ...sx, position: 'relative' },
  };
  if (href) {
    buttonProps.component = Link;
    buttonProps.to = href;
  }

  return (
    <Button {...buttonProps} disabled={disabled}>
      <Box sx={{ opacity: busy ? 0 : 1 }}>{children}</Box>
      {busy && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            textAlign: 'center',
            width: '100%',
            pt: 0.75,
          }}
        >
          <Progress
            small={true}
            sx={{
              color: progressColor
                ? progressColor
                : theme.palette.action.active,
            }}
            aria-label='Loading'
          />
        </Box>
      )}
    </Button>
  );
};
