import { Components } from '@mui/material';
import { pxToRem } from 'theme/theme.utils';

export const iconButtonStyles: Components = {
  MuiIconButton: {
    styleOverrides: {
      sizeSmall: {
        padding: 4,
        '& > svg': {
          fontSize: pxToRem[24],
        },
      },
      sizeMedium: {
        '& > svg': {
          fontSize: pxToRem[24],
        },
      },
      sizeLarge: {
        '& > svg': {
          fontSize: pxToRem[35],
        },
      },
    },
  },
};
