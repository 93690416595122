import React from 'react';
import { Box, CardProps as MdCardProps, Stack } from '@mui/material';
import { Text } from '..';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useCollapse from 'react-collapsed';

export type CollapsePanelProps = Pick<MdCardProps, 'sx'> & {
  title: React.ReactNode;
  children?: React.ReactNode;
};

export const CollapsePanel = ({ children, title, sx }: CollapsePanelProps) => {
  const collapseConfig = {
    duration: 150,
    defaultExpanded: true,
  };
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(collapseConfig);

  return (
    <Box sx={{ ...sx }}>
      <Stack {...getToggleProps()} direction='row' sx={{ pt: 3, pb: 5, alignItems: 'center', height: 20, cursor: 'pointer' }}>
        <Text variant='h6' component='h6' bold sx={{ textTransform: 'none' }}>
          {title}
        </Text>
        <Box sx={{ marginLeft: 'auto', color: 'primary.main' }}>{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</Box>
      </Stack>

      <div {...getCollapseProps()}>{children}</div>
    </Box>
  );
};
