import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextBlock from 'components/common/text/TextBlock';
import { EditableCard } from 'components/common';
import { TextCodes } from 'hooks';
import { getArrangers } from 'store/user/user.slice';
import { useAppSelector } from 'store/store';

const TravelArrangersCard = () => {
  const { t } = useTranslation();
  const arrangers = useAppSelector(getArrangers);

  const sortedTravelArrangers = [...arrangers].sort((a, b) =>
    (a?.lastName || '') > (b?.lastName || '') ? 1 : -1
  );

  return (
    <EditableCard
      routeId='TravelArrangers'
      title={t(TextCodes.WG6.TravelArrangers.Title)}
    >
      {sortedTravelArrangers?.map((arranger, index) => (
        <TextBlock
          removeDivider={sortedTravelArrangers?.length === index + 1}
          key={arranger.tac}
        >
          {arranger.firstName} {arranger.lastName}
        </TextBlock>
      ))}
      {sortedTravelArrangers?.length === 0 && (
        <Typography variant='body2' color='text.secondary'>
          {t(TextCodes.WG6.User.NoneSelected)}
        </Typography>
      )}
    </EditableCard>
  );
};

export default TravelArrangersCard;
