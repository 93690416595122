const Facebook = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <mask maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
        <path d='M0 23.9906H23.9905V6.10352e-05H0V23.9906Z' />
      </mask>
      <g mask='url(#mask0_218_7408)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M22.6664 6.10352e-05H1.32404C0.592719 6.10352e-05 0 0.59278 0 1.32411V22.6665C0 23.3977 0.592719 23.9906 1.32404 23.9906H12.8141V14.7002H9.68764V11.0795H12.8141V8.40941C12.8141 5.3108 14.7066 3.62352 17.4708 3.62352C18.795 3.62352 19.9329 3.72204 20.2645 3.76608V7.00437L18.3474 7.00527C16.8441 7.00527 16.553 7.71961 16.553 8.76788V11.0795H20.1383L19.6716 14.7002H16.553V23.9906H22.6664C23.3977 23.9906 23.9905 23.3977 23.9905 22.6665V1.32411C23.9905 0.59278 23.3977 6.10352e-05 22.6664 6.10352e-05Z'
        />
      </g>
    </svg>
  );
};

export default Facebook;
