import { Alert as MdAlert, AlertProps as MdAlertProps, AlertTitle, useTheme } from '@mui/material';

export type Severity = 'info' | 'warning' | 'success' | 'error';

export type AlertProps = Pick<MdAlertProps, 'variant' | 'sx'> & {
  children: React.ReactNode;
  severity: Severity;
  action?: React.ReactNode;
  title?: string;
};

export const Alert = (props: AlertProps) => {
  const { severity, children, title, action, sx, variant = 'filled' } = props;
  const theme = useTheme();
  const color = theme.palette[severity].textColor;
  const background = theme.palette[severity].background;

  let additionalSx: any = {
    width: '100%',
    color,
    background: 'transparent',
  };
  if (variant === 'filled') {
    additionalSx.background = background;
  }
  return (
    <MdAlert severity={severity} sx={{ ...sx, ...additionalSx }} action={action} variant={variant} color={severity}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </MdAlert>
  );
};
