import { AppBar, Box, IconButton, Toolbar, useTheme } from '@mui/material';
import Close from 'components/common/icons/svg/Close';
import Icon from 'components/common/icons/Icon';
import Menu from 'components/common/icons/svg/Menu';
import ProfileIcon from 'components/features/header/ProfileIcon';
import SiteLogo from 'components/common/icons/svg/SiteLogo';
import { LinkInternal } from 'components/common';
import { isDarkMode } from 'store/app/app.slice';
import { useMainMenuDrawer, useProfileMenuDrawer } from 'hooks/menu/menu.hooks';
import { useAppSelector } from 'store/store';
import { useAuthorization } from 'hooks/auth/auth.hooks';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const routesWithoutHamburgerMenu = ['/login', '/new-profile'];

const Header = () => {
  const location = useLocation();
  const [showHamburger, setShowHamburger] = useState(
    !routesWithoutHamburgerMenu.includes(location.pathname)
  );
  const { isAuthorized } = useAuthorization();
  const { palette } = useTheme();
  const { mainMenuExpanded, toggleMainMenuDrawer } = useMainMenuDrawer();
  const { profileMenuExpanded, toggleProfileMenuDrawer } =
    useProfileMenuDrawer();

  const isDark = useAppSelector(isDarkMode);
  const color = isDark ? palette.common.white : palette.common.black;

  useEffect(() => {
    setShowHamburger(!routesWithoutHamburgerMenu.includes(location.pathname));
  }, [location]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position='fixed'
        sx={{
          backgroundColor: palette.background.paper,
          borderBottom: '1px solid',
          borderBottomColor: 'divider',
          boxShadow: 'none',
          zIndex: 5,
        }}
      >
        <Toolbar sx={{ height: '61px' }}>
          <Box
            component='div'
            sx={{ flexGrow: 1, color: palette.text.primary }}
          >
            <LinkInternal routeId='Home'>
              <Icon width={124} height={25}>
                <SiteLogo />
              </Icon>
            </LinkInternal>
          </Box>
          {isAuthorized && !mainMenuExpanded && !profileMenuExpanded && (
            <ProfileIcon />
          )}
          {showHamburger && (
            <IconButton
              size='medium'
              edge='end'
              aria-label='menu'
              onClick={
                profileMenuExpanded
                  ? toggleProfileMenuDrawer
                  : toggleMainMenuDrawer
              }
              sx={{ marginRight: '0px' }}
            >
              {mainMenuExpanded || profileMenuExpanded ? (
                <Icon color={color}>
                  <Close />
                </Icon>
              ) : (
                <Icon color={color}>
                  <Menu />
                </Icon>
              )}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
