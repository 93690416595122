import { Box, Button, Typography, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';

interface Props {
  onClose: () => void;
}

const OrdersFilterHeader = ({ onClose }: Props) => {
  const theme = useTheme();
  const [canEmpty, setCanEmpty] = useState<boolean>(false);
  const { setValue, watch } = useFormContext();
  const values = watch();
  const emptyFilters = () => {
    Object.keys(values).forEach((val: string) =>
      val === 'type' ? setValue(val, 'upcoming') : setValue(val, '')
    );
    setCanEmpty(false);
  };

  useEffect(() => {
    for (const key in values) {
      if (key !== 'type' && values[key] !== '') {
        setCanEmpty(true);
        return;
      }
    }
  }, [values]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderBottom: 1,
        borderColor: theme.palette.divider,
      }}
    >
      <Box display='flex' justifyContent='space-between' padding={2}>
        <Button
          onClick={emptyFilters}
          variant='text'
          size='small'
          sx={{
            p: 0,
            color: canEmpty
              ? theme.palette.primary.main
              : theme.palette.action.disabled,
          }}
        >
          Tøm
        </Button>
        <Typography variant='h4' fontWeight='bold'>
          Filter
        </Typography>
        <IconButton size='small' onClick={onClose} sx={{ padding: 0 }}>
          <CloseIcon sx={{ fill: theme.palette.primary.main }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default OrdersFilterHeader;
