import { DirectionsCar, Flight, Hotel, Train } from '@mui/icons-material';
import { Tabs, Tab } from '@mui/material';
import { SyntheticEvent, useState } from 'react';

export const BookingTabs = () => {
  // TODO - a placeholder component only
  // TODO - handle routing https://mui.com/material-ui/guides/routing/#tabs
  // TODO - add aria labels
  const [value, setValue] = useState(0);

  // TODO - implement tab change behaviour when it is decided
  // Either navigate or dispatch an action to set tabs state to change search view
  const handleTabChange = (_event: SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Tabs value={value} onChange={handleTabChange} aria-label='Tabs navigation' variant='fullWidth' sx={{ m: 0 }}>
      <Tab icon={<Flight />} aria-label='Flight' />
      <Tab icon={<Hotel />} aria-label='Hotel' />
      <Tab icon={<Train />} aria-label='Train' />
      <Tab icon={<DirectionsCar />} aria-label='Car' />
    </Tabs>
  );
};
