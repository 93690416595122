import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectedTravelArrangers from 'components/features/user/personalInformation/travelArrangers/SelectedTravelArrangers';
import TravelArrangersSearch from 'components/features/user/personalInformation/travelArrangers/TravelArrangersSearch';
import TravelArrangersSearchResult from 'components/features/user/personalInformation/travelArrangers/TravelArrangersSearchResult';
import { ArrangerData, userApi } from 'services/api/user';
import { TextCodes, useToast } from 'hooks';
import { getArrangers } from 'store/user/user.slice';
import { routeConfig } from 'routes/config/route.config';
import { useAppSelector } from 'store/store';
import FormPanel from 'components/common/forms/FormPanel';

interface FormFields {
  search: string;
}

const EditTravelArrangersForm = () => {
  // Local state
  const [searchValue, setSearchValue] = useState('');
  const [hasDataBeenDeleted, setHasDataBeenDeleted] = useState(false);
  const [selectedTravelArrangers, setSelectedTravelArrangers] = useState<
    ArrangerData[]
  >([]);

  // Global state
  const travelArrangersInStore = useAppSelector(getArrangers);

  // Hooks/Variables
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toast } = useToast();

  // API
  const [updateTravelArrangers, { isLoading, data }] =
    userApi.useUserArrangersSaveMutation();

  const [
    search,
    {
      isLoading: isSearching,
      data: searchResult,
      isUninitialized: isSearchUninitialized,
    },
  ] = userApi.useSearchArrangersMutation();

  // Search
  const searchTravelArrangers = (data: FormFields) => {
    if (!data?.search) return;

    setSearchValue(data.search);
    search({
      searchArrangersRequest: { name: `${data.search}*` },
    });
  };

  // Add/remove travel arrangers to local state
  const updateSelectedTravelArrangers = (arranger: ArrangerData) => {
    const arrangerIndex = selectedTravelArrangers.findIndex(
      (a) => a.tac === arranger.tac
    );
    if (arrangerIndex === -1) {
      setSelectedTravelArrangers([...selectedTravelArrangers, arranger]);
    } else {
      setSelectedTravelArrangers(
        selectedTravelArrangers.filter((a) => a.tac !== arranger.tac)
      );
    }
  };

  // Update selectedTravelArrangers when travelArrangersInStore changes
  useEffect(() => {
    setSelectedTravelArrangers(travelArrangersInStore);
  }, [travelArrangersInStore]);

  useEffect(() => {
    const selectedTacs =
      selectedTravelArrangers.map((arranger) => arranger.tac).sort() || [];
    const storeTacs =
      travelArrangersInStore.map((arranger) => arranger.tac).sort() || [];

    // Checks if any of the previously saved travel arrangers have been deleted. When this happens we will display a modal that says "are you sure"
    let missingTravelArranger = false;

    storeTacs.forEach((tac) => {
      if (!selectedTacs.includes(tac)) {
        missingTravelArranger = true;
      }
    });

    if (missingTravelArranger) {
      setHasDataBeenDeleted(true);
    } else {
      setHasDataBeenDeleted(false);
    }
  }, [
    hasDataBeenDeleted,
    searchValue,
    selectedTravelArrangers,
    travelArrangersInStore,
  ]);

  // Success handler
  useEffect(() => {
    if (data?.isSuccess) {
      navigate(routeConfig.PersonalInformation.path);
      toast({
        severity: 'success',
        autoHideDuration: 3000,
        message: t(TextCodes.WG6.Status.ChangesSavedSuccessfully),
      });
    }
  }, [data]);

  // Submit
  const onSubmit = () => {
    updateTravelArrangers({ body: selectedTravelArrangers });
  };

  // Form methods 📋✍️
  const formMethods = useForm<FormFields>({
    defaultValues: {
      search: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        search: Yup.string().matches(
          /^[^()[\]£{}~*,._&|^%´§€'$<#/@>!¨¤?1234567890]+/,
          TextCodes.WG6.Validation.NoSpecialChars
        ),
      })
    ),
  });

  return (
    <Grid sx={{ pt: 1 }}>
      <FormProvider {...formMethods}>
        <form>
          <TravelArrangersSearch
            name='search'
            onSearch={formMethods.handleSubmit(searchTravelArrangers)}
            isSearching={isSearching}
          />
        </form>
      </FormProvider>
      <form>
        <TravelArrangersSearchResult
          searchResult={searchResult || {}}
          isUninitialized={isSearchUninitialized}
          isSearching={isSearching}
          searchValue={searchValue}
          updateSelectedTravelArrangers={updateSelectedTravelArrangers}
          selectedTravelArrangers={selectedTravelArrangers}
        />
        <SelectedTravelArrangers
          travelArrangers={selectedTravelArrangers}
          updateSelectedTravelArrangers={updateSelectedTravelArrangers}
        />
        <FormPanel
          errorMessage={data?.message || ''}
          isLoading={isLoading}
          onSubmit={() => onSubmit()}
          shouldShowCancelWarning={
            hasDataBeenDeleted || formMethods.formState.isDirty
          }
          shouldShowSaveWarning={hasDataBeenDeleted}
        />
      </form>
    </Grid>
  );
};

export default EditTravelArrangersForm;
