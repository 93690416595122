import { DeleteOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';

import { TextCodes } from 'hooks';
import { ArrangerData } from 'services/api/user';

interface SelectedTravelArrangersProps {
  travelArrangers: ArrangerData[];
  updateSelectedTravelArrangers: (arranger: ArrangerData) => void;
}
const SelectedTravelArrangers = ({
  travelArrangers,
  updateSelectedTravelArrangers,
}: SelectedTravelArrangersProps) => {
  // Hooks/Variables
  const { t } = useTranslation();

  // Sorts travel arrangers by last name alphabetically
  const sortedTravelArrangers = [...travelArrangers]?.sort((a, b) =>
    (a?.lastName || '') > (b?.lastName || '') ? 1 : -1
  );

  return (
    <Box>
      <Divider sx={{ my: 2 }} />

      {/* Title */}
      <Typography
        variant='body2'
        component='h2'
        fontWeight='bold'
        paragraph
        sx={{ mb: 0 }}
      >
        {t(TextCodes.WG6.TravelArrangers.Select)}
      </Typography>

      {/* Selected Travel Arrangers */}
      {sortedTravelArrangers.length > 0 && (
        <List
          component={Stack}
          divider={<Divider sx={{ borderStyle: 'dashed' }} />}
          sx={{
            mt: 4,
            mb: 2,
            p: 0,
          }}
          spacing={2}
        >
          {sortedTravelArrangers?.map((arranger) => (
            <ListItem
              key={arranger.tac}
              component={Stack}
              direction='row'
              alignItems='center'
              sx={{ justifyContent: 'space-between', p: 0 }}
            >
              <Typography variant='body2'>
                {arranger.firstName} {arranger.lastName}
              </Typography>
              <IconButton
                onClick={() => updateSelectedTravelArrangers(arranger)}
                aria-label='delete'
              >
                <DeleteOutline color='primary' />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}

      {/* No travel arrangers chosen */}
      {!sortedTravelArrangers.length && (
        <Typography
          sx={{ my: 4 }}
          variant='body2'
          paragraph
          color='text.secondary'
        >
          {t(TextCodes.WG6.TravelArrangers.NoArrangersAssigned)}
        </Typography>
      )}
    </Box>
  );
};

export default SelectedTravelArrangers;
