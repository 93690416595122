import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface OrderReceiptState {
    type?: 'email' | 'sms' | "";
    orderNo?: string;
    showModal?: boolean;
}

const initialState: OrderReceiptState = {
    type: '',
    orderNo: '',
    showModal: false
}

const orderReceiptSlice = createSlice({
    name: 'orderReceipt',
    initialState: initialState,
    reducers: {
        setOrderReceipt: (state: OrderReceiptState, { payload }) => {
            state.orderNo = payload.orderNo;
            state.type = payload.type;
            state.showModal = payload.showModal;
        },
        closeOrderReceipt: (state: OrderReceiptState) => {
            state.orderNo = '';
            state.type = '';
            state.showModal = false;

        }
    }
})

export const getOrderReceipt = createSelector(
    (state: RootState) => state.orderReceipt,
    (data: OrderReceiptState) => data
)

export const { setOrderReceipt, closeOrderReceipt } = orderReceiptSlice.actions;
export default orderReceiptSlice.reducer;