import allowEmpty from 'components/validators/allowEmpty';
import * as yup from 'yup';
import disallowFutureDate from './disallowFutureDate';
import disallowPastDate from './disallowPastDate';
import minAge from './minAge';

// Extending the YUP module so we can add our own custom validation methods.
declare module 'yup' {
  interface StringSchema {
    /**
     * @param {number} minAge - The minimum age required to pass validation.
     * @param {string} [message] - Override default error message.
     */
    minAge(minAge: number, message?: string): StringSchema;
    /**
     * @param {string} [message] - Override default error message.
     */
    disallowPastDate(message?: string, format?: string): StringSchema;
    /**
     * @param {string} [message] - Override default error message.
     */
    disallowFutureDate(message?: string): StringSchema;
  }
  // TShape is the "value", while TContext is the equivalent of "this",
  // and gives access to the "path", "parent" and other properties.
  interface ObjectSchema<TShape, TContext> {
    /**
     *  @param {string []} [exceptions] - These fields will be ignored in the evaluation.
     * @description This validator allows an object to be completely empty,
     * but will cause errors if some fields have values and others are empty.
     */
    allowEmpty(exceptions?: string[]): ObjectSchema<TShape, TContext>;
  }
}

// We add all our custom methods below

// String Schemas.
yup.addMethod<yup.StringSchema>(yup.string, 'minAge', minAge);
yup.addMethod<yup.StringSchema>(
  yup.string,
  'disallowPastDate',
  disallowPastDate
);
yup.addMethod<yup.StringSchema>(
  yup.string,
  'disallowFutureDate',
  disallowFutureDate
);

// Object Schemas.
yup.addMethod<yup.AnyObjectSchema>(yup.object, 'allowEmpty', allowEmpty);

export default yup;
