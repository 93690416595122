import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import LocationSearchInput from 'components/common/inputs/LocationSearchInput';
import OrdersFilterHeader from './OrdersFilterHeader';
import RadioList from 'components/common/inputs/RadioList';
import SingleSelect from 'components/common/inputs/SingleSelect';
import TextInput from 'components/common/inputs/textInput/TextInput';
import { ItinerariesListTravellerResponse } from 'services/api/itinerary';
import { TextCodes } from 'hooks';
import {
  generateDictionaryOfYears,
  getMonthsAsDictionary,
  getYear,
} from 'services/text/datetime-format';
import { getAllTravellers, getAllTrips } from 'store/trips/trips.slice';
import { slideFromRight } from 'components/common/animations/slide.variants';
import { useAppSelector } from 'store/store';

interface Props {
  onClose: () => void;
  open: boolean;
}

const OrdersFilterMenu = ({ onClose, open }: Props) => {
  const trips = useAppSelector(getAllTrips);
  const travellers = useAppSelector(getAllTravellers);
  let [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const defaultValues = useMemo(() => {
    return {
      type: searchParams.get('type') ?? 'upcoming',
      traveller: searchParams.get('traveller') ?? '',
      reference: searchParams.get('reference') ?? '',
      from: searchParams.get('from') ?? '',
      to: searchParams.get('to') ?? '',
      year: searchParams.get('year') ?? '',
      month: searchParams.get('month') ?? '',
    };
  }, [searchParams]);
  const formMethods = useForm({
    defaultValues,
  });
  const watch = formMethods.watch();
  const onSubmit = (data: any) => {
    Object.keys(data).forEach((k) => !data[k] && delete data[k]);
    setSearchParams({
      ...data,
    });
    onClose();
  };
  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return generateDictionaryOfYears(1980, currentYear + 5);
  }, []);
  const months = useMemo(() => {
    return getMonthsAsDictionary();
  }, []);

  const newFilteredList = useMemo(() => {
    let newList = trips.filter(
      (t) => t?.bookingStatus?.toLowerCase() === watch.type?.toLowerCase()
    );
    if (watch.reference)
      newList = newList.filter((trip) => trip.reference === watch.reference);
    if (watch.year)
      newList = newList.filter(
        (trip) => getYear(trip.outwardDate) === watch.year
      );
    if (watch.month)
      newList = newList.filter(
        (trip) => dayjs(trip.outwardDate).month() === parseInt(watch.month) - 1
      );
    if (watch.traveller) {
      const [firstName, lastName] = watch.traveller.split(' ');
      newList = newList.filter((trip) =>
        trip?.travellers?.some(
          (traveller) =>
            traveller.firstName!.toLowerCase() === firstName.toLowerCase() &&
            traveller.lastName!.toLowerCase() === lastName.toLowerCase()
        )
      );
    }
    if (watch.from)
      newList = newList.filter(
        (trip) => trip.departureCode?.trim() === watch.from?.trim()
      );
    if (watch.to)
      newList = newList.filter(
        (trip) => trip.destinationCode?.trim() === watch.to?.trim()
      );
    return newList;
  }, [watch]);

  useEffect(() => {
    Object.entries(defaultValues).forEach(([name, value]: any) =>
      formMethods.setValue(name, value)
    );
  }, [open]);

  return (
    <AnimatePresence>
      {open && (
        <Modal sx={{ zIndex: 6 }} open={open}>
          <Box
            variants={slideFromRight}
            initial='hidden'
            animate='visible'
            exit='hidden'
            transition={{ duration: 0.3 }}
            component={motion.div}
            sx={{
              height: '100%',
              overflowX: 'scroll',
            }}
          >
            <FormProvider {...formMethods}>
              <OrdersFilterHeader onClose={onClose} />
              <Box
                sx={{
                  px: 2,
                  pt: 2,
                  pb: 5,
                  backgroundColor: theme.palette.background.paper,
                }}
                component='form'
              >
                <RadioList
                  label={t(TextCodes.WG6.Orders.Title)}
                  name='type'
                  options={[
                    {
                      value: 'upcoming',
                      text: t(TextCodes.WG6.Orders.Upcoming),
                    },
                    { value: 'past', text: t(TextCodes.WG6.Orders.Past) },
                    {
                      value: 'cancelled',
                      text: t(TextCodes.WG6.Orders.Cancelled),
                    },
                  ]}
                />
                <Divider sx={{ my: 2 }} />
                <Box>
                  <Typography
                    component='h3'
                    sx={{ mb: 2 }}
                    variant='body2'
                    fontWeight='bold'
                  >
                    {t(TextCodes.WG6.Orders.Period)}
                  </Typography>
                  <SingleSelect
                    sx={{ marginBottom: 2 }}
                    size='small'
                    name='year'
                    label={t(TextCodes.WG6.Orders.Year)}
                    options={years}
                  />
                  <SingleSelect
                    size='small'
                    name='month'
                    label={t(TextCodes.WG6.Orders.Month)}
                    options={months}
                  />
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box marginBottom={2}>
                  <Typography
                    component='h3'
                    sx={{ mb: 2 }}
                    variant='body2'
                    fontWeight='bold'
                  >
                    {t(TextCodes.WG6.Orders.Destination)}
                  </Typography>
                  <LocationSearchInput
                    sx={{ mb: 2 }}
                    name='from'
                    label={t(TextCodes.WG6.Orders.From)}
                  />
                  <LocationSearchInput
                    name='to'
                    label={t(TextCodes.WG6.Orders.To)}
                  />
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                  <Typography
                    component='h3'
                    sx={{ mb: 2 }}
                    variant='body2'
                    fontWeight='bold'
                  >
                    {t(TextCodes.WG6.Orders.SearchByReference)}
                  </Typography>
                  <TextInput
                    fullWidth
                    size='small'
                    label={t(TextCodes.WG6.Generic.Reference)}
                    name='reference'
                  />
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                  <Typography
                    component='h3'
                    sx={{ mb: 2 }}
                    variant='body2'
                    fontWeight='bold'
                  >
                    {t(TextCodes.WG6.Generic.Traveller)}
                  </Typography>
                  <SingleSelect
                    size='small'
                    name='traveller'
                    label={t(TextCodes.WG6.Generic.Traveller)}
                    options={travellers.map(
                      (t: ItinerariesListTravellerResponse) => ({
                        value: `${t.firstName} ${t.lastName}`,
                        text: `${t.firstName} ${t.lastName}`,
                      })
                    )}
                  />
                  <Divider sx={{ my: 2 }} />
                </Box>
              </Box>
              <Box
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  zIndex: 100,
                  px: 2,
                  pb: 4,
                  backgroundColor: theme.palette?.background?.paper,
                }}
              >
                <Button
                  onClick={formMethods.handleSubmit(onSubmit)}
                  variant='contained'
                  fullWidth
                >
                  {t(TextCodes.WG6.Generic.Show)} {newFilteredList?.length ?? 0}{' '}
                  {t(TextCodes.WG6.Orders.Hits)}
                </Button>
              </Box>
            </FormProvider>
          </Box>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default OrdersFilterMenu;
