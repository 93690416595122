import Box from '@mui/material/Box';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Stack from '@mui/material/Stack';

import { Alert, Button, Progress, Text } from 'components/common';
import { LanguageCodes } from 'store/texts/language.config';
import { LoadingStatus } from 'hooks';
import { authApi } from 'services/api/authentication';
import { textApi, TextsArgs } from 'services/api/translations';
import { userApi } from 'services/api/user';

type LoadingIndicatorProps = {
  status: LoadingStatus;
};

export const LoadingIndicator = ({ status }: LoadingIndicatorProps) => {
  const { isUninitialized, isLoading, isSuccess, isError, isFetching } = status;
  return (
    <Stack spacing={2} sx={{ py: 2 }}>
      <Text variant='body2'>Request status</Text>
      {isUninitialized && (
        <Box>
          <RadioButtonUnchecked sx={{ verticalAlign: 'top' }} /> Is
          uninitialized
        </Box>
      )}
      {isLoading && (
        <Box>
          <Progress small sx={{ verticalAlign: 'top' }} /> Is loading
        </Box>
      )}
      {isFetching && (
        <Box>
          <Progress small sx={{ verticalAlign: 'top' }} /> Is fetching
        </Box>
      )}
      {isSuccess && (
        <Box>
          <CheckCircle sx={{ verticalAlign: 'top' }} /> Is success
        </Box>
      )}
      {isError && (
        <Box>
          <Cancel sx={{ verticalAlign: 'top' }} />
          Is error
        </Box>
      )}
    </Stack>
  );
};

export type ExampleCallStatus = LoadingStatus & {
  data?: {
    message?: string | null;
  };
};

export const getSeverity = (status: ExampleCallStatus) => {
  console.log(status);
  if (status?.isError || status?.data?.message) return 'warning';
  if (status?.isSuccess) return 'success';
  return 'info';
};

// API CALL EXAMPLES
export const ExampleApiCall = () => {
  // Example 1 - use GET request immediately
  const textRequestArgs: TextsArgs = {
    translationsRequest: { languageCode: LanguageCodes.EN },
  };
  const call1 = textApi.useTextsQuery(textRequestArgs);

  // Example 2 - use lazy GET - customize use of fetch function
  const [call2execute, call2status] = textApi.useLazyTextsQuery();

  // Example 3
  const [call3execute, call3status] = authApi.useLoginMutation();

  // Example 4 - use GET request immediately - handle error
  const call4 = userApi.useUserProfileDetailsQuery();

  return (
    <Stack spacing={2}>
      <Alert severity={getSeverity(call1)}>
        [NO AUTH] Example 1 - use GET request immediately
        <LoadingIndicator status={call1 as LoadingStatus} />
        <Button onClick={() => call1.refetch()} variant='contained'>
          Execute
        </Button>
      </Alert>
      <Alert severity={getSeverity(call2status)}>
        [NO AUTH] Example 2 - use GET request with custom trigger <br />
        <LoadingIndicator status={call2status as LoadingStatus} />
        <Button
          onClick={() => call2execute(textRequestArgs)}
          variant='contained'
        >
          Execute
        </Button>
      </Alert>

      <Alert severity={getSeverity(call3status)}>
        [WITH AUTH] Example 3 - use POST request with custom trigger
        <LoadingIndicator status={call3status} />
        <Button
          onClick={() =>
            call3execute({
              loginRequest: { username: 'Testteam1', password: '1057607' },
            })
          }
          variant='contained'
        >
          Execute
        </Button>
        {call3status.data?.message && (
          <Box sx={{ pb: 2 }}>
            M3 Error returned from API - "{call3status.data?.message}"
          </Box>
        )}
      </Alert>

      <Alert severity={getSeverity(call4)}>
        [WITH AUTH] Example 4 - call with M3 error <br />
        The request itself should end with success, but with error message.
        <LoadingIndicator status={call4 as LoadingStatus} />
        {call4.data?.message && (
          <Box sx={{ pb: 2 }}>
            M3 Error returned from API - "{call4.data?.message}"
          </Box>
        )}
        <Button onClick={() => call4.refetch()} variant='contained'>
          Refetch
        </Button>
      </Alert>
    </Stack>
  );
};
