import { Button, useTheme } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useTranslation } from 'react-i18next';
import { TextCodes } from 'hooks';

interface Props {
  onClick: () => void;
}

const FilterButton = ({ onClick }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Button
      sx={{ color: theme.palette.primary.main }}
      onClick={onClick}
      endIcon={
        <FilterAltOutlinedIcon sx={{ fill: theme.palette.primary.main }} />
      }
      variant='text'
      size='small'
    >
      {t(TextCodes.WG6.Generic.Filter)}
    </Button>
  );
};

export default FilterButton;
