import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, CssBaseline, GlobalStyles } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';

import AirFareRulesDrawer from 'components/common/flight/AirFareRulesDrawer';
import BaggageInfoDrawer from 'components/common/flight/BaggageInfoDrawer';
import FlightNumberModal from 'components/common/flight/FlightNumberModal';
import NotImplementedModal from 'components/common/not-implemented/NotImplementedModal';
import { AppLoadingBackdrop } from 'components/features/backdrop/AppLoadingBackdrop';
import { DevPanel } from 'components/features/dev/DevPanel';
import { Routes } from 'routes/Routes';
import { ToastProvider } from 'components/features';
import { useTheme } from 'hooks/theme/theme.hooks';
import { getAirFareRulesDrawer } from 'store/app/app.slice';
import { useAppSelector } from 'store/store';
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const { currentTheme } = useTheme();
  const airFareRulesDrawer = useAppSelector(getAirFareRulesDrawer);

  return (
    <>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
      />
      <Box sx={{ pb: 24, pt: '61px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <HelmetProvider>
            <ThemeProvider theme={currentTheme}>
              <CssBaseline />
              <ToastProvider />
              <Router>
                <Routes />
              </Router>
              <AppLoadingBackdrop />
              <DevPanel />
              <NotImplementedModal />
              <FlightNumberModal />
              <BaggageInfoDrawer />
              {airFareRulesDrawer && <AirFareRulesDrawer />}
            </ThemeProvider>
          </HelmetProvider>
        </LocalizationProvider>
      </Box>
    </>
  );
}

export default App;
