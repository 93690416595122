import { Stack, Box, Typography } from '@mui/material';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { useTranslation } from 'react-i18next';
import { TextCodes } from 'hooks';
import IconLinkCard from 'components/common/cards/IconLinkCard';
import { NotImplementedButton } from 'components/common/not-implemented/NotImplementedModal';
import { routeConfig } from 'routes/config/route.config';

const UsefulInformation = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'none' }}
      component='section'
    >
      <Typography fontWeight='bold' variant='body1'>
        {t(TextCodes.WG6.LandingPage.UsefulInfo)}
      </Typography>
      <Stack sx={{ mt: 2 }} spacing={2}>
        <NotImplementedButton>
          <IconLinkCard
            route=''
            icon={<CorporateFareIcon />}
            title={t(TextCodes.WG6.LandingPage.InfoAboutEmployer)}
          />
        </NotImplementedButton>
        <IconLinkCard
          route={`${routeConfig.Orders.path}?type=past`}
          icon={<ReceiptOutlinedIcon />}
          title={t(TextCodes.WG6.LandingPage.Receipts)}
        />
        <NotImplementedButton>
          <IconLinkCard
            route=''
            icon={<SignalCellularAltOutlinedIcon />}
            title={t(TextCodes.WG6.LandingPage.Insight)}
          />
        </NotImplementedButton>
      </Stack>
    </Box>
  );
};

export default UsefulInformation;
