import { Components } from '@mui/material';
import { pxToRem } from 'theme/theme.utils';

export const buttonStyles: Components = {
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontSize: pxToRem[35],
        fontWeight: 600,
        fontFamily: '"Inter", "Roboto","Helvetica","Arial",sans-serif',
        position: 'relative',
        minWidth: 'unset',
      },
      sizeSmall: {
        fontSize: pxToRem[13],
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: pxToRem[0.46],
        padding: '4px 10px',
      },
      textSizeSmall: {
        padding: '4px 5px',
      },
      sizeMedium: {
        fontSize: pxToRem[14],
        fontWeight: 500,
        lineHeight: '26px',
        letterSpacing: pxToRem[0.46],
        padding: '6px 16px',
      },
      textSizeMedium: {
        padding: '6px 8px',
      },
      sizeLarge: {
        fontSize: pxToRem[16],
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: pxToRem[0.15],
        padding: '8px 22px',
      },
      textSizeLarge: {
        padding: '8px 11px',
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
};
