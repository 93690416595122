import { useAppSelector } from '../../store/store';
import { ProfileDictionaries } from '../../services/api/user';
import { getDictionary } from '../../store/user/user.slice';

export const useDictionary = (dictionary: keyof ProfileDictionaries) => {
  const entries = useAppSelector(getDictionary(dictionary));
  const getText = (code?: string | null) => {
    return code ? entries.find((entry) => entry.value === code)?.text || code : '';
  };
  const getCode = (text?: string | null) => {
    return text ? entries.find((entry) => entry.text === text)?.value || text : '';
  };
  return [getText, getCode];
};
