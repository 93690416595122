import { Container } from '@mui/system';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BasicInformationCard from 'components/features/user/personalInformation/BasicInformationCard';
import ContactInformationCard from 'components/features/user/personalInformation/ContactInformationCard';
import EmployerAndReportingReferencesCard from 'components/features/user/personalInformation/EmployerAndReportingReferencesCard';
import PageTitle from 'components/common/pageTitle/PageTitle';
import StandardHeader from 'components/common/headers/StandardHeader';
import TravelArrangersCard from 'components/features/user/personalInformation/TravelArrangersCard';
import { TextCodes } from 'hooks';

const PersonalInformationPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <StandardHeader />
      <Container>
        <PageTitle title={t(TextCodes.WG6.User.PersonalTitle)} />
        <Stack spacing={2}>
          <BasicInformationCard />
          <ContactInformationCard />
          <EmployerAndReportingReferencesCard />
          <TravelArrangersCard />
        </Stack>
      </Container>
    </>
  );
};

export default PersonalInformationPage;
