import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useTheme from '@mui/material/styles/useTheme';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { visuallyHidden } from '@mui/utils';

export interface DropdownMenuProps {
  icon?: ReactNode;
  options: DropdownMenuOptions[];
  id: string;
  label?: string;
  maxHeight?: string;
}

export interface DropdownMenuOptions {
  label: string;
  action: () => void;
  disabled?: boolean;
}

const DropdownMenu = ({
  icon,
  options,
  id,
  label,
  maxHeight = '250px', // This is mui default
}: DropdownMenuProps) => {
  // Hooks 🎣
  const { t } = useTranslation();
  const theme = useTheme();

  // Local state 🏡
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // Variables ♟️
  const open = Boolean(anchorEl);
  const renderedIcon = icon ?? (
    <MoreHorizIcon sx={{ fill: theme.palette.primary.main }} />
  );

  const handleOpenMenu = (e: any) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton sx={{ padding: 0 }} onClick={handleOpenMenu}>
        <>{renderedIcon}</>
        <Box sx={visuallyHidden}>{label}</Box>
      </IconButton>
      <Menu
        sx={{
          maxHeight: maxHeight,
        }}
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => handleClose(e)}
        tabIndex={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option) => (
          <MenuItem
            // disabled={option.disabled}
            sx={{
              color: option.disabled ? theme.palette.text.disabled : 'inherit',
            }}
            aria-disabled={option.disabled}
            disableRipple={option.disabled}
            key={option.label}
            onClick={(e) => {
              e.stopPropagation();

              if (!option.disabled) {
                option.action();
                setAnchorEl(null);
              }
            }}
          >
            {t(option.label)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default DropdownMenu;
