import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import i18next from 'i18next';
import { RootState } from '../../store/store';

// Create API using Redux Toolkit Query
// https://redux-toolkit.js.org/rtk-query/overview

// Using Swagger with Redux Toolkit Code Generator
// https://redux-toolkit.js.org/rtk-query/usage/code-generation

// initialize an empty api service that we'll inject endpoints into later as needed
const baseUrl = process.env.REACT_APP_API_URL;

export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState, endpoint }) => {
    if (endpoint === 'login') return headers;

    const token = (getState() as RootState).auth.token;
    // TODO - add token validation here
    // For now assume that if we have a token set in state, we should be passing it.
    // The token is currently valid for 30 minutes
    // Need to implement pre-request check for token validity and a call to /refresh endpoint
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      headers.set("language", i18next.language)
    }

    return headers;
  },
});

export const baseApi = createApi({
  reducerPath: 'base',
  baseQuery,
  endpoints: () => ({}),
});
