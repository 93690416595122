import { baseApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    texts: build.query<TextsResult, TextsArgs>({
      query: (queryArg) => ({
        url: `/api/texts`,
        method: 'POST',
        body: queryArg.translationsRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as textApi };
export type TextsResult =
  /** status 200 Success */ IResultIListTranslationResponse;
export type TextsArgs = {
  translationsRequest: TranslationsRequest;
};
export type TranslationResponse = {
  code?: string | null;
  value?: string | null;
};
export type IResultIListTranslationResponse = {
  value?: TranslationResponse[] | null;
  errorCode?: string | null;
  message?: string | null;
  isSuccess?: boolean;
};
export type TranslationsRequest = object;
