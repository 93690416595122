import { FormProvider, useForm } from 'react-hook-form';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormPanel from 'components/common/forms/FormPanel';
import TextInput from 'components/common/inputs/textInput/TextInput';
import {
  ClientReferenceType,
  userApi,
  ClientReferences,
} from 'services/api/user';
import { TextCodes, useToast } from 'hooks';
import { getProfile, getReferences } from 'store/user/user.slice';
import { routeConfig } from 'routes/config/route.config';
import { useAppSelector } from 'store/store';

type DefaultValues = { [Key in ClientReferenceType]?: string };

const EditEmployerAndReportingReferencesForm = () => {
  // Global store
  const references = useAppSelector(getReferences);
  const profile = useAppSelector(getProfile);

  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toast } = useToast();

  // API
  const [saveApi, { isLoading, data }] =
    userApi.useUserClientReferencesSaveMutation();

  // Generate default values for the form fields
  let defaultValues: DefaultValues = {};

  for (let item of references) {
    const index = item.type || '';
    defaultValues[index as ClientReferenceType] = item.value || '';
  }

  // Add default values to the form
  const methods = useForm({ defaultValues });

  // Success handler
  useEffect(() => {
    if (data?.isSuccess) {
      navigate(routeConfig.PersonalInformation.path);
      toast({
        severity: 'success',
        autoHideDuration: 3000,
        message: t(TextCodes.WG6.Status.ChangesSavedSuccessfully),
      });
    }
  }, [data]);

  const onSubmit = (data: DefaultValues) => {
    saveApi({ clientReferences: data as ClientReferences });
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Typography variant='body1' paragraph sx={{ mb: 2 }}>
          {profile?.employerName}
        </Typography>
        {references?.map(({ type, description, label, readonly }) => (
          <TextInput
            key={description}
            name={type || ''}
            label={label}
            helperText={description}
            fullWidth
            disabled={readonly}
          />
        ))}
        {!references?.length && (
          <Typography
            variant='body2'
            paragraph
            color='text.secondary'
            sx={{ mb: 2, wordBreak: 'break-word' }}
          >
            {t(
              TextCodes.WG6.EmployerAndReportingReferences
                .NoInputFieldsAvailable
            )}
          </Typography>
        )}
        <FormPanel
          errorMessage={data?.message || ''}
          onSubmit={methods.handleSubmit(onSubmit)}
          shouldShowCancelWarning={methods.formState.isDirty}
          isLoading={isLoading}
        />
      </form>
    </FormProvider>
  );
};

export default EditEmployerAndReportingReferencesForm;
