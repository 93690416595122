import { Box, Divider, IconButton } from '@mui/material';
import DateField from 'components/common/inputs/date-field/DateField';
import useAnimatedFieldArray from 'hooks/animation/useAnimatedFieldArray';
import { isMatch } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { DeleteOutline } from '@mui/icons-material';
import { TextCodes } from 'hooks/texts/text.codes';
import { CountryInput } from 'components/features/dropdowns/CountryInput';
import TextInput from 'components/common/inputs/textInput/TextInput';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import AddMoreInputsButton from 'components/common/forms/AddMoreInputsButton';
import dayjs from 'dayjs';

export const defaultDocument = {
  number: '',
  issuedDate: '',
  expiryDate: '',
  countryCode: '',
};

interface EditDocumentsProps {
  arrayName: string;
  documentLabel: string;
  addButtonLabel: string;
}

const EditDocuments = ({
  arrayName,
  documentLabel,
  addButtonLabel,
}: EditDocumentsProps) => {
  // Hooks
  const { t } = useTranslation();

  // form
  const { getValues } = useFormContext();
  const { append, remove, variants, fields, itemControls, setValue } =
    useAnimatedFieldArray(arrayName);

  const handleDelete = (index: number) => {
    const deletedPassport = getValues('documents')[index];
    if (!isMatch(deletedPassport, defaultDocument)) setValue('didDelete', true);
    remove(index);
  };

  return (
    <Box component='ul'>
      {fields.map((field, i) => (
        <Box
          component={motion.li}
          variants={variants}
          animate={itemControls}
          custom={i}
          key={field.id}
        >
          <Box textAlign='right' sx={{ pt: 1, pb: 3 }}>
            <IconButton
              color='primary'
              sx={{ p: 0 }}
              onClick={() => handleDelete(i)}
            >
              <DeleteOutline />
            </IconButton>
          </Box>
          <TextInput
            name={`${arrayName}.${i}.number`}
            label={documentLabel}
            fullWidth
          />
          <DateField
            name={`documents.${i}.issuedDate`}
            maxDate={dayjs()}
            label={t(TextCodes.WG6.Passport.IssuedDate)}
          />
          <DateField
            name={`documents.${i}.expiryDate`}
            label={t(TextCodes.WG6.Passport.ExpiryDate)}
            minDate={dayjs()}
            maxDate={dayjs().add(10, 'year')}
          />
          <CountryInput
            name={`documents.${i}.countryCode`}
            label={t(TextCodes.WG6.Passport.Country)}
            fullWidth
          />
          <Divider sx={{ mt: 2, mb: 2, borderStyle: 'dashed' }} />
        </Box>
      ))}
      <AddMoreInputsButton
        label={addButtonLabel}
        onClick={() => {
          append(defaultDocument);
        }}
      />
    </Box>
  );
};

export default EditDocuments;
