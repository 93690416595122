import { List, ListItem } from '@mui/material';
import { ReactElement } from 'react';

import Facebook from 'components/common/icons/svg/Facebook';
import Icon from 'components/common/icons/Icon';
import Instagram from 'components/common/icons/svg/Instagram';
import Linkedin from 'components/common/icons/svg/Linkedin';
import { LinkExternal } from 'components/common';

interface SocialMedia {
  name: string;
  icon: ReactElement;
  link: string;
}

const SocialMediaLinks = ({ color }: { color: string }) => {
  const socialMediaLinks: SocialMedia[] = [
    {
      name: 'facebook',
      icon: <Facebook />,
      link: 'https://www.facebook.com/BergHansenAS/',
    },
    {
      name: 'instagram',
      icon: <Instagram />,
      link: 'https://www.instagram.com/berghansen_as/',
    },
    {
      name: 'linkedin',
      icon: <Linkedin />,
      link: 'https://www.linkedin.com/company/berg-hansen-as/mycompany/',
    },
  ];

  return (
    <List
      sx={{
        padding: '24px 16px',
        margin: '0px',
        display: 'flex',
        gap: '40px',
      }}
    >
      {socialMediaLinks?.map(({ name, icon, link }) => (
        <ListItem sx={{ padding: '0px' }} key={name}>
          <LinkExternal href={link}>
            <Icon color={color}>{icon}</Icon>
          </LinkExternal>
        </ListItem>
      ))}
    </List>
  );
};

export default SocialMediaLinks;
