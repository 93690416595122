import { List, ListItem, Typography, Link, Switch } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import {
  AccountCircleOutlined,
  LanguageOutlined,
  LightModeOutlined,
  DarkModeOutlined,
  Logout,
} from '@mui/icons-material';
import { LanguageCodes } from 'store/texts/language.config';
import { LinkInternal } from 'components/common';
import { TextCodes } from 'hooks';
import {
  lightColorPalette,
  darkColorPalette,
} from 'theme/colorPalette/colorPalette';
import { themeLight, themeDark } from 'theme/theme';
import { useLogoutAction } from 'hooks/auth/auth.hooks';
import { useProfileMenuDrawer } from 'hooks/menu/menu.hooks';
import { useTheme } from 'hooks/theme/theme.hooks';

const ProfileMenuList = () => {
  const { toggleProfileMenuDrawer } = useProfileMenuDrawer();
  const { i18n, t } = useTranslation();
  const { logout } = useLogoutAction();
  const { currentTheme, isDarkTheme, switchTheme } = useTheme();

  const onChangeLanguage = () => {
    i18n.changeLanguage(
      i18n.language === LanguageCodes.EN ? LanguageCodes.NO : LanguageCodes.EN
    );
  };

  return (
    <List sx={{ width: '100%', padding: 0 }}>
      <ListItem
        sx={{
          padding: '0px 16px',
          height: '71px',
          borderTop: `1px solid ${currentTheme.palette.divider}`,
          gap: 2,
        }}
      >
        <LinkInternal
          routeId='User'
          onClick={toggleProfileMenuDrawer}
          underline='none'
          sx={{
            display: 'flex',
            gap: 2,
            width: '100%',
            alignItem: 'center',
            color: currentTheme.palette.text.primary,
          }}
        >
          <AccountCircleOutlined
            sx={{
              width: '32px',
              height: '32px',
              alignSelf: 'center',
              color: currentTheme.palette.text.primary,
            }}
          />
          <Typography
            fontWeight={700}
            sx={{
              alignSelf: 'center',
              wordBreak: 'break-all',
              color: currentTheme.palette.text.primary,
            }}
          >
            {t(TextCodes.WG6.ProfileMenu.ViewAndChangeProfile)}
          </Typography>
        </LinkInternal>
      </ListItem>
      <ListItem
        sx={{
          padding: '0px 16px',
          height: '71px',
          borderTop: `1px solid ${currentTheme.palette.divider}`,
          gap: 2,
        }}
      >
        <LanguageOutlined sx={{ width: '32px', height: '32px' }} />
        <Typography
          fontWeight={700}
          sx={{ flexGrow: 1, wordBreak: 'break-all' }}
        >
          {t(TextCodes.WG6.ProfileMenu.ChangeLanguages)}
        </Typography>
        <Switch
          inputProps={{
            'aria-label': t(TextCodes.WG6.ProfileMenu.ChangeLanguages),
          }}
          checked={i18n.language === LanguageCodes.EN}
          onClick={onChangeLanguage}
        />
      </ListItem>
      <ListItem
        sx={{
          backgroundColor: isDarkTheme
            ? lightColorPalette.background?.default
            : darkColorPalette.background?.default,
          color: isDarkTheme
            ? lightColorPalette.text?.primary
            : darkColorPalette.text?.primary,
          gap: 2,
          padding: '0px 16px',
          height: '71px',
        }}
      >
        <ThemeProvider theme={isDarkTheme ? themeLight : themeDark}>
          {isDarkTheme ? (
            <LightModeOutlined sx={{ width: '32px', height: '32px' }} />
          ) : (
            <DarkModeOutlined sx={{ width: '32px', height: '32px' }} />
          )}
          <Typography
            fontWeight={700}
            sx={{ flexGrow: 1, wordBreak: 'break-all' }}
          >
            {t(TextCodes.WG6.ProfileMenu.ChangeTheme)}
          </Typography>
          <Switch
            inputProps={{
              'aria-label': t(TextCodes.WG6.ProfileMenu.ChangeTheme),
            }}
            checked={isDarkTheme}
            onClick={switchTheme}
          />
        </ThemeProvider>
      </ListItem>
      <ListItem sx={{ padding: 0 }}>
        <Link
          sx={{
            padding: '0px 16px',
            display: 'flex',
            gap: 2,
            width: '100%',
            alignItem: 'center',
            height: '71px',
          }}
          component='button'
          underline='none'
          onClick={logout}
        >
          <Logout
            sx={{
              alignSelf: 'center',
              width: '32px',
              height: '32px',
              color: currentTheme.palette.text.primary,
            }}
          />
          <Typography
            fontWeight={700}
            variant='body1'
            sx={{
              alignSelf: 'center',
              color: currentTheme.palette.text.primary,
            }}
          >
            {t(TextCodes.WG6.Menu.LogOut)}
          </Typography>
        </Link>
      </ListItem>
    </List>
  );
};

export default ProfileMenuList;
