import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { ReactNode } from 'react';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';

interface AddMoreInputsButtonProps {
  label: ReactNode;
  onClick: () => void;
  sx?: SxProps;
}

/**
 * @param label - Button text
 * @param onClick - Send in a function that appends the new group of input into the React-Hook-Form
 *
 *
 * @example
 *
 * const formMethods = useForm<FormFields>({
 *   defaultValues: { documents: visas ?? [] },
 *   resolver: yupResolver(Yup.object().shape({
 *    documents: Yup.array().of(
 *      Yup.object()
 *        .shape({
 *          number: Yup.string().required(
 *            TextCodes.WG6.Validation.Required
 *          ),
 *          issuedDate: Yup.string().required(
 *            TextCodes.WG6.Validation.Required
 *          ),
 *          expiryDate: Yup.string().required(
 *            TextCodes.WG6.Validation.Required
 *          ),
 *          countryCode: Yup.string().required(
 *            TextCodes.WG6.Validation.Required
 *          ),
 *        })
 *        .optional()
 *     ),
 *   })),
 * });
 *
 * const { fields, append, remove } = useFieldArray({
 *   control: formMethods.control,
 *   name: 'documents',
 * });
 *
 * <AddMoreInputsButton
 *   label={t(TextCodes.WG6.Visa.Add)}
 *   onClick={() =>
 *     append({
 *     number: '',
 *     issuedDate: '',
 *     expiryDate: '',
 *     countryCode: '',
 *    })
 *   }
 * />
 */
const AddMoreInputsButton = ({
  onClick,
  label,
  sx,
}: AddMoreInputsButtonProps) => {
  return (
    <Button
      fullWidth
      size='small'
      startIcon={<Add />}
      sx={{ mb: 2, ...sx }}
      variant='text'
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default AddMoreInputsButton;
