import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';

import {
  getDidLoadInitialPaymentData,
  getIsLoadingPayments,
  getPaymentMethods,
  getPaymentPreferences,
  PaymentOption,
  setDidLoadInitialPaymentData,
  setIsLoadingPayments,
} from 'store/payment/payment.slice';
import { getLac } from 'store/user/user.slice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { userApi } from 'services/api/user';

const usePayment = () => {
  // Selectors 🤏
  const paymentMethods = useAppSelector(getPaymentMethods);
  const lac = useAppSelector(getLac);
  const isLoadingPayment = useAppSelector(getIsLoadingPayments);
  const paymentPreferences = useAppSelector(getPaymentPreferences);
  const didLoadInitialPaymentData = useAppSelector(
    getDidLoadInitialPaymentData
  );

  // Hooks 🎣
  const dispatch = useAppDispatch();
  const invoices: PaymentOption[] = useMemo(() => {
    return paymentMethods.filter((method) => method.type === 'invoice');
  }, [paymentMethods]);

  // Api 🚀
  const [
    savePaymentDetails,
    {
      isLoading: isSavingPaymentDetails,
      data,
      isSuccess: didSavePaymentDetails,
    },
  ] = userApi.useUserPaymentAndCreditCardsSettingsMutation();

  const [getPaymentOptions] = userApi.useLazyUserPaymentMethodsQuery();

  // Get payment options
  useEffect(() => {
    if (lac && !didLoadInitialPaymentData) {
      dispatch(setDidLoadInitialPaymentData(true));
      dispatch(setIsLoadingPayments(true));
      getPaymentOptions({
        lacNumber: lac,
      });
    }
  }, [lac]);

  let savePaymentError = '';

  if (data && 'message' in data) savePaymentError = data['message'];

  return {
    invoices,
    isSavingPaymentDetails,
    savePaymentError,
    paymentMethods,
    savePaymentDetails,
    didSavePaymentDetails: didSavePaymentDetails && isEmpty(savePaymentError),
    isLoadingPayment,
    didLoadInitialPaymentData,
    paymentPreferences,
  };
};

export default usePayment;
