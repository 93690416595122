import { Container } from '@mui/material';
import { Box } from '@mui/system';
import Header from 'components/features/header/Header';
import MainMenu from 'components/features/header/MainMenu';
import ProfileMenu from 'components/features/header/profileMenu/ProfileMenu';
import { LayoutProps } from 'components/layouts/Layout.types';

/**
 *
 * @deprecated This component does too much. Please avoid using it. See "StandardHeader" instead.
 */
export const CenteredLayout = ({
  children,
  header,
  footer,
  maxWidth = 'md',
}: LayoutProps) => {
  return (
    <Box>
      <Header />
      <MainMenu />
      <ProfileMenu />
      {header}
      <Container component='main' maxWidth={maxWidth} sx={{ pt: 2 }}>
        {children}
      </Container>
      {footer}
    </Box>
  );
};
