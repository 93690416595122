import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
} from '@mui/material';
import { AutocompleteOption } from 'components/common/inputs/input.types';
import { get, useFormContext, useWatch } from 'react-hook-form';
import { useAppSelector } from 'store/store';
import { getDictionary } from 'store/user/user.slice';
import { TextInputProps } from 'components/common/inputs/textInput/TextInput';
import { useTranslation } from 'react-i18next';
import { TextCodes } from 'hooks/texts/text.codes';

/*
TODO: Country names in the list may not be in the same language as
the user's set value. So if my value is set to "Norway", but the list
is in Norwegian, then the default value will not match, and the 
field will be empty by default.
*/

export const CountryInput = ({ name, label }: TextInputProps) => {
  const { t } = useTranslation();
  const { setValue, register, control, formState } = useFormContext();

  const value = useWatch({
    control: control,
    name: name,
  });

  const countries = useAppSelector(getDictionary('countries'));
  const options = [
    {
      text: t(TextCodes.WG6.Generic.Select),
      value: '',
    },
    ...countries,
  ] as AutocompleteOption[];

  const error = get(formState?.errors, name);

  return (
    <FormControl fullWidth error={!!error && !value}>
      <InputLabel htmlFor={name} id={name}>
        {label}
      </InputLabel>
      <Select
        {...register(name)}
        size='small'
        SelectDisplayProps={{
          style: { display: 'flex', alignItems: 'center' },
        }}
        labelId={name}
        id={name}
        value={value}
        label={label}
        inputProps={{ id: name }}
      >
        {options.map((o) => (
          <MenuItem
            onClick={() => {
              setValue(name, o.value!!);
            }}
            key={o.value}
            value={o.value!!}
          >
            <Typography variant='body1'>{o.text}</Typography>
          </MenuItem>
        ))}
      </Select>
      {!!error && !value && (
        <FormHelperText>{t(error?.message)}</FormHelperText>
      )}
    </FormControl>
  );
};
