import { useTranslation } from 'react-i18next';
import { TextCodes } from 'hooks';
import Button, { ButtonProps } from '@mui/material/Button';

const FormCancelButton = ({ size = 'medium', onClick }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button variant='text' size={size} onClick={onClick}>
      {t(TextCodes.WG6.Navigation.Cancel)}
    </Button>
  );
};

export default FormCancelButton;
