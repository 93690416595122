import { Routes as DomRoutes, Route, Location } from 'react-router-dom';
import PageNotFound from 'components/common/errors/errorHandlers/PageNotFound';
import { InternalRoute } from 'routes/components/InternalRoute';
import { routeConfig } from 'routes/config/route.config';

export const RouteList = ({ location }: { location: Location }) => {
  return (
    <DomRoutes location={location}>
      {Object.entries(routeConfig).map((item, index) => {
        const route = item[1];
        const element = (
          <InternalRoute element={route.element} skipLogin={route.skipLogin} />
        );
        return <Route path={route.path} key={index} element={element} />;
      })}
      <Route path='*' element={<PageNotFound />} />
    </DomRoutes>
  );
};
