import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EditSasTravelPassForm from 'components/features/user/benefitCardsAndPreferences/SasTravelPass/SasTravelPassForm';
import InfoButtonWithDialog from 'components/common/modals/InfoButtonWithDialog';
import PageTitle from 'components/common/pageTitle/PageTitle';
import StandardHeader from 'components/common/headers/StandardHeader';
import { Card } from 'components/common';
import { TextCodes } from 'hooks';

const SasTravelPassPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <StandardHeader showBreadcrumbs={false} />
      <Container>
        <PageTitle
          title={t(TextCodes.WG6.SasTravel.Title)}
          sx={{ mt: 7 }}
          button={
            <InfoButtonWithDialog
              ariaLabel={t(TextCodes.WG6.SasTravel.InfoButtonAriaLabel)}
              popupText={t(TextCodes.WG6.SasTravel.Popup)}
            />
          }
        />
        <Card aria-live='polite'>
          <EditSasTravelPassForm />
        </Card>
      </Container>
    </>
  );
};

export default SasTravelPassPage;
