import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EditNationalIdForm from 'components/features/user/documents/nationalId/EditNationalIdForm';
import InfoButtonWithDialog from 'components/common/modals/InfoButtonWithDialog';
import PageTitle from 'components/common/pageTitle/PageTitle';
import StandardHeader from 'components/common/headers/StandardHeader';
import { Card } from 'components/common';
import { TextCodes } from 'hooks';

const EditNationalIdPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <StandardHeader />
      <Container>
        <PageTitle
          title={t(TextCodes.WG6.NationalId.Title)}
          button={
            <InfoButtonWithDialog
              popupText={t(TextCodes.WG6.NationalId.Popup)}
            />
          }
        />
        <Card aria-live='polite'>
          <EditNationalIdForm />
        </Card>
      </Container>
    </>
  );
};

export default EditNationalIdPage;
