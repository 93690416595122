import { DeleteOutline } from '@mui/icons-material';
import { Divider, Grid, IconButton } from '@mui/material';
import AddMoreInputsButton from 'components/common/forms/AddMoreInputsButton';
import TextInput from 'components/common/inputs/textInput/TextInput';
import { motion } from 'framer-motion';
import useAnimatedFieldArray from 'hooks/animation/useAnimatedFieldArray';
import { TextCodes } from 'hooks/texts/text.codes';
import { useTranslation } from 'react-i18next';
import LanguageSelect from 'components/features/language/LanguageSelect';
import { LanguageCodes } from 'store/texts/language.config';

const OrderReceiptEmails = () => {
  // Hooks
  const { t } = useTranslation();

  // Form
  const { append, remove, variants, fields, itemControls } =
    useAnimatedFieldArray('emails');

  return (
    <Grid container component='ul' sx={{ p: 0, m: 0 }}>
      {fields.map((f, i) => (
        <Grid
          container
          component={motion.li}
          variants={variants}
          animate={itemControls}
          custom={i}
          key={f.id}
          gap={2}
          item
          display='flex'
          justifyContent='flex-end'
        >
          {i !== 0 && (
            <IconButton color='primary' sx={{ p: 0 }} onClick={() => remove(i)}>
              <DeleteOutline />
            </IconButton>
          )}
          <TextInput
            name={`emails.${i}.mail`}
            label={t(TextCodes.WG6.Generic.Email)}
            fullWidth
            sx={{ mb: 0 }}
          />
          <LanguageSelect name={`emails.${i}.language`} size='small' />
        </Grid>
      ))}
      <Grid container gap={2}>
        <Grid item xs={12}>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>
        <AddMoreInputsButton
          sx={{ mb: 0 }}
          onClick={() => append({ mail: '', language: LanguageCodes.NO })}
          label={t(TextCodes.WG6.Add.Emails)}
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderReceiptEmails;
