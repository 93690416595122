import { Link as MdLink, LinkProps } from '@mui/material';
import { Link as RouterLink, Params } from 'react-router-dom';
import { useInternalPath } from 'hooks';
import { RouteId } from 'routes/config/route.config';

export type LinkInternalProps = Pick<LinkProps, 'onClick' | 'sx' | 'variant' | 'underline'> & {
  routeId: RouteId;
  routeParams?: Params;
  children?: string | JSX.Element | JSX.Element[];
};

export const LinkInternal = ({ routeId, routeParams, children, onClick, sx, variant, underline }: LinkInternalProps) => {
  const to = useInternalPath(routeId, routeParams);

  return (
    <MdLink fontWeight={500} underline={underline} variant={variant} component={RouterLink} to={to} sx={sx} onClick={onClick}>
      {children}
    </MdLink>
  );
};
