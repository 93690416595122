import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import StaticMap from 'components/features/map/StaticMap';
import { GeoCords } from 'services/api/itinerary';
import { NotImplementedButton } from 'components/common/not-implemented/NotImplementedModal';
import { TextCodes } from 'hooks/texts/text.codes';
import { Stack } from '@mui/material';

interface LocationDetailsProps {
  headerText?: string | null;
  address: string;
  phoneNumber?: string | null;
  coords: GeoCords;
}

const LocationDetails = ({
  headerText,
  address,
  phoneNumber,
  coords,
}: LocationDetailsProps) => {
  // Hooks
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack
      component={Card}
      gap={2}
      elevation={0}
      border={`1px solid ${theme.palette.other?.outlineBorder}`}
    >
      <Stack component={Container} paddingTop={2} gap={2}>
        {headerText && <Typography variant='body2'>{headerText}</Typography>}
        {phoneNumber && (
          <Typography
            component='a'
            href={`tel:${phoneNumber}`}
            variant='body2link'
            sx={{
              ':visited': { color: theme.palette.primary.main },
            }}
          >
            {phoneNumber}
          </Typography>
        )}
        <NotImplementedButton>
          <Typography
            component='a'
            variant='body2link'
            aria-label={t(TextCodes.WG6.Hidden.LinkToGoogleMapsLocation)}
            href={`https://www.google.com/maps/search/?api=1&query=${address}`}
            target='_blank'
            sx={{
              ':visited': { color: theme.palette.primary.main },
            }}
          >
            {address}
          </Typography>
        </NotImplementedButton>
      </Stack>
      <NotImplementedButton>
        <StaticMap
          height={88}
          longitude={coords.longitude!!}
          latitude={coords.latitude!!}
        />
      </NotImplementedButton>
    </Stack>
  );
};

export default LocationDetails;
