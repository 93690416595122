import { DropdownOption, Dropdown } from 'components/common/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'store/texts/language.config';

export const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const languages = [
    {
      label: 'Norsk',
      value: LanguageCodes.NO,
    },
    {
      label: 'English',
      value: LanguageCodes.EN,
    },
  ];
  let options: DropdownOption[] = languages.map((language) => {
    const isSelected = language.value === i18n.language;
    return {
      name: language.label,
      action: () => (isSelected ? null : i18n.changeLanguage(language.value)),
      isSelected,
    };
  });
  return (
    <Dropdown
      menuSx={{ mt: 1 }}
      itemSx={{ width: '133px' }}
      textVariant='buttonMedium'
      elevation={3}
      label={languages.find((l) => l.value === i18n.language)?.label}
      options={options}
      buttonId='languageSwitchButton'
      menuId='languageSwitchMenu'
    />
  );
};
