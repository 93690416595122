import { CreditCardTypeCardBrandId } from 'credit-card-type/dist/types';

export type M3CardType = 'VI' | 'CA' | 'DC' | 'AX';

export type CardProviderMapping = {
  m3Type: M3CardType;
  brandId: CreditCardTypeCardBrandId;
};

const cardTypes: CardProviderMapping[] = [
  { m3Type: 'AX', brandId: 'american-express' },
  { m3Type: 'CA', brandId: 'mastercard' },
  { m3Type: 'DC', brandId: 'diners-club' },
  { m3Type: 'VI', brandId: 'visa' },
];

export const convertFromM3Type = (m3CardType: M3CardType | null | undefined) =>
  cardTypes.find((brand) => brand.m3Type === m3CardType)?.brandId;

export const convertToM3Type = (
  cardBrandId: CreditCardTypeCardBrandId | string | null | undefined
) => cardTypes.find((brand) => brand.brandId === cardBrandId)?.m3Type;
