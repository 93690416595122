import { Drawer, Grid } from '@mui/material';
import ProfileInformationAndShortCuts from 'components/features/header/profileMenu/ProfileInformationAndShortCuts';
import ProfileMenuList from 'components/features/header/profileMenu/ProfileMenuList';
import { useProfileMenuDrawer } from 'hooks/menu/menu.hooks';
import { useTheme } from 'hooks/theme/theme.hooks';

const ProfileMenu = () => {
  const { profileMenuExpanded } = useProfileMenuDrawer();
  const { currentTheme } = useTheme();

  return (
    <Drawer
      open={profileMenuExpanded}
      anchor='right'
      sx={{
        minHeight: '100vh',
        height: '100%',
        flexShrink: 0,
        zIndex: 2,
        width: '100%',
      }}
    >
      <Grid
        container
        justifyContent='center'
        sx={{
          backgroundColor: currentTheme.palette.background.paper,
          width: '100vw',
          marginTop: '61px',
          paddingTop: 2,
        }}
      >
        <ProfileInformationAndShortCuts />
        <ProfileMenuList />
      </Grid>
    </Drawer>
  );
};

export default ProfileMenu;
