import { useAppDispatch, useAppSelector } from 'store/store';
import { getMainMenuDrawerExpandedFlag, getProfileMenuDrawerExpandedFlag, setMainMenuDrawer, setProfileMenuDrawer } from 'store/app/app.slice';

export const useMainMenuDrawer = () => {
  const dispatch = useAppDispatch();
  const mainMenuExpanded = useAppSelector(getMainMenuDrawerExpandedFlag);
  function toggleMainMenuDrawer() {
    dispatch(setMainMenuDrawer(!mainMenuExpanded));
  }
  return { mainMenuExpanded, toggleMainMenuDrawer };
};

export const useProfileMenuDrawer = () => {
  const dispatch = useAppDispatch();
  const profileMenuExpanded = useAppSelector(getProfileMenuDrawerExpandedFlag);
  function toggleProfileMenuDrawer() {
    dispatch(setProfileMenuDrawer(!profileMenuExpanded));
  }
  return { profileMenuExpanded, toggleProfileMenuDrawer };
};
