import { InfoOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextCodes } from 'hooks';
import { Dialog, DialogProps } from 'components/common/modals/Modal';

type InfoButtonProps = Pick<DialogProps, 'maxWidth'> & {
  popupText: string;
  ariaLabel?: string;
  popupTitle?: string;
  size?: 'small' | 'medium' | 'large';
};

const InfoButtonWithDialog = ({
  popupText,
  popupTitle,
  ariaLabel = 'info',
  maxWidth = 'xs',
  size = 'small',
}: InfoButtonProps) => {
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const closePopup = () => setDialogOpen(false);

  return (
    <>
      <Dialog
        title={popupTitle}
        open={isDialogOpen}
        onClose={closePopup}
        confirmLabel={t(TextCodes.WG6.Navigation.Close)}
        onConfirm={closePopup}
        maxWidth={maxWidth}
      >
        {popupText}
      </Dialog>
      <IconButton
        size={size}
        onClick={() => setDialogOpen(true)}
        color='primary'
        aria-label={ariaLabel}
      >
        <InfoOutlined />
      </IconButton>
    </>
  );
};

export default InfoButtonWithDialog;
