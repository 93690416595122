import Box from '@mui/material/Box';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { get, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TooltipButton from 'components/common/buttons/TooltipButton';

export type CheckboxInputProps = Pick<
  CheckboxProps,
  'sx' | 'onChange' | 'onBlur' | 'value'
> & {
  name: string;
  label?: string;
  error?: boolean;
  errorMessage?: string;
  tooltip?: string;
  helperText?: string;
};

export const CheckboxField = ({
  name,
  label = '',
  tooltip,
  helperText,
  sx,
  ...rest
}: CheckboxInputProps) => {
  const { t } = useTranslation();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const value = useWatch({
    name: name,
    control,
  });
  const error = get(errors, name);

  const priorityHelperText = error ? t(error.message) : helperText;

  return (
    <Box>
      <Box display='flex'>
        <FormControlLabel
          sx={{ mr: 0 }}
          control={<Checkbox {...register(name)} sx={sx} checked={value} />}
          label={label}
        />
        {tooltip && <TooltipButton title={tooltip} arrow placement='top' />}
      </Box>
      <FormHelperText error={error && true}>
        {priorityHelperText}
      </FormHelperText>
    </Box>
  );
};
