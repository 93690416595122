import { SelectProps } from '@mui/material';
import SingleSelect from 'components/common/inputs/SingleSelect';
import { useTranslation } from 'react-i18next';
import { DictionaryItem } from 'services/api/user';
import { TextCodes } from '../../../hooks';

interface Props extends SelectProps {
  name: string;
  label: string;
}

const GenderInput = ({ name, label, ...rest }: Props) => {
  const { t } = useTranslation();
  const genders: DictionaryItem[] = [
    { value: 'MS', text: t(TextCodes.WG6.Profile.Female) },
    { value: 'MR', text: t(TextCodes.WG6.Profile.Male) },
  ];

  return <SingleSelect {...rest} options={genders} name={name} label={label} labelId={name} />;
};

export default GenderInput;
