import { useAppDispatch, useAppSelector } from '../../store/store';
import { getCurrentThemeId, getCurrentTheme, setCurrentThemeId } from '../../store/app/app.slice';
import { useLocalStorage } from '../';
import { ThemeIds } from '../../theme/theme.types';
import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';

// Use theme saved in local storage
// If no theme is saved in local storage, try to detect dark mode preference
// Otherwise use default light theme
export const useTheme = () => {
  const dispatch = useAppDispatch();

  // Hooks & vars
  const [storedTheme, setStoredTheme] = useLocalStorage('theme', null);
  const detectedDefaultTheme = useDetectedTheme();

  // Set theme to specific id
  const setTheme = (themeId: ThemeIds) => {
    setStoredTheme(themeId);
    dispatch(setCurrentThemeId(themeId));
  };

  // Dark & light theme toggle
  const switchTheme = () => {
    const themeId = isDarkTheme ? ThemeIds.Light : ThemeIds.Dark;
    setTheme(themeId);
  };

  // Read and use saved theme
  useEffect(() => {
    const initialTheme = storedTheme !== null ? storedTheme : detectedDefaultTheme;
    setTheme(initialTheme);
  });

  const currentThemeId = useAppSelector(getCurrentThemeId);
  const currentTheme = useAppSelector(getCurrentTheme);
  const isDarkTheme = currentThemeId === ThemeIds.Dark;

  return { currentThemeId, currentTheme, isDarkTheme, setTheme, switchTheme };
};

// Attempt to detect if user prefers dark color schema
// NoSsr flag is necessary as otherwise first call to useMediaQuery always return false
export const useDetectedTheme = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
  return prefersDarkMode ? ThemeIds.Dark : ThemeIds.Light;
};
