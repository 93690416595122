import { Container } from '@mui/material';
import StandardHeader from 'components/common/headers/StandardHeader';
import InfoButtonWithDialog from 'components/common/modals/InfoButtonWithDialog';
import PageTitle from 'components/common/pageTitle/PageTitle';
import PaymentSettingsForm from 'components/features/user/payment-settings/components/PaymentSettingsForm';
import usePayment from 'hooks/payment/usePayment';
import { TextCodes } from 'hooks/texts/text.codes';
import { useTranslation } from 'react-i18next';
import PaymentSettingsSkeleton from 'routes/payment-settings/PaymentSettingsSkeleton';

const PaymentSettingsPage = () => {
  const { isLoadingPayment, didLoadInitialPaymentData } = usePayment();
  const { t } = useTranslation();

  return (
    <>
      <StandardHeader showBreadcrumbs={false} />
      <Container aria-live='polite' sx={{ mt: 7 }}>
        <PageTitle
          title={t(TextCodes.WG6.Payment.Title)}
          button={
            <InfoButtonWithDialog popupText={t(TextCodes.WG6.Payment.Popup)} />
          }
        />
        {isLoadingPayment || !didLoadInitialPaymentData ? (
          <PaymentSettingsSkeleton />
        ) : (
          <PaymentSettingsForm />
        )}
      </Container>
    </>
  );
};

export default PaymentSettingsPage;
