import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextBlock from 'components/common/text/TextBlock';
import { Alert, EditableCard } from 'components/common';
import { TextCodes } from 'hooks';
import {
  getIsReadOnlyProfile,
  getMappedBonusCards as getBonusCardsWithData,
} from 'store/user/user.slice';
import { useAppSelector } from 'store/store';

const BenefitCardsSection = () => {
  // Global state
  const benefitCards = useAppSelector(getBonusCardsWithData);
  const isReadOnly = useAppSelector(getIsReadOnlyProfile);

  // Hooks/Variables
  const { t } = useTranslation();
  const isEmpty = !benefitCards?.all || benefitCards.all.length === 0;

  return (
    <EditableCard
      isEditable={!isReadOnly}
      routeId='BenefitCards'
      title={t(TextCodes.WG6.BonusCards.Title)}
    >
      {!benefitCards.canAdd && (
        <Alert severity='info'>{t(TextCodes.WG6.BonusCards.CannotAdd)}</Alert>
      )}
      {!isEmpty && (
        <Stack spacing={1}>
          {benefitCards?.all?.map((card, index) => (
            <TextBlock
              key={index}
              title={card.label as string}
              removeDivider={benefitCards?.all?.length === index + 1}
            >
              {card.cardNumber}
            </TextBlock>
          ))}
        </Stack>
      )}

      {isEmpty && benefitCards.canAdd && (
        <Typography variant='body2' color='text.secondary'>
          {t(TextCodes.WG6.BonusCards.Empty)}
        </Typography>
      )}
    </EditableCard>
  );
};

export default BenefitCardsSection;
