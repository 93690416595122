import { Box, Container, Skeleton, Typography } from '@mui/material';
import ScrollToTopFab from 'components/common/buttons/fab/ScrollToTopFab';
import FilterButton from 'components/common/buttons/FilterButton';
import StandardHeader from 'components/common/headers/StandardHeader';
import OrdersAccordion from 'components/features/order/OrdersAccordion';
import OrdersChips from 'components/features/order/OrdersChips';
import OrdersFilterMenu from 'components/features/order/OrdersFilterMenu';
import OrdersFilterText from 'components/features/order/OrdersFilterText';
import { TextCodes } from 'hooks/texts/text.codes';
import useTrips from 'hooks/trips/useFetchAllTrips';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getMonthByNumber, getYear } from 'services/text/datetime-format';
import { isEmpty } from 'lodash';
import OrderReceiptModal from 'components/features/order/OrderReceiptModal';
import useOrder from 'hooks/orders/useOrder';
import dayjs from 'dayjs';
import SearchParamProps from 'components/common/search-params/SearchParamProps';

const OrdersPage = () => {
  // Hooks
  const [searchParams] = useSearchParams();
  const {
    currentTab,
    referenceParam,
    monthParam,
    yearParam,
    travellerParam,
    from,
    to,
  } = useMemo(() => {
    return {
      currentTab: searchParams.get('type'),
      referenceParam: searchParams.get('reference'),
      monthParam: searchParams.get('month'),
      yearParam: searchParams.get('year'),
      from: searchParams.get('from'),
      to: searchParams.get('to'),
      travellerParam: searchParams.get('traveller')?.replaceAll('+', ' '),
    };
  }, [searchParams]);
  const { typesToFetch, allTrips } = useTrips();
  const { t } = useTranslation();
  const { orderNumber } = useOrder();

  // Local state
  const [isFilterTabOpen, setIsFilterTabOpen] = useState(false);

  // Create chips according to filters that have been applied
  const chips: SearchParamProps[] = useMemo(() => {
    let chipList: SearchParamProps[] = [];
    // There are some filter items that we do not want to show in the filter list, or want to parse differently.
    const excludedFilters = ['type', 'month', 'reference', 'from', 'to'];

    searchParams.forEach((value: string, key: string) => {
      if (!excludedFilters.includes(key))
        chipList.push({ param: key, label: value, value: value });
      if (key === 'month' && !isEmpty(value)) {
        chipList.push({
          param: key,
          label: getMonthByNumber(parseInt(value) - 1)?.toString()!,
          value: (parseInt(value) - 1).toString(),
        });
      }
      if (key === 'reference' && !isEmpty(value)) {
        chipList.push({
          param: key,
          label: `ref. ${value}`,
          value: value,
        });
      }
      if (key === 'from' && !isEmpty(value)) {
        chipList.push({
          param: key,
          label: `${t(TextCodes.WG6.Orders.From)} ${value}`,
          value: value,
        });
      }
      if (key === 'to' && !isEmpty(value)) {
        chipList.push({
          param: key,
          label: `${t(TextCodes.WG6.Orders.To)} ${value}`,
          value: value,
        });
      }
    });
    return chipList;
  }, [searchParams]);

  const toggleFilterTab = () => {
    setIsFilterTabOpen(!isFilterTabOpen);
  };

  const filteredTrips = useMemo(() => {
    if (allTrips.length === 0) return [];

    let newFilterList = allTrips.filter(
      (t) => t.bookingStatus?.toLowerCase() === currentTab?.toLowerCase()
    );
    if (referenceParam)
      newFilterList = newFilterList.filter(
        (trip) => trip.reference === referenceParam.toUpperCase()
      );
    if (travellerParam) {
      const [firstName, lastName] = travellerParam.split(' ');
      newFilterList = newFilterList.filter((trip) =>
        trip?.travellers?.some(
          (traveller) =>
            traveller.firstName!.toLowerCase() === firstName.toLowerCase() &&
            traveller.lastName!.toLowerCase() === lastName.toLowerCase()
        )
      );
    }
    if (yearParam)
      newFilterList = newFilterList.filter(
        (trip) => getYear(trip.outwardDate) === yearParam
      );
    if (monthParam)
      newFilterList = newFilterList.filter(
        (trip) => dayjs(trip.outwardDate).month() === parseInt(monthParam) - 1
      );
    if (from)
      newFilterList = newFilterList.filter(
        (trip) => trip.departureCode?.trim() === from?.trim()
      );
    if (to)
      newFilterList = newFilterList.filter(
        (trip) => trip.destinationCode?.trim() === to?.trim()
      );

    return newFilterList;
  }, [typesToFetch, searchParams, allTrips]);

  const hasFilters = chips.length > 0;
  return (
    <>
      <Box>
        <StandardHeader amountOfBreadcrumbs={2} />
        <Container>
          <Box justifyContent='space-between' sx={{ pb: 2, mt: 4 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant='h4' component='h1' fontWeight='bold'>
                {t(TextCodes.WG6.Orders.Title)}
              </Typography>
              <FilterButton onClick={toggleFilterTab} />
            </Box>
            <OrdersChips filters={chips} />
            {typesToFetch.length === 0 && hasFilters && (
              <OrdersFilterText
                hits={filteredTrips.length}
                total={allTrips.length - filteredTrips.length}
              />
            )}
            {typesToFetch.length !== 0 && hasFilters && (
              <Skeleton width={200} height={20} />
            )}
          </Box>
        </Container>
        <Box>
          <OrdersAccordion
            filteredTrips={filteredTrips}
            hasFilters={hasFilters}
            title={t(TextCodes.WG6.Orders.Upcoming)}
            tabName={'upcoming'}
            loading={typesToFetch.includes('Upcoming')}
          />
          <OrdersAccordion
            filteredTrips={filteredTrips}
            hasFilters={hasFilters}
            title={t(TextCodes.WG6.Orders.Past)}
            tabName={'past'}
            loading={typesToFetch.includes('Past')}
          />
          <OrdersAccordion
            filteredTrips={filteredTrips}
            hasFilters={hasFilters}
            title={t(TextCodes.WG6.Orders.Cancelled)}
            tabName={'cancelled'}
            loading={typesToFetch.includes('Cancelled')}
          />
          <OrdersFilterMenu open={isFilterTabOpen} onClose={toggleFilterTab} />
          <ScrollToTopFab />
        </Box>
      </Box>
      <OrderReceiptModal key={orderNumber} />
    </>
  );
};

export default OrdersPage;
