import PhoneNumber from 'awesome-phonenumber';
import { useTranslation } from 'react-i18next';

import NotRegistered from 'components/common/text/NotRegistered';
import TextBlock from 'components/common/text/TextBlock';
import { PhoneData } from 'services/api/user';
import { TextCodes } from 'hooks';

const PhoneBlock = ({ list }: { list: PhoneData[] | null | undefined }) => {
  const { t } = useTranslation();
  const isEmpty = !list || list.length === 0;

  return (
    <>
      {!isEmpty ? (
        list?.map(({ number, type }, key) => {
          const formattedPhoneNumber = new PhoneNumber(`+${number}`).getNumber(
            'international'
          );

          return (
            <TextBlock
              removeDivider
              title={t(TextCodes.WG6.Contact.Phone)}
              key={key}
            >
              {formattedPhoneNumber}
            </TextBlock>
          );
        })
      ) : (
        <NotRegistered
          removeDivider
          textBlock
          title={t(TextCodes.WG6.Contact.Phone)}
        />
      )}
    </>
  );
};

export default PhoneBlock;
