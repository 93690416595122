const PrivateProfile = () => (
  <svg
    width='93'
    height='122'
    viewBox='0 0 93 122'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M61.5 30.2375C67.445 30.2375 72.2625 35.055 72.2625 41C72.2625 46.945 67.445 51.7625 61.5 51.7625C55.555 51.7625 50.7375 46.945 50.7375 41C50.7375 35.055 55.555 30.2375 61.5 30.2375ZM61.5 76.3625C76.7212 76.3625 92.7625 83.845 92.7625 87.125V92.7625H30.2375V87.125C30.2375 83.845 46.2787 76.3625 61.5 76.3625ZM61.5 20.5C50.1737 20.5 41 29.6737 41 41C41 52.3263 50.1737 61.5 61.5 61.5C72.8263 61.5 82 52.3263 82 41C82 29.6737 72.8263 20.5 61.5 20.5ZM61.5 66.625C47.8162 66.625 20.5 73.4925 20.5 87.125V102.5H102.5V87.125C102.5 73.4925 75.1838 66.625 61.5 66.625Z'
      fill='#3057E4'
      fillOpacity='0.1'
    />
  </svg>
);

export default PrivateProfile;
