import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import EditDocuments, {
  defaultDocument,
} from 'components/features/documents/EditDocuments';
import { Document, userApi } from 'services/api/user';
import { TextCodes } from 'hooks/texts/text.codes';
import { getVisas } from 'store/user/user.slice';
import { routeConfig } from 'routes/config/route.config';
import { useAppSelector } from 'store/store';
import { useToast } from 'hooks';
import FormPanel from 'components/common/forms/FormPanel';

interface FormFields {
  documents: Document[];
  didDelete: boolean;
}

const EditVisaForm = () => {
  // Global state
  const visas = useAppSelector(getVisas);

  // Hooks/Variables
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toast } = useToast();

  // API
  const [saveVisas, { isLoading, data }] = userApi.useUserVisasSaveMutation();

  // Form methods
  const formMethods = useForm<FormFields>({
    defaultValues: {
      documents: visas?.length ? visas : [defaultDocument],
      didDelete: false,
    },
    resolver: yupResolver(
      Yup.object().shape({
        documents: Yup.array().of(
          Yup.object()
            .shape({
              number: Yup.string().required(TextCodes.WG6.Validation.Required),
              issuedDate: Yup.string()
                .disallowFutureDate()
                .required(TextCodes.WG6.Validation.Required),
              expiryDate: Yup.string()
                .disallowPastDate(TextCodes.WG6.Documents.ExpiredVisa)
                .required(TextCodes.WG6.Validation.Required),
              countryCode: Yup.string().required(
                TextCodes.WG6.Validation.Required
              ),
            })
            .optional()
        ),
      })
    ),
  });
  const watch = formMethods.watch();

  // Success handler
  useEffect(() => {
    if (data?.isSuccess) {
      navigate(routeConfig.Documents.path);
      toast({
        severity: 'success',
        autoHideDuration: 3000,
        message: t(TextCodes.WG6.Status.ChangesSavedSuccessfully),
      });
    }
  }, [data]);

  const onSubmit = (formData: FormFields) => {
    const docs = formData.documents.filter((doc) => doc != null);
    saveVisas({ body: docs });
  };

  return (
    <FormProvider {...formMethods}>
      <form>
        <EditDocuments
          arrayName='documents'
          addButtonLabel={t(TextCodes.WG6.Visa.Add)}
          documentLabel={t(TextCodes.WG6.Visa.VisaNumber)}
        />
        <FormPanel
          isLoading={isLoading}
          errorMessage={data?.message || ''}
          onSubmit={formMethods.handleSubmit(onSubmit)}
          shouldShowCancelWarning={
            watch.didDelete || formMethods.formState.isDirty
          }
          shouldShowSaveWarning={watch.didDelete}
        />
      </form>
    </FormProvider>
  );
};

export default EditVisaForm;
