import { Container, Stack, Skeleton } from '@mui/material';

const AirFareRulesDrawerSkeleton = () => (
  <Container sx={{ p: 0 }}>
    <Stack spacing={2}>
      <Skeleton variant='rectangular' height={116} width={'100%'} />
      <Skeleton variant='rectangular' height={116} width={'100%'} />
      <Skeleton variant='rectangular' height={116} width={'100%'} />
    </Stack>
  </Container>
);

export default AirFareRulesDrawerSkeleton;
