import { baseApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    locations: build.mutation<LocationsResult, LocationsArgs>({
      query: (queryArg) => ({
        url: `/api/locations`,
        method: 'POST',
        body: queryArg.searchLocationsRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as locationsApi };
export type LocationsResult =
  /** status 200 Success */ IResultIListCodeDescriptionPair;
export type LocationsArgs = {
  searchLocationsRequest: SearchLocationsRequest;
};
export type CodeDescriptionPair = {
  code?: string | null;
  description?: string | null;
};
export type IResultIListCodeDescriptionPair = {
  value?: CodeDescriptionPair[] | null;
  errorCode?: string | null;
  message?: string | null;
  isSuccess?: boolean;
};
export type SearchLocationsRequest = {
  keyword?: string | null;
  cacheKey?: string | null;
};
