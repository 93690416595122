import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'store/texts/language.config';

export const DevLanguageSwitch = () => {
  const { i18n } = useTranslation();
  const onChangeLanguage = () => {
    i18n.changeLanguage(i18n.language === LanguageCodes.EN ? LanguageCodes.NO : LanguageCodes.EN);
  };
  return (
    <FormGroup sx={{ ml: 2, mt: 1 }}>
      <FormControlLabel control={<Switch checked={i18n.language === LanguageCodes.EN} onClick={onChangeLanguage} />} label='Switch language' />
    </FormGroup>
  );
};
