export const SiteLogoLight = () => {
  return (
    <svg width='124px' height='25px' viewBox='12 12 124 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M37.0079 24.5013C37.0079 31.4026 31.4081 37 24.5039 37C17.5997 37 12 31.4026 12 24.5013C12 17.6 17.5997 12.0027 24.5039 12.0027C31.4081 12.0027 37.0079 17.6 37.0079 24.5013Z'
        fill='#F3F3F3'
      />
      <path
        d='M19.5087 19.0312C17.027 19.2538 14.5984 19.9005 12.6363 20.5684C14.8211 14.0169 21.9056 10.4656 28.4598 12.6388C28.6825 12.713 28.9052 12.7978 29.1174 12.8826C27.3038 12.7766 25.1721 13.1794 22.9131 14.653C21.3117 15.6813 20.1132 17.229 19.5087 19.0312ZM24.0479 24.4378C24.7054 25.6887 25.5645 26.823 26.572 27.7983C27.4311 28.6252 28.8416 29.8655 30.3158 31.1588C30.4855 29.8867 30.4324 28.71 29.9764 27.8301C29.0537 25.9961 26.975 24.7028 24.0479 24.4378ZM19.1163 24.8724C17.0695 25.3706 14.8105 26.2611 12.403 27.6393C13.8348 33.1518 18.8194 37 24.5145 37C25.1721 37 25.8402 36.947 26.4978 36.841C25.0554 35.3356 23.507 33.6501 22.2131 32.0917C20.7496 30.3425 19.5512 27.6711 19.1163 24.8724ZM32.3626 14.7802C29.7325 13.8049 25.8084 14.0169 23.8676 16.699C23.3691 17.3881 23.0404 18.1831 22.9131 19.0206C24.4403 19.169 25.9251 19.5613 27.2508 20.2821C31.5884 22.6568 32.4687 27.2046 31.4506 32.1235C32.0233 32.6218 32.596 33.1094 33.1262 33.5653C38.1427 28.816 38.3548 20.9076 33.6035 15.8933C33.2111 15.5011 32.7975 15.1194 32.3626 14.7802Z'
        fill='#3057E4'
      />
      <path
        d='M26.572 27.7983C27.4417 28.6251 28.8416 29.8655 30.3158 31.1588C30.093 32.8126 29.4779 34.6359 28.8204 36.2473C28.0674 36.5229 27.2826 36.7243 26.4872 36.8516C25.0448 35.3462 23.4858 33.6606 22.2025 32.1023C20.7496 30.3425 19.5511 27.6711 19.1163 24.8724C18.8087 22.9006 18.883 20.8546 19.5087 19.0312C20.1132 17.229 21.3117 15.6813 22.9131 14.653C25.1721 13.19 27.3038 12.7872 29.1173 12.8932C30.2839 13.3596 31.3763 13.9957 32.352 14.7908C29.7218 13.8049 25.7978 14.0275 23.857 16.7096C23.3585 17.3986 23.0297 18.1937 22.9025 19.0312C22.6055 20.8228 23.1464 22.7522 24.0479 24.4483C24.7054 25.6887 25.5645 26.823 26.572 27.7983ZM31.4399 32.1341C31.196 33.279 30.8778 34.4027 30.4748 35.4946C31.4293 34.9752 32.3202 34.3285 33.1156 33.5758C32.5853 33.12 32.0126 32.6323 31.4399 32.1341Z'
        fill='#68D8EA'
      />
      <path
        d='M62.2278 31.5829H61.1885V20.1655H64.7731C67.626 20.1655 68.5063 21.4165 68.5063 23.3776C68.5063 24.9042 67.976 25.9961 66.4064 26.4095L69.132 31.5935H67.9336L65.367 26.5792C65.1974 26.5898 65.0277 26.5898 64.858 26.5898H62.2278V31.5829ZM62.2278 25.6357H65.0489C67.0745 25.6357 67.3821 24.5968 67.3821 23.3776C67.3821 22.1585 67.0745 21.1196 65.0489 21.1196H62.2278V25.6357Z'
        fill='black'
        fillOpacity='0.87'
      />
      <path
        d='M58.6008 26.1233H53.5419V30.6288H59.1416V31.5829H52.5132V20.1655H59.1416V21.1302H53.5419V25.1586H58.6008V26.1233Z'
        fill='black'
        fillOpacity='0.87'
      />
      <path
        d='M43.1485 20.1655H46.7544C49.4164 20.1655 50.3072 21.5225 50.3072 23.1762C50.3072 24.1621 49.8088 25.1268 48.8119 25.5509C50.1269 25.9007 50.689 27.1516 50.689 28.3707C50.689 30.1517 49.7133 31.5829 46.871 31.5829H43.1591V20.1655H43.1485ZM46.9029 21.1302H44.1772V25.1692H47.0301C48.7482 25.1692 49.1831 24.1621 49.1831 23.1868C49.1724 22.1797 48.8437 21.1302 46.9029 21.1302ZM44.1772 30.6288H46.9983C49.1406 30.6288 49.5542 29.4733 49.5542 28.3707C49.5542 26.8336 48.8967 26.1233 47.0195 26.1233H44.1666L44.1772 30.6288Z'
        fill='black'
        fillOpacity='0.87'
      />
      <path
        d='M70.4259 25.8795C70.4259 22.6355 71.5077 20.0171 74.9227 20.0171C76.5029 20.0171 77.4998 20.3351 78.1256 20.6532V21.6921C76.9377 21.2044 76.1635 20.9712 74.9227 20.9712C72.4198 20.9712 71.5289 23.1126 71.5289 25.8689C71.5289 28.6251 72.4304 30.7666 74.9227 30.7666C75.7923 30.7666 76.8529 30.6923 77.3938 30.4379V26.5367H75.0075V25.5826H78.4331V31.1164C77.6589 31.4874 76.5241 31.7418 74.9333 31.7418C71.5077 31.7418 70.4259 29.1234 70.4259 25.8795Z'
        fill='black'
        fillOpacity='0.87'
      />
      <path
        d='M86.8327 31.5829V26.452H82.1981V31.5829H80.4906V20.1655H82.1981V24.883H86.8327V20.1655H88.5402V31.5829H86.8327Z'
        fill='black'
        fillOpacity='0.87'
      />
      <path
        d='M96.1656 28.7948H92.5067L91.7006 31.5829H89.8447L93.4718 20.0277H95.2111L98.8381 31.5829H96.9716L96.1656 28.7948ZM94.3414 22.3387L92.8778 27.3954H95.805L94.3414 22.3387Z'
        fill='black'
        fillOpacity='0.87'
      />
      <path
        d='M106.75 31.5829L101.787 23.4731L101.829 31.5829H100.143V20.1655H101.691L106.665 28.2859L106.623 20.1655H108.309V31.5829H106.75Z'
        fill='black'
        fillOpacity='0.87'
      />
      <path
        d='M117.642 28.1269C117.642 30.2895 116.359 31.7631 113.304 31.7631C112.159 31.7631 110.695 31.5087 109.921 31.1058V29.399C110.95 29.8867 112.063 30.1305 113.198 30.1411C115.256 30.1411 115.86 29.3036 115.86 28.2223C115.86 26.9608 115.33 26.6534 113.792 26.452C111.003 26.1021 109.921 25.36 109.921 23.2716C109.921 21.5967 110.865 19.9641 113.972 19.9641C115.447 19.9641 116.486 20.2185 117.069 20.5154V22.1797C116.115 21.7769 115.097 21.5755 114.068 21.5861C112.286 21.5861 111.671 22.3175 111.671 23.2928C111.671 24.5226 112.371 24.7346 113.877 24.936C116.263 25.2646 117.642 25.7947 117.642 28.1269Z'
        fill='black'
        fillOpacity='0.87'
      />
      <path d='M125.691 26.452H120.93V30.0139H126.179V31.5829H119.233V20.1655H126.179V21.7345H120.93V24.883H125.691V26.452Z' fill='black' fillOpacity='0.87' />
      <path
        d='M134.441 31.5829L129.478 23.4731L129.52 31.5829H127.834V20.1655H129.393L134.356 28.2859L134.314 20.1655H136V31.5829H134.441Z'
        fill='black'
        fillOpacity='0.87'
      />
    </svg>
  );
};
