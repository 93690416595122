import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AirFareRulesDrawerSkeleton from 'components/common/flight/AirFareRulesDrawerSkeleton';
import GenericError from 'components/common/errors/GenericError';
import { TextCodes } from 'hooks';
import {
  getAirFareRulesDrawer,
  setAirFareRulesDrawer,
} from 'store/app/app.slice';
import { itineraryApi } from 'services/api/itinerary';
import { useAppSelector } from 'store/store';

const AirFareRulesDrawer = () => {
  // Hooks
  const { palette } = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Global state
  const airFareRulesDrawer = useAppSelector(getAirFareRulesDrawer);
  const { isOpen, searchString, departureCityName, arrivalCityName } =
    airFareRulesDrawer;

  // API ✉️
  const [getAirFareRules, { isLoading, data: airFareRules, isError }] =
    itineraryApi.useAirFareRuleMutation();

  useEffect(() => {
    if (!!searchString) {
      // M3 is currently sending us the wrong searchString's in the itinerary details, so we only get an empty array. To test the UI with a working string, use this one: '%3BZSTT2CR%2BZSTT2CR%3B%3B%3B%3B%3B%3B%3B%3B%3BBUSPRO%2BBUSPRO%3B%3B%3B%C2%A7OSL%3BNYC%7CNYC%3BOSL%C2%A7,OSL,NYC,SK,907,Z,09.11.2022,11%3A00,09.11.2022,13%3A25,,,,Amadeus,,,,,,0,,%7C,NYC,OSL,SK,908,Z,16.11.2022,18%3A55,17.11.2022,08%3A15,,,,Amadeus,,,,,,0,,,BUSPRO%2BBUSPRO'
      getAirFareRules({ airFareRuleRequest: { searchString } });
    }
  }, [searchString]);

  return (
    <Drawer
      sx={{ width: '100%' }}
      open={isOpen}
      onClose={() =>
        dispatch(
          setAirFareRulesDrawer({
            ...airFareRulesDrawer,
            isOpen: false,
          })
        )
      }
      anchor='right'
      PaperProps={{
        sx: {
          width: '100%',
          backgroundColor: palette?.backgroundColors?.paper01,
        },
      }}
    >
      <Button
        onClick={() =>
          dispatch(
            setAirFareRulesDrawer({
              ...airFareRulesDrawer,
              isOpen: false,
            })
          )
        }
        variant='text'
        sx={{
          p: 2,
          color: palette?.text?.primary,
          justifyContent: 'space-between',
        }}
      >
        <ArrowBackIos color='primary' />
        <Typography variant='h4' component='h1' fontWeight='bold'>
          {t(TextCodes.WG6.Flight.Rules)}
        </Typography>
        <span></span>
      </Button>
      <Divider />
      <Stack spacing={2} aria-live='polite' sx={{ mt: 2, p: 2 }}>
        <Typography
          variant='h5'
          fontWeight={400}
          sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
        >
          {departureCityName}
          <ArrowForward
            sx={{ fontSize: '23px' }}
            aria-label={t(TextCodes.WG6.Base.To)}
          />
          {arrivalCityName}
        </Typography>
        {isLoading ? (
          <AirFareRulesDrawerSkeleton />
        ) : isError ? (
          <GenericError />
        ) : !airFareRules?.length ? (
          <Alert severity='info'>
            <AlertTitle>
              {t(TextCodes.WG6.Flight.MissingInformationTitle)}
            </AlertTitle>
            {t(TextCodes.WG6.Flight.MissingInformation)}
          </Alert>
        ) : (
          <>
            {airFareRules!?.map(({ ruleName, rules }) => (
              <Box
                key={ruleName}
                sx={{
                  backgroundColor: palette?.backgroundColors?.selectedCard,
                  p: 2,
                  borderRadius: 1,
                }}
              >
                <Typography variant='body2' fontWeight='bold' sx={{ pb: 1 }}>
                  {ruleName}
                </Typography>
                <List
                  sx={{
                    p: 0,
                    display: 'flex',
                    gap: 0.5,
                    flexDirection: 'column',
                  }}
                >
                  {rules?.map((li, index) => (
                    <ListItem
                      key={`${li}-${index}`}
                      component={Typography}
                      variant='body2'
                      sx={{ p: 0 }}
                    >
                      {li}
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </>
        )}
      </Stack>
    </Drawer>
  );
};

export default AirFareRulesDrawer;
