import { Grid, Container } from '@mui/material';
import StandardHeader from 'components/common/headers/StandardHeader';
import { SetPasswordForm } from 'components/features/set-password/SetPasswordForm';
import { ForgotPasswordFooter } from 'routes/forgot-password/ForgotPasswordPage';

export const SetPasswordPage = () => {
  return (
    <>
      <StandardHeader showBreadcrumbs={false} />
      <Grid
        component={Container}
        container
        flexDirection='column'
        gap={3}
        sx={{ mt: 2 }}
      >
        <SetPasswordForm />
        <ForgotPasswordFooter />
      </Grid>
    </>
  );
};
