export const TextCodes = {
  // WEBGATE 5 - old entries
  //--------------------------
  ValidationErrors: {
    Email: 'ValidationErrors.Email',
    Required: 'ValidationErrors.Required',
    PasswordRepeat: 'ValidationErrors.RepeatedPasswordsMustEqual',
  },
  Models: {
    Password: 'Models.Password',
    Email: 'Models.Email',
  },
  Common: {
    Submit: 'Submit',
    Delete: 'Delete',
  },
  PageTitle: {
    AccountLogin: 'PageTitle.AccountLogin',
    ForgotPassword: 'PageTitle.AccountForgottenPasswordEmail',
    SetPassword: 'PageTitle.AccountForgottenPasswordEmail',
  },
  ResponseMessage: {
    CredentialError: 'ResponseMessages.CredentialError',
  },
  Login: {
    InvalidUsernameOrPassword: 'Account.InvalidUsernameOrPassword',
  },
  Home: {
    HomePageTitle: 'Home.HomePageTitle',
  },
  Dashboard: {
    NoUpcomingTrips: 'Dashboard.NoUpcomingTrips',
    UpcomingTrips: 'Dashboard.UpcomingTrips',
  },

  // WEBGATE 6 - new entries
  //--------------------------
  WG6: {
    Date: {
      DateFieldPlaceholder: 'WG6.Date.DateFieldPlaceholder',
      January: 'W6.Date.January',
      February: 'W6.Date.February',
      March: 'W6.Date.March',
      April: 'W6.Date.April',
      May: 'W6.Date.May',
      June: 'W6.Date.June',
      July: 'W6.Date.July',
      August: 'W6.Date.August',
      September: 'W6.Date.September',
      October: 'W6.Date.October',
      November: 'W6.Date.November',
      December: 'W6.Date.December',
      OneLetterHour: 'W6.Date.OneLetterHour',
      OneLetterMinute: 'W6.Date.OneLetterMinute',
      OneLetterSecond: 'W6.Date.OneLetterSecond',
    },
    // Generic status messages
    Status: {
      ChangesSavedSuccessfully: 'W6.Status.ChangesSavedSuccessfully',
      UnsavedChanges: 'W6.Status.UnsavedChanges',
      ConfirmChanges: 'W6.Status.ConfirmChanges',
    },
    // BASE - common strings - translated in app
    Base: {
      AddAncillaries: 'W6.Base.AddAncillaries',
      Cancel: 'W6.Base.Cancel',
      CancelNotPossible: 'W6.Base.CancelNotPossible',
      ContactUs: 'W6.Base.ContactUs',
      Day: 'W6.Base.Day',
      Days: 'W6.Base.Days',
      Edit: 'W6.Base.Edit',
      EditNotPossible: 'W6.Base.EditNotPossible',
      NotAdded: 'W6.Base.NotAdded',
      NotRegistered: 'W6.Base.NotRegistered',
      ReloadPage: 'W6.Base.ReloadPage',
      Search: 'W6.Base.Search',
      Seat: 'W6.Base.Seat',
      SelectedAncillaries: 'W6.Base.SelectedAncillaries',
      To: 'W6.Base.To',
    },
    // ERROR
    Error: {
      BackToHome: 'W6.Error.BackToHome',
      NotFoundErrorAltText: 'W6.Error.NotFoundErrorAltText',
      NotFoundErrorCode: 'W6.Error.NotFoundErrorCode',
      NotFoundErrorMessage: 'W6.Error.NotFoundErrorMessage',
      NotFoundErrorTitle: 'W6.Error.NotFoundErrorTitle',
      PageCrashMessage: 'W6.Error.PageCrashMessage',
      PageCrashTitle: 'W6.Error.PageCrashTitle',
      RefreshPage: 'W6.Error.RefreshPage',
      SomethingWentWrongMessage: 'W6.Error.SomethingWentWrongMessage',
      SomethingWentWrongTitle: 'W6.Error.SomethingWentWrongTitle',
      UpdateThePageOrContactUs: 'W6.Error.UpdateThePageOrContactUs',
    },
    // HOME
    Home: {
      Title: 'W6.Home.Title',
    },
    Orders: {
      AlsoEmail: 'W6.Menu.AlsoEmail',
      AlsoSms: 'W6.Menu.AlsoSms',
      CancellationPolicy: 'W6.Orders.CancellationPolicy',
      Cancelled: 'W6.Orders.Cancelled',
      Destination: 'W6.Orders.Destination',
      From: 'W6.Orders.From',
      HiddenByFilter: 'W6.Orders.HiddenByFilter',
      Hits: 'W6.Orders.Hits',
      Month: 'W6.Orders.Month',
      NewOrder: 'W6.Orders.NewOrder',
      NoStatus: 'W6.Menu.NoStatus',
      OrderCardMenuFor: 'W6.Menu.OrderCardMenuFor',
      Past: 'W6.Orders.Past',
      Period: 'W6.Orders.Period',
      ReceiptNotAvailable: 'W6.Menu.ReceiptNotAvailable',
      ReceiptSent: 'W6.Menu.ReceiptSent',
      SearchByReference: 'W6.Orders.SearchByReference',
      SendTravelDetailsEmail: 'W6.Menu.SendTravelDetailsEmail',
      SendTravelDetailsSms: 'W6.Menu.SendTravelDetailsSms',
      SendTravelDetailsTitle: 'W6.Orders.SendTravelDetailsTitle',
      ShowReceipt: 'W6.Menu.ShowReceipt',

      Title: 'W6.Orders.Title',
      To: 'W6.Orders.To',
      Upcoming: 'W6.Orders.Upcoming',
      Year: 'W6.Orders.Year',
    },
    OrderDetails: {
      ByRequest: 'W6.OrderDetails.ByRequest',
      CancelForAll: 'W6.OrderDetails.CancelForAll',
      ChangeSeat: 'W6.OrderDetails.ChangeSeat',
      ChooseSeat: 'W6.OrderDetails.ChooseSeat',
      FlightTransfer: 'W6.OrderDetails.FlightTransfer',
      OrSimilar: 'W6.OrderDetails.OrSimilar',
      SelectedAccessories: 'W6.OrderDetails.SelectedAccessories',
      SendByEmail: 'W6.OrderDetails.SendByEmail',
      SendBySms: 'W6.OrderDetails.SendBySms',
      ShowReceipt: 'W6.OrderDetails.ShowReceipt',
      TimeToNextFlight: 'W6.OrderDetails.TimeToNextFlight',
      TotalTravelTime: 'W6.OrderDetails.TotalTravelTime',
    },
    Flight: {
      AmountOfBagsIncludedPlural: 'W6.Flight.AmountOfBagsIncludedPlural',
      AmountOfBagsIncludedSingular: 'W6.Flight.AmountOfBagsIncludedSingular',
      BaggageInfo: 'W6.Flight.BaggageInfo',
      BookingClass: 'W6.Flight.BookingClass',
      CancelFlight: 'W6.Flight.CancelFlight',
      CarrierName: 'W6.Flight.CarrierName',
      ChangeAirportWarning: 'W6.Flight.ChangeAirportWarning',
      ClassOfService: 'W6.Flight.ClassOfService',
      EditFlight: 'W6.Flight.EditFlight',
      FareBasis: 'W6.Flight.FareBasis',
      FlightEquipment: 'W6.Flight.FlightEquipment',
      FlightNumberModalTitle: 'W6.Flight.FlightNumberModalTitle',
      Included: 'W6.Flight.Included',
      MissingInformation: 'W6.Flight.MissingInformation',
      MissingInformationTitle: 'W6.Flight.MissingInformationTitle',
      NoBagsIncluded: 'W6.Flight.NoBagsIncluded',
      Rules: 'W6.Flight.Rules',
      StatusConfirmedTooltip: 'W6.Flight.StatusConfirmedTooltip',
      StatusInfoNotAvailableTooltip: 'W6.Flight.StatusInfoNotAvailableTooltip',
      StatusWaitingListTooltip: 'W6.Flight.StatusWaitingListTooltip',
      StopWithoutAChangeOfFlight: 'W6.Flight.StopWithoutAChangeOfFlight',
      WeightOfBagsIncluded: 'W6.Flight.WeightOfBagsIncluded',
    },
    Car: {
      CancelCar: 'W6.Car.CancelCar',
      CarInfo: 'W6.OrderDetails.CarInfo',
      EditCar: 'W6.Car.EditCar',
      PriceDisclaimer: 'W6.OrderDetails.PriceDisclaimer',
      RentalCar: 'W6.OrderDetails.RentalCar',
      StatusConfirmedTooltip: 'W6.Car.StatusConfirmedTooltip',
      StatusInfoNotAvailableTooltip: 'W6.Car.StatusInfoNotAvailableTooltip',
      StatusWaitingListTooltip: 'W6.Car.StatusWaitingListTooltip',
      TotalCost: 'W6.OrderDetails.TotalCost',
    },
    Hotel: {
      Nights: 'W6.Hotel.Nights',
      EditHotel: 'W6.Hotel.EditHotel',
      CancelHotel: 'W6.Hotel.CancelHotel',
      CheckInFrom: 'W6.Hotel.CheckInFrom',
      CheckOutBefore: 'W6.Hotel.CheckOutBefore',
      CheckIn: 'W6.Hotel.CheckIn',
      Checkout: 'W6.Hotel.Checkout',
      TotalPrice: 'W6.Hotel.TotalPrice',
      Disclaimer: 'W6.Hotel.Disclaimer',
      StatusConfirmedTooltip: 'W6.Hotel.StatusConfirmedTooltip',
      StatusWaitingListTooltip: 'W6.Hotel.StatusWaitingListTooltip',
      StatusInfoNotAvailableTooltip: 'W6.Hotel.StatusInfoNotAvailableTooltip',
    },
    // Landing Page
    LandingPage: {
      UsefulInfo: 'W6.LandingPage.UsefulInfo',
      InfoAboutEmployer: 'W6.LandingPage.InfoAboutEmployer',
      Receipts: 'W6.LandingPage.Receipts',
      Insight: 'W6.LandingPage.Insight',
      NoUpcomingTrips: 'W6.LandingPage.NoUpcomingTrips',
    },

    // Common validation messages
    Validation: {
      BankCardExpired: 'W6.Validation.BankCardExpired',
      BankCardFormat: 'W6.Validation.BankCardFormat',
      CapsLockWarning: 'W6.Validation.CapsLockWarning',
      CustomerReferenceFormat: 'W6.Validation.CustomerReferenceFormat',
      DateFormat: 'W6.Validation.DateFormat',
      EmailFormat: 'W6.Validation.EmailFormat',
      HighlightedFieldsRequired: 'W6.Validation.HighlightedFieldsRequired',
      InvalidDate: 'W6.Validation.InvalidDate',
      DateCannotBeInPast: 'W6.Validation.DateCannotBeInPast',
      DateCannotBeInFuture: 'W6.Validation.DateCannotBeInFuture',
      MinAge: 'W6.Validation.MinAge',
      NoSpecialChars: 'W6.Validation.NoSpecialChars',
      NumbersOnly: 'W6.Validation.NumbersOnly',
      OneCheckRequired: 'W6.Validation.OneCheckRequired',
      PasswordFormat: 'W6.Validation.PasswordFormat',
      PasswordMatch: 'W6.Validation.PasswordMatch',
      PhoneNumberFormat: 'W6.Validation.PhoneNumberFormat',
      Required: 'W6.Validation.Required',
      SeatNumberFormat: 'W6.Validation.SeatNumberFormat',
      SelectionRequired: 'W6.Validation.SelectionRequired',
      InvalidExpirationDateForBankCards:
        'W6.Validation.InvalidExpirationDateForBankCards',
    },
    // LOGIN
    Login: {
      Login: 'WG6.Login.Login',
      ForgotPassword: 'WG6.Login.ForgotPassword',
      CreateProfile: 'WG6.Login.CreateProfile',
      UsernameOrEmail: 'WG6.Login.UsernameOrEmail',
      Password: 'WG6.Login.Password',
      SingleSignOnLink: 'WG6.Login.SingleSignOnLink',
      SingleSignOnDescription: 'WG6.Login.SingleSignOnDescription',
      ValidationRequiredField: 'WG6.Login.ValidationRequiredField',
    },
    ForgottenPassword: {
      Title: 'W6.ForgottenPassword.Title',
      Description: 'W6.ForgottenPassword.Description',
      Email: 'W6.ForgottenPassword.Email',
      SuccessTitle: 'W6.ForgottenPassword.SuccessTitle',
      SuccessDescription: 'W6.ForgottenPassword.SuccessDescription',
      SuccessCheckSpam: 'W6.ForgottenPassword.SuccessCheckSpam',
      SuccessTryAgain: 'W6.ForgottenPassword.SuccessTryAgain',
      PasswordDescription: 'W6.ForgottenPassword.PasswordDescription',
      SetPasswordSuccessTitle: 'W6.ForgottenPassword.SetPasswordSuccessTitle',
      SetPasswordSuccessDescription:
        'W6.ForgottenPassword.SetPasswordSuccessDescription',
      ChangePasswordTitle: 'W6.ForgottenPassword.ChangePasswordTitle',
      ChangePasswordDescription:
        'W6.ForgottenPassword.ChangePasswordDescription',
    },
    NewProfile: {
      Title: 'W6.NewProfile.Title',
      PersonalProfile: 'W6.NewProfile.PersonalProfile',
      PersonalProfileDescription: 'W6.NewProfile.PersonalProfileDescription',
      BusinessProfile: 'W6.NewProfile.BusinessProfile',
      BusinessProfileDescription: 'W6.NewProfile.BusinessProfileDescription',
      BackToLogin: 'W6.NewProfile.BackToLogin',
    },
    // USER - customer profile
    User: {
      // Section selection screen
      ChangeUsername: 'W6.User.ChangeUsername',
      UsernameDescription: 'W6.User.UsernameDescription',
      SectionTitle: 'W6.User.SectionTitle',
      PersonalTitle: 'W6.User.PersonalSectionTitle',
      PersonalDescription: 'W6.User.PersonalSectionDescription',
      PasswordTitle: 'W6.User.PasswordSectionTitle',
      PasswordDescription: 'W6.User.PasswordSectionDescription',
      PaymentTitle: 'W6.User.PaymentSectionTitle',
      PaymentDescription: 'W6.User.PaymentSectionDescription',
      DocumentsTitle: 'W6.User.DocumentsSectionTitle',
      DocumentsDescription: 'W6.User.DocumentsSectionDescription',
      BonusTitle: 'W6.User.BonusSectionTitle',
      BonusDescription: 'W6.User.BonusSectionDescription',
      FamilyTitle: 'W6.User.FamilySectionTitle',
      FamilyDescription: 'W6.User.FamilySectionDescription',
      BankCardExpirationFormat: 'W6.User.BankCardExpirationFormat',
      NoneSelected: 'W6.User.NoneSelected',
    },

    // Profile
    Profile: {
      Personal: 'W6.Profile.Personal',
      PersonalPopup: 'W6.Profile.PersonalPopup',
      BaseInfo: 'W6.Profile.BaseInfo',
      Name: 'W6.Profile.Name',
      FirstName: 'W6.Profile.FirstName',
      LastName: 'W6.Profile.LastName',
      Username: 'W6.Profile.Username',
      Gender: 'W6.Profile.Gender',
      BirthDate: 'W6.Profile.BirthDate',
      Phone: 'W6.Profile.Phone',
      Email: 'W6.Profile.Email',
      Address: 'W6.Profile.Address',
      Contact: 'W6.Profile.Contact',
      Employer: 'W6.Profile.Employer',
      Arranger: 'W6.Profile.Arranger',
      Female: 'W6.Profile.Female',
      Male: 'W6.Profile.Male',
      MinAge: 'W6.Profile.MinAge',
    },

    BasicInformation: {
      UsernameHelperText: 'W6.BasicInformation.UsernameHelperText',
    },

    Contact: {
      AtLeastOneEmail: 'W6.Contact.AtLeastOneEmail',
      NoContactInfo: 'W6.Contact.NoContactInfo',
      AddEmail: 'W6.Contact.AddEmail',
      AddPhone: 'W6.Contact.AddPhone',
      CheckboxGroupError: 'W6.Contact.CheckboxGroupError',
      ETicketReceipt: 'W6.Contact.ETicketReceipt',
      ETicketTooltip: 'W6.Contact.ETicketTooltip',
      Email: 'W6.Contact.Email',
      Phone: 'W6.Contact.Phone',
      PhoneNumber: 'W6.Contact.PhoneNumber',
      PhoneType: 'W6.Contact.PhoneType',
      Popup: 'W6.Contact.Popup',
      Receipt: 'W6.Contact.Receipt',
      ReceiptTooltip: 'W6.Contact.ReceiptTooltip',
      RegisteredPhoneNumbers: 'W6.Contact.RegisteredPhoneNumbers',
      TravelRoute: 'W6.Contact.TravelRoute',
      TravelRouteTooltip: 'W6.Contact.TravelRouteTooltip',
      UsernameAndPassword: 'W6.Contact.UsernameAndPassword',
      UsernameAndPasswordTooltip: 'W6.Contact.UsernameAndPasswordTooltip',
    },

    EmailConsent: {
      UsernameAndPassword: 'W6.EmailConsent.UsernameAndPassword',
      ETicket: 'W6.EmailConsent.ETicket',
      Itinerary: 'W6.EmailConsent.Itinerary',
      Receipt: 'W6.EmailConsent.Receipt',
      UsernameAndPasswordInfo: 'W6.EmailConsent.UsernameAndPasswordInfo',
      ETicketInfo: 'W6.EmailConsent.ETicketInfo',
      ItineraryInfo: 'W6.EmailConsent.ItineraryInfo',
      ReceiptInfo: 'W6.EmailConsent.ReceiptInfo',
    },

    Address: {
      Domicile: 'W6.Address.Domicile',
      Street: 'W6.Address.Street',
      City: 'W6.Address.City',
      ZipCode: 'W6.Address.ZipCode',
      Country: 'W6.Address.Country',
    },

    BenefitCardsAndPreferences: {
      PageTitle: 'W6.BenefitCardsAndPreferences.PageTitle',
      NoPreference: 'W6.BenefitCardsAndPreferences.NoPreference',
    },

    SasTravel: {
      CorporateHelperText: 'W6.SasTravel.CorporateHelperText',
      CorporateLabel: 'W6.SasTravel.CorporateLabel',
      CorporateNumber: 'W6.SasTravel.CorporateNumber',
      InfoButtonAriaLabel: 'W6.SasTravel.InfoButtonAriaLabel',
      PhoneNo: 'W6.SasTravel.PhoneNo',
      Popup: 'W6.SasTravel.Popup',
      RegularContactPart1: 'W6.SasTravel.RegularContactPart1',
      RegularContactPart2: 'W6.SasTravel.RegularContactPart2',
      RegularLabel: 'W6.SasTravel.RegularLabel',
      RegularMissingContactUs: 'W6.SasTravel.RegularMissingContactUs',
      RegularNumber: 'W6.SasTravel.RegularNumber',
      Title: 'W6.SasTravel.Title',
    },

    Preferences: {
      Currency: 'W6.Preferences.Currency',
      DepartureAirport: 'W6.Preferences.DepartureAirport',
      DestinationAirport: 'W6.Preferences.DestinationlAirport',
      FlightAndHotel: 'W6.Preferences.FlightAndHotel',
      InfoButtonAriaLabel: 'W6.Preferences.InfoButtonAriaLabel',
      Language: 'W6.Preferences.Language',
      MealType: 'W6.Preferences.MealType',
      NoOptions: 'W6.Preferences.NoOptions',
      Ordering: 'W6.Preferences.Payment',
      Popup: 'W6.Preferences.Popup',
      RoomType: 'W6.Preferences.RoomType',
      SeatNumber: 'W6.Preferences.SeatNumber',
      SeatType: 'W6.Preferences.SeatType',
      Title: 'W6.Preferences.Title',
    },

    BonusCards: {
      AddAirPlural: 'W6.BonusCards.AddAirPlural',
      AddAirSingular: 'W6.BonusCards.AddAirSingular',
      AddCarPlural: 'W6.BonusCards.AddCarPlural',
      AddCarSingular: 'W6.BonusCards.AddCarSingular',
      AddHotelPlural: 'W6.BonusCards.AddHotelPlural',
      AddHotelSingular: 'W6.BonusCards.AddHotelSingular',
      AirLabel: 'W6.BonusCards.AirLabel',
      AirTitle: 'W6.BonusCards.AirTitle',
      CarLabel: 'W6.BonusCards.CarLabel',
      CarTitle: 'W6.BonusCards.CarTitle',
      CardNumber: 'W6.BonusCards.CardNumber',
      Empty: 'W6.BonusCards.Empty',
      HotelLabel: 'W6.BonusCards.HotelLabel',
      HotelTitle: 'W6.BonusCards.HotelTitle',
      Popup: 'W6.BonusCards.Popup',
      Title: 'W6.BonusCards.Title',
      CannotAdd: 'W6.BonusCards.CannotAdd',
    },

    FamilyAndFriends: {
      Title: 'W6.FamilyAndFriends.Title',
    },

    EmployerAndReportingReferences: {
      Title: 'W6.EmployerAndReportingReferences.Title',
      Popup: 'W6.EmployerAndReportingReferences.Popup',
      Employer: 'W6.EmployerAndReportingReferences.Employer',
      NoInputFieldsAvailable:
        'W6.EmployerAndReportingReferences.NoInputFieldsAvailable',
    },

    TravelArrangers: {
      ArrangersFoundLabel: 'W6.TravelArrangers.ArrangersFoundLabel',
      ArrangersHitsLabel: 'W6.TravelArrangers.ArrangersHitsLabel',
      InfoButtonAriaLabel: 'W6.TravelArrangers.InfoButtonAriaLabel',
      NoArrangersAssigned: 'W6.TravelArrangers.NoArrangersAssigned',
      NoSearchResults: 'W6.TravelArrangers.NoSearchResults',
      Popup: 'W6.TravelArrangers.Popup',
      Select: 'W6.TravelArrangers.Select',
      Title: 'W6.TravelArrangers.Title',
    },

    // Password screen
    Password: {
      Title: 'W6.Password.Title',
      Description: 'W6.Password.Description',
      Popup: 'W6.Password.Popup',
      PasswordField: 'W6.Password.PasswordField',
      NewPasswordField: 'W6.Password.NewPasswordField',
      ConfirmPasswordField: 'W6.Password.ConfirmPasswordField',
      OldPasswordField: 'W6.Password.OldPasswordField',
      NotStrongEnough: 'W6.Password.NotStrongEnough',
      DoesNotMatch: 'W6.Password.DoesNotMatch',
    },

    // Documents
    Documents: {
      Expired: 'W6.Documents.Expired',
      ExpiredPassport: 'W6.Documents.ExpiredPassport',
      ExpiredVisa: 'W6.Documents.ExpiredVisa',
      EmptyList: 'W6.Documents.EmptyList',
      ExpirationDate: 'W6.Documents.ExpirationDate',
      IssueDate: 'W6.Documents.IssueDate',
      IssueCountry: 'W6.Documents.IssueCountry',
      NationalIdCardNotSupported: 'W6.Documents.NationalIdCardNotSupported',
    },

    Passport: {
      Title: 'W6.Passport.Title',
      Popup: 'W6.Passport.Passport',
      AddMore: 'W6.Passport.AddMore',
      PassportNumber: 'W6.Passport.PassportNumber',
      IssuedDate: 'W6.Passport.IssuedDate',
      ExpiryDate: 'W6.Passport.ExpiryDate',
      Country: 'W6.Passport.Country',
    },

    Visa: {
      Title: 'W6.Visa.Title',
      Popup: 'W6.Visa.Popup',
      VisaNumber: 'W6.Visa.VisaNumber',
      Add: 'W6.Visa.Add',
      IssuedDate: 'W6.Visa.IssuedDate',
      ExpiryDate: 'W6.Visa.ExpiryDate',
      Country: 'W6.Visa.Country',
    },

    NationalId: {
      Title: 'W6.NationalId.Title',
      Popup: 'W6.NationalId.Popup',
      Number: 'W6.NationalId.Number',
      ExpiryDate: 'W6.NationalId.ExpiryDate',
      Country: 'W6.NationalId.Country',
      TPCNumberInfoPartOne: 'W6.NationalId.TPCNumberInfoPartOne',
      TPCNumberInfoPartTwo: 'W6.NationalId.TPCNumberInfoPartTwo',
    },

    // Navigation, menus, modals
    Navigation: {
      Submit: 'W6.Navigation.Submit',
      Back: 'W6.Navigation.Back',
      Yes: 'W6.Navigation.Yes',
      No: 'W6.Navigation.No',
      Save: 'W6.Navigation.Save',
      Cancel: 'W6.Navigation.Cancel',
      Close: 'W6.Navigation.Close',
      Loading: 'W6.Navigation.Loading',
      EmptySearchResult: 'W6.Navigation.EmptySearchResult',
      BackTitle: 'W6.Navigation.BackTitle',
      BackDescription: 'W6.Navigation.BackDescription',
      Delete: 'W6.Navigation.Delete',
      DeleteConfirmation: 'W6.Navigation.DeleteConfirmation',
      AreYouSure: 'W6.Navigation.AreYouSure',
    },

    // Payments
    Payment: {
      Title: 'W6.Payment.Title',
      Popup: 'W6.Payment.Popup',
      Card: 'W6.Payment.Card',
      AddCard: 'W6.Payment.AddCard',
      CardNumber: 'W6.Payment.CardNumber',
      ExpirationDate: 'W6.Payment.ExpirationDate',
      ExpirationMonth: 'W6.Payment.ExpirationMonth',
      ExpirationYear: 'W6.Payment.ExpirationYear',
      Method: 'W6.Payment.Method',
      PaymentBusiness: 'W6.Payment.PaymentBusiness',
      PaymentPrivate: 'W6.Payment.PaymentPrivate',
      PaymentRent: 'W6.Payment.PaymentRent',
      AddMorePaymentCards: 'W6.Payment.AddMorePaymentCards',
    },

    // Trip Details
    TripDetails: {
      Title: 'W6.TripDetails.Title',
      PagePopupInfo: 'W6.TripDetails.PagePopupInfo',
      Details: 'W6.TripDetails.Details',
      Add: 'W6.TripDetails.Add',
      Ref: 'W6.TripDetails.Ref',
      TotalPrice: 'W6.TripDetails.TotalPrice',
      AirlineRef: 'W6.TripDetails.AirlineRef',
      Travellers: 'W6.TripDetails.Travellers',
      TotalTravelTime: 'W6.TripDetails.TotalTravelTime',
      WaitingTime: 'W6.TripDetails.WaitingTime',
      HotelReadMore: 'W6.TripDetails.HotelReadMore',
      HotelTaxInfo: 'W6.TripDetails.HotelTaxInfo',
      HotelCancelDate: 'W6.TripDetails.HotelCancelDate',
      ReportsSectionTitle: 'W6.TripDetails.ReportsSectionTitle',
      ReportsBusinessTrip: 'W6.TripDetails.ReportsBusinessTrip',
      ReportsDepartment: 'W6.TripDetails.ReportsDepartment',
      ReportsEmployeeNo: 'W6.TripDetails.ReportsEmployeeNo',
    },

    Messages: {
      SaveSuccess: 'W6.Messages.SaveSuccess',
      SaveError: 'W6.Messages.SaveError',
    },
    Menu: {
      Order: 'W6.Menu.Order',
      Orders: 'W6.Menu.Orders',
      InformationFromEmployer: 'W6.Menu.InformationFromEmployer',
      Profile: 'W6.Menu.Profile',
      ERM: 'W6.Menu.ERM',
      Arrangements: 'W6.Menu.Arrangements',
      TravelExpenses: 'W6.Menu.TravelExpenses',
      ContactUs: 'W6.Menu.ContactUs',
      LogOut: 'W6.Menu.LogOut',
    },

    ProfileMenu: {
      ViewAndChangeProfile: 'W6.ProfileMenu.ViewAndChangeProfile',
      ChangeLanguages: 'W6.ProfileMenu.ChangeLanguages',
      ChangeTheme: 'W6.ProfileMenu.ChangeTheme',
    },

    Booking: {
      StartLocation: 'W6.Booking.StartLocation',
      EndLocation: 'W6.Booking.EndLocation',
      SingleTrip: 'W6.Booking.SingleTrip',
      ReturnTrip: 'W6.Booking.ReturnTrip',
      Search: 'W6.Booking.Search',
    },
    Greetings: {
      Morning: 'W6.Greetings.Morning',
      Day: 'W6.Greetings.Day',
      Afternoon: 'W6.Greetings.Afternoon',
      Evening: 'W6.Greetings.Evening',
      Night: 'W6.Greetings.Night',
    },
    Add: {
      Emails: 'W6.Add.Emails',
      PhoneNumbers: 'W6.Add.PhoneNumbers',
    },
    Support: {
      NeedHelp: 'W6.Support.NeedHelp',
      EmailTo: 'W6.Support.EmailTo',
      CallUs: 'W6.Support.CallUs',
    },
    Generic: {
      Copied: 'W6.Generic.Copied',
      Email: 'W6.Generic.Email',
      PhoneNumber: 'W6.Generic.PhoneNumber',
      SeeMore: 'W6.Generic.SeeMore',
      Reference: 'W6.Generic.Reference',
      NoHits: 'W6.Generic.NoHits',
      Traveller: 'W6.Generic.Traveller',
      Show: 'W6.Generic.Show',
      Clear: 'W6.Generic.Clear',
      OrderNumber: 'W6.Generic.OrderNumber',
      AddToCalendar: 'W6.Generic.AddToCalendar',
      CustomerReference: 'W6.Generic.CustomerReference',
      Department: 'W6.Generic.Department',
      EmployeeNumber: 'W6.Generic.EmployeeNumber',
      Total: 'W6.Generic.Total',
      IncludedInEstimatedPrice: 'W6.Generic.IncludedInEstimatedPrice',
      UnknownError: 'W6.Generic.UnknownError',
      Filter: 'W6.Generic.Filter',
      Send: 'W6.Generic.Send',
      Select: 'W6.Generic.Select',
      FeatureNotImplemented: 'W6.Generic.FeatureNotImplemented',
      Edit: 'W6.Generic.Edit',
      MoreOptions: 'W6.Generic.MoreOptions',
      Pickup: 'W6.Generic.Pickup',
      Dropoff: 'W6.Generic.Dropoff',
    },
    // Visually hidden text
    Hidden: {
      DeletePhoneNumber: 'W6.Hidden.DeletePhoneNumber',
      DeleteEmail: 'W6.Hidden.DeleteEmail',
      LinkToGoogleMapsLocation: 'W6.Hidden.LinkToGoogleMapsLocation',
      ExpandPriceDetails: 'W6.Hidden.ExpandPriceDetails',
      OpenCalendar: 'W6.Hidden.OpenCalendar',
    },
  },
};
