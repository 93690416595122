import { Card, Progress } from '..';
import { Backdrop as MdBackdrop } from '@mui/material';

export type BackdropProps = {
  open: boolean;
};

export const Backdrop = ({ open }: BackdropProps) => (
  <MdBackdrop sx={{ color: 'common.white', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
    <Card sx={{ width: 200, height: 200, pt: 4, textAlign: 'center' }}>
      <Progress />
    </Card>
  </MdBackdrop>
);
