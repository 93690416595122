import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

import StandardHeader from 'components/common/headers/StandardHeader';
import UsefulInformation from 'components/features/landingPage/UsefulInformation';
import { Greetings } from 'routes/landingPage/Greetings';
import { MyTrips } from 'components/features';
import { TripSearch } from 'components/features/booking/common/TripSearch';
import { NotImplementedButton } from 'components/common/not-implemented/NotImplementedModal';

const LandingPage = () => {
  return (
    <>
      <StandardHeader showBreadcrumbs={false} />
      <Container>
        <Box component='section' sx={{ mb: 4 }}>
          <Greetings />
          <NotImplementedButton>
            <TripSearch />
          </NotImplementedButton>
        </Box>
        <MyTrips />
      </Container>
      <Divider sx={{ my: 2 }} />
      <Container>
        <UsefulInformation />
      </Container>
    </>
  );
};

export default LandingPage;
