import { Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface HighlightedTextProps {
  children: ReactNode;
  type?: 'warning' | 'selected' | 'default';
}

const HighlightedText = ({
  children,
  type = 'default',
}: HighlightedTextProps) => {
  const { palette } = useTheme();

  let backgroundColor: string | undefined = palette?.action?.active;
  let color: string | undefined = palette?.common?.white;

  switch (type) {
    case 'warning':
      backgroundColor = palette?.warning?.main;
      color = palette?.warning?.contrastText;
      break;

    case 'selected':
      backgroundColor = palette?.backgroundColors?.selectedCard;
      color = palette?.text?.primary;
      break;

    default:
      backgroundColor = palette?.action?.active;
      color = palette?.common?.white;
      break;
  }

  return (
    <Typography
      variant='caption'
      fontWeight='bold'
      sx={{
        backgroundColor,
        color,
        borderRadius: 1,
        p: '2px 5px',
        mr: 1,
      }}
    >
      {children}
    </Typography>
  );
};

export default HighlightedText;
