import TextField from '@mui/material/TextField/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { TextCodes } from 'hooks/texts/text.codes';
import { useEffect } from 'react';
import { useFormContext, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreditCardData } from 'services/api/user';

type CreditCardExpirationInputProps = {
  name: string;
  disabled?: boolean;
  size?: 'small' | 'medium';
};

const CreditCardExpirationInput = ({
  name,
  disabled = false,
  size = 'small',
}: CreditCardExpirationInputProps) => {
  // Form
  const {
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const fieldName = `${name}.expiration`;
  const expirationError = get(errors, fieldName);
  const expirationDateError = get(errors, `${name}.expirationDateError`);
  const invalidDateError = get(errors, `${name}.invalidDate`);
  const value = watch(fieldName);

  // Hooks
  const { t } = useTranslation();

  const priorityError =
    expirationError || expirationDateError || invalidDateError;

  const handleChange = (newValue: Date | null) => {
    setValue(fieldName, newValue, {
      shouldDirty: true,
      shouldValidate: priorityError ? true : false,
    });
  };

  // Set default value
  useEffect(() => {
    const card: CreditCardData = get(getValues(), name);
    if (card && card.expirationMonth && card.expirationYear) {
      setValue(
        fieldName,
        dayjs(`${card.expirationYear}/${card.expirationMonth}`).toDate()
      );
    } else {
      setValue(fieldName, null);
    }
  }, []);

  return (
    <DesktopDatePicker
      label={t(TextCodes.WG6.Documents.ExpirationDate)}
      inputFormat='MM/YY'
      value={value ?? null}
      disableOpenPicker
      onChange={handleChange}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={t(priorityError?.message)}
          error={priorityError ? true : false}
          size={size}
        />
      )}
    />
  );
};

export default CreditCardExpirationInput;
