import { DeleteOutline } from '@mui/icons-material';
import { DictionaryInput } from 'components/features/dropdowns/DictionaryInput';
import { TextCodes } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext, get, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInputComponent from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { getCurrentThemeId } from 'store/app/app.slice';
import { useAppSelector } from 'store/store';
import { LanguageCodes } from 'store/texts/language.config';
import { ThemeIds } from 'theme/theme.types';
import './material.css';
import no from './no.json';
import visuallyHidden from '@mui/utils/visuallyHidden';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { TextFieldProps } from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

type PhoneInputProps = Pick<
  TextFieldProps,
  'onChange' | 'type' | 'value' | 'defaultValue' | 'fullWidth'
> & {
  name: string;
  showTitle?: boolean;
  onDelete?: () => void;
};

const PhoneInput = ({ name, onDelete, showTitle = true }: PhoneInputProps) => {
  // Hooks 🎣
  const { i18n, t } = useTranslation();
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);

  // Form 📋
  const {
    register,
    control,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useFormContext();
  const fieldName = `${name}.number`;
  const readOnly = getValues(`${name}.readonly`);
  const value = useWatch({
    name: fieldName,
    control,
  });

  const error = useMemo(() => {
    return get(errors, fieldName);
  }, [errors]);

  // Language - load correct translations
  const localizationDictionary = i18n.language === LanguageCodes.NO ? no : {};

  // Theme - use correct theme --- Is this still necessary?
  const currentThemeId = useAppSelector(getCurrentThemeId);
  const isDarkTheme = currentThemeId === ThemeIds.Dark;
  let classNames = '';
  classNames += error ? 'error' : '';
  classNames += isDarkTheme ? ' dark' : '';

  const changeHandler = useCallback(
    (phone: string) => {
      setValue(fieldName, phone);
      trigger(fieldName);
    },
    [fieldName, setValue]
  );

  const handleDelete = () => {
    if (onDelete && !disableDeleteBtn) {
      onDelete && onDelete();
      setDisableDeleteBtn(true);
    }
  };

  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ width: '100%' }}
      >
        <Typography
          variant='body2'
          visibility={showTitle ? 'visible' : 'hidden'}
          component='h2'
          fontWeight='bold'
        >
          {t(TextCodes.WG6.Contact.PhoneNumber)}
        </Typography>
        {!readOnly && (
          <IconButton color='primary' size='small' onClick={handleDelete}>
            <DeleteOutline />
            <Typography sx={visuallyHidden}>
              {t(TextCodes.WG6.Hidden.DeletePhoneNumber)}
            </Typography>
          </IconButton>
        )}
      </Box>
      <Grid container gap={2}>
        <Grid item xs={12}>
          <DictionaryInput
            name={`${name}.type`}
            dictionary='phones'
            label={t(TextCodes.WG6.Contact.PhoneType)}
            fullWidth
            sx={{ mb: 0 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box className={classNames}>
            <PhoneInputComponent
              disabled={readOnly}
              localization={localizationDictionary}
              inputProps={{
                label: t(TextCodes.WG6.Contact.PhoneNumber),
                ...register(fieldName),
              }}
              country='no'
              value={value}
              priority={{ no: 0, se: 1, dk: 2 }}
              preferredCountries={['no', 'se', 'dk']}
              onChange={changeHandler}
              placeholder='47 000 00 000'
            />
            {error?.message && (
              <>
                <FormHelperText color='error' sx={{ ml: 2 }} error>
                  {t(error.message)}
                </FormHelperText>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PhoneInput;
