import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import FormCancelButton from 'components/common/buttons/FormCancelButton';
import { ProgressButton } from 'components/common/buttons/ProgressButton';
import useSiteNavigation from 'hooks/navigation/useSiteNavigation';
import { TextCodes } from 'hooks/texts/text.codes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormPanelModal from './FormPanelModal';

type FormPanelProps = {
  errorMessage: string;
  shouldShowCancelWarning?: boolean;
  shouldShowSaveWarning?: boolean;
  isLoading: boolean;
  saveMessage?: string;
  onSubmit: () => void;
  hideDivider?: boolean;
};

const FormPanel = ({
  errorMessage,
  shouldShowCancelWarning = false,
  shouldShowSaveWarning = false,
  isLoading,
  onSubmit,
  saveMessage,
  hideDivider = false,
}: FormPanelProps) => {
  // Hooks 🎣
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<'submit' | 'cancel' | ''>('');
  const navigate = useSiteNavigation();

  const handleSubmit = () => {
    if (shouldShowSaveWarning) setShowModal('submit');
    else onSubmit();
  };

  const handleCancel = () => {
    if (shouldShowCancelWarning) setShowModal('cancel');
    else navigate(-1);
  };

  const handleConfirmSubmit = () => {
    setShowModal('');
    onSubmit();
  };

  const handleConfirmCancel = () => {
    setShowModal('');
    navigate(-1);
  };

  const prioritySaveMessage = saveMessage
    ? saveMessage
    : t(TextCodes.WG6.Status.ConfirmChanges);

  return (
    <>
      <Stack spacing={errorMessage ? 2 : 4} sx={{ width: '100%' }}>
        <Stack spacing={2}>
          {!hideDivider && <Divider sx={{ width: '100%' }} />}
          {errorMessage && (
            <Alert sx={{ mb: 2 }} severity='error'>
              {errorMessage}
            </Alert>
          )}
        </Stack>
        <Grid container justifyContent='flex-end' gap={4}>
          <FormCancelButton size='large' onClick={handleCancel} />
          <ProgressButton
            size='large'
            variant='contained'
            disabled={isLoading}
            busy={isLoading}
            onClick={handleSubmit}
          >
            {t(TextCodes.WG6.Navigation.Save)}
          </ProgressButton>
        </Grid>
      </Stack>
      <FormPanelModal
        isVisible={showModal !== ''}
        text={
          showModal === 'cancel'
            ? t(TextCodes.WG6.Status.UnsavedChanges)
            : prioritySaveMessage
        }
        onConfirm={() =>
          showModal === 'cancel' ? handleConfirmCancel() : handleConfirmSubmit()
        }
        onCancel={() => setShowModal('')}
      />
    </>
  );
};

export default FormPanel;
