import { Box } from '@mui/material';

interface ImageProps {
  altText: string;
  source: string;
  height?: string;
  loading?: 'lazy' | 'eager';
  role?: string;
  width?: string;
}

const Image = ({
  altText,
  source,
  height,
  loading = 'lazy',
  role,
  width = '100%',
}: ImageProps) => {
  return (
    <Box>
      <img
        alt={altText}
        src={source}
        loading={loading}
        role={role}
        style={{
          height,
          width,
        }}
      />
    </Box>
  );
};

export default Image;
