import { Divider, Grid, IconButton, Typography } from '@mui/material';
import { DictionaryInput } from 'components/features/dropdowns/DictionaryInput';
import { ProfileDictionaries } from 'services/api/user';
import { DeleteOutline } from '@mui/icons-material';
import TextInput from 'components/common/inputs/textInput/TextInput';
import useAnimatedFieldArray from 'hooks/animation/useAnimatedFieldArray';
import { motion } from 'framer-motion';
import AddMoreInputsButton from 'components/common/forms/AddMoreInputsButton';
import { visuallyHidden } from '@mui/utils';
import { TextCodes } from 'hooks/texts/text.codes';
import { useTranslation } from 'react-i18next';

interface Props {
  addPluralButtonText: string;
  addSingularButtonText: string;
  dictionaryLabel: string;
  dictionaryType: keyof ProfileDictionaries;
  groupName: string;
  textFieldLabel: string;
  title: string;
}

const BonusCardsList = ({
  addPluralButtonText,
  addSingularButtonText,
  dictionaryLabel,
  dictionaryType,
  groupName,
  textFieldLabel,
  title,
}: Props) => {
  // Hooks
  const { t } = useTranslation();
  const {
    fields,
    append,
    remove,
    variants,
    itemControls,
    setValue,
    formState,
  } = useAnimatedFieldArray(groupName);

  const handleRemove = (i: number) => {
    setValue('didDelete', true);
    remove(i);
  };

  return (
    <Grid container component='ul' gap={2}>
      {/* Show title with disabled trashcan if there are no items. */}
      {fields.length === 0 && (
        <Grid container alignItems='center'>
          <Typography variant='body2' component='h2' fontWeight='bold'>
            {title}
          </Typography>
          <IconButton
            size='small'
            color='primary'
            sx={{ marginLeft: 'auto' }}
            disabled={formState.isDirty}
          >
            <Typography sx={visuallyHidden}>
              {t(TextCodes.WG6.Navigation.Delete)}
            </Typography>
            <DeleteOutline />
          </IconButton>
        </Grid>
      )}
      {fields.map((f, i) => (
        <Grid
          container
          component={motion.li}
          custom={i}
          variants={variants}
          animate={itemControls}
          key={f.id}
          gap={2}
        >
          <Grid container alignItems='center'>
            {i === 0 && (
              <Typography variant='body2' component='h2' fontWeight='bold'>
                {title}
              </Typography>
            )}
            <IconButton
              size='small'
              color='primary'
              sx={{ marginLeft: 'auto' }}
              onClick={() => handleRemove(i)}
            >
              <Typography sx={visuallyHidden}>
                {t(TextCodes.WG6.Navigation.Delete)}
              </Typography>
              <DeleteOutline />
            </IconButton>
          </Grid>
          <Grid container gap={2}>
            <DictionaryInput
              dictionary={dictionaryType}
              name={`${groupName}.${[i]}.companyCode`}
              label={dictionaryLabel}
              fullWidth
              sx={{ mb: 0 }}
            />
            <TextInput
              name={`${groupName}.${[i]}.cardNumber`}
              label={textFieldLabel}
              fullWidth
              sx={{ mb: 0 }}
            />
          </Grid>
        </Grid>
      ))}
      {fields.length > 0 && (
        <Divider component={Grid} item xs={12} sx={{ borderStyle: 'dashed' }} />
      )}
      <AddMoreInputsButton
        sx={{ mb: 0 }}
        onClick={() =>
          append({
            cardNumber: '',
            companyCode: '',
          })
        }
        label={
          fields.length === 0 ? addSingularButtonText : addPluralButtonText
        }
      />
      <Divider component={Grid} item xs={12} />
    </Grid>
  );
};

export default BonusCardsList;
