import { v4 as uuid } from 'uuid';
import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { ToastProps } from '../../components/features/toast/Toast';
import { RootState } from '../store';

// INITIAL STATE

export type ToastItem = ToastProps & {
  isVisible?: boolean;
  id: string;
};

export type Toast = ToastProps;

export interface ToastState {
  list: ToastItem[];
}

const initialState: ToastState = {
  list: [],
};

// REDUCERS
export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<Toast>) => {
      const toast = { id: uuid(), isVisible: true, ...action.payload };
      state.list.push(toast);
    },
    removeToast: (state, action: PayloadAction<string>) => {
      const item = state.list.find((x) => x.id === action.payload);
      if (item) {
        item.isVisible = false;
      }
    },
    resetToast: () => initialState,
  },
});

// ACTIONS
export const { addToast, removeToast, resetToast } = toastSlice.actions;

// SELECTORS
export const getToastList = createSelector(
  (state: RootState) => state.toast.list,
  (items) => items
);

export const getCurrentToast = createSelector([getToastList], (items) =>
  items.find((x) => x.isVisible)
);

export default toastSlice.reducer;
