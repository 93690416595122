import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FlightIcon from '@mui/icons-material/Flight';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import Ancillary from 'components/common/flight/Ancillary';
import FlightDetailsHeader from 'components/common/flight/FlightDetailsHeader';
import FlightSegmentDetails from './FlightSegmentDetails';
import HeaderCard, {
  HeaderCardReference,
} from 'components/common/containers/HeaderCard';
import OrderDetailsCardHeader from 'components/features/orderDetails/OrderDetailsCardHeader';
import { DropdownMenuOptions } from 'components/common/dropdownMenu/DropdownMenu';
import { ItineraryInfo } from 'services/api/itinerary';
import { TextCodes } from 'hooks';
import { durationToString } from 'services/text/datetime-format';
import { setShowNotImplementedModal } from 'store/app/app.slice';
import { useAppDispatch } from 'store/store';

export interface AncillaryProps {
  departure?: String;
  destination?: String;
  list?: String[];
}

const FlightDetails = ({
  arrivalCityCode,
  arrivalDateTime,
  arrivalDateTimeUpdated,
  delete: cancel,
  departureCityCode,
  departureDateTime,
  departureDateTimeUpdated,
  edit,
  itineraryType,
  reference,
  segments,
  totalTime,
  search,
}: ItineraryInfo) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const openNotImplementedModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(setShowNotImplementedModal(true));
  };

  // Dropdown options of edit and cancel booking
  const options: DropdownMenuOptions[] = [
    {
      label: !!edit
        ? TextCodes.WG6.Flight.EditFlight
        : TextCodes.WG6.Base.EditNotPossible,
      disabled: !edit,
      action: () => {
        dispatch(setShowNotImplementedModal(true));
      },
    },
    {
      label: !!cancel
        ? TextCodes.WG6.Flight.CancelFlight
        : TextCodes.WG6.Base.CancelNotPossible,
      disabled: !cancel,
      action: () => dispatch(setShowNotImplementedModal(true)),
    },
  ];

  const ancillaries: AncillaryProps[] =
    segments!
      ?.filter((seg) => !isEmpty(seg?.flightExtras))
      .map((segment) => ({
        departure: segment?.departureCityName,
        destination: segment?.arrivalCityName,
        list: segment?.flightExtras,
      })) || [];

  const travelTime = durationToString(totalTime!!);

  return (
    <HeaderCard
      sx={{ width: '100%', boxShadow: 2 }}
      headerContent={<HeaderCardReference reference={reference} />}
    >
      <Stack spacing={2} sx={{ p: 2 }}>
        <Box>
          <OrderDetailsCardHeader
            options={options}
            id={departureDateTime!!}
            icon={<FlightIcon />}
            content={
              <FlightDetailsHeader
                arrivalCityCode={arrivalCityCode}
                arrivalDateTime={arrivalDateTime}
                departureCityCode={departureCityCode}
                departureDateTime={departureDateTime}
                arrivalDateTimeUpdated={arrivalDateTimeUpdated}
                departureDateTimeUpdated={departureDateTimeUpdated}
              />
            }
          />
        </Box>
        <Typography variant='body2' paragraph sx={{ textAlign: 'right' }}>
          {`${t(TextCodes.WG6.OrderDetails.TotalTravelTime)} ${travelTime}`}
        </Typography>

        {/* TODO: Add "Check in" button once that is implemented with the api. This wont be implemented in M1 */}

        <FlightSegmentDetails segments={segments} searchString={search} />

        {ancillaries?.map((ancillary, index) => (
          <Ancillary
            key={`${ancillary?.departure}-${index}`}
            ancillary={ancillary}
          />
        ))}

        {/* TODO: Implement "Add Ancillaries" once the data has been added to the "itinerary/details" endpoint */}
        <Button
          onClick={openNotImplementedModal}
          size='small'
          fullWidth
          sx={{ gap: 1, width: '100%' }}
        >
          <Add />
          {t(TextCodes.WG6.Base.AddAncillaries)}
        </Button>
      </Stack>
    </HeaderCard>
  );
};

export default FlightDetails;
