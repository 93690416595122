import { Divider, Grid } from '@mui/material';
import PhoneInput from 'components/common/inputs/phone-input/PhoneInput';
import { TextCodes } from 'hooks/texts/text.codes';
import { useTranslation } from 'react-i18next';
import useAnimatedFieldArray from 'hooks/animation/useAnimatedFieldArray';
import { motion } from 'framer-motion';
import AddMoreInputsButton from 'components/common/forms/AddMoreInputsButton';

interface PhoneNumbersProps {
  arrayName?: string;
}
const PhoneNumbers = ({ arrayName = 'phoneNumbers' }: PhoneNumbersProps) => {
  const { t } = useTranslation();
  const { fields, append, remove, variants, itemControls, setValue } =
    useAnimatedFieldArray(arrayName);

  const handleDelete = (index: number) => {
    remove(index);
    setValue('didDelete', true);
  };

  return (
    <Grid container gap={2}>
      {fields.map((f, i) => (
        <Grid
          aria-label={t(TextCodes.WG6.Contact.RegisteredPhoneNumbers)}
          container
          component={motion.div}
          variants={variants}
          animate={itemControls}
          custom={i}
          key={f.id}
          gap={2}
        >
          <PhoneInput
            onDelete={() => handleDelete(i)}
            showTitle={i === 0}
            name={`${arrayName}[${i}]`}
          />
          {i !== fields.length - 1 && (
            <Divider
              component={Grid}
              item
              xs={12}
              sx={{ borderStyle: 'dashed' }}
            />
          )}
        </Grid>
      ))}
      <Grid container gap={2.5}>
        <Divider component={Grid} item xs={12} sx={{ borderStyle: 'dashed' }} />
        <AddMoreInputsButton
          sx={{ mb: 0 }}
          onClick={() => append({ type: '', number: '' })}
          label={t(TextCodes.WG6.Add.PhoneNumbers)}
        />
        <Divider component={Grid} item xs={12} />
      </Grid>
    </Grid>
  );
};

export default PhoneNumbers;
