import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import FormPanel from 'components/common/forms/FormPanel';
import TextInput from 'components/common/inputs/textInput/TextInput';
import { CountryInput } from 'components/features/dropdowns/CountryInput';
import { TextCodes, useToast } from 'hooks';
import { routeConfig } from 'routes/config/route.config';
import DateField from 'components/common/inputs/date-field/DateField';
import dayjs from 'dayjs';

interface FormFields {
  number: string;
  expiryDate: string;
  countryCode: string;
}

const EditNationalIdForm = () => {
  // Global state
  // TODO: Replace dummy data with something like: const nationalId = useAppSelector(getNationalId);
  /* const nationalId = {
    number: '123456789',
    expiryDate: '2025-12-24T22:00:00.000Z',
    countryCode: 'NO',
  }; */

  // Hooks/Variables
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toast } = useToast();
  const defaultNationalId = {
    number: '',
    expiryDate: '',
    countryCode: '',
  };

  // API
  // TODO: Add something likes this and replace hardcoded data below:
  /* const [upDateNationalId, { isLoading, data, isUninitialized }] =
    userApi.useUpdateNationalIdMutation(); */
  const isLoading = false;
  const data = {
    isSuccess: false,
    message: '',
  };

  // Form methods
  const formMethods = useForm<FormFields>({
    // TODO: Replace below values with this one: defaultValues: nationalId ? nationalId : defaultNationalId,
    defaultValues: defaultNationalId,
    resolver: yupResolver(
      Yup.object().shape({
        number: Yup.string().required(TextCodes.WG6.Validation.Required),
        expiryDate: Yup.string()
          .disallowPastDate()
          .required(TextCodes.WG6.Validation.Required),
        countryCode: Yup.string().required(TextCodes.WG6.Validation.Required),
      })
    ),
  });

  // Success handler
  useEffect(() => {
    if (data?.isSuccess) {
      navigate(routeConfig.Documents.path);
      toast({
        severity: 'success',
        autoHideDuration: 3000,
        message: t(TextCodes.WG6.Status.ChangesSavedSuccessfully),
      });
    }
  }, [data]);

  const onSubmit = (formData: FormFields) => {
    console.log(formData);
    // TODO: Replace console log with: updateNationalId({ body: formData });
  };

  return (
    <FormProvider {...formMethods}>
      <form>
        <Stack spacing={2} sx={{ mb: 2 }}>
          <TextInput
            name='number'
            label={t(TextCodes.WG6.NationalId.Number)}
            fullWidth
            sx={{ mb: 0, mt: 1 }}
          />
          <DateField
            name='expiryDate'
            label={t(TextCodes.WG6.NationalId.ExpiryDate)}
            minDate={dayjs()}
          />
          <CountryInput
            name='countryCode'
            label={t(TextCodes.WG6.NationalId.Country)}
            fullWidth
          />
        </Stack>
        <FormPanel
          errorMessage={data?.message || ''}
          isLoading={isLoading}
          onSubmit={formMethods.handleSubmit(onSubmit)}
        />
      </form>
    </FormProvider>
  );
};

export default EditNationalIdForm;
