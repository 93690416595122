import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

import { CardProps } from 'components/common/containers/Card';
import { Edit } from '@mui/icons-material';
import { RouteId } from 'routes/config/route.config';
import { useInternalRedirect } from 'hooks';

export type EditableCardProps = Pick<CardProps, 'title' | 'children'> & {
  title?: ReactNode;
  editAction?: Function;
  routeId?: RouteId;
  underline?: boolean;
  isEditable?: boolean;
};

/**
 * TODO:
 * This card could do with a rename... its name is misleading because some of the places its used are not editable.
 * Maybe something like "SectionCard" would be more fitting?
 */
export const EditableCard = ({
  title = '',
  children,
  editAction,
  routeId,
  underline = true,
  isEditable = true,
}: EditableCardProps) => {
  const redirect = useInternalRedirect();

  const editButtonClickHandler = () => {
    if (typeof editAction === 'function') {
      editAction();
    }
    if (routeId) {
      redirect(routeId);
    }
  };

  return (
    <Card sx={{ boxShadow: 2 }}>
      <CardContent
        sx={{
          width: '100%',
          '&:last-child': { paddingBottom: 2 },
        }}
      >
        <Typography
          variant='body1'
          fontWeight={700}
          component='h2'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 0,
            wordBreak: 'break-all',
          }}
        >
          {title}
          {isEditable && (
            <IconButton sx={{ p: 0.5 }} onClick={editButtonClickHandler}>
              <Edit
                sx={{ height: '20px', width: '20px' }}
                color='primary'
                fontSize='small'
              />
            </IconButton>
          )}
        </Typography>
        {underline && <Divider />}
        <Box sx={{ pt: 2 }}>
          <Typography variant='body2'>{children}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
