import { Alert, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LinkPhone } from 'components/common';
import { TextCodes } from 'hooks';
import { TravelPass } from 'services/api/user';

export type TravelPassProductsProps = {
  travelPass: TravelPass;
};

const TravelPassProducts = ({ travelPass }: TravelPassProductsProps) => {
  // Hooks/Variables
  const { t } = useTranslation();

  return (
    <>
      <Stack spacing={2}>
        <Divider />
        <Typography variant='body2' component='h2' fontWeight='bold'>
          {t(TextCodes.WG6.SasTravel.RegularLabel)}
        </Typography>
        {travelPass?.routings?.length ? (
          <Grid>
            <Typography variant='body2' paragraph sx={{ mt: 0, mb: 0.5 }}>
              {t(TextCodes.WG6.SasTravel.RegularNumber)} {travelPass?.number}
            </Typography>
            <Typography variant='body2' color='text.secondary' component='p'>
              {t(TextCodes.WG6.SasTravel.RegularContactPart1)}
              &nbsp;
              <LinkPhone phone={t(TextCodes.WG6.SasTravel.PhoneNo)} />
              &nbsp;
              {t(TextCodes.WG6.SasTravel.RegularContactPart2)}
            </Typography>
            <Stack
              divider={<Divider sx={{ borderStyle: 'dashed' }} />}
              component='ul'
              spacing={2}
              sx={{ pt: 2 }}
            >
              {travelPass?.routings?.map((TP, index) => (
                <Grid
                  container
                  key={`${TP.carrierCode}-${index}`}
                  component='li'
                  alignItems='center'
                >
                  <Stack component={Grid} item xs={6}>
                    <Typography variant='body2'>
                      <Typography fontWeight='bold'>
                        {TP.originCode} - {TP.destinationCode}
                      </Typography>
                      &nbsp;
                      <Typography>{TP.class}</Typography>
                    </Typography>
                    <Typography variant='caption' color='text.secondary'>
                      {TP.carrierCode}
                    </Typography>
                  </Stack>
                  <Stack component={Grid} item xs={6} alignItems='flex-end'>
                    <Typography variant='caption'>
                      {TP.dateFrom?.replaceAll('.', ' / ')}
                    </Typography>
                    <Typography variant='caption'>
                      {TP.dateTo?.replaceAll('.', ' / ')}
                    </Typography>
                  </Stack>
                </Grid>
              ))}
            </Stack>
          </Grid>
        ) : (
          <Alert severity='info'>
            {t(TextCodes.WG6.SasTravel.RegularMissingContactUs)}&nbsp;
            <LinkPhone phone={t(TextCodes.WG6.SasTravel.PhoneNo)} />
          </Alert>
        )}
      </Stack>
    </>
  );
};

export default TravelPassProducts;
