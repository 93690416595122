import { number } from 'card-validator';
import { useToast } from 'hooks/toast/toast.hooks';
import { useNavigate } from 'react-router-dom';

interface NavigateOptions {
  scrollToTop?: boolean;
  /**
   * @param {boolean} replace - if true, the current entry will be replaced in the history stack
   */
  replace?: boolean;
  /**
   *
   * @param {string} message - The message to show in the toast
   * @param {ToastSeverity} severity - The severity of the toast
   * @param {number} autoHideDuration - The duration to show the toast
   */
  toast?: {
    message: string;
    severity: 'success' | 'error' | 'warning' | 'info';
    autoHideDuration?: number;
  };
}

const useSiteNavigation = () => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const onNavigate = (
    route: string | number,
    options: NavigateOptions = {
      scrollToTop: true,
      replace: false,
      toast: undefined,
    }
  ) => {
    if (number(route)) navigate(route as number);
    else navigate(route as string, { replace: options?.replace });

    if (options && options.toast) {
      toast({
        message: options.toast.message,
        severity: options.toast.severity,
        autoHideDuration: options.toast.autoHideDuration ?? 3000,
      });
    }
    if (options?.scrollToTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return onNavigate;
};

export default useSiteNavigation;
