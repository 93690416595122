import { Box, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { Dropdown } from 'components/common/dropdown/Dropdown';
import { TextCodes } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getOrderDetails, setTraveller } from 'store/orders/orderDetails.slice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { capitalizeName } from 'utils/text.utils';

const OrderDetailsTraveller = () => {
  // Hooks
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Selectors
  const { travellers, traveller, orderNo } = useAppSelector(getOrderDetails);

  // Local state
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const copyorderNo = () => {
    if (!orderNo) return null;
    setOpenTooltip(true);
    navigator.clipboard.writeText(orderNo);
    setTimeout(() => setOpenTooltip(false), 3000);
  };

  if (!travellers) return null;

  return (
    <Grid container gap={1.5}>
      <Grid container alignItems='center' gap={1}>
        <Typography
          variant='caption'
          sx={{ fontSize: 12, color: theme.palette.text.secondary }}
        >
          {t(TextCodes.WG6.Generic.Traveller)}:
        </Typography>
        {travellers?.length === 1 ? (
          <Typography variant='body2' sx={{ fontSize: 14 }}>
            {capitalizeName([traveller.firstName!, traveller.lastName!])}
          </Typography>
        ) : (
          <Dropdown
            sx={{ px: 0 }}
            textSx={{ fontSize: 13, pt: 0, verticalAlign: 'middle' }}
            textVariant='button'
            label={capitalizeName([traveller.firstName!, traveller.lastName!])}
            buttonId='travellerDropdown'
            menuId='travellerMenu'
            itemSx={{ minWidth: '254px' }}
            options={travellers.map((trvlr) => ({
              name: capitalizeName([trvlr.firstName!, trvlr.lastName!]),
              value: trvlr,
              action: () => {
                dispatch(setTraveller(trvlr.id!!));
              },
              isSelected: trvlr.id === traveller.id,
            }))}
          />
        )}
      </Grid>
      <Tooltip
        open={openTooltip}
        title={t(TextCodes.WG6.Generic.Copied)}
        arrow
        placement='bottom'
        onClick={copyorderNo}
      >
        <Grid container alignItems='center' gap={1} sx={{ lineHeight: 0 }}>
          <Typography
            variant='caption'
            sx={{ fontSize: 12, color: theme.palette.text.secondary }}
          >
            Berg-Hansen {t(TextCodes.WG6.Generic.OrderNumber)}:
          </Typography>
          <Box display='inline-block'>
            <Typography
              variant='caption'
              fontWeight='bold'
              sx={{ fontSize: 12 }}
            >
              {orderNo}
            </Typography>
          </Box>
        </Grid>
      </Tooltip>
    </Grid>
  );
};

export default OrderDetailsTraveller;
