import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import NotRegistered from 'components/common/text/NotRegistered';
import TextBlock from 'components/common/text/TextBlock';
import { EmailData } from 'services/api/user';
import { TextCodes } from 'hooks';

const EmailBlock = ({ list }: { list: EmailData[] | null | undefined }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const dot = <Typography color='text.secondary'> • </Typography>;

  return (
    <>
      {!isEmpty(list) ? (
        list?.map(
          (
            {
              address,
              consentUsernameAndPassword,
              consentItinerary,
              consentReceipt,
              consentETicket,
            },
            key
          ) => (
            <TextBlock
              removeDivider
              title={t(TextCodes.WG6.Profile.Email)}
              key={key}
            >
              <Typography sx={{ mb: 1 }}>{address}</Typography>

              <Box
                sx={{
                  backgroundColor: palette?.backgroundColors?.selectedCard,
                  borderRadius: 1,
                  p: 1,
                  mt: 0.5,
                }}
              >
                <Typography variant='caption' paragraph sx={{ mb: 0 }}>
                  {consentUsernameAndPassword && (
                    <>
                      {t(TextCodes.WG6.EmailConsent.UsernameAndPassword)}{' '}
                      {(consentETicket || consentReceipt || consentItinerary) &&
                        dot}
                    </>
                  )}
                  {consentItinerary && (
                    <>
                      {t(TextCodes.WG6.EmailConsent.Itinerary)}{' '}
                      {(consentETicket || consentReceipt) && dot}
                    </>
                  )}
                  {consentReceipt && (
                    <>
                      {t(TextCodes.WG6.EmailConsent.Receipt)}{' '}
                      {consentETicket && dot}
                    </>
                  )}
                  {consentETicket && (
                    <>{t(TextCodes.WG6.EmailConsent.ETicket)}</>
                  )}
                </Typography>
              </Box>
            </TextBlock>
          )
        )
      ) : (
        <NotRegistered
          removeDivider
          textBlock
          title={t(TextCodes.WG6.Profile.Email)}
        />
      )}
    </>
  );
};

export default EmailBlock;
