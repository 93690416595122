import { isDarkMode } from 'store/app/app.slice';
import { useAppSelector } from 'store/store';
import { SiteLogoDark } from 'components/common/icons/svg/SiteLogoDark';
import { SiteLogoLight } from 'components/common/icons/svg/SiteLogoLight';

const SiteLogo = () => {
  const darkMode = useAppSelector(isDarkMode);
  return darkMode ? <SiteLogoDark /> : <SiteLogoLight />;
};

export default SiteLogo;
