import { RouteId, routeConfig } from '../../routes/config/route.config';
import { generatePath, Params, useNavigate } from 'react-router-dom';

//* @deprecated - using old routeId system before Kristoff reworked the routes. */
export const useInternalRoutes = () => {
  const scrollToTop = useScrollToTop();
  const getInternalPath = (routeId: RouteId, routeParams?: Params) => generatePath(routeConfig[routeId].path || '', routeParams);
  const getRouteData = (routeId: RouteId) => routeConfig[routeId];
  const getRouteIndexByPath = (path: string) => Object.entries(routeConfig).find((x) => x[1].path === path) || [];
  const getRouteDataByPath = (path: string) => {
    const [key = ''] = getRouteIndexByPath(path);
    return routeConfig[key as RouteId];
  };
  const navigate = useNavigate();
  const redirect = (routeId: RouteId, noScroll?: boolean) => {
    const path = getInternalPath(routeId);
    navigate(path);
    if (noScroll !== true) {
      scrollToTop();
    }
  };

  return { getInternalPath, getRouteData, redirect, getRouteDataByPath, getRouteIndexByPath };
};

export const useInternalPath = (routeId: RouteId, routeParams?: Params) => {
  const { getInternalPath } = useInternalRoutes();
  return getInternalPath(routeId, routeParams);
};

/**
 * @deprecated how does this even work? It was used with parameters, but this does not accept any parameters?
 */
export const useInternalRedirect = () => {
  const { redirect } = useInternalRoutes();
  return redirect;
};

export const useReload = () => () => window.location.reload();

export const useScrollToTop = () => () => window.scrollTo({ top: 0, behavior: 'smooth' });
