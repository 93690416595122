import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'store/texts/language.config';

export const DevTextLabelSwitch = () => {
  const { i18n } = useTranslation();

  const toggleLabels = () => {
    if (i18n.language === LanguageCodes.TextCodes) {
      i18n.changeLanguage("");
    } else {
      i18n.changeLanguage(LanguageCodes.TextCodes);
    }
  };

  return (
    <FormGroup sx={{ ml: 2, mt: 1 }}>
      <FormControlLabel
        control={<Switch checked={i18n.language === LanguageCodes.TextCodes ? true : false} onClick={toggleLabels} />}
        label='Show text codes'
      />
    </FormGroup>
  );
};
