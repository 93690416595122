import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SelectProps,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Button } from '..';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { motion } from 'framer-motion';

// ♻️♻️ This component should be refactored when we have time to do so, as it is very messy. ♻️♻️
// Task: https://dev.azure.com/Berg-Hansen/Berg-Hansen/_sprints/taskboard/WebGate%20Team%20Six/Berg-Hansen/Webgate6/M1-A?workitem=36389

export type DropdownOption = {
  name: string;
  action: any;
  icon?: React.ReactNode;
  isSelected?: boolean;
};

export interface DropdownProps extends SelectProps {
  options: DropdownOption[];
  label?: string;
  buttonId: string;
  menuId: string;
  textSx?: SxProps;
  menuSx?: SxProps;
  itemSx?: SxProps;
  elevation?: number;
  textVariant?:
    | 'caption'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'button'
    | 'buttonMedium'
    | undefined;
}

export const Dropdown = ({
  options,
  label,
  buttonId,
  menuId,
  textSx,
  menuSx,
  itemSx,
  textVariant,
  elevation,
  sx,
  ...rest
}: DropdownProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = (clickHandler?: any) => {
    if (clickHandler) {
      clickHandler();
    }
    setAnchorEl(null);
  };

  const buttonSx = {
    py: 0,
    mx: 0,
    textTransform: 'none' as const,
  };

  const hasIcons = options.find((x) => x.icon);

  const allSx = { ...buttonSx, ...rest, ...sx };

  return (
    <>
      <Button
        id={buttonId}
        aria-controls={menuId}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={openMenu}
        sx={{ ...allSx }}
        variant='text'
      >
        <Box display='flex' alignItems='center'>
          <Typography
            variant={textVariant ?? 'body1'}
            sx={{
              display: 'inline-block',
              p: 0,
              ...textSx,
            }}
          >
            {label}
          </Typography>
          <ArrowDropDownIcon
            component={motion.svg}
            animate={{ rotate: open ? 180 : 0 }}
            sx={{ fontSize: 24 }}
          />
        </Box>
      </Button>
      <Menu
        elevation={elevation}
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClick()}
        MenuListProps={{
          'aria-labelledby': buttonId,
          disablePadding: true,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ ...menuSx }}
      >
        {options.map((option, key) => (
          <MenuItem
            sx={{
              backgroundColor: option.isSelected
                ? theme.palette.primary.outlinedHover
                : '',
              ...itemSx,
            }}
            key={key}
            onClick={() => handleClick(option.action)}
          >
            {hasIcons && (
              <ListItemIcon sx={{ stroke: '#000' }}>{option.icon}</ListItemIcon>
            )}
            <ListItemText>{option.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
