import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';

import CarDetails from 'components/common/car/CarDetails';
import FlightDetails from 'components/common/flight/FlightDetails';
import HotelDetails from 'components/common/hotel/HotelDetails';
import OrderDetailsIconRow from 'components/features/orderDetails/OrderDetailsIconRow';
import { ItineraryDetails } from 'services/api/itinerary';
import { bookingCardDateFormat } from 'services/text/datetime-format';
import { getOrderDetails } from 'store/orders/orderDetails.slice';
import { useAppSelector } from 'store/store';

const OrderDetailsAccordion = ({
  date,
  itineraries,
  itineraryTypes,
}: ItineraryDetails) => {
  // Hooks
  const theme = useTheme();

  // Global store
  const { traveller } = useAppSelector(getOrderDetails);

  return (
    <Accordion sx={{ borderTop: `1px solid ${theme.palette.other?.divider}` }}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon sx={{ fill: theme.palette.primary.main }} />
        }
      >
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          marginRight={2}
        >
          <Box>
            <Typography
              variant='body1'
              fontWeight='bold'
              sx={{ color: theme.palette.primary.main }}
            >
              {bookingCardDateFormat(date!!)}
            </Typography>
          </Box>
          <Box flexGrow={1}>
            <OrderDetailsIconRow types={itineraryTypes!!} />
          </Box>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ pb: 4 }}>
        <Grid container gap={2}>
          {itineraries?.map((itinerary, i) => {
            if (!itinerary?.travellerIds?.includes(traveller?.id || ''))
              return null;

            switch (itinerary.itineraryType as string) {
              case 'Flight':
                return <FlightDetails key={i} {...itinerary} />;
              case 'Car':
                return <CarDetails key={i} {...itinerary} />;
              case 'Hotel':
                return <HotelDetails key={i} {...itinerary} />;
              default:
                return null;
            }
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderDetailsAccordion;
