export const slideFromBottom = {
    hidden: {
        y: "100vh",
    },
    visible: {
        y: 0,
    }
}

export const slideFromRight = {
    hidden: {
        x: "100vw",
    },
    visible: {
        x: 0,
    }
}