import * as Yup from 'yup';
import { Container, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import InfoButtonWithDialog from 'components/common/modals/InfoButtonWithDialog';
import PageTitle from 'components/common/pageTitle/PageTitle';
import PasswordInput from 'components/common/inputs/password/PasswordInput';
import useSiteNavigation from 'hooks/navigation/useSiteNavigation';
import { NewPasswordFields } from 'components/features/user/personalInformation/profile.types';
import { PlainCard } from 'components/common/containers/Card';
import { TextCodes } from 'hooks/texts/text.codes';
import { routeConfig } from 'routes/config/route.config';
import { useToast } from 'hooks';
import { userApi } from 'services/api/user';
import FormPanel from 'components/common/forms/FormPanel';

export const ChangePassword = () => {
  // API
  const [updatePassword, { isLoading, data }] =
    userApi.useUserPasswordSaveMutation();

  // hooks
  const navigate = useSiteNavigation();
  const { toast } = useToast();
  const { t } = useTranslation();

  // Form
  const formMethods = useForm<NewPasswordFields>({
    resolver: yupResolver(
      Yup.object().shape({
        newPassword: Yup.string()
          .required(TextCodes.WG6.Validation.Required)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d$@.,!%*#?&(){}_+-=[\]/':;"|`~^<>\\])[a-zA-Z\\d$@.,!%*#?&(){}_+-=[\]/':;"|`~^<> ]{8,}$/,
            TextCodes.WG6.Password.NotStrongEnough
          ),
        newPasswordRepeat: Yup.string()
          .required(TextCodes.WG6.Validation.Required)
          .equals(
            [Yup.ref('newPassword')],
            TextCodes.WG6.Password.DoesNotMatch
          ),
        currentPassword: Yup.string().required(
          TextCodes.WG6.Validation.Required
        ),
      })
    ),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
    },
    reValidateMode: 'onChange',
  });

  const onSubmit = (formData: NewPasswordFields) => {
    updatePassword({ passwordChangeRequest: formData });
  };

  // Navigate on submit
  useEffect(() => {
    if (data?.isSuccess) {
      navigate(routeConfig.User.path);
      toast({
        severity: 'success',
        autoHideDuration: 3000,
        message: t(TextCodes.WG6.Status.ChangesSavedSuccessfully),
      });
    }
  }, [data]);

  return (
    <Container>
      <PageTitle
        sx={{ mb: 1.5 }}
        title={t(TextCodes.WG6.Password.Title)}
        button={
          <InfoButtonWithDialog popupText={t(TextCodes.WG6.Password.Popup)} />
        }
      />
      <Grid component={PlainCard} container gap={2}>
        <FormProvider {...formMethods}>
          <Typography variant='body2'>
            {t(TextCodes.WG6.Password.Description)}
          </Typography>
          <form>
            <Grid container gap={2}>
              <Grid container gap={2}>
                <PasswordInput
                  id='oldPassword'
                  name='currentPassword'
                  size='small'
                  label={t(TextCodes.WG6.Password.OldPasswordField)}
                  helperText={t(TextCodes.WG6.Password.OldPasswordField)}
                  fullWidth
                  sx={{ mb: 0 }}
                />
                <PasswordInput
                  id='password'
                  name='newPassword'
                  size='small'
                  label={t(TextCodes.WG6.Password.PasswordField)}
                  helperText={t(TextCodes.WG6.Password.PasswordField)}
                  fullWidth
                  sx={{ mb: 0 }}
                />
                <PasswordInput
                  id='confirmPassword'
                  name='newPasswordRepeat'
                  size='small'
                  label={t(TextCodes.WG6.Password.ConfirmPasswordField)}
                  helperText={t(TextCodes.WG6.Password.ConfirmPasswordField)}
                  fullWidth
                  sx={{ mb: 0 }}
                />
              </Grid>
              <FormPanel
                isLoading={isLoading}
                errorMessage={data?.message || ''}
                shouldShowCancelWarning={formMethods.formState.isDirty}
                onSubmit={formMethods.handleSubmit(onSubmit)}
              />
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </Container>
  );
};
