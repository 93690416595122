import { Alert, AlertTitle, Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextCodes } from 'hooks';

const GenericError = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Alert severity='error'>
        <AlertTitle>
          {t(TextCodes.WG6.Error.SomethingWentWrongTitle)}
        </AlertTitle>
        {t(TextCodes.WG6.Error.UpdateThePageOrContactUs)}{' '}
        <Link>{t(TextCodes.WG6.Base.ContactUs).toLowerCase()}</Link>
      </Alert>
    </Stack>
  );
};

export default GenericError;
