import i18n from "i18next";
import backend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const loadResources = async (locale: string) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}api/texts-dictionary`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "language": locale
    },
    body: JSON.stringify({ languageCode: locale })
  });
  return await res.json();
};

const backendOptions = {
  loadPath: "{{lng}}|{{ns}}",
  request: (options: any, url: any, payload: any, callback: any) => {
    try {
      const [lng] = url.split("|");

      loadResources(lng).then((response) => {
        callback(null, {
          data: JSON.stringify(response.value),
          status: 200,
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  .use(LanguageDetector)
  .use(backend)
  .use(initReactI18next)
  .init({
    supportedLngs: ["no", "en"],
    // fallbackLng: "no",
    backend: backendOptions,
    detection: {
      order: ['localStorage', 'querystring', 'cookie', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },
    debug: false,
    load: "currentOnly",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    }
  });

export default i18n;
