import { Stack, ButtonProps, ButtonGroup } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

export type ButtonPanelProps = Pick<ButtonProps, 'variant'> & {
  children?: React.ReactNode;
  align?: 'right' | 'left' | 'center' | 'justify';
  sx?: SxProps<Theme>;
};

export const ButtonPanel = ({
  children,
  variant,
  align = 'left',
  sx,
}: ButtonPanelProps) => {
  let justifyContent;
  switch (align) {
    case 'right':
      justifyContent = 'flex-end';
      break;
    case 'center':
      justifyContent = 'center';
      break;
    case 'justify':
      justifyContent = 'space-between';
      break;
    default:
      justifyContent = 'flex-start';
      break;
  }
  return variant ? (
    <ButtonGroup variant={variant} sx={sx}>
      {children}
    </ButtonGroup>
  ) : (
    <Stack
      direction='row'
      spacing={4}
      sx={{ width: '100%', justifyContent, ...sx }}
    >
      {children}
    </Stack>
  );
};
