import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from 'store/auth/auth.slice';
import appReducer from 'store/app/app.slice';
import userReducer from 'store/user/user.slice';
import toastReducer from 'store/toast/toast.slice';
import tripsReducer from 'store/trips/trips.slice';
import paymentReducer from 'store/payment/payment.slice';
import orderDetailsReducer from 'store/orders/orderDetails.slice';
import orderReceiptReducer from 'store/orders/orderReceipt.slice';
import { baseApi } from 'services/api/baseApi';
import { rtkQueryErrorHandler } from 'services/api/middleware/errorHandler.middleware';

const reduxPersistConfig = {
  key: 'root',
  storage,
};

const reducersCombined = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  auth: authReducer,
  user: userReducer,
  app: appReducer,
  toast: toastReducer,
  trips: tripsReducer,
  orderDetails: orderDetailsReducer,
  orderReceipt: orderReceiptReducer,
  payment: paymentReducer,
});

const reducerPersited = persistReducer(reduxPersistConfig, reducersCombined);

// Redux store composition
export const store = configureStore({
  reducer: reducerPersited,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(baseApi.middleware)
      .concat(rtkQueryErrorHandler),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Typed hooks
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
