import { Components, CSSInterpolation, Palette } from '@mui/material';

interface SkeletonComponent extends Components {
  MuiSkeleton?: {
    styleOverrides: {
      root: CSSInterpolation;
    };
  };
}

export const skeletonStyles = (palette: Palette): SkeletonComponent => ({
  MuiSkeleton: {
    styleOverrides: {
      root: {
        borderRadius: '.3125rem',
      },
    },
  },
});
