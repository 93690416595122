import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ItineraryDetailsResponse,
  ItineraryTravellerOrder,
} from 'services/api/itinerary';
import { RootState } from 'store';
import { itineraryApi } from 'services/api/itinerary';

interface OrderDetailsState extends ItineraryDetailsResponse {
  traveller: ItineraryTravellerOrder;
}

const initialState = {
  orderNo: '',
  traveller: {
    firstName: '',
    lastName: '',
    id: '',
    travCode: {
      label: '',
      value: '',
    },
    depCode: {
      label: '',
      value: '',
    },
  },
  travellers: [],
  itineraryDetails: [],
  pointOfTurnaround: {
    location: {
      name: '',
      code: '',
      geoCode: {
        latitude: 0,
        longitude: 0,
      },
    },
    city: {
      name: '',
      code: '',
      geoCode: {
        latitude: 0,
        longitude: 0,
      },
    },
    country: {
      name: '',
      code: '',
      geoCode: {
        latitude: 0,
        longitude: 0,
      },
    },
    displayName: '',
  },
};

export const orderDetailsSlice = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {
    setTraveller: (state: OrderDetailsState, action: PayloadAction<string>) => {
      state.traveller =
        state?.travellers?.find((t) => t.id === action.payload) ??
        state.traveller;
    },
    resetOrderDetails: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      itineraryApi.endpoints.itineraryDetails.matchFulfilled,
      (
        state: OrderDetailsState,
        { payload }: PayloadAction<ItineraryDetailsResponse>
      ) => {
        state.orderNo = payload.orderNo;
        state.itineraryDetails = payload.itineraryDetails ?? [];
        state.travellers = payload.travellers ?? [];
        state.traveller = payload.travellers
          ? payload.travellers[0]
          : state.traveller;
        state.pointOfTurnaround =
          payload.pointOfTurnaround ?? state.pointOfTurnaround;
      }
    );
  },
});

export const getOrderDetails = createSelector(
  (state: RootState) => state.orderDetails,
  (data: OrderDetailsState) => data
);

export const getSelectedOrderDetailsTraveller = createSelector(
  (state: RootState) => state.orderDetails,
  (data: OrderDetailsState) => data.traveller
);

export const { setTraveller, resetOrderDetails } = orderDetailsSlice.actions;
export default orderDetailsSlice.reducer;
