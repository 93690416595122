import dayjs from 'dayjs';
import { TextCodes } from 'hooks/texts/text.codes';
import * as yup from 'yup';

export default function disablePastDate(message?: string, format?: string) {
  const errorMessage = message
    ? message
    : TextCodes.WG6.Validation.DateCannotBeInPast;

  return yup
    .string()
    .typeError(TextCodes.WG6.Validation.Required)
    .test('disallowPastDate', errorMessage, function (value, context) {
      // We do not validate "required".
      if (!value) return true;

      const date = dayjs(value);

      if (!date.isValid())
        return context.createError({
          path: context.path,
          message: format ? format : TextCodes.WG6.Validation.InvalidDate,
        });

      return dayjs().isBefore(date);
    });
}
