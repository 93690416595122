import { Grid } from '@mui/material';
import DropdownMenu, {
  DropdownMenuOptions,
} from 'components/common/dropdownMenu/DropdownMenu';
import { TextCodes } from 'hooks/texts/text.codes';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface OrderDetailsCardHeaderProps {
  icon: ReactElement;
  content: ReactElement;
  info?: string;
  options: DropdownMenuOptions[];
  id: string;
}

const OrderDetailsCardHeader = ({
  icon,
  content,
  options,
  id,
}: OrderDetailsCardHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems='start' justifyContent='space-between' gap={0.5}>
      <Grid item xs={10} display='flex' alignItems='start' gap={1}>
        {icon}
        {content}
      </Grid>
      <Grid item xs={1}>
        <DropdownMenu
          label={t(TextCodes.WG6.Generic.MoreOptions)}
          id={id}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

export default OrderDetailsCardHeader;
