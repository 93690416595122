import moment from 'moment';
import { Button } from '@mui/material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { useTranslation } from 'react-i18next';

import ArrivalFlightPoint from 'components/common/timeline/flight/ArrivalFlightPoint';
import DepartureFlightPoint from 'components/common/timeline/flight/DepartureFlightPoint';
import { ArrowDropDown } from '@mui/icons-material';
import { setShowNotImplementedModal } from 'store/app/app.slice';
import { useAppDispatch } from 'store/store';
import { TextCodes } from 'hooks';

/* 
The <TimelineOppositeContent /> Component is used and set to display none,
because without it the width of the actual content is only 50%.
There's supposed to be some way to edit this out using theme,
but theme does not contain any of the timeline elements 
because Timeline is Mui Lab and not main library.
I didn't bother looking too much into it and just did it the old fashioned way
by just applying tons of styles and leaving a bit janky looking JSX.
I thought this was OK because this is not a component that has a lot of business logic,
nor is it going to be re-used many places, but if you do figure out a better way to
solve this problem, then feel free to refactor.
*/

/* 
TODO: Maybe there needs to be some logic of how many connector lines there are in the timeline.
If this becomes necessary, one way we can determine that is to give the lines fixed heights,
then we can use the height of the parent box to determine how many lines there should be. 
*/

interface FlightTimelineProps {
  arrival: string | undefined;
  departure: string | undefined;
  duration: number;
  flightNumberOfStops: string | undefined;
  gate: string | undefined;
  originalArrivalDate: string;
  originalDepartureDate: string;
  seat: string | undefined;
  updatedArrivalDate: string;
  updatedDepartureDate: string;
}

const FlightTimeline = ({
  arrival,
  departure,
  duration,
  flightNumberOfStops,
  gate,
  originalArrivalDate,
  originalDepartureDate,
  seat,
  updatedArrivalDate,
  updatedDepartureDate,
}: FlightTimelineProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const startTime = updatedDepartureDate ?? originalDepartureDate;
  const endTime = updatedArrivalDate ?? originalArrivalDate;

  const dateDifference = moment(endTime)
    .startOf('day')
    .diff(moment(startTime).startOf('day'), 'days');

  const showNotImplementedModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(setShowNotImplementedModal(true));
  };

  return (
    <Timeline
      position='right'
      sx={{ p: 1, display: 'flex', flexDirection: 'column', gap: '2px' }}
    >
      <DepartureFlightPoint
        city={departure}
        duration={duration}
        gate={gate}
        originalDate={originalDepartureDate}
        seat={seat}
        updatedDate={updatedDepartureDate}
      />

      {!!Number(flightNumberOfStops) && (
        <TimelineItem sx={{ mb: 0.25, minHeight: 'unset' }}>
          <TimelineSeparator sx={{ ml: 0.25 }}>
            <TimelineConnector />
            <TimelineConnector />
            <TimelineConnector />
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ p: 0, pl: 1, alignSelf: 'center' }}>
            <Button
              variant='text'
              size='small'
              onClick={showNotImplementedModal}
            >
              {`${flightNumberOfStops} ${t(
                TextCodes.WG6.Flight.StopWithoutAChangeOfFlight
              )}`}
              <ArrowDropDown />
            </Button>
          </TimelineContent>
        </TimelineItem>
      )}

      <ArrivalFlightPoint
        city={arrival}
        dateDifference={dateDifference}
        originalDate={originalArrivalDate}
        updatedDate={updatedArrivalDate}
      />
    </Timeline>
  );
};

export default FlightTimeline;
