import { Alert, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextInput from 'components/common/inputs/textInput/TextInput';
import { LinkPhone } from 'components/common';
import { TextCodes } from 'hooks';

const TravelPassCorporate = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Typography variant='body2' component='h2' fontWeight='bold'>
        {t(TextCodes.WG6.SasTravel.CorporateLabel)}
      </Typography>
      <TextInput
        name='number'
        maxLength={9}
        label={t(TextCodes.WG6.SasTravel.CorporateNumber)}
        helperText={t(TextCodes.WG6.SasTravel.CorporateHelperText)}
        fullWidth
      />
      <Alert severity='info'>
        {`${t(TextCodes.WG6.NationalId.TPCNumberInfoPartOne)} `}
        <LinkPhone phone={t(TextCodes.WG6.SasTravel.PhoneNo)} />
        {` ${t(TextCodes.WG6.NationalId.TPCNumberInfoPartTwo)}`}
      </Alert>
    </Stack>
  );
};

export default TravelPassCorporate;
