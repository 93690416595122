import { DeleteOutline } from '@mui/icons-material';
import { Grid, IconButton, Divider, Typography } from '@mui/material';
import AcceptedBankCardLogos from 'components/common/icons/AcceptedBankCardLogos';
import CreditCardExpirationInput from './CreditCardExpirationInput';
import CreditCardInput from 'components/common/inputs/CreditCardInput';
import AddMoreInputsButton from 'components/common/forms/AddMoreInputsButton';
import { TextCodes } from 'hooks';
import { useTranslation } from 'react-i18next';
import useAnimatedFieldArray from 'hooks/animation/useAnimatedFieldArray';
import { motion } from 'framer-motion';
import { newCreditCardDefaultValues } from './PaymentSettingsForm';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { useAppSelector } from 'store/store';
import { getIsReadOnlyProfile } from 'store/user/user.slice';

const NewCreditCards = () => {
  // Hooks
  const { t } = useTranslation();

  // Selectors 🤏
  const isReadOnly = useAppSelector(getIsReadOnlyProfile);

  // Form methods
  const { fields, append, remove, variants, itemControls } =
    useAnimatedFieldArray('newCards');

  if (isReadOnly) return null;

  return (
    <Grid container gap={2} component='ul'>
      {fields?.map((f, i) => (
        <Grid
          container
          gap={2}
          component={motion.li}
          custom={i}
          variants={variants}
          animate={itemControls}
          key={f.id}
        >
          <Grid container justifyContent='space-between'>
            <Grid item xs={8}>
              <AcceptedBankCardLogos />
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'right' }}>
              <IconButton
                color='primary'
                sx={{ pt: 0 }}
                onClick={() => remove(i)}
              >
                <DeleteOutline />
                <Typography sx={visuallyHidden}>
                  {t(TextCodes.Common.Delete)}
                </Typography>
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CreditCardInput name={`newCards.${i}`} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CreditCardExpirationInput name={`newCards.${i}`} />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <AddMoreInputsButton
          sx={{ mb: 0 }}
          onClick={() => append(newCreditCardDefaultValues)}
          label={t(TextCodes.WG6.Payment.AddMorePaymentCards)}
        />
      </Grid>
      <Divider component={Grid} item xs={12} />
    </Grid>
  );
};

export default NewCreditCards;
