import SingleSelect from 'components/common/inputs/SingleSelect';
import { TextCodes } from 'hooks';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'store/texts/language.config';

interface LanguageSelectProps {
  size?: 'small' | 'medium' | undefined;
  name: string;
}

const LanguageSelect = ({ size, name }: LanguageSelectProps) => {
  const { t, i18n } = useTranslation();
  const languages = [
    {
      text: 'Norsk',
      value: LanguageCodes.NO,
    },
    {
      text: 'English',
      value: LanguageCodes.EN,
    },
  ];

  return (
    <SingleSelect
      label={t(TextCodes.WG6.Preferences.Language)}
      name={name}
      options={languages}
      size={size}
      defaultValue={i18n.language}
    />
  );
};

export default LanguageSelect;
