interface StaticMapProps {
  latitude: number;
  longitude: number;
  zoom?: number;
  width?: number;
  height?: number;
  openOnTap?: boolean;
}

const StaticMap = ({
  latitude,
  longitude,
  zoom = 13,
  width = 309,
  height = 200,
}: StaticMapProps) => {
  const mapImage = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoom}&size=${width}x${height}&maptype=roadmap&markers=size:tiny|color:red|${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

  /* 
  TODO: When we implement the fully functional map for booking, we will also add it so that it gets openeed here when you tap this static map.
  */

  return (
    <img
      style={{ display: 'block', width: '100%' }}
      src={mapImage}
      alt='Map showing location'
    />
  );
};

export default StaticMap;
