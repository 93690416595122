import { Box } from '@mui/material';
import StandardHeader from 'components/common/headers/StandardHeader';
import BonusCardsForm from 'components/features/user/benefitCardsAndPreferences/benefitCards/BonusCardsForm';

const BonusCardsPage = () => (
  <Box aria-live='polite'>
    <StandardHeader showBreadcrumbs={false} />
    <BonusCardsForm />
  </Box>
);

export default BonusCardsPage;
