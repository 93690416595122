import { Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LinkCard } from 'components/common';
import { NotImplementedButton } from 'components/common/not-implemented/NotImplementedModal';
import { TextCodes } from 'hooks/texts/text.codes';

export const UserIndex = () => {
  const { t } = useTranslation();

  return (
    <>
      <LinkCard
        title={t(TextCodes.WG6.User.PersonalTitle)}
        routeId='PersonalInformation'
      >
        {t(TextCodes.WG6.User.PersonalDescription)}
      </LinkCard>
      <LinkCard
        title={t(TextCodes.WG6.User.PaymentTitle)}
        routeId='UserPayment'
      >
        {t(TextCodes.WG6.User.PaymentDescription)}
      </LinkCard>
      <LinkCard
        title={t(TextCodes.WG6.User.PasswordTitle)}
        routeId='UserPassword'
      >
        {t(TextCodes.WG6.User.PasswordDescription)}
      </LinkCard>
      <LinkCard title={t(TextCodes.WG6.User.ChangeUsername)} routeId='Username'>
        {t(TextCodes.WG6.User.UsernameDescription)}
      </LinkCard>
      <LinkCard
        title={t(TextCodes.WG6.User.DocumentsTitle)}
        routeId='Documents'
      >
        {t(TextCodes.WG6.User.DocumentsDescription)}
      </LinkCard>
      <LinkCard
        title={t(TextCodes.WG6.User.BonusTitle)}
        routeId='BenefitCardsAndPreferences'
      >
        {t(TextCodes.WG6.User.BonusDescription)}
      </LinkCard>
      <Card>
        <NotImplementedButton>
          <CardContent
            sx={{ pt: 2, width: '100%', '&:last-child': { paddingBottom: 2 } }}
          >
            <Typography
              variant='body1'
              fontWeight='bold'
              component='h3'
              sx={{ mb: 1 }}
            >
              {t(TextCodes.WG6.User.FamilyTitle)}
            </Typography>
            <Typography variant='body2'>
              {t(TextCodes.WG6.User.FamilyDescription)}
            </Typography>
          </CardContent>
        </NotImplementedButton>
      </Card>
    </>
  );
};
