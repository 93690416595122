import { Box, Container } from '@mui/system';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BenefitCardsSection from 'components/features/user/benefitCardsAndPreferences/benefitCards/BenefitCardsSection';
import PageTitle from 'components/common/pageTitle/PageTitle';
import PreferencesSection from 'components/features/user/benefitCardsAndPreferences/preferences/PreferencesSection';
import SasTravelPassSection from 'components/features/user/benefitCardsAndPreferences/SasTravelPass/SasTravelPassSection';
import StandardHeader from 'components/common/headers/StandardHeader';
import { TextCodes } from 'hooks';

const BenefitCardsAndPreferencesOverviewPage = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <StandardHeader amountOfBreadcrumbs={3} />
      <Container sx={{ mt: 2 }} aria-live='polite'>
        <PageTitle
          title={t(TextCodes.WG6.BenefitCardsAndPreferences.PageTitle)}
        />
        <Stack spacing={2}>
          <SasTravelPassSection />
          <BenefitCardsSection />
          <PreferencesSection />
        </Stack>
      </Container>
    </Box>
  );
};

export default BenefitCardsAndPreferencesOverviewPage;
