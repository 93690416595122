import Autocomplete from '@mui/material/Autocomplete';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { SxProps } from '@mui/system/styleFunctionSx';
import { debounce } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AutocompleteOption } from 'components/common/inputs/input.types';
import { TextCodes } from 'hooks';
import { locationsApi } from 'services/api/locations';

interface LocationSearchInputProps {
  label: string;
  name: string;
  defaultLabel?: string;
  defaultOption?: AutocompleteOption | undefined;
  sx?: SxProps;
}

const LocationSearchInput = ({
  label,
  name,
  defaultLabel,
  defaultOption,
  sx = { mb: 0 },
}: LocationSearchInputProps) => {
  // Hooks 🎣
  const { t } = useTranslation();
  const { control, register, setValue } = useFormContext();

  // 📋 Form methods 📋
  const value = useWatch({
    name: name,
    control,
  });

  // 🏡 Local state 🏡
  const [options, setOptions] = useState<AutocompleteOption[]>(
    defaultOption ? [defaultOption] : []
  );
  const [inputValue, setInputValue] = useState(defaultLabel || value);
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] =
    useState<string | null | undefined>('');

  // 🚀 API call 🚀
  const [getAirports, { data, isUninitialized }] =
    locationsApi.useLocationsMutation();

  // Initial search if no search has been performed yet 🔎
  useEffect(() => {
    if (isUninitialized) {
      getAirports({
        searchLocationsRequest: { keyword: 'a' },
      });
    }
  }, [isUninitialized]);

  // ✅ Success handler ✅
  useEffect(() => {
    if (!!data?.isSuccess) {
      const mappedResult: AutocompleteOption[] =
        data?.value?.map((option) => ({
          text: option.description || '',
          value: option.code || '',
        })) || [];

      setOptions(mappedResult);
      setError(false);
      setErrorMessage('');
    }

    if (data?.isSuccess === false) {
      console.log('[data]', data);
      setError(true);
      setErrorMessage(data?.message);
      setFocus(false);
      setHover(false);
    }
  }, [data, isUninitialized]);

  // Location search with debounce 🔎✈️
  const debounceSearch = useCallback(
    debounce((keyword: string) => {
      if (keyword) {
        getAirports({
          searchLocationsRequest: { keyword },
        });
      } else {
        getAirports({
          searchLocationsRequest: { keyword: 'a' },
        });
        setValue(name, '', { shouldDirty: true });
      }
    }, 500),
    []
  );

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event?.target?.value || '');
    debounceSearch(event?.target?.value || '');
  };

  const handleAutocompleteChange = (
    _: SyntheticEvent<Element, Event>,
    selected: string | null | undefined
  ) => {
    setValue(name, options.find((option) => option?.text === selected)?.value, {
      shouldDirty: true,
    });
    setInputValue(selected || '');
    debounceSearch(selected || '');
  };

  const isVisible = !!inputValue && (focus || hover);

  return (
    <Autocomplete
      {...register(name)}
      id={name}
      freeSolo
      disablePortal
      inputValue={inputValue}
      onChange={handleAutocompleteChange}
      options={options.map((option) => option.text)}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={sx}
          size='small'
          label={label}
          error={error}
          helperText={error ? errorMessage : ''}
          onChange={handleTextFieldChange}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          onMouseLeave={() => setHover(false)}
          onMouseOver={() => setHover(true)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment
                sx={{ visibility: isVisible ? 'visible' : 'hidden' }}
                position='end'
              >
                <IconButton
                  onClick={() => {
                    setInputValue('');
                    setValue(name, '', { shouldDirty: true });
                  }}
                >
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      // Renders each option in the list
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option || '', inputValue);
        const parts = parse(option || '', matches);

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <Typography
                  key={index}
                  variant='body1'
                  fontWeight={part.highlight ? 700 : 400}
                >
                  {part.text}
                </Typography>
              ))}
            </div>
          </li>
        );
      }}
      // Renders the dropdown popup. If there is no options, display a "No options" text
      PaperComponent={(params) => (
        <>
          {error ? null : options?.length ? (
            <Paper {...params} />
          ) : (
            <Paper {...params}>
              <ListItem sx={{ p: 2 }}>
                <Typography variant='body1' color='text.secondary'>
                  {t(TextCodes.WG6.Preferences.NoOptions)}
                </Typography>
              </ListItem>
            </Paper>
          )}
        </>
      )}
    />
  );
};

export default LocationSearchInput;
