import AirplaneModeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import Box from '@mui/material/Box';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HotelIcon from '@mui/icons-material/Hotel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TrainIcon from '@mui/icons-material/Train';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Card } from 'components/common';
import { ItineraryListItem } from 'services/api/itinerary';
import { TextCodes } from 'hooks';
import { humanizeDate } from 'services/text/datetime-format';

type TripCardProps = {
  trip: ItineraryListItem;
};

export const TripCard = ({ trip }: TripCardProps) => {
  const { t } = useTranslation();

  let additionalTravelersLabel = trip?.travellers
    ? `${trip.travellers[0]?.firstName} ${trip.travellers[0]?.lastName}`
    : '';

  const additionalTravelersCount = trip?.travellers
    ? trip.travellers.length - 1
    : 0;

  if (additionalTravelersCount > 0) {
    additionalTravelersLabel += `, ${additionalTravelersCount} ${t(
      TextCodes.WG6.TripDetails.Travellers
    )}`;
  }

  return (
    <Link
      to={`/orders/details?orderNumber=${trip?.orderNo}`}
      component={RouterLink}
      sx={{ textDecoration: 'none' }}
    >
      <Card sx={{ textDecoration: 'none' }}>
        <Stack spacing={0}>
          <Stack direction='row' spacing={1}>
            {trip?.containsAir && <AirplaneModeActiveIcon />}
            {trip?.containsHotel && <HotelIcon />}
            {trip?.containsTrain && <TrainIcon />}
            {trip?.containsCar && <DirectionsCarIcon />}

            <Typography
              fontWeight={700}
              variant='body1'
              sx={{ paddingBottom: 1 }}
            >
              {trip?.destinationName}
            </Typography>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 2,
            }}
          >
            <Typography variant='body2'>
              {humanizeDate(trip.outwardDate)}
            </Typography>
            <Typography variant='body2'>
              {t(TextCodes.WG6.TripDetails.Ref)} {trip.reference}
            </Typography>
          </Box>
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{ textTransform: 'capitalize' }}
          >
            {additionalTravelersLabel?.toLowerCase()}
          </Typography>
        </Stack>
      </Card>
    </Link>
  );
};
