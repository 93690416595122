import * as Yup from 'yup';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import PasswordInput from 'components/common/inputs/password/PasswordInput';
import TextInput from 'components/common/inputs/textInput/TextInput';
import { ButtonPanel } from 'components/common';
import { LoginCredentials } from 'components/features/login/login.types';
import { ProgressButton } from 'components/common/buttons/ProgressButton';
import { TextCodes } from 'hooks';
import { routeConfig } from 'routes/config/route.config';
import { userApi } from 'services/api/user';
import { authApi } from 'services/api/authentication';

export const LoginForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [login, loginStatus] = authApi.useLoginMutation();
  const [getProfile, getProfileStatus] =
    userApi.useLazyUserProfileDetailsQuery();

  const formMethods = useForm<LoginCredentials>({
    resolver: yupResolver(
      Yup.object().shape({
        username: Yup.string().required(
          TextCodes.WG6.Login.ValidationRequiredField
        ),
        password: Yup.string().required(
          TextCodes.WG6.Login.ValidationRequiredField
        ),
      })
    ),
    defaultValues: { username: '', password: '' },
  });

  const onSubmit = (data: LoginCredentials) => {
    login({
      loginRequest: { username: data.username, password: data.password },
    });
  };

  useEffect(() => {
    if (loginStatus?.isSuccess) {
      getProfile();
    }
  }, [loginStatus]);

  useEffect(() => {
    if (getProfileStatus.isSuccess) {
      navigate(routeConfig.Home.path);
    }
  }, [getProfileStatus]);

  const isBusy = loginStatus.isLoading || getProfileStatus.isLoading;
  const errorMessage =
    loginStatus.data?.message || getProfileStatus.data?.message;

  return (
    <FormProvider {...formMethods}>
      <form>
        <TextInput
          name='username'
          label={t(TextCodes.WG6.Login.UsernameOrEmail)}
          size='medium'
          fullWidth
          autoFocus
          showClearButton
        />
        <PasswordInput
          name='password'
          label={t(TextCodes.WG6.Login.Password)}
          autoComplete='password'
          size='medium'
          fullWidth
          sx={{ mb: 0 }}
        />
        <Collapse in={errorMessage ? true : false} collapsedSize={0}>
          <Box sx={{ mt: 2 }}>
            <Alert severity='error'>{errorMessage}</Alert>
          </Box>
        </Collapse>
        <Stack justifyItems='flex-end'>
          <Typography
            component='a'
            variant='body2link'
            href={process.env.REACT_APP_FORGOT_PASSWORD_URL}
            sx={{
              color: theme.palette.primary.main,
              ml: 'auto',
              mt: 2,
              mb: 5,
            }}
          >
            {t(TextCodes.WG6.Login.ForgotPassword)}
          </Typography>
        </Stack>
        <ButtonPanel align='justify'>
          <Button
            component={Link}
            to={routeConfig.NewProfile.path}
            variant='outlined'
          >
            <Typography variant='buttonLarge'>
              {t(TextCodes.WG6.Login.CreateProfile)}
            </Typography>
          </Button>
          <ProgressButton
            sx={{ minWidth: '6.75rem' }}
            variant='contained'
            type='submit'
            disabled={isBusy}
            busy={isBusy}
            onClick={formMethods.handleSubmit(onSubmit)}
          >
            <Typography variant='buttonLarge'>
              {t(TextCodes.WG6.Login.Login)}
            </Typography>
          </ProgressButton>
        </ButtonPanel>
      </form>
    </FormProvider>
  );
};
