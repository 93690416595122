import { Box, Link, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'components/common';
import { TextCodes } from 'hooks';
import { NotImplementedButton } from 'components/common/not-implemented/NotImplementedModal';

export const LoginFooter = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const closePopup = () => setDialogOpen(false);
  const confirmLabel = t(TextCodes.WG6.Navigation.Close);
  return (
    <NotImplementedButton>
      <Box sx={{ textAlign: 'center', mt: 3 }}>
        <Dialog
          open={isDialogOpen}
          onClose={closePopup}
          confirmLabel={confirmLabel}
          onConfirm={closePopup}
          maxWidth='xs'
        >
          {t(TextCodes.WG6.Login.SingleSignOnDescription)}
        </Dialog>
        <Box sx={{ maxWidth: '300px', textAlign: 'center', margin: '0 auto' }}>
          <Link
            variant='body2link'
            onClick={() => setDialogOpen(true)}
            sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
          >
            {t(TextCodes.WG6.Login.SingleSignOnLink)}
          </Link>
        </Box>
      </Box>
    </NotImplementedButton>
  );
};
