import { LoginForm } from '../../components/features/login/LoginForm';
import { LanguageSwitch } from '../../components/features/language/LanguageSwitch';
import { TextCodes } from '../../hooks/texts/text.codes';
import { LoginFooter } from './LoginFooter';
import { useTranslation } from 'react-i18next';
import StandardHeader from 'components/common/headers/StandardHeader';
import { Box, Card, Container, Typography } from '@mui/material';

export const LoginPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <StandardHeader showBreadcrumbs={false} />
      <Container sx={{ mt: 2 }}>
        <Card sx={{ pt: 4, pb: 3 }}>
          <Container>
            <Box
              sx={{ mb: 4 }}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography variant='subtitle1' component='h1'>
                {t(TextCodes.WG6.Login.Login)}
              </Typography>
              <LanguageSwitch />
            </Box>
            <LoginForm />
          </Container>
        </Card>
        <LoginFooter />
      </Container>
    </>
  );
};
