import {
  createSlice,
  PayloadAction,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  userApi,
  GetUserProfileDto,
  UserProfileResponse,
  ProfileDictionaries,
  FrequentTraveller,
  Preferences,
  BonusCardDetails,
  BonusCardsData,
} from 'services/api/user';

// INITIAL STATE
export interface UserState {
  profile: UserProfileResponse;
  dictionaries: ProfileDictionaries;
  frequentFlyers: FrequentTraveller[] | null;
}

const initialState: UserState = {
  profile: {},
  dictionaries: {},
  frequentFlyers: [],
};

// REDUCERS
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUserProfile: (
      state: UserState,
      action: PayloadAction<GetUserProfileDto>
    ) => {
      state.profile = action?.payload?.profileData || {};
      state.dictionaries = action?.payload?.dictionaries || {};
      state.frequentFlyers = action.payload?.frequentFlyers || [];
    },
    saveDictionaries: (
      state: UserState,
      action: PayloadAction<ProfileDictionaries>
    ) => {
      state.dictionaries = action.payload;
    },
    resetUser: () => initialState,
  },
  // Extra reducers responds to the actions happening in different slices of the store
  // This reducers responds to the actions in API slice
  extraReducers: (builder) => {
    builder
      // Load complete user profile, dictionary and frequent flyers when on user profile load
      .addMatcher(
        userApi.endpoints.userProfileDetails.matchFulfilled,
        (state, { payload }) => {
          if (payload?.isSuccess) {
            const data = payload?.value;
            state.profile = data?.profileData || {};
            state.dictionaries = data?.dictionaries || {};
            state.frequentFlyers = data?.frequentFlyers || [];
          }
        }
      )
      .addMatcher(
        isAnyOf(
          userApi.endpoints.userArrangersSave.matchFulfilled,
          userApi.endpoints.userBonusCardsSave.matchFulfilled,
          userApi.endpoints.userContactDataSave.matchFulfilled,
          userApi.endpoints.userPassportsSave.matchFulfilled,
          userApi.endpoints.userPersonalDataSave.matchFulfilled,
          userApi.endpoints.userTravelPassCorporateSave.matchFulfilled,
          userApi.endpoints.userVisasSave.matchFulfilled
        ),
        (state, { payload }) => {
          if (payload?.isSuccess) {
            state.profile = payload?.value || {};
          }
        }
      )
      .addMatcher(
        userApi.endpoints.userPreferencesSave.matchFulfilled,
        (state, { payload }) => {
          if (payload?.isSuccess) {
            state.profile.preferences = payload?.value?.preferences || {};
          }
        }
      )
      .addMatcher(
        userApi.endpoints.userTravellersSave.matchFulfilled,
        (state, { payload }) => {
          if (payload?.isSuccess) {
            state.frequentFlyers = payload?.value || [];
          }
        }
      )
      .addMatcher(
        userApi.endpoints.userClientReferencesSave.matchFulfilled,
        (state, { payload }) => {
          if (payload?.isSuccess) {
            state.profile.clientReferences =
              payload?.value?.clientReferences || [];
          }
        }
      );
  },
});
export default userSlice.reducer;

// ACTIONS
export const { saveUserProfile, saveDictionaries, resetUser } =
  userSlice.actions;

// SELECTORS
export const getUser = createSelector(
  (state: RootState) => state.user,
  (data) => data
);

export const getDictionaries = createSelector(
  (state: RootState) => state.user.dictionaries,
  (data: ProfileDictionaries) => data
);

export const getProfile = createSelector(
  (state: RootState) => state.user.profile,
  (data: UserProfileResponse) => data
);

export const getDictionary = (code: keyof ProfileDictionaries) =>
  createSelector(
    getDictionaries,
    (data: ProfileDictionaries) => data[code] || []
  );
export const getDictionaryValue = (
  code: keyof ProfileDictionaries,
  value: string
) =>
  createSelector(getDictionaries, (data: ProfileDictionaries) => {
    const dictionary = data[code] || [];
    return dictionary.find((item) => item.value === value)?.text;
  });

// Client / employer references
export const getReferences = createSelector(
  getProfile,
  (data: UserProfileResponse) => data.clientReferences || []
);

// Documents
export const getPassports = createSelector(
  getProfile,
  (data: UserProfileResponse) => data.passports
);
export const getVisas = createSelector(
  getProfile,
  (data: UserProfileResponse) => data.visas
);

// Frequent Flyers
export const getFrequentFlyers = createSelector(
  getUser,
  (data: UserState) => data.frequentFlyers
);

//Arrangers
export const getArrangers = createSelector(
  getProfile,
  (data: UserProfileResponse) => data.arrangers || []
);

// Shortcuts
export const getShortcuts = createSelector(
  getProfile,
  (data: UserProfileResponse) => data.shortcuts
);

// Roles
export const getRoles = createSelector(
  getProfile,
  (data: UserProfileResponse) => data.roles
);

// Preferences
export const getPreferences = createSelector(
  getProfile,
  (data: UserProfileResponse) => data.preferences
);
export const getPreferencesForEdit = createSelector(
  getPreferences,
  (preferences) => ({
    ...preferences,
    defaultDepartureCity: preferences?.defaultDepartureCity?.code || '',
    defaultDestinationCity: preferences?.defaultDestinationCity?.code || '',
  })
);

export type PreferenceValues = Omit<
  Preferences,
  'hotelRoomSmoking' | 'defaultDepartureCity' | 'defaultDestinationCity'
> & {
  hotelRoomSmoking?: string | null;
  defaultDepartureCity?: string | null;
  defaultDestinationCity?: string | null;
};

export const getPreferenceValues = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const getValue = (
      code: keyof ProfileDictionaries,
      value: string | undefined | null
    ) => getDictionary(code)(state).find((x) => x.value === value)?.text;
    const preferences = state.user.profile.preferences;
    const values: PreferenceValues = {
      airMealCode: getValue('planeMeals', preferences?.airMealCode),
      airSeatCode: getValue('planeSeats', preferences?.airSeatCode),
      currencyCode: getValue('currencies', preferences?.currencyCode),
      languageCode: getValue('languages', preferences?.languageCode),
      hotelRoomSmoking: getValue(
        'hotelSmokeTypes',
        preferences?.hotelRoomSmoking
      ),
      defaultDepartureCity: preferences?.defaultDepartureCity?.description,
      defaultDestinationCity: preferences?.defaultDestinationCity?.description,
      airSeatNumber: preferences?.airSeatNumber,
    };
    return values;
  }
);

// Bonus cards
export const getBonusCards = createSelector(
  getProfile,
  (data: UserProfileResponse) => data.bonusCards
);

export const getMappedBonusCardsByType = (
  code: keyof ProfileDictionaries,
  list: BonusCardDetails[] | null | undefined
) =>
  createSelector(getDictionaries, (dictionaries: ProfileDictionaries) => {
    const dictionary = dictionaries[code];
    const mappedList: MappedBonusCard[] | undefined = list?.map((card) => ({
      ...card,
      label: dictionary?.find((entry) => entry.value === card.companyCode)
        ?.text,
    }));
    return mappedList;
  });

export type MappedBonusCard = BonusCardDetails & {
  label?: string | null;
};
export type MappedBonusCardList = {
  canAdd: boolean;
  airline?: MappedBonusCard[];
  car?: MappedBonusCard[];
  hotel?: MappedBonusCard[];
  all?: MappedBonusCard[];
};

export const getMappedBonusCards = createSelector(
  (state: RootState) => state,
  getBonusCards,
  getDictionaries,
  (state: RootState, bonusCards: BonusCardsData | undefined, _: any) => {
    const airline = getMappedBonusCardsByType(
      'frequentFlyerAirlines',
      bonusCards?.airlineCards
    )(state);
    const car = getMappedBonusCardsByType(
      'frequentFlyerCars',
      bonusCards?.carCards
    )(state);
    const hotel = getMappedBonusCardsByType(
      'frequentFlyerHotels',
      bonusCards?.hotelCards
    )(state);
    const concat = (...arrays: any) =>
      [].concat(...arrays.filter(Array.isArray));

    const mapped: MappedBonusCardList = {
      canAdd: bonusCards?.canAdd || false,
      airline,
      car,
      hotel,
      all: concat(airline, car, hotel),
    };
    return mapped;
  }
);

// SAS Travel Pass cards
export const getTravelPassRegular = createSelector(
  getProfile,
  (data: UserProfileResponse) => data.travelPassRegular
);
export const getTravelPassCorporate = createSelector(
  getProfile,
  (data: UserProfileResponse) => data.travelPassCorporate
);

export const getPhoneNumbers = createSelector(
  getProfile,
  (data: UserProfileResponse) => data.phoneNumbers ?? []
);

export const getEmails = createSelector(
  getProfile,
  (data: UserProfileResponse) => data.emails ?? []
);

export const getLac = createSelector(
  getProfile,
  (data: UserProfileResponse) => data.lac
);

export const getIsReadOnlyProfile = createSelector(
  getProfile,
  (data: UserProfileResponse) => (data.canEditCredentials ? false : true)
);
