import { CircularProgress, CircularProgressProps, SxProps } from '@mui/material';

export type ProgressProps = Pick<CircularProgressProps, 'color'> & {
  small?: boolean;
  sx?: SxProps;
};

export const Progress = ({ small, sx, color }: ProgressProps) => {
  const size = small ? 24 : 32;
  return <CircularProgress size={size} sx={sx} color={color} />;
};
