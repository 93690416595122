import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import GenericError from 'components/common/errors/GenericError';
import { Progress } from 'components/common';
import { TextCodes } from 'hooks/texts/text.codes';
import { TripCard } from 'components/features/my-trips/components/TripCard';
import { itineraryApi } from 'services/api/itinerary';
import { useAppSelector } from 'store/store';
import { Link } from 'react-router-dom';
import { getUpcomingTrips } from 'store/trips/trips.slice';

export const MyTrips = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [getTrips, { isFetching, isError }] =
    itineraryApi.useLazyItineraryListQuery();

  const trips = useAppSelector(getUpcomingTrips);

  useEffect(() => {
    getTrips({
      bookingStatus: 'Upcoming',
    });
  }, []);

  return (
    <Stack spacing={2} component='section'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant='body1' fontWeight={700}>
          {t(TextCodes.Dashboard.UpcomingTrips)}
        </Typography>
        {!!trips?.length && (
          <Link style={{ textDecoration: 'none' }} to='/orders?type=upcoming'>
            <Box
              sx={{
                display: 'flex',
                alignContent: 'center',
                color: palette.primary.main,
              }}
            >
              <Typography
                variant='button'
                fontWeight={500}
                sx={{ alignSelf: 'center', paddingRight: 1 }}
              >
                {t(TextCodes.WG6.Generic.SeeMore)}
              </Typography>
              <ArrowForwardIcon />
            </Box>
          </Link>
        )}
      </Box>
      {isFetching ? (
        <Stack>
          <Progress sx={{ alignSelf: 'center' }} />
        </Stack>
      ) : isError ? (
        <GenericError />
      ) : !!trips?.length ? (
        <Stack spacing={2}>
          {trips?.slice(0, 2)?.map((trip) => (
            <TripCard key={trip.orderNo} trip={trip} />
          ))}
        </Stack>
      ) : (
        <Typography variant='body2' color='text.secondary'>
          {t(TextCodes.WG6.LandingPage.NoUpcomingTrips)}
        </Typography>
      )}
    </Stack>
  );
};
