import React from 'react';
import { Text, TextProps } from '..';

export type CardTitleProps = Pick<TextProps, 'variant' | 'component'> & {
  children: React.ReactNode;
  bold?: boolean;
};

export const CardTitle = ({ children, variant = 'h6', component = 'h2', bold = true }: CardTitleProps) => {
  return (
    <Text variant={variant} component={component} bold={bold}>
      {children}
    </Text>
  );
};
