export const useDateFormatting = () => {
  const toShortDate = (dateString: string | null | undefined) => {
    if (typeof dateString !== 'string') {
      return '';
    }
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const monthText = month > 9 ? month.toString() : `0${month}`;
    return `${date.getDate()} / ${monthText} / ${date.getFullYear()}`;
  };
  return { toShortDate };
};

export const useTextFormatting = () => {
  const stripSpaces = (text: any) => (typeof text === 'string' ? text.replace(/\s+/g, '') : text);
  return { stripSpaces };
};
