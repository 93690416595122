import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import BankCardLogoIcon from 'utils/BankCardLogoIcon.util';
import { CreditCardTypeCardBrandId } from 'credit-card-type/dist/types';
import { convertFromM3Type, M3CardType } from 'utils/card.utils';
import usePayment from 'hooks/payment/usePayment';
import { isEmpty } from 'lodash';
import { PaymentOption } from 'store/payment/payment.slice';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface PaymentPreferencesProps {
  name: string;
  label: string;
  type: 'private' | 'business' | 'guarantee';
}

const PaymentPreferenceSelect = ({
  name,
  label,
  type,
}: PaymentPreferencesProps) => {
  // Hooks 🎣
  const { isLoadingPayment, invoices } = usePayment();
  const ref = useRef<HTMLSelectElement | null>(null);

  // Form 📋
  const { setValue, register, watch } = useFormContext();
  const registeredCards: PaymentOption[] = watch('cards');
  const newCards: PaymentOption[] = watch('newCards');
  const lodgeCards: PaymentOption[] = watch('lodgeCards');
  const value = watch(name);
  let options = [
    ...registeredCards,
    ...newCards.filter((card) => card.displayText!.length > 14),
  ];

  // Only business trips can select invoices.
  if (type === 'business') options = [...invoices, ...lodgeCards, ...options]; // we sort by invoices first.

  const handleChange = (e: SelectChangeEvent<string>) => {
    setValue(name, e.target.value, { shouldDirty: true });
  };

  useEffect(() => {
    if (isEmpty(value)) return;
    // If we deleted the selected card, we need to set this to default value
    if (
      isEmpty(
        options.find(
          (mthd) =>
            mthd.number === value ||
            mthd.displayText === value ||
            mthd.code === value
        )
      )
    )
      setValue(name, '', { shouldDirty: true });
  }, [registeredCards, newCards]);

  if (isLoadingPayment) return null;

  return (
    <FormControl fullWidth>
      <InputLabel id={name} htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        {...register(name)}
        SelectDisplayProps={{
          style: { display: 'flex', alignItems: 'center' },
        }}
        MenuProps={{
          PaperProps: {
            style: { maxWidth: ref?.current?.clientWidth },
          },
          MenuListProps: {
            style: { padding: 0 },
          },
        }}
        inputProps={{
          id: name,
        }}
        labelId={name}
        label={label}
        ref={ref}
        // Due to backend logic, the value can be the number, displayText or code.
        value={
          options.find((mthd) => mthd.number === value)?.number ||
          options.find((mthd) => mthd.displayText === value)?.displayText ||
          options.find((mthd) => mthd.code === value)?.code ||
          ''
        }
        onChange={handleChange}
        size='small'
      >
        {options.map((mthd, i) => (
          <MenuItem
            key={i}
            // For cards the value can either be the displayText or number. If the card is one the user is currently adding, the value will be the displayText.
            value={
              mthd.type === 'invoice'
                ? mthd.code!
                : isEmpty(mthd.number)
                ? mthd.displayText!
                : mthd.number!
            }
            sx={{
              display: 'flex',
              whiteSpace: 'break-spaces',
            }}
          >
            {mthd.type === 'creditCard' && (
              <Box sx={{ mb: 'auto', mt: 0.75 }}>
                <BankCardLogoIcon
                  type={
                    convertFromM3Type(
                      mthd?.code as M3CardType
                    ) as CreditCardTypeCardBrandId
                  }
                />
              </Box>
            )}
            <Typography
              variant='body1'
              paddingLeft={mthd.code ? 1 : 0}
              sx={{ overflow: 'hidden' }}
            >
              {mthd.displayText}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default PaymentPreferenceSelect;
