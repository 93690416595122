import { useLocation } from 'react-router-dom';

import './routes.css';
import { RouteList } from './components/RouteList';
import { RouteTitle } from './components/RouteTitle';

// TODO:Remove this component completely 📋

export const Routes = () => {
  const location = useLocation();

  return (
    <>
      <RouteTitle />
      <RouteList location={location} />
    </>
  );
};
