import { Box, Container, Grid, Link, Typography } from '@mui/material';
import StandardHeader from 'components/common/headers/StandardHeader';
import { useTranslation } from 'react-i18next';
import { ForgotPasswordForm } from 'components/features/forgot-password/ForgotPasswordForm';
import { TextCodes } from 'hooks';

export const ForgotPasswordPage = () => {
  return (
    <>
      <StandardHeader showBreadcrumbs={false} />
      <Grid
        component={Container}
        container
        flexDirection='column'
        gap={3}
        sx={{ mt: 2 }}
        aria-live='polite'
      >
        <ForgotPasswordForm />
        <ForgotPasswordFooter />
      </Grid>
    </>
  );
};

export const ForgotPasswordFooter = () => {
  const { t } = useTranslation();

  return (
    <Grid justifyContent='center' container gap={1}>
      <Typography variant='body2'>
        {t(TextCodes.WG6.Support.NeedHelp)}
      </Typography>
      <Box display='flex' gap={1}>
        <Typography variant='body2'>
          {t(TextCodes.WG6.Support.EmailTo)}
        </Typography>
        <Typography
          variant='body2'
          component={Link}
          href='mailto:bestilling@berg-hansen.no'
        >
          bestilling@berg-hansen.no
        </Typography>
      </Box>
      <Box display='flex' gap={1}>
        <Typography variant='body2'>
          {t(TextCodes.WG6.Support.CallUs)}
        </Typography>
        <Typography
          variant='body2'
          component={Link}
          href='tel:(+47 22 0) 08050'
        >
          (+47 22 0) 08050
        </Typography>
      </Box>
    </Grid>
  );
};
