import { useEffect } from 'react';
import { addEventListeners, removeEventListeners } from 'utils/listeners.utils';

/**
 * Reusable event listener hook that automatically cleans up the listeners when
 * the component is unmounted.
 * @param {string} events - The events to listen to.
 * @param {function} handler - the handler to call when the events are fired.
 * @param {Element} element - element to attach the event to.
 *
 * @example
 * const [ref, setRef] = useState<HTMLInputElement>();
 * useEventListener("click", (e: KeyboardEvent) => {
 * console.log("you clicked", ref)
 * }, ref);
 *
 * return (
 *  <TextField
 *      inputRef={setRef}
 *      // Other props
 *  />
 * );
 */
const useEventListener = (
  events: string | string[],
  handler: any,
  element?: Element | Document | Window | null
) => {
  useEffect(() => {
    if (element) {
      addEventListeners(events, handler, element);
      return () => {
        removeEventListeners(events, handler, element);
      };
    }
  }, [events, handler, element]);
};

export default useEventListener;
