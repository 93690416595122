import { Box } from '@mui/system';
import { Card, Divider } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import RadioList from 'components/common/inputs/RadioList';
import TextInput from 'components/common/inputs/textInput/TextInput';
import { BookingTabs } from 'components/features/booking/common/BookingTabs';
import { DictionaryItem } from 'services/api/user';
import { TextCodes } from 'hooks';

// TODO - this is a placeholder only
export const TripSearch = () => {
  const formMethods = useForm();
  const { t } = useTranslation();

  const tripOptions: DictionaryItem[] = [
    { text: t(TextCodes.WG6.Booking.ReturnTrip), value: 'returnTrip' },
    { text: t(TextCodes.WG6.Booking.SingleTrip), value: 'singleTrip' },
  ];

  return (
    <Card>
      <FormProvider {...formMethods}>
        <BookingTabs />
        <Divider />
        <Box sx={{ p: 2 }}>
          <RadioList
            sx={{ mb: 2 }}
            name='tripSearchMode'
            row
            options={tripOptions}
          />
          <TextInput
            name='startLocation'
            label={t(TextCodes.WG6.Booking.StartLocation)}
            type='text'
            fullWidth
          />
          <TextInput
            sx={{ mb: 0 }}
            name='endLocation'
            label={t(TextCodes.WG6.Booking.EndLocation)}
            type='text'
            fullWidth
          />
        </Box>
      </FormProvider>
    </Card>
  );
};
