import jwtDecode from 'jwt-decode';

import useSiteNavigation from 'hooks/navigation/useSiteNavigation';
import {
  LoginArgs,
  LoginRequest,
  LoginResult,
  authApi,
} from 'services/api/authentication';
import { resetApp } from 'store/app/app.slice';
import { resetOrderDetails } from 'store/orders/orderDetails.slice';
import { resetToast } from 'store/toast/toast.slice';
import { resetTrips } from 'store/trips/trips.slice';
import { resetUser } from 'store/user/user.slice';
import { resetPayments } from 'store/payment/payment.slice';
import { setToken, setTac, resetAuth } from 'store/auth/auth.slice';
import { useAppDispatch } from 'store/store';

// LOGIN
// Hook to send API request and retrieve login token
export const useLoginAction = () => {
  const { setTokenValue } = useLoginToken();

  const onSuccess = (response: LoginResult) => {
    if (response?.isSuccess && response?.value?.accessToken) {
      setTokenValue(response?.value?.accessToken);
    }
  };

  const [loginCallExecute, status] = authApi.useLoginMutation();

  const execute = (loginRequest: LoginRequest) => {
    const request: LoginArgs = {
      loginRequest,
    };
    loginCallExecute(request).unwrap().then(onSuccess);
  };

  return { execute, ...status };
};

// LOGIN TOKEN
// Hook to get or set login token
export const useLoginToken = () => {
  const storedTokenValue = window.localStorage.getItem('token');
  const dispatch = useAppDispatch();

  const setTokenValue = (newTokenValue: string) => {
    window.localStorage.setItem('token', newTokenValue);
    dispatch(setToken(newTokenValue));
    if (newTokenValue) {
      const tokenData: TokenData = jwtDecode(newTokenValue);
      dispatch(setTac(tokenData.Tac));
    }
  };

  return { tokenValue: storedTokenValue ?? '', setTokenValue };
};

// AUTHORIZATION
// Hook used to determine authorized state
// Check if user has a non-expired token
type TokenData = {
  Tac: string;
  exp: number;
  iat: number;
  nbf: number;
};

export const useAuthorization = () => {
  const { tokenValue } = useLoginToken();
  let isAuthorized = false;
  try {
    const tokenData: TokenData = jwtDecode(tokenValue);
    const isExpired = tokenData?.exp * 1000 < Date.now();
    if (tokenValue && !isExpired && tokenData?.Tac) {
      isAuthorized = true;
    }
  } catch {}

  return { isAuthorized };
};

export const useLogoutAction = () => {
  const { setTokenValue } = useLoginToken();
  const navigate = useSiteNavigation();
  const dispatch = useAppDispatch();

  const logout = () => {
    // Reset all the states
    dispatch(resetAuth());
    dispatch(resetUser());
    dispatch(resetApp());
    dispatch(resetToast());
    dispatch(resetTrips());
    dispatch(resetOrderDetails());
    dispatch(resetPayments());

    setTokenValue('');

    navigate('/', { replace: true });
  };
  return { logout };
};
