import { Components, createTheme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { pxToRem } from 'theme/theme.utils';

const muiStandardTheme = createTheme();
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body2link: true;
    buttonMedium: true;
    buttonLarge: true;
  }
}

export const typographyStyles: Components = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h2',
        subtitle2: 'h2',
        body1: 'span',
        body2: 'span',
        body2link: 'a',
        buttonMedium: 'span',
        buttonLarge: 'span',
      },
    },
    styleOverrides: {
      root: {
        fontFamily: '"Inter", "Roboto","Helvetica","Arial",sans-serif',
        letterSpacing: pxToRem[0.15],
      },
      button: {
        textTransform: 'unset',
        fontSize: pxToRem[13],
        letterSpacing: pxToRem[0.15],
      },
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        fontWeight: 500,
        fontSize: pxToRem[16],
        lineHeight: 1.5,
        letterSpacing: pxToRem[0.15],
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      label: {
        fontSize: pxToRem[13],
        lineHeight: '18px',
        letterSpacing: pxToRem[0.15],
        fontWeight: 400,
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        fontSize: pxToRem[14],
        fontWeight: 400,
        letterSpacing: '0.15px',
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        fontSize: pxToRem[14],
        fontFamily: '"Inter", "Roboto","Helvetica","Arial",sans-serif',
        lineHeight: 1.3,
        letterSpacing: pxToRem[0.15],
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontWeight: 400,
        fontSize: pxToRem[12],
        lineHeight: 1.43,
        letterSpacing: pxToRem[0.15],
      },
    },
  },
};

interface TypographyAdditionalOptions extends TypographyOptions {
  body2link?: React.CSSProperties;
  buttonLarge?: React.CSSProperties;
  buttonMedium?: React.CSSProperties;
}

export const typography: TypographyAdditionalOptions = {
  h1: {
    fontSize: pxToRem[30],
    fontWeight: 600,
    lineHeight: 1.1,
    letterSpacing: pxToRem[0.15],
    [muiStandardTheme.breakpoints.up('md')]: {
      fontSize: pxToRem[35],
    },
  },
  h2: {
    fontSize: pxToRem[25],
    fontWeight: 600,
    lineHeight: 1.3,
    letterSpacing: pxToRem[0.15],
    [muiStandardTheme.breakpoints.up('md')]: {
      fontSize: pxToRem[30],
    },
  },
  h3: {
    fontSize: pxToRem[25],
    lineHeight: 1.3,
    letterSpacing: pxToRem[0.15],
    fontWeight: 600,
  },
  h4: {
    fontSize: pxToRem[20],
    lineHeight: 1.3,
    fontWeight: 700,
    letterSpacing: pxToRem[0.15],
  },
  h5: {
    fontSize: pxToRem[18],
    lineHeight: 1.3,
    letterSpacing: pxToRem[0.15],
    fontWeight: 700,
  },
  h6: {
    fontSize: pxToRem[18],
    lineHeight: 1.1,
    letterSpacing: pxToRem[0.15],
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: pxToRem[16],
    lineHeight: 1.1,
    letterSpacing: pxToRem[0.15],
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  subtitle2: {
    fontSize: pxToRem[14],
    lineHeight: 1.1,
    letterSpacing: pxToRem[0.15],
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  body1: {
    fontSize: pxToRem[16],
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: pxToRem[0.15],
  },
  body2: {
    fontSize: pxToRem[14],
    fontWeight: 400,
    lineHeight: 1.3,
    letterSpacing: pxToRem[0.15],
  },
  body2link: {
    fontSize: pxToRem[14],
    fontWeight: 500,
    lineHeight: 1.3,
    letterSpacing: pxToRem[0.15],
  },
  buttonMedium: {
    fontSize: pxToRem[14],
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: pxToRem[0.15],
  },
  buttonLarge: {
    fontSize: pxToRem[16],
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: pxToRem[0.15],
  },
  caption: {
    fontSize: pxToRem[12],
    lineHeight: 1.2,
    letterSpacing: pxToRem[0.15],
    fontWeight: 400,
  },
  overline: {
    fontSize: pxToRem[12],
    fontWeight: 500,
    lineHeight: '120%',
    textTransform: 'none',
    letterSpacing: pxToRem[0.15],
  },
};

// https://mui.com/material-ui/customization/theme-components/
// https://mui.com/material-ui/customization/theme-components/#default-props
// https://mui.com/material-ui/customization/theme-components/#global-style-overrides
