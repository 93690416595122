import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import TextBlock from 'components/common/text/TextBlock';
import { TextCodes } from 'hooks';

const NotRegistered = ({
  title,
  removeDivider = false,
  textBlock = false,
}: {
  title?: string;
  removeDivider?: boolean;
  textBlock?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {textBlock ? (
        <TextBlock removeDivider={removeDivider} title={title}>
          <Typography variant='body1' color='text.secondary'>
            {t(TextCodes.WG6.Base.NotRegistered)}
          </Typography>
        </TextBlock>
      ) : (
        <Typography variant='body1' color='text.secondary'>
          {t(TextCodes.WG6.Base.NotRegistered)}
        </Typography>
      )}
    </>
  );
};

export default NotRegistered;
