import { Box, Card, CardContent, CardProps, Typography } from '@mui/material';
import { LinkExternal } from 'components/common/links/LinkExternal';
import { LinkInternal } from 'components/common/links/LinkInternal';
import { RouteId } from 'routes/config/route.config';

export type LinkCardProps = Pick<CardProps, 'title' | 'children'> & {
  routeId?: RouteId;
  externalLink?: string;
  backgroundIcon?: React.ReactElement;
};

export const LinkCard = ({
  title,
  children,
  backgroundIcon,
  routeId,
  externalLink = '',
}: LinkCardProps) => {
  const minHeight = backgroundIcon ? '130px' : '';
  const content = (
    <Card sx={{ mb: 2, minHeight, position: 'relative' }}>
      {backgroundIcon && (
        <Box sx={{ position: 'absolute', right: 0, top: 6 }}>
          {backgroundIcon}
        </Box>
      )}
      <CardContent
        sx={{ pt: 2, width: '100%', '&:last-child': { paddingBottom: 2 } }}
      >
        <Typography
          variant='body1'
          fontWeight='bold'
          component='h3'
          sx={{ mb: 1 }}
        >
          {title}
        </Typography>
        <Typography variant='body2'>{children}</Typography>
      </CardContent>
    </Card>
  );
  const linkSx = { textDecoration: 'none' };
  return routeId ? (
    <LinkInternal routeId={routeId} sx={linkSx}>
      {content}
    </LinkInternal>
  ) : (
    <LinkExternal href={externalLink} sx={linkSx}>
      {content}
    </LinkExternal>
  );
};
