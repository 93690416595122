import CircleIcon from '@mui/icons-material/Circle';
import FlightIcon from '@mui/icons-material/Flight';
import HotelIcon from '@mui/icons-material/Hotel';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TrainIcon from '@mui/icons-material/Train';
import {
  Box,
  Typography,
  Stack,
  useTheme,
  List,
  ListItem,
} from '@mui/material';
import { routeConfig } from 'routes/config/route.config';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DropdownMenu, {
  DropdownMenuOptions,
} from 'components/common/dropdownMenu/DropdownMenu';
import HeaderCard, {
  HeaderCardReference,
} from 'components/common/containers/HeaderCard';
import useOrder from 'hooks/orders/useOrder';
import { ItinerariesListTravellerResponse } from 'services/api/itinerary';
import { TextCodes } from 'hooks';
import { bookingCardsDateFormat } from 'services/text/datetime-format';
import { getTac } from 'store/auth/auth.slice';
import { useAppSelector } from 'store/store';

interface Props {
  reference?: string;
  title: string;
  departureDate: string;
  travellers: ItinerariesListTravellerResponse[];
  containsAir: boolean;
  containsTrain: boolean;
  containsHotel: boolean;
  containsCar: boolean;
  bookingStatus: string;
  orderNumber: string;
}

const OrderCard = ({
  containsHotel,
  containsAir,
  containsTrain,
  containsCar,
  reference,
  title,
  departureDate,
  travellers,
  bookingStatus,
  orderNumber,
}: Props) => {
  // Selectors
  const tac = useAppSelector(getTac);

  // Hooks
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showReceipt, handleSetOrderReceipt } = useOrder();

  // Memoized options for performance
  const menuOptions = useMemo(() => {
    const options: DropdownMenuOptions[] = [
      {
        label: t(TextCodes.WG6.Orders.SendTravelDetailsSms),
        action: async () => {
          handleSetOrderReceipt(orderNumber, 'sms');
        },
      },
      {
        label: t(TextCodes.WG6.Orders.SendTravelDetailsEmail),
        action: async () => {
          handleSetOrderReceipt(orderNumber, 'email');
        },
      },
      {
        label: t(TextCodes.WG6.Orders.ShowReceipt),
        action: async () => {
          await showReceipt(orderNumber);
        },
        disabled: travellers.find((t) => t.tacNumber === tac && t.hasReceipt)
          ? false
          : true,
      },
    ];
    return options;
  }, []);

  const handleClick = () => {
    if (bookingStatus === 'cancelled') return;

    navigate(`${routeConfig.OrderDetails.path}?orderNumber=${orderNumber}`);
  };

  const formattedTravellers = travellers.map(
    (t) => `${t.firstName!!.toLowerCase()} ${t.lastName!!.toLowerCase()}`
  );

  return (
    <>
      <HeaderCard headerContent={<HeaderCardReference reference={reference} />}>
        <Stack
          aria-label='click to go to order'
          onClick={handleClick}
          sx={{
            p: 2,
            pb: 2,
            backgroundColor:
              bookingStatus === 'cancelled'
                ? theme.palette.divider
                : theme.palette.background.paper,
          }}
        >
          <Box
            display='flex'
            justifyContent='space-between'
            sx={{
              flexWrap: 'nowrap',
              width: '100%',
            }}
          >
            <Box>
              {containsAir && <FlightIcon />}
              {containsTrain && <TrainIcon />}
              {containsHotel && <HotelIcon />}
              {containsCar && <DirectionsCarIcon />}
            </Box>
            <DropdownMenu
              label={`${t(TextCodes.WG6.Orders.OrderCardMenuFor)} ${reference}`}
              options={menuOptions}
              id={reference!!}
            />
          </Box>
          <Box>
            <Typography
              sx={{ hyphens: 'auto', wordBreak: 'break-word' }}
              variant='body1'
              fontWeight='bold'
            >
              {title}
            </Typography>
          </Box>
          <Box sx={{ pb: 1.75 }}>
            <Typography variant='body2'>
              {bookingCardsDateFormat(departureDate)}
            </Typography>
          </Box>
          {formattedTravellers && (
            <List
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                backgroundColor: theme.palette.backgroundColors?.selectedCard,
                px: 1,
                borderRadius: 1,
                rowGap: 1,
              }}
            >
              {formattedTravellers.map((traveller, i) => (
                <ListItem
                  key={traveller}
                  disablePadding
                  sx={{ width: 'initial' }}
                >
                  <Typography
                    variant='caption'
                    sx={{ textTransform: 'capitalize' }}
                    key={i}
                  >
                    {traveller}
                  </Typography>
                  {i !== travellers.length - 1 && (
                    <CircleIcon
                      sx={{
                        fill: theme.palette.text.secondary,
                        width: 7,
                        height: 7,
                        mx: 1,
                      }}
                    />
                  )}
                </ListItem>
              ))}
            </List>
          )}
        </Stack>
      </HeaderCard>
    </>
  );
};

export default OrderCard;
