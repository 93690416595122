import * as Yup from 'yup';
import { Grid, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import TravelPassCorporate from 'components/features/user/benefitCardsAndPreferences/SasTravelPass/TravelPassCorporate';
import TravelPassProducts from 'components/features/user/benefitCardsAndPreferences/SasTravelPass/TravelPassProducts';
import useSiteNavigation from 'hooks/navigation/useSiteNavigation';
import { TextCodes } from 'hooks';
import { TravelPass, userApi } from 'services/api/user';
import { getTravelPassCorporate } from 'store/user/user.slice';
import { routeConfig } from 'routes/config/route.config';
import { useAppSelector } from 'store/store';
import FormPanel from 'components/common/forms/FormPanel';

interface FormField {
  number: string;
}

const SasTravelPassForm = () => {
  // Global store
  const travelPass = useAppSelector(getTravelPassCorporate);

  // Hooks
  const { t } = useTranslation();
  const navigate = useSiteNavigation();

  // API
  const [saveSasTravelPass, { isLoading, data }] =
    userApi.useUserTravelPassCorporateSaveMutation();

  // Form methods
  const formMethods = useForm<FormField>({
    defaultValues: { number: travelPass?.number || '' },
    resolver: yupResolver(
      Yup.object().shape({
        number: Yup.number()
          .required(TextCodes.WG6.Validation.Required)
          .typeError(TextCodes.WG6.Validation.NumbersOnly)
          .test(
            'number',
            TextCodes.WG6.SasTravel.CorporateHelperText,
            (val) => val?.toString().length === 9 && typeof val === 'number'
          ),
      })
    ),
  });

  // Submit
  const onSubmit = (data: FormField) => {
    saveSasTravelPass({
      travelPassCorporateUpdateModel: { number: data.number.toString() },
    });
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate(routeConfig.BenefitCardsAndPreferences.path, {
        replace: true,
        scrollToTop: true,
        toast: {
          severity: 'success',
          autoHideDuration: 3000,
          message: t(TextCodes.WG6.Status.ChangesSavedSuccessfully),
        },
      });
    }
  }, [data]);

  return (
    <FormProvider {...formMethods}>
      <form>
        <Grid sx={{ pt: 1 }}>
          <Stack spacing={2} sx={{ mb: 2 }}>
            <TravelPassCorporate />
            <TravelPassProducts travelPass={travelPass as TravelPass} />
          </Stack>
          <FormPanel
            isLoading={isLoading}
            errorMessage={data?.message || ''}
            shouldShowCancelWarning={formMethods.formState.isDirty}
            onSubmit={formMethods.handleSubmit(onSubmit)}
          />
        </Grid>
      </form>
    </FormProvider>
  );
};

export default SasTravelPassForm;
