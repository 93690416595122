// import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import GenderInput from 'components/features/dropdowns/GenderInput';
import TextInput from 'components/common/inputs/textInput/TextInput';
import { BasicProfile } from 'components/features/user/personalInformation/profile.types';
import { TextCodes, useToast } from 'hooks';
import { getProfile } from 'store/user/user.slice';
import { routeConfig } from 'routes/config/route.config';
import { useAppSelector } from 'store/store';
import { userApi } from 'services/api/user';
import DateField from 'components/common/inputs/date-field/DateField';
import dayjs from 'dayjs';
import Yup from 'components/validators/yup';
import FormPanel from 'components/common/forms/FormPanel';

const EditBasicInformationForm = () => {
  // Global state
  const profile = useAppSelector(getProfile);

  // Hooks/Variables
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toast } = useToast();

  // API
  const [updateProfile, { isLoading, data }] =
    userApi.useUserPersonalDataSaveMutation();

  // Form methods
  const formMethods = useForm<BasicProfile>({
    resolver: yupResolver(
      Yup.object().shape({
        dateOfBirth: Yup.string().required('Pepe').minAge(2),
        gender: Yup.string().required(TextCodes.WG6.Validation.Required),
      })
    ),
    defaultValues: {
      name: `${profile?.firstName} ${profile?.lastName}`,
      dateOfBirth: profile?.dateOfBirth ?? undefined,
      gender: profile?.gender ?? undefined,
    },
  });

  // Success handler
  useEffect(() => {
    if (data?.isSuccess) {
      navigate(routeConfig.PersonalInformation.path);
      toast({
        severity: 'success',
        autoHideDuration: 3000,
        message: t(TextCodes.WG6.Status.ChangesSavedSuccessfully),
      });
    }

    if (!data?.isSuccess && data?.errorCode === '1') {
      formMethods.setError('username', {});
    }
  }, [data]);

  const onSubmit = (formData: BasicProfile) => {
    const withUsername = { ...formData, userName: profile?.userName };
    updateProfile({ saveUserPersonalDataDto: withUsername });
  };

  return (
    <FormProvider {...formMethods}>
      <form>
        <TextInput
          name='name'
          label={t(TextCodes.WG6.Profile.Name)}
          fullWidth
          disabled
        />
        <GenderInput
          name='gender'
          label={t(TextCodes.WG6.Profile.Gender)}
          size='small'
          fullWidth
        />
        <DateField
          name='dateOfBirth'
          label={t(TextCodes.WG6.Profile.BirthDate)}
          maxDate={dayjs().subtract(2, 'year')}
        />
        <FormPanel
          errorMessage={data?.message || ''}
          shouldShowCancelWarning={formMethods.formState.isDirty}
          isLoading={isLoading}
          onSubmit={formMethods.handleSubmit(onSubmit)}
        />
      </form>
    </FormProvider>
  );
};

export default EditBasicInformationForm;
