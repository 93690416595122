import dayjs from 'dayjs';
import { TextCodes } from 'hooks/texts/text.codes';
import * as yup from 'yup';

export default function minAge(minAge: number, message?: string) {
  const errorMessage = message ? message : TextCodes.WG6.Profile.MinAge;
  return yup.string().test('minAge', errorMessage, function (value) {
    // We do not validate "required".
    if (!value) return true;

    if (!dayjs(value).isValid()) return false;
    return dayjs().diff(value, 'years') >= minAge;
  });
}
