import ArrowForward from '@mui/icons-material/ArrowForward';
import Box from '@mui/system/Box';
import Circle from '@mui/icons-material/Circle';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { TextCodes } from 'hooks';
import { AncillaryProps } from 'components/common/flight/FlightDetails';

interface AncillariesProps {
  ancillary: AncillaryProps;
}

const Ancillary = ({ ancillary }: AncillariesProps) => {
  // Hooks
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        variant='body2'
        fontWeight='bold'
        component='p'
        sx={{ mb: 0.5 }}
      >
        {t(TextCodes.WG6.Base.SelectedAncillaries)}
      </Typography>
      <Typography
        variant='caption'
        fontWeight='bold'
        sx={{
          display: 'flex',
          alignItem: 'center',
          mb: 1,
        }}
      >
        {ancillary?.departure}
        <ArrowForward
          sx={{ fontSize: '14px' }}
          aria-label={t(TextCodes.WG6.Base.To)}
        />
        {ancillary?.destination}
      </Typography>
      <Stack component='ul' spacing={1}>
        {ancillary?.list?.map((li, index) => {
          const extra = li.toLowerCase();

          return (
            <ListItem key={`${li}-${index}`} sx={{ p: 0 }}>
              <Circle
                sx={{
                  fontSize: '6px',
                  mr: 2,
                  ml: 1,
                  '.&::first-letter': {
                    textTransform: 'uppercase',
                  },
                }}
              />
              <Typography variant='body2'>
                {extra[0].toUpperCase() + extra.substring(1)}
              </Typography>
            </ListItem>
          );
        })}
      </Stack>
    </Box>
  );
};

export default Ancillary;
