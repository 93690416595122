import dayjs from 'dayjs';
import { TextCodes } from 'hooks/texts/text.codes';
import * as yup from 'yup';

export default function disallowFutureDate(message?: string) {
  const errorMessage = message
    ? message
    : TextCodes.WG6.Validation.DateCannotBeInFuture;

  return yup
    .string()
    .test('disallowFutureDate', errorMessage, function (value, context) {
      // We do not validate "required".
      if (!value) return true;

      const date = dayjs(value);

      if (!date.isValid())
        return context.createError({
          path: context.path,
          message: TextCodes.WG6.Validation.InvalidDate,
        });

      return dayjs().isAfter(date);
    });
}
