import { TextCodes } from 'hooks/texts/text.codes';
import { isEmpty } from 'lodash';
import * as yup from 'yup';

export default function allowEmpty(exceptions?: string[]) {
  return yup
    .object()
    .test(
      'allowEmpty',
      TextCodes.WG6.Validation.Required,
      function (object, context) {
        let hasNonEmptyFields = false;
        let hasEmptyFields = false;

        for (const [key, val] of Object.entries(object)) {
          // If a key is in the exceptions array, we pretend it doesn't exist.
          if (exceptions && exceptions.includes(key)) continue;

          if (hasNonEmptyFields && hasEmptyFields) break;

          if (!isEmpty(val) && !hasNonEmptyFields) hasNonEmptyFields = true;
          else if (isEmpty(val) && !hasEmptyFields) hasEmptyFields = true;
        }

        if (hasNonEmptyFields && hasEmptyFields) {
          let errors: yup.ValidationError[] = [];
          // We manually create an error for each empty field.
          for (const [name, val] of Object.entries(object)) {
            if (isEmpty(val) && !exceptions?.includes(name)) {
              errors.push(
                context.createError({
                  path: `${context.path}.${name}`,
                  message: TextCodes.WG6.Validation.Required,
                })
              );
            }
          }
          return new yup.ValidationError(errors);
        }
        return true;
      }
    );
}
