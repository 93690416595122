import { createTheme, Palette } from '@mui/material/styles';
import { ThemeConfig, ThemeIds } from 'theme/theme.types';
import { buttonStyles } from 'components/common/buttons/Button.styles';
import {
  darkColorPalette,
  lightColorPalette,
} from 'theme/colorPalette/colorPalette';
import { fabStyles } from 'components/common/buttons/fab/Fab.styles';
import { getHeaderStyles } from 'components/features/header/Header.styles';
import { tabStyles } from 'components/common/tabs/Tabs.styles';
import { lightAccordionStyles } from 'components/common/accordions/accordions.styles';
import { getTextInputStyles } from 'components/common/inputs/textInput/TextInput.styles';
import { getLinkStyles } from 'components/common/links/Link.styles';
import { iconButtonStyles } from 'components/common/buttons/iconButton/IconButton.styles';
import {
  typography,
  typographyStyles,
} from 'theme/typography/typography.styles';
import { timelineStyles } from 'components/common/timeline/timeline.styles';
import { skeletonStyles } from 'components/common/skeleton/skeleton.styles';
import { dropdownStyles } from 'components/common/dropdown/dropdown.styles';

export const themeBase = createTheme();

const globalStyles = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        hyphens: 'auto',
      },
    },
  },
};

export const themeLight = createTheme({
  palette: {
    ...lightColorPalette,
  },
  typography: {
    ...typography,
  },
  components: {
    ...globalStyles,
    ...buttonStyles,
    ...fabStyles,
    ...getHeaderStyles(lightColorPalette as Palette),
    ...getLinkStyles(lightColorPalette as Palette),
    ...getTextInputStyles(lightColorPalette as Palette),
    ...iconButtonStyles,
    ...tabStyles,
    ...typographyStyles,
    ...lightAccordionStyles,
    ...timelineStyles(lightColorPalette as Palette),
    ...skeletonStyles(lightColorPalette as Palette),
    ...dropdownStyles(lightColorPalette as Palette),
  },
});

export const themeDark = createTheme({
  palette: {
    ...darkColorPalette,
  },
  typography: {
    ...typography,
  },
  components: {
    ...globalStyles,
    ...buttonStyles,
    ...fabStyles,
    ...getHeaderStyles(darkColorPalette as Palette),
    ...getLinkStyles(darkColorPalette as Palette),
    ...getTextInputStyles(darkColorPalette as Palette),
    ...iconButtonStyles,
    ...tabStyles,
    ...typographyStyles,
    ...lightAccordionStyles,
    ...timelineStyles(darkColorPalette as Palette),
    ...skeletonStyles(darkColorPalette as Palette),
    ...dropdownStyles(darkColorPalette as Palette),
  },
});

const defaultLight = createTheme();
const defaultDark = createTheme({
  palette: {
    mode: 'dark',
  },
});

export const themes: ThemeConfig[] = [
  { value: ThemeIds.Light, title: 'Light theme', theme: themeLight },
  { value: ThemeIds.Dark, title: 'Dark theme', theme: themeDark },
  {
    value: ThemeIds.DefaultLight,
    title: 'Default MUI light',
    theme: defaultLight,
  },
  {
    value: ThemeIds.DefaultDark,
    title: 'Default MUI dark',
    theme: defaultDark,
  },
];

/* 
  TODO: Implement styling on these components in Milestone 1:
  [X] Button
  [X] Icon Button
  [X] Floating Action Button
  [X] Tabs
  [X] Checkbox
  [X] Radio
  [ ] Switch
  [ ] Dialog/Modal
  [ ] Slider
  [ ] Chips
  [ ] Breadcrumbs
  [ ] Date/Time
  [ ] Alert
  [ ] Avatar
  [ ] Divider
  [ ] Tooltip
  [ ] Timeline 
  [ ] Progress 
  [ ] Skeleton
  [ ] snackbar
  [ ] List
  [ ] Menu
  [ ] Autocomplete
  [X] input elements
  [ ] select - outlined
  [ ] Highlighted text

  NOT NEEDED THIS MILESTONE:
  - Rating
  - Bottom Navigation
  - Stepper
  - Table 
  - Tree view
  - 
*/
