import { Fab, useTheme } from '@mui/material';
import { useScrollToTop } from 'hooks';
import useEventListener from 'hooks/event/useEventListener';
import { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollToTopFab = () => {
  const theme = useTheme();
  const [visible, setVisible] = useState<boolean>(false);
  const scrollToTop = useScrollToTop();

  useEventListener(
    'scroll',
    () => {
      if (window) {
        const viewportHeight = window.innerHeight;
        const windowOffset = window.scrollY;
        if (viewportHeight * 2 < windowOffset && !visible) {
          setVisible(true);
        } else if (viewportHeight * 2 > windowOffset && visible) {
          setVisible(false);
        }
      }
    },
    window
  );

  if (!visible) return null;

  return (
    <Fab
      color='primary'
      onClick={scrollToTop}
      sx={{ position: 'fixed', bottom: '1.5rem', right: '1rem' }}
    >
      <KeyboardArrowUpIcon
        sx={{ fill: theme.palette.primary.contrastText }}
        fontSize='large'
      />
    </Fab>
  );
};

export default ScrollToTopFab;
