import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { TextCodes } from 'hooks';
import { getReferences } from 'store/user/user.slice';
import { useAppSelector } from 'store/store';

const OrderDetailsCustomerReferenceAccordion = () => {
  // Hooks
  const theme = useTheme();
  const { t } = useTranslation();

  // Selectors
  const clientReferences = useAppSelector(getReferences);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon sx={{ fill: theme.palette.primary.main }} />
        }
      >
        <Box>
          <Typography
            variant='body1'
            fontWeight='bold'
            sx={{ color: theme.palette.primary.main }}
          >
            {t(TextCodes.WG6.Generic.CustomerReference)}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ minHeight: '24.375rem' }}>
        <Grid container gap={2} component={Card} sx={{ p: 2 }}>
          {clientReferences?.map((reference, index) => (
            <Grid
              key={`${reference?.value}-${index}`}
              container
              flexDirection='column'
            >
              <Typography variant='caption'>{reference?.label}</Typography>
              <Typography variant='body2'>{reference?.value}</Typography>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderDetailsCustomerReferenceAccordion;
