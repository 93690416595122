import { Box, Button, useTheme } from '@mui/material';
import Flexbox from 'components/layouts/Flexbox';
import { Add } from '@mui/icons-material';

// Both these buttons are disabled in Sprint 1

const OrderDetailsBottomMenu = () => {
  const theme = useTheme();
  return (
    <Flexbox
      alignItems='center'
      sx={{
        backgroundColor: theme.palette.background.paper,
        position: 'fixed',
        bottom: 0,
        px: 2,
        height: 72,
        width: '100%',
      }}
    >
      <Box sx={{ ml: 'auto' }}>
        <Button disabled size='large' startIcon={<Add />}>
          Legg til
        </Button>
      </Box>
    </Flexbox>
  );
};

export default OrderDetailsBottomMenu;
