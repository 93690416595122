import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EditTravelArrangersForm from 'components/features/user/personalInformation/travelArrangers/EditTravelArrangersForm';
import InfoButtonWithDialog from 'components/common/modals/InfoButtonWithDialog';
import PageTitle from 'components/common/pageTitle/PageTitle';
import StandardHeader from 'components/common/headers/StandardHeader';
import { Card } from 'components/common';
import { TextCodes } from 'hooks';

const EditTravelArrangersPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <StandardHeader showBreadcrumbs={false} />
      <Container>
        <PageTitle
          sx={{ paddingInline: 0, mt: 7 }}
          title={t(TextCodes.WG6.TravelArrangers.Title)}
          button={
            <InfoButtonWithDialog
              popupText={t(TextCodes.WG6.TravelArrangers.Popup)}
              ariaLabel={t(TextCodes.WG6.TravelArrangers.InfoButtonAriaLabel)}
            />
          }
        />
        <Card aria-live='polite'>
          <EditTravelArrangersForm />
        </Card>
      </Container>
    </>
  );
};

export default EditTravelArrangersPage;
