import CircleIcon from '@mui/icons-material/Circle';
import Grid from '@mui/material/Grid';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Typography from '@mui/material/Typography';
import { TextCodes } from 'hooks';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import {
  durationToString,
  punctualDate,
  timeOfDate,
} from 'services/text/datetime-format';

interface DepartureFlightPointProps {
  city: string | undefined;
  duration: number;
  gate: string | undefined;
  originalDate: string | undefined;
  seat: string | undefined | null;
  updatedDate: string | undefined;
}

const DepartureFlightPoint = ({
  city,
  duration,
  gate,
  originalDate,
  seat,
  updatedDate,
}: DepartureFlightPointProps) => {
  // Hooks 🎣
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <TimelineItem sx={{ mb: '2.5px', minHeight: 'unset' }}>
      <Separator />
      <TimelineContent sx={{ py: 0 }}>
        <Grid container alignItems='center'>
          <Typography variant='caption' fontSize={12}>
            {punctualDate(updatedDate || originalDate)}
          </Typography>
          <CircleIcon
            sx={{
              fill: theme.palette.text.secondary,
              width: 6,
              height: 6,
              mx: 1,
            }}
          />
          <Typography variant='caption' fontSize={12}>
            {durationToString(duration)}
          </Typography>
          {seat && (
            <>
              <CircleIcon
                sx={{
                  fill: theme.palette.text.secondary,
                  width: 6,
                  height: 6,
                  mx: 1,
                }}
              />
              <Typography variant='caption' fontSize={12}>
                {`${t(TextCodes.WG6.Base.Seat)} ${seat}`}
              </Typography>
            </>
          )}
          {gate && (
            <>
              <CircleIcon
                sx={{
                  fill: theme.palette.text.secondary,
                  width: 6,
                  height: 6,
                  mx: 1,
                }}
              />
              <Typography variant='caption' fontSize={12}>
                {gate}
              </Typography>
            </>
          )}
        </Grid>
        <Typography
          fontWeight='bold'
          variant='body2'
          component='p'
          sx={{ mt: 0.5, display: updatedDate ? 'flex' : '' }}
        >
          {updatedDate ? (
            <Typography
              fontWeight='bold'
              variant='body2'
              sx={{
                mr: 1,
                color: theme?.palette?.warning?.main,
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              {timeOfDate(updatedDate)}
              <Typography
                variant='caption'
                color='text.secondary'
                sx={{ textDecoration: 'line-through' }}
              >
                {timeOfDate(originalDate)}
              </Typography>
            </Typography>
          ) : (
            <Typography
              sx={{ mr: 1 }}
              fontWeight='bold'
              variant='body2'
              fontSize={14}
            >
              {timeOfDate(originalDate)}
            </Typography>
          )}
          {city}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export default DepartureFlightPoint;

const Separator = () => {
  return (
    <>
      <TimelineSeparator>
        <TimelineDot sx={{ padding: '1px', my: 0 }} />
        <TimelineConnector className='small' />
        <TimelineConnector />
        <TimelineConnector />
        <TimelineConnector />
      </TimelineSeparator>
    </>
  );
};
