export enum LanguageCodes {
  NO = 'no',
  EN = 'en',
  TextCodes = 'cimode'
}

type Language = {
  code: LanguageCodes;
  name: string;
};

export const languages: Language[] = [
  { code: LanguageCodes.NO, name: 'Norsk' },
  { code: LanguageCodes.EN, name: 'English' },
  { code: LanguageCodes.TextCodes, name: 'cimode' },
];
