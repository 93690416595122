import * as Yup from 'yup';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import PhoneNumber from 'awesome-phonenumber';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import OrderReceiptEmails from './OrderReceiptEmails';
import OrderReceiptPhones from './OrderReceiptPhones';
import useOrder, { ReceiptEmail, ReceiptSms } from 'hooks/orders/useOrder';
import { LanguageCodes } from 'store/texts/language.config';
import { ProgressButton } from 'components/common/buttons/ProgressButton';
import { TextCodes } from 'hooks/texts/text.codes';
import { useToast } from 'hooks';

interface OrderReceiptFormProps {
  emails: ReceiptEmail[];
  phoneNumbers: ReceiptSms[];
  smsLanguage: string;
  showBoth: boolean;
}

const OrderReceiptModal = () => {
  // hooks
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const { toast } = useToast();
  const {
    handleCancelOrderReceipt,
    isSending,
    sendTravelDetails,
    receiptType,
    mainEmail,
    mainPhoneNumber,
  } = useOrder();
  // Form
  const formMethods = useForm<OrderReceiptFormProps>({
    resolver: yupResolver(orderReceiptSchema),
    defaultValues: {
      emails: [
        {
          mail: mainEmail!,
          language: i18n.language ?? LanguageCodes.NO,
        },
      ],
      phoneNumbers: [
        {
          phoneNumber: mainPhoneNumber!,
          language: i18n.language ?? LanguageCodes.NO,
        },
      ],
      showBoth: false,
    },
  });
  formMethods.watch();

  // Watch for change in checkbox value
  const showBoth = formMethods.watch('showBoth');

  const onSubmit = async (data: OrderReceiptFormProps) => {
    await sendTravelDetails({ ...data }, showBoth);
    formMethods.reset();
    handleCancelOrderReceipt();
    toast({
      severity: 'success',
      autoHideDuration: 3000,
      message: t(TextCodes.WG6.Orders.ReceiptSent),
    });
  };

  const onShowBoth = () => {
    if (!showBoth) {
      if (receiptType === 'email') {
        formMethods.setValue('phoneNumbers', [
          {
            phoneNumber: mainPhoneNumber!,
            language: i18n.language ?? LanguageCodes.NO,
          },
        ]);
      } else {
        formMethods.setValue('showBoth', !showBoth);
        formMethods.setValue('emails', [
          {
            mail: mainEmail!,
            language: i18n.language ?? LanguageCodes.NO,
          },
        ]);
      }
    }
  };

  // We need to order the renders based on which type was selected.
  const renderFirst =
    receiptType === 'email' ? <OrderReceiptEmails /> : <OrderReceiptPhones />;
  const renderSecond =
    receiptType === 'email' ? <OrderReceiptPhones /> : <OrderReceiptEmails />;

  return (
    <Dialog keepMounted fullWidth open={receiptType ? true : false}>
      <Grid
        container
        gap={2}
        sx={{
          minHeight: '350px',
          margin: 'auto',
          px: 3,
          pt: 2,
          pb: 1,
          overflowY: 'scroll',
          wordBreak: 'break-word',
        }}
      >
        <Typography variant='h6' component='h6'>
          {t(TextCodes.WG6.Orders.SendTravelDetailsTitle)}
        </Typography>
        <FormProvider {...formMethods}>
          <Grid component='form' display='flex' flexDirection='column' gap={3}>
            <Grid container aria-live='polite' gap={2}>
              {renderFirst}
              <Collapse
                in={showBoth}
                unmountOnExit
                onExited={() => {
                  // Unregister the array so that it doesnt validate
                  if (receiptType === 'email')
                    formMethods.unregister('phoneNumbers');
                  if (receiptType === 'sms') formMethods.unregister('emails');
                }}
              >
                {renderSecond}
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <Box display='flex'>
                <FormControlLabel
                  sx={{ mr: 0 }}
                  control={
                    <Checkbox
                      {...formMethods.register('showBoth')}
                      onClick={onShowBoth}
                      checked={showBoth}
                    />
                  }
                  label={t(
                    receiptType === 'sms'
                      ? TextCodes.WG6.Orders.AlsoEmail
                      : TextCodes.WG6.Orders.AlsoSms
                  )}
                />
              </Box>
            </Grid>
            <Grid display='flex' justifyContent='flex-end'>
              <Button
                variant='text'
                size='medium'
                onClick={handleCancelOrderReceipt}
              >
                {t(TextCodes.WG6.Navigation.Cancel)}
              </Button>
              <ProgressButton
                onClick={formMethods.handleSubmit(onSubmit)}
                busy={isSending}
                size='small'
                variant='text'
                progressColor={theme.palette.primary.main}
              >
                {t(TextCodes.WG6.Generic.Send)}
              </ProgressButton>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
    </Dialog>
  );
};
export default OrderReceiptModal;

const orderReceiptSchema = Yup.object().shape({
  emails: Yup.array().of(
    Yup.object().shape({
      mail: Yup.string()
        .email(TextCodes.WG6.Validation.EmailFormat)
        .required(TextCodes.WG6.Validation.Required)
        .optional(),
      language: Yup.string().required(TextCodes.WG6.Validation.Required),
    })
  ),
  phoneNumbers: Yup.array().of(
    Yup.object().shape({
      phoneNumber: Yup.string()
        .required(TextCodes.WG6.Validation.Required)
        .test(
          'phoneNumber',
          TextCodes.WG6.Validation.PhoneNumberFormat,
          function (value) {
            // If field is empty, no need to validate
            if (value === undefined || value === null || value === '')
              return true;
            const number = '+' + value || '';
            const result = PhoneNumber(number);
            return result.isValid();
          }
        )
        .optional(),
      language: Yup.string().required(TextCodes.WG6.Validation.Required),
    })
  ),
});
