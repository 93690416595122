import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EditPassportForm from 'components/features/user/documents/passport/EditPassportForm';
import InfoButtonWithDialog from 'components/common/modals/InfoButtonWithDialog';
import PageTitle from 'components/common/pageTitle/PageTitle';
import StandardHeader from 'components/common/headers/StandardHeader';
import { Card } from 'components/common';
import { TextCodes } from 'hooks';

const EditPassportPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <StandardHeader showBreadcrumbs={false} />
      <Container>
        <PageTitle
          title={t(TextCodes.WG6.Passport.Title)}
          sx={{
            mt: 7,
          }}
          button={
            <InfoButtonWithDialog popupText={t(TextCodes.WG6.Passport.Popup)} />
          }
        />
        <Card aria-live='polite'>
          <EditPassportForm />
        </Card>
      </Container>
    </>
  );
};

export default EditPassportPage;
