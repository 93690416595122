import 'dayjs/locale/nb';
import 'moment/locale/nb';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import moment from 'moment';

import i18n from 'i18n';
import { DictionaryItem } from 'services/api/user';
import { TextCodes } from 'hooks';
import { capitalizeFirstLetterInAString } from 'utils/text.utils';
dayjs.extend(advancedFormat);

/* ⚠️⚠️⚠️
 *  Do not use MomentJS❌ for new functions.
 *  We are migrating to DayJs.✅
 * ⚠️⚠️⚠️
 */

export const durationToString = (duration?: number) => {
  if (!duration) {
    duration = 0;
  }

  const momentDuration = moment.duration(duration, 'seconds');
  return `${momentDuration.hours()}${i18n.t(
    TextCodes.WG6.Date.OneLetterHour
  )}${momentDuration.minutes()}${i18n.t(TextCodes.WG6.Date.OneLetterMinute)}`;
};

export const bookingCardDateFormat = (date: string) => {
  const language = i18n.language ?? 'no';

  return moment(date)
    .locale(getLocale())
    .format(language === 'no' ? 'ddd D. MMM YYYY' : 'ddd, MMMM Do YYYY');
};

/**
 *
 * @param date
 * @param short If true, shortens words to 3 characters
 * @returns
 */
export const humanizeDate = (
  date?: string | null | undefined,
  short: boolean = false
) => {
  if (!date) return;
  const locale = i18n.language ?? 'no';

  if (locale === 'no') {
    return dayjs(date)
      .locale('nb')
      .format(short ? 'ddd Do MMM YYYY' : 'dddd Do MMMM YYYY');
  } else {
    return dayjs(date)
      .locale(locale)
      .format(short ? 'ddd Do MMM YYYY' : 'dddd Do MMMM YYYY');
  }
};

export const punctualDate = (
  date: Date | string | null | undefined,
  includeYear = false
) => {
  if (!date) {
    return '';
  }

  let formattedDate = moment(date).format(
    `DD. MMM ${includeYear ? 'YYYY' : ''}`
  );
  if (formattedDate.startsWith('0')) formattedDate = formattedDate.substring(1);

  return formattedDate;
};

export const timeOfDate = (date: Date | string | null | undefined) => {
  if (!date) {
    return '';
  }

  return moment(date).format('HH:mm');
};

export const getMonth = (
  date: Date | string | undefined | null,
  short: boolean = false
) => {
  if (!date) return '';

  if (short) return moment(date).locale(getLocale()).format('MMM');

  return moment(date).locale(getLocale()).format('MMMM');
};

export const getYear = (date: Date | string | undefined | null) => {
  if (!date) return '';

  return moment(date).format('YYYY');
};

/**
 * @summary Dayjs month is zero-indexed. Meaning January === 0
 **/
export const getMonthByNumber = (month: number) => {
  if (month > 11) return;

  const language = getLocale();
  // dayjs get month by number
  return capitalizeFirstLetterInAString(
    dayjs().locale(language).month(month).format('MMMM')
  );
};

/**
 * Checks if date1 is before date2
 * @param date1
 * @param date2
 * @returns true if date1 is before date2, otherwise false.
 */
export const dateIsBefore = (
  date1: Date | string | null | undefined,
  date2: Date | string | null | undefined
): boolean => {
  if (!date1 || !date2) {
    return false;
  }

  return moment(date1).isBefore(date2);
};

export const generateDictionaryOfYears = (min: number, max: number) => {
  var years: DictionaryItem[] = [];

  for (var i = max; i >= min; i--) {
    years.push({ value: i.toString(), text: i.toString() });
  }
  return years;
};

/**
 * @param short If true, shortens months to 3 characters
 * @returns Dictionary of months
 */
export const getMonthsAsDictionary = (short: boolean = false) => {
  let months: DictionaryItem[] = [];

  for (var i = 1; i <= 12; i++) {
    const month = moment()
      .month(i - 1)
      .format(short ? 'MMM' : 'MMMM');
    months.push({ value: i.toString(), text: month });
  }
  return months;
};

export const timeBetweenDates = (
  date1: Date | string | null | undefined,
  date2: Date | string | null | undefined,
  measurement?: 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds'
) => {
  if (!date1 || !date2) {
    return 0;
  }

  return moment(date2).diff(moment(date1), measurement);
};

// Get duration
export const getTotalDuration = (
  time: number,
  type: 'hours' | 'minutes' | 'seconds' | 'milliseconds'
) => {
  const duration = moment.duration(time, type);
  return {
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };
};

/**
 * @param date string date
 * @returns A formatted date string for booking cards
 *
 * This format the dates based on the "Design System - Date formatting" point 5 and 6.
 * https://www.figma.com/file/yyIqXNbF5CYw17NYMBskTz/Design-system?node-id=922%3A25367
 *
 * **Format:**
 *
 * Norwegian: Fr. 1. jan. 2022
 *
 * English: Fr, Jan 1st 2022
 */
export const bookingCardsDateFormat = (date: string) => {
  const language = getLocale();
  const norwegianFormat = dayjs(date)
    .locale(language)
    .format(`ddd D[.] MMM YYYY`);
  const englishFormat = dayjs(date).locale(language).format(`dd, MMM Do YYYY`);

  return language === 'nb'
    ? capitalizeFirstLetterInAString(norwegianFormat)
    : capitalizeFirstLetterInAString(englishFormat);
};

/* @returns A date object
 *
 * Format:
 *
 * Norwegian: 1. jan 2022, kl 01:00
 *
 * English: Jan 1st at 01:00
 */

export const getDateAndTime = (date: string) => {
  const language = getLocale();
  const timePrefix = language === 'nb' ? 'kl' : 'at';

  return language === 'nb'
    ? dayjs(date)
        .locale(language)
        .format(`D[.] MMM YYYY[,] [${timePrefix}] HH:mm`)
    : dayjs(date).locale(language).format(`MMM Do [${timePrefix}] HH:mm`);
};

/**
 *
 * @param  date Date in string form
 * @returns Like 08. sep
 */
export const dayOfMonth = (date: string) => {
  return moment(date).format('Do MMM');
};

const getLocale = () => {
  const userLocale = i18n.language;
  const isoLocale = userLocale === 'no' ? 'nb' : userLocale;

  return isoLocale;
};
