import { get, useFormContext } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import ClearInputIcon from '../ClearInputIcon';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { TextCodes } from 'hooks';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

export interface DateFieldProps {
  label: string;
  name: string;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  size?: 'small' | 'medium';
}

/**
 * @param {string} label - Describes the input
 * @param {string} name - Used with React Hooks form property
 * @param {string} size - Size of the input
 */
const DateField = ({
  label,
  name,
  size = 'small',
  maxDate = dayjs().add(1, 'year'),
  minDate = dayjs().subtract(100, 'year'),
}: DateFieldProps) => {
  // Hooks
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);

  // Form
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);
  const value = watch(name);

  // Local state
  const [inputDate, setInputDate] = useState<Dayjs | null>(
    value ? value : null
  );
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const [key, setKey] = useState(0);
  const [isClearIconVisible, setIsClearIconVisible] = useState(
    value ? true : false
  );

  const handleChange = (newValue: Dayjs | string | null) => {
    const isValidDate = dayjs(newValue, 'DD/MM/YYYY').isValid();

    if (isValidDate) {
      // We have to convert it back to a string, because if the value is null, then picking from
      // the date picker will add your local timezone, which screws up the date.
      const formattedDate = dayjs(newValue, 'DD/MM/YYYY').format('YYYY-MM-DD');
      // We remove the time from the date, so that the date doesn't add or remove a day randomly.
      setValue(
        name,
        dayjs(formattedDate).add(1, 'day').toISOString().slice(0, 10),
        {
          shouldDirty: true,
        }
      );
      setInputDate(dayjs(newValue));
    } else {
      setValue(name, newValue, { shouldDirty: true });
    }
  };

  const handleClear = () => {
    /* 
    Settings the value to "", null or undefined would still leave a date
    in the field, even though the value was falsy. I came up with this
    solution of updating the key to force the component to re-render
    to fix that.
    */
    setKey(key + 1);
    setValue(name, '');
    setInputDate(null);
    setIsClearIconVisible(false);
  };

  useEffect(() => {
    if (key !== 0) ref?.current?.focus();
  }, [key]);

  return (
    <DesktopDatePicker
      label={label}
      key={name + key}
      minDate={minDate}
      maxDate={maxDate}
      inputFormat='DD/MM/YYYY'
      value={inputDate}
      onChange={handleChange}
      PopperProps={{
        popperOptions: {
          placement: 'bottom-end',
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size={size}
          error={error ? true : false}
          helperText={t(error?.message)}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          onMouseLeave={() => setHover(false)}
          onMouseOver={() => setHover(true)}
          inputProps={{
            placeholder: t(TextCodes.WG6.Date.DateFieldPlaceholder),
            ...params.inputProps,
          }}
          InputProps={{
            onChange: (val: ChangeEvent<HTMLInputElement>) => {
              setIsClearIconVisible(
                !isEmpty(val.currentTarget.value) && (focus || hover)
              );
            },
            endAdornment: (
              <>
                <ClearInputIcon
                  isVisible={isClearIconVisible}
                  disabled={false}
                  onClick={handleClear}
                />
                {params.InputProps!!.endAdornment}
              </>
            ),
          }}
          inputRef={ref}
        />
      )}
    />
  );
};

export default DateField;
