import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { DictionaryItem } from 'services/api/user';
import { useFormContext, useWatch, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface SingleSelectProps extends SelectProps {
  name: string;
  label: string;
  options: DictionaryItem[];
}

const SingleSelect = ({
  name,
  label,
  options,
  sx,
  defaultValue,
  ...rest
}: SingleSelectProps) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext();
  const value = useWatch({
    name: name,
    control,
    defaultValue: defaultValue,
  });
  const error = get(errors, name);

  return (
    <FormControl sx={{ mb: 2 }} fullWidth error={error && error.message}>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        // size='small'
        {...rest}
        {...register(name)}
        multiple={false}
        SelectDisplayProps={{
          style: { display: 'flex', alignItems: 'center' },
        }}
        labelId={name}
        value={value}
        label={label}
        // sx={{ ...sx, mb: 0 }}
      >
        {options.map((o) => (
          <MenuItem value={o.value!!} key={o.value}>
            <Typography variant='body1'>{o.text}</Typography>
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{t(error?.message)}</FormHelperText>}
    </FormControl>
  );
};
export default SingleSelect;
