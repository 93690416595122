import { Link as MdLink, LinkProps } from '@mui/material';
import { MouseEventHandler } from 'react';
import { TextVariant } from '../text/Text.types';

export type LinkExternalProps = Pick<LinkProps, 'sx'> & {
  children?: string | JSX.Element | JSX.Element[];
  variant?: TextVariant;
  underline?: 'hover' | 'none' | 'always';
  sx?: any;
  href: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export const LinkExternal = (props: LinkExternalProps) => {
  const { children, variant, underline, href, onClick, sx } = props;
  const external = href && (href.includes('https://') || href.includes('http://'));
  const rel = external ? 'noopener' : '';
  const target = external ? '_blank' : '';
  return (
    <MdLink variant={variant} sx={sx} underline={underline} rel={rel} href={href} onClick={onClick} target={target}>
      {children}
    </MdLink>
  );
};
