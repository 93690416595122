import { Chip, useTheme, Container, List, ListItem } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { AnimatePresence, motion } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import SearchParamProps from 'components/common/search-params/SearchParamProps';

interface OrderChipsProps {
  filters: SearchParamProps[];
}

const OrdersChips = ({ filters }: OrderChipsProps) => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const removeParam = (param: string) => {
    searchParams.delete(param);
    setSearchParams(searchParams);
  };
  const removeAllParams = () => {
    const tripType = searchParams.get('type');
    const resetParams: URLSearchParams = new URLSearchParams();
    if (tripType) resetParams.append('type', tripType);
    setSearchParams(resetParams);
  };

  useEffect(() => {
    if (!searchParams.get('type')) removeAllParams();
  }, [searchParams]);

  return (
    <Container sx={{ px: 0, mt: -2, pt: 2.5, display: 'flex' }}>
      <AnimatePresence>
        {filters.length > 0 && (
          <Chip
            component={motion.div}
            variants={dropVariant}
            exit='exit'
            transition={{ duration: 0.3 }}
            onClick={removeAllParams}
            onDelete={removeAllParams}
            deleteIcon={
              <CancelIcon sx={{ fill: theme.palette.primary.contrastText }} />
            }
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
            label='Tøm filter'
          />
        )}
      </AnimatePresence>
      <List
        sx={{
          p: 0,
          m: 0,
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'scroll',
          scrollbarColor: 'transparent transparent',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <AnimatePresence>
          {filters?.length > 0 &&
            filters?.map((filter) => (
              <ListItem
                sx={{ listStyle: 'none', py: 0, px: 1 }}
                key={filter.param}
                component={motion.li}
                variants={dropVariant}
                exit='exit'
                transition={{ duration: 0.3 }}
              >
                <Chip
                  onDelete={() => removeParam(filter.param!)}
                  deleteIcon={
                    <CancelIcon
                      key={filter.value}
                      sx={{ fill: theme.palette.primary.main }}
                    />
                  }
                  sx={{
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                    ml: 0,
                  }}
                  variant='outlined'
                  label={filter.label}
                />
              </ListItem>
            ))}
        </AnimatePresence>
      </List>
    </Container>
  );
};

const dropVariant = {
  exit: {
    y: [-1, 0, 2, 4],
    opacity: [1, 1, 0.5, 0],
  },
};

export default OrdersChips;
