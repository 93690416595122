import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { TextCodes } from 'hooks/texts/text.codes';
import { useTranslation } from 'react-i18next';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { TextFieldProps } from '@mui/material/TextField';
import { useState } from 'react';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import FormControl from '@mui/material/FormControl';
import TextInput from 'components/common/inputs/textInput/TextInput';
import { useFormContext, get } from 'react-hook-form';
import { FormHelperText, useTheme } from '@mui/material';
import { CheckboxField } from 'components/common/inputs/CheckboxField';
import { isEmpty } from 'lodash';

type EmailContactProps = Pick<
  TextFieldProps,
  'onChange' | 'type' | 'value' | 'defaultValue' | 'fullWidth'
> & {
  name: string;
  label?: string;
  showTitle?: boolean;
  onDelete?: () => void;
};

const EmailContact = ({
  name,
  onDelete,
  showTitle = true,
}: EmailContactProps) => {
  // Hooks 🎣
  const { t } = useTranslation();
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const theme = useTheme();

  // Form 📋
  const {
    getValues,
    formState: { errors },
  } = useFormContext();

  const addressError = get(errors, `${name}.address`);
  const consentError = get(errors, `${name}.emailConsentMessage`);
  const priorityError = !isEmpty(addressError) ? addressError : consentError;
  const readOnly = getValues(`${name}.readonly`);

  const handleDelete = () => {
    if (onDelete && !disableDeleteBtn) {
      onDelete && onDelete();
      setDisableDeleteBtn(true);
    }
  };

  return (
    <>
      <Grid
        item
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        xs={12}
      >
        <Typography
          visibility={showTitle ? 'visible' : 'hidden'}
          variant='body2'
          component='h2'
          fontWeight='bold'
        >
          {t(TextCodes.WG6.Contact.Email)}
        </Typography>
        {!readOnly && (
          <IconButton color='primary' size='small' onClick={handleDelete}>
            <Typography sx={visuallyHidden}>
              {t(TextCodes.WG6.Hidden.DeleteEmail)}
            </Typography>
            <DeleteOutline />
          </IconButton>
        )}
      </Grid>
      <TextInput
        name={`${name}.address`}
        label={t(TextCodes.WG6.Generic.Email)}
        fullWidth
        sx={{ mb: 0 }}
        disabled={readOnly}
      />
      {priorityError && (
        <Typography sx={{ color: theme.palette.error.main }}>
          {t(priorityError.message)}
        </Typography>
      )}
      <Grid container display='flex' flexDirection='column'>
        <FormControl error={!isEmpty(priorityError)}>
          <CheckboxField
            name={`${name}.consentUsernameAndPassword`}
            label={t(TextCodes.WG6.Contact.UsernameAndPassword)}
            tooltip={t(TextCodes.WG6.Contact.UsernameAndPasswordTooltip)}
            sx={{ mb: 0 }}
          />
          <CheckboxField
            name={`${name}.consentItinerary`}
            label={t(TextCodes.WG6.Contact.TravelRoute)}
            tooltip={t(TextCodes.WG6.Contact.TravelRouteTooltip)}
            sx={{ mb: 0 }}
          />
          <CheckboxField
            name={`${name}.consentReceipt`}
            label={t(TextCodes.WG6.Contact.Receipt)}
            tooltip={t(TextCodes.WG6.Contact.ReceiptTooltip)}
            sx={{ mb: 0 }}
          />
          <CheckboxField
            name={`${name}.consentETicket`}
            label={t(TextCodes.WG6.Contact.ETicketReceipt)}
            tooltip={t(TextCodes.WG6.Contact.ETicketTooltip)}
          />
          {consentError && (
            <FormHelperText sx={{ mx: 0 }}>
              {t(TextCodes.WG6.Contact.CheckboxGroupError)}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
    </>
  );
};

export default EmailContact;
