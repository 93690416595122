import { MiddlewareAPI, Middleware, isRejectedWithValue } from '@reduxjs/toolkit';

// Middleware for error handling
// Based on https://redux-toolkit.js.org/rtk-query/usage/error-handling
export const isM3ErrorMessage = (action: any) => {
  return action?.payload?.isSuccess === false;
};

export const isApiSuccessMessage = (action: any) => {
  return action?.payload?.isSuccess === true;
};

export const rtkQueryErrorHandler: Middleware = (_api: MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

  if (isRejectedWithValue(action)) {
    console.warn('API error response');
  } else if (isM3ErrorMessage(action)) {
    console.warn('M3 error response');
  }

  return next(action);
};
