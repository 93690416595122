import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import LocationSearchInput from 'components/common/inputs/LocationSearchInput';
import { AutocompleteOption } from 'components/common/inputs/input.types';
import { DictionaryInput } from 'components/features/dropdowns/DictionaryInput';
import { Preferences } from 'services/api/user';
import { TextCodes } from 'hooks';

interface OrderPreferencesProps {
  defaultDepartureCity: AutocompleteOption;
  defaultDestinationCity: AutocompleteOption;
  preferences: Preferences;
}

const OrderPreferences = ({
  defaultDepartureCity,
  defaultDestinationCity,
  preferences,
}: OrderPreferencesProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography component='h2' variant='body2' fontWeight='bold'>
        {t(TextCodes.WG6.Preferences.Ordering)}
      </Typography>
      <LocationSearchInput
        defaultLabel={preferences?.defaultDepartureCity?.description || ''}
        defaultOption={defaultDepartureCity}
        label={t(TextCodes.WG6.Preferences.DepartureAirport)}
        name='defaultDepartureCity'
      />
      <LocationSearchInput
        defaultLabel={preferences?.defaultDestinationCity?.description || ''}
        defaultOption={defaultDestinationCity}
        label={t(TextCodes.WG6.Preferences.DestinationAirport)}
        name='defaultDestinationCity'
      />
      <DictionaryInput
        name='languageCode'
        dictionary='languages'
        label={t(TextCodes.WG6.Preferences.Language)}
        fullWidth
        sx={{ mb: 0 }}
      />
      <DictionaryInput
        name='currencyCode'
        dictionary='currencies'
        label={t(TextCodes.WG6.Preferences.Currency)}
        fullWidth
        sx={{ mb: 0 }}
      />
    </>
  );
};

export default OrderPreferences;
