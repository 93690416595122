import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import {  useInternalRoutes } from 'hooks';
import { useTranslation } from 'react-i18next';

export const RouteTitle = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { getRouteDataByPath } = useInternalRoutes();
  const data = getRouteDataByPath(location.pathname);
  return (
    <Helmet>
      <title>{t(data?.breadcrumb || '')} | BergHansen</title>
    </Helmet>
  );
};
