import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/system/Container';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { useTranslation } from 'react-i18next';

import { TextCodes } from 'hooks';
import {
  baggageInfoDrawer,
  initialState,
  setBaggageInfoDrawer,
} from 'store/app/app.slice';
import { useAppDispatch, useAppSelector } from 'store/store';

const BaggageInfoDrawer = () => {
  // Hooks 🎣
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const { t } = useTranslation();

  // Global State 🏘️
  const {
    isOpen,
    baggageValue,
    baggageCode,
    departureCityName,
    arrivalCityName,
  } = useAppSelector(baggageInfoDrawer);

  let baggageValueText = TextCodes.WG6.Flight.NoBagsIncluded;

  switch (baggageCode) {
    // 'N' stands for "Number", as in "number of bags included"
    case 'N':
      baggageValueText =
        baggageValue === 0
          ? TextCodes.WG6.Flight.NoBagsIncluded
          : baggageValue!! === 1
          ? TextCodes.WG6.Flight.AmountOfBagsIncludedSingular
          : TextCodes.WG6.Flight.AmountOfBagsIncludedPlural;
      break;

    // 'WK' stands for "Weight", as in "the amount of weight allowed for your baggage"
    case 'WK':
      baggageValueText =
        baggageValue === 0
          ? TextCodes.WG6.Flight.NoBagsIncluded
          : TextCodes.WG6.Flight.WeightOfBagsIncluded;
      break;

    default:
      baggageValueText = TextCodes.WG6.Flight.NoBagsIncluded;
      break;
  }

  return (
    <Drawer
      sx={{ width: '100%' }}
      open={isOpen}
      onClose={() =>
        dispatch(setBaggageInfoDrawer(initialState.baggageInfoDrawer))
      }
      anchor='right'
      PaperProps={{
        sx: {
          width: '100%',
          backgroundColor: palette?.backgroundColors?.paper01,
        },
      }}
    >
      <Button
        onClick={() =>
          dispatch(setBaggageInfoDrawer(initialState.baggageInfoDrawer))
        }
        variant='text'
        sx={{
          p: 2,
          color: palette?.text?.primary,
          justifyContent: 'space-between',
        }}
      >
        <ArrowBackIos color='primary' />
        <Typography variant='h4' component='h1' fontWeight='bold'>
          {t(TextCodes.WG6.Flight.BaggageInfo)}
        </Typography>
        <span></span>
      </Button>
      <Divider />
      <Container sx={{ mt: 4 }}>
        <Typography
          variant='h5'
          fontWeight={400}
          sx={{ display: 'flex', gap: 1, mb: 2, alignItems: 'center' }}
        >
          {departureCityName}
          <ArrowForward
            sx={{ fontSize: '23px' }}
            aria-label={t(TextCodes.WG6.Base.To)}
          />

          {arrivalCityName}
        </Typography>
        <Box
          sx={{
            backgroundColor: palette?.backgroundColors?.selectedCard,
            p: 2,
            borderRadius: 1,
          }}
        >
          <Typography
            paragraph
            sx={{ mb: 1 }}
            variant='body2'
            fontWeight='bold'
          >
            {t(TextCodes.WG6.Flight.Included)}
          </Typography>

          <Typography variant='body2'>
            {!!baggageValue
              ? `${baggageValue ?? ''} ${t(baggageValueText)}`
              : t(baggageValueText)}
          </Typography>
        </Box>
      </Container>
    </Drawer>
  );
};

export default BaggageInfoDrawer;
