import { Divider, Grid } from '@mui/material';
import AddMoreInputsButton from 'components/common/forms/AddMoreInputsButton';
import { motion } from 'framer-motion';
import useAnimatedFieldArray from 'hooks/animation/useAnimatedFieldArray';
import { TextCodes } from 'hooks/texts/text.codes';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'store/texts/language.config';
import OrderReceiptPhoneNumber from './OrderReceiptPhoneNumber';

const OrderReceiptPhones = () => {
  // Hooks
  const { t } = useTranslation();

  // Form
  const { append, remove, variants, fields, itemControls } =
    useAnimatedFieldArray('phoneNumbers');

  return (
    <Grid container component='ul' sx={{ p: 0, m: 0 }}>
      {fields.map((f, i) => (
        <Grid
          container
          component={motion.li}
          variants={variants}
          animate={itemControls}
          custom={i}
          key={f.id}
          gap={2}
          item
          display='flex'
          justifyContent='flex-end'
        >
          <OrderReceiptPhoneNumber
            name={`phoneNumbers.${i}`}
            onDelete={() => remove(i)}
            canDelete={fields.length !== 1}
          />
        </Grid>
      ))}
      <Grid container gap={2}>
        <Grid item xs={12}>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>
        <AddMoreInputsButton
          sx={{ mb: 0 }}
          onClick={() =>
            append({ phoneNumber: '', language: LanguageCodes.NO })
          }
          label={t(TextCodes.WG6.Add.PhoneNumbers)}
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderReceiptPhones;
