import { Grid } from '@mui/material';
import AMEX from 'components/common/icons/svg/AMEX';
import DinersClub from 'components/common/icons/svg/DinersClub';
import Mastercard from 'components/common/icons/svg/Mastercard';
import Visa from 'components/common/icons/svg/Visa';
import Icon from './Icon';

const AcceptedBankCardLogos = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs='auto'>
        <Icon width={34}>
          <AMEX />
        </Icon>
      </Grid>
      <Grid item xs='auto'>
        <Icon width={34}>
          <DinersClub />
        </Icon>
      </Grid>
      <Grid item xs='auto'>
        <Icon width={34}>
          <Mastercard />
        </Icon>
      </Grid>
      <Grid item xs='auto'>
        <Icon width={34}>
          <Visa />
        </Icon>
      </Grid>
    </Grid>
  );
};

export default AcceptedBankCardLogos;
