import { Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TextCodes } from 'hooks';

export const Greetings = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getGreetings = () => {
    let greeting = '';
    const currentHour = moment().hour();

    if (currentHour >= 6 && currentHour < 9) {
      greeting = t(TextCodes.WG6.Greetings.Morning);
    } else if (currentHour >= 9 && currentHour < 12) {
      greeting = t(TextCodes.WG6.Greetings.Day);
    } else if (currentHour >= 12 && currentHour < 18) {
      greeting = t(TextCodes.WG6.Greetings.Afternoon);
    } else if (currentHour >= 18 && currentHour < 24) {
      greeting = t(TextCodes.WG6.Greetings.Day);
    } else if (currentHour >= 0 && currentHour < 6) {
      greeting = t(TextCodes.WG6.Greetings.Night);
    }

    return greeting;
  };

  return (
    <Typography
      variant='h4'
      fontWeight={700}
      component='h1'
      color={theme.palette.text.primary}
      sx={{ mt: 9, mb: 1 }}
    >
      {getGreetings()}
    </Typography>
  );
};
