import { Typography } from '@mui/material';
import { TextCodes } from 'hooks';
import { useTranslation } from 'react-i18next';

interface OrdersFilterTextProps {
  hits: number;
  total: number;
}

const OrdersFilterText = ({ hits, total }: OrdersFilterTextProps) => {
  const { t } = useTranslation();
  if (hits === 0)
    return (
      <Typography variant='caption'>
        <strong>{t(TextCodes.WG6.Generic.NoHits)} </strong> ({total}{' '}
        {t(TextCodes.WG6.Orders.HiddenByFilter)})
      </Typography>
    );

  return (
    <Typography variant='caption'>
      {hits} {t(TextCodes.WG6.Orders.Hits)} ({total}{' '}
      {t(TextCodes.WG6.Orders.HiddenByFilter)})
    </Typography>
  );
};

export default OrdersFilterText;
