import { useAppDispatch, useAppSelector } from '../../store/store';
import { addToast, getToastList, getCurrentToast, removeToast, Toast } from '../../store/toast/toast.slice';
import { RootState } from '../../store';

export const useToast = () => {
  const toastList = useAppSelector((state: RootState) => getToastList(state));
  const currentToast = useAppSelector((state: RootState) => getCurrentToast(state));
  const dispatch = useAppDispatch();

  const toast = (toastData: Toast) => dispatch(addToast(toastData));
  const hideToast = (toastId: string) => dispatch(removeToast(toastId));

  return { toastList, currentToast, hideToast, toast };
};
