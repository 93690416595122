import { Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';

interface FlexSxProps
  extends Omit<SxProps, 'display' | 'flexDirection' | 'gap'> {}

interface FlexboxProps {
  sx?: FlexSxProps;
  direction?: 'row' | 'column';
  justifyContent?: string;
  alignItems?: string;
  children?: ReactNode | ReactNode[];
  gap?: number;
}

/**
 *
 *
 * @deprecated Normal BOX has all of these properties as well, so no need to use this anymore.
 * MY BAD!
 */

const Flexbox = ({
  children,
  sx,
  justifyContent,
  alignItems,
  gap,
  direction = 'row',
}: FlexboxProps) => {
  return (
    <Box
      display='flex'
      alignItems={alignItems}
      justifyContent={justifyContent}
      sx={{ ...sx, gap: gap, flexDirection: direction }}
    >
      {children}
    </Box>
  );
};

export default Flexbox;
