import { Components, CSSInterpolation, Palette } from '@mui/material';

interface DropdownComponent extends Components {
  MuiMenu?: {
    styleOverrides: {
      root: CSSInterpolation;
    };
  };
}

export const dropdownStyles = (palette: Palette): DropdownComponent => ({
  MuiMenu: {
    styleOverrides: {
      root: {
        maxHeight: 250,
      },
    },
  },
});
