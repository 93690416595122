import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextBlock from 'components/common/text/TextBlock';
import { EditableCard } from 'components/common';
import { TextCodes } from 'hooks';
import {
  getIsReadOnlyProfile,
  getTravelPassCorporate,
} from 'store/user/user.slice';
import { useAppSelector } from 'store/store';

const SasTravelPassSection = () => {
  // Hooks 🎣
  const { t } = useTranslation();

  // Selectors 🤏
  const TPC = useAppSelector(getTravelPassCorporate);
  const isReadOnly = useAppSelector(getIsReadOnlyProfile);

  return (
    <EditableCard
      isEditable={!isReadOnly}
      routeId='SasTravelPass'
      title={t(TextCodes.WG6.SasTravel.Title)}
    >
      {TPC?.number && (
        <TextBlock
          title={t(TextCodes.WG6.SasTravel.CorporateLabel)}
          removeDivider={TPC?.routings?.length === 0}
        >
          {TPC?.number}
        </TextBlock>
      )}

      {TPC?.routings?.length !== 0 && TPC?.number && (
        <TextBlock
          title={t(TextCodes.WG6.SasTravel.RegularLabel)}
          removeDivider
        >
          {TPC?.number}
        </TextBlock>
      )}

      {TPC?.routings?.length === 0 && !TPC?.number && (
        <Typography variant='body2' color='text.secondary'>
          {t(TextCodes.WG6.Base.NotAdded)}
        </Typography>
      )}
    </EditableCard>
  );
};

export default SasTravelPassSection;
