import {
  Box,
  Button,
  Dialog,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  flightNumberModal,
  initialState,
  setFlightNumberModal,
} from 'store/app/app.slice';
import { TextCodes } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/store';

const FlightNumberModal = () => {
  const dispatch = useAppDispatch();
  const modal = useAppSelector(flightNumberModal);
  const { t } = useTranslation();

  return (
    <Dialog maxWidth='md' open={modal?.isOpen}>
      <Box>
        <Typography variant='h6' fontWeight='bold' sx={{ px: 3, py: 2 }}>
          {`${t(TextCodes.WG6.Flight.FlightNumberModalTitle)} ${
            modal?.flightCarrierCode
          } ${modal?.flightNo}`}
        </Typography>

        <List
          sx={{
            px: 3,
            py: 1,
            '& > li': {
              p: 0,
            },
          }}
        >
          <ListItem>
            <Typography variant='body2'>
              {`${t(TextCodes.WG6.Flight.ClassOfService)}: ${
                modal?.flightClassOfService
              }`}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body2'>
              {`${t(TextCodes.WG6.Flight.BookingClass)}: ${
                modal?.flightBookingClass
              }`}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body2'>
              {`${t(TextCodes.WG6.Flight.FareBasis)}: ${
                modal?.flightFareBasis
              }`}
            </Typography>
          </ListItem>

          <ListItem sx={{ mt: 2 }}>
            <Typography variant='body2'>
              {`${t(TextCodes.WG6.Flight.CarrierName)}: ${
                modal?.flightCarrierName
              }`}
            </Typography>
          </ListItem>
          {/* <ListItem>
          TODO: Needs to be implemented in the future
            <Typography variant='body2'>Opereres av: </Typography>
          </ListItem> */}
          <ListItem>
            <Typography variant='body2'>
              {`${t(TextCodes.WG6.Flight.FlightEquipment)}: ${
                modal?.flightEquipment
              }`}
            </Typography>
          </ListItem>

          {/* <ListItem sx={{ mt: 2 }}>
          TODO: Needs to be implemented in the future
            <Typography variant='body2'>Estimert utslipp: 44kg CO2</Typography>
          </ListItem> */}
        </List>

        <Grid container justifyContent='flex-end' direction='row' sx={{ p: 1 }}>
          <Button
            variant='text'
            size='medium'
            onClick={() =>
              dispatch(setFlightNumberModal(initialState.flightNumberModal))
            }
          >
            {t(TextCodes.WG6.Navigation.Close)}
          </Button>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default FlightNumberModal;
