import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton } from '@mui/material';

interface Props {
  showPassword: boolean;
  onClick: () => void;
}

const PasswordVisibilityIcon = ({ showPassword, onClick }: Props) => {
  return (
    <InputAdornment position='end'>
      <IconButton aria-label='toggle password visibility' onClick={onClick} edge='end' color={'inherit'}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
};

export default PasswordVisibilityIcon;
