import { Components } from '@mui/material';
import { pxToRem } from 'theme/theme.utils';

/**
 * https://mui.com/material-ui/react-tabs/
 *
 * Customized styling of the MUI Tabs component
 */
export const tabStyles: Components = {
  MuiTabs: {
    defaultProps: {
      centered: true,
      variant: 'fullWidth',
    },
    styleOverrides: {
      root: {
        textTransform: 'unset',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'unset',
        fontSize: pxToRem[14],
        fontWeight: 500,
        lineHeight: '186%',
        letterSpacing: '0.46px',
      },
    },
  },
};
