import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import NotRegistered from 'components/common/text/NotRegistered';
import TextBlock from 'components/common/text/TextBlock';
import { EditableCard } from 'components/common';
import { TextCodes } from 'hooks';
import {
  getIsReadOnlyProfile,
  getProfile,
  getReferences,
} from 'store/user/user.slice';
import { useAppSelector } from 'store/store';

const EmployerAndReportingReferencesCard = () => {
  const references = useAppSelector(getReferences);
  const profile = useAppSelector(getProfile);
  const isReadOnly = useAppSelector(getIsReadOnlyProfile);

  const { t } = useTranslation();

  return (
    <EditableCard
      isEditable={!isReadOnly && !!references?.length}
      routeId='EmployerAndReportingReferences'
      title={t(TextCodes.WG6.EmployerAndReportingReferences.Title)}
    >
      {!references?.length ? (
        <Typography variant='body1'>{profile?.employerName}</Typography>
      ) : (
        <Stack spacing={2} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
          <TextBlock
            removeDivider
            title={t(TextCodes.WG6.EmployerAndReportingReferences.Employer)}
          >
            {profile?.employerName}
          </TextBlock>
          {references?.map((item) => (
            <Fragment key={item?.type}>
              {item?.value ? (
                <TextBlock removeDivider title={item.label ?? ''}>
                  {item.value}
                </TextBlock>
              ) : (
                <NotRegistered
                  textBlock
                  removeDivider
                  title={item.label ?? ''}
                />
              )}
            </Fragment>
          ))}
        </Stack>
      )}
    </EditableCard>
  );
};

export default EmployerAndReportingReferencesCard;
