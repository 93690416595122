import { TextCodes } from 'hooks';
import { useTranslation } from 'react-i18next';

// TODO: This page needs to be redesigned. This is currently just a placeholder. Also, I cannot use any UI library elements here as the items cannot be loaded before initialisation
const AppCrashPage = () => {
  const { t } = useTranslation();
  return (
    <div style={{ padding: '16px' }}>
      <h1>{t(TextCodes.WG6.Error.PageCrashTitle)}</h1>
      <p>{t(TextCodes.WG6.Error.PageCrashMessage)}</p>
      <div
        style={{
          display: 'flex',
          gap: '16px',
        }}
      >
        <a href='/'>{t(TextCodes.WG6.Error.BackToHome)}</a>
        <button onClick={() => window.location.reload()}>{t(TextCodes.WG6.Error.RefreshPage)}</button>
      </div>
    </div>
  );
};

export default AppCrashPage;
