import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BookingStatus, itineraryApi } from 'services/api/itinerary';
import { getAllTrips } from 'store/trips/trips.slice';
import { useAppSelector } from 'store/store';

const useTrips = () => {
  const allTrips = useAppSelector(getAllTrips);
  const [searchParams] = useSearchParams();
  const [typesToFetch, setTypesToFetch] = useState<string[]>([
    'Upcoming',
    'Past',
    'Cancelled',
  ]);
  const [getTrips] = itineraryApi.useLazyItineraryListQuery();

  const fetchTripType = async (type: string) => {
    await getTrips({
      bookedByMe: true,
      bookingStatus: type as BookingStatus,
    });
    const typesRemaining = typesToFetch.filter(
      (t) => t?.toLowerCase() !== type?.toLowerCase()
    );
    setTypesToFetch(typesRemaining);
  };

  useEffect(() => {
    if (typesToFetch.length === 3) {
      fetchTripType(searchParams.get('type') ?? typesToFetch[0]);
    } else if (typesToFetch.length !== 0) {
      fetchTripType(typesToFetch[0]);
    }
  }, [typesToFetch]);

  return { allTrips, typesToFetch };
};

export default useTrips;
