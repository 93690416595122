import {
  Card,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import React from 'react';

export type Severity = 'info' | 'warning' | 'success' | 'error';

export type ActionResultMessageProps = {
  severity: Severity;
  title: string;
  sx?: SxProps;
  children?: React.ReactNode;
};

const renderIcon = (severity: Severity, theme: Theme) => {
  const sxDefault = { fontSize: 64, color: theme.palette[severity].main };

  switch (severity) {
    case 'warning':
      return <WarningAmberIcon sx={sxDefault} />;
    case 'success':
      return <CheckCircleOutlineIcon sx={sxDefault} />;
    case 'error':
      return <ErrorOutlineIcon sx={sxDefault} />;
    case 'info':
    default:
      return <InfoIcon sx={sxDefault} />;
  }
};

export const ActionResultMessage = (props: ActionResultMessageProps) => {
  const theme = useTheme();
  const { severity, title, children, sx } = props;

  return (
    <Card sx={{ px: 2, pt: 5, pb: 3 }}>
      <Stack sx={{ ...sx, alignItems: 'center' }}>
        {renderIcon(severity, theme)}
        <Typography variant='h2' sx={{ mt: 4 }}>
          {title}
        </Typography>
        {children}
      </Stack>
    </Card>
  );
};
