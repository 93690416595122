import { useTranslation } from 'react-i18next';

import NotRegistered from 'components/common/text/NotRegistered';
import TextBlock from 'components/common/text/TextBlock';
import { Address } from 'services/api/user';
import { TextCodes } from 'hooks';
import { getDictionaryValue } from 'store/user/user.slice';
import { useAppSelector } from 'store/store';

const AddressBlock = ({ address }: { address: Address }) => {
  const { t } = useTranslation();
  const country = useAppSelector(
    getDictionaryValue('countries', address?.country || '')
  );

  const areAllValuesInAddressFalsy = Object.values(address!!).every(
    (value) => !value
  );

  return (
    <>
      {areAllValuesInAddressFalsy ? (
        <NotRegistered
          removeDivider
          textBlock
          title={t(TextCodes.WG6.Profile.Address)}
        />
      ) : (
        <TextBlock removeDivider title={t(TextCodes.WG6.Profile.Address)}>
          {address?.street}, {address?.zipCode} {address?.city} {country}
        </TextBlock>
      )}
    </>
  );
};

export default AddressBlock;
