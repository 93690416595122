import Container from '@mui/material/Container';

import NewProfile from 'components/features/new-profile/NewProfile';
import StandardHeader from 'components/common/headers/StandardHeader';

const NewProfilePage = () => {
  return (
    <>
      <StandardHeader showBreadcrumbs={false} />
      <Container sx={{ py: 2 }}>
        <NewProfile />
      </Container>
    </>
  );
};

export default NewProfilePage;
