import * as Yup from 'yup';
import { ActionResultMessage, ButtonPanel } from 'components/common';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TextCodes } from 'hooks/texts/text.codes';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProgressButton } from 'components/common/buttons/ProgressButton';
import TextInput from 'components/common/inputs/textInput/TextInput';
import { authApi } from 'services/api/authentication';
import { routeConfig } from 'routes/config/route.config';
import useSiteNavigation from 'hooks/navigation/useSiteNavigation';
import { PlainCard } from 'components/common/containers/Card';

export const ForgotPasswordForm = () => {
  const navigate = useSiteNavigation();
  const [resetPassword, { isLoading, data, isError }] =
    authApi.useSendPasswordResetEmailMutation();
  const { t } = useTranslation();
  const formMethods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .email(TextCodes.WG6.Validation.EmailFormat)
          .required(TextCodes.WG6.Validation.Required),
      })
    ),
    defaultValues: { email: '' },
  });

  const onSubmit: SubmitHandler<any> = (data) => {
    resetPassword({ passwordResetEmailRequest: { email: data.email } });
  };

  let errorMessage: string | null = null;
  if (isError) errorMessage = t(TextCodes.WG6.Generic.UnknownError);
  if (data?.message) errorMessage = data.message;

  const onBackButtonClick = () => navigate('/');

  return (
    <>
      {data?.isSuccess ? (
        <SuccessCard />
      ) : (
        <PlainCard>
          <Grid container gap={3.5}>
            <Grid container gap={3}>
              <Typography variant='subtitle1' component='h1'>
                {t(TextCodes.WG6.ForgottenPassword.Title)}
              </Typography>
              <Typography variant='body1' component='p'>
                {t(TextCodes.WG6.ForgottenPassword.Description)}
              </Typography>
            </Grid>
            <Grid container component='form' gap={5}>
              <FormProvider {...formMethods}>
                <TextInput
                  fullWidth
                  label={t(TextCodes.WG6.ForgottenPassword.Email)}
                  name='email'
                  autoComplete='email'
                  autoFocus
                  size='medium'
                  sx={{ mb: 0 }}
                />
                <Grid
                  component={Collapse}
                  in={errorMessage !== null}
                  collapsedSize={0}
                  item
                  xs={12}
                  unmountOnExit
                >
                  <Box>
                    <Alert severity='error'>{errorMessage}</Alert>
                  </Box>
                </Grid>
                <ButtonPanel align='right'>
                  <Button
                    onClick={onBackButtonClick}
                    sx={{ pr: 4 }}
                    type='button'
                    variant='text'
                  >
                    <Typography variant='buttonLarge'>
                      {t(TextCodes.WG6.Navigation.Back)}
                    </Typography>
                  </Button>
                  <ProgressButton
                    variant='contained'
                    type='submit'
                    disabled={isLoading}
                    busy={isLoading}
                    onClick={formMethods.handleSubmit(onSubmit)}
                  >
                    <Typography variant='buttonLarge'>
                      {t(TextCodes.WG6.Navigation.Submit)}
                    </Typography>
                  </ProgressButton>
                </ButtonPanel>
              </FormProvider>
            </Grid>
          </Grid>
        </PlainCard>
      )}
    </>
  );
};

const SuccessCard = () => {
  const { t } = useTranslation();

  return (
    <ActionResultMessage
      severity='success'
      title={t(TextCodes.WG6.ForgottenPassword.SuccessTitle)}
    >
      <Stack sx={{ alignItems: 'center', textAlign: 'center', px: 2 }}>
        <Typography variant='body1' sx={{ mt: 3 }}>
          {t(TextCodes.WG6.ForgottenPassword.SuccessDescription)}
        </Typography>
        <Typography variant='body2' sx={{ mt: 5 }}>
          {t(TextCodes.WG6.ForgottenPassword.SuccessCheckSpam)}{' '}
          <Link href={routeConfig.ForgotPassword.path}>
            {t(TextCodes.WG6.ForgottenPassword.SuccessTryAgain)}.
          </Link>
        </Typography>
      </Stack>
    </ActionResultMessage>
  );
};
