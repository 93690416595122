import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { TextCodes } from 'hooks';

interface OrderDetailsStatusBarProps {
  additionalInfo?: string | null;
  justifyContent?: string;
  statusCode?: string | null;
  statusText?: string | null;
}

const OrderDetailsStatusBar = ({
  additionalInfo,
  justifyContent = 'space-between',
  statusCode,
  statusText,
}: OrderDetailsStatusBarProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  return (
    <Grid container alignItems='center' justifyContent={justifyContent}>
      <Stack direction='row' spacing={0.5} alignItems='center'>
        <Typography variant='body2' sx={{ color: palette?.text?.secondary }}>
          {statusText || t(TextCodes.WG6.Orders.NoStatus)}
        </Typography>
      </Stack>
      {additionalInfo && (
        <Typography variant='body2' sx={{ color: palette?.text?.primary }}>
          {additionalInfo}
        </Typography>
      )}
    </Grid>
  );
};

export default OrderDetailsStatusBar;
