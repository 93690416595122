import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import StandardHeader from 'components/common/headers/StandardHeader';
import PageTitle from 'components/common/pageTitle/PageTitle';
import { TextCodes } from 'hooks';
import { useTranslation } from 'react-i18next';
import UsernameForm from 'routes/user/username/UsernameForm';

const UsernamePage = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <StandardHeader
        finalCrumbLabel={t(TextCodes.WG6.User.ChangeUsername)}
        showBreadcrumbs={false}
      />
      <Stack aria-live='polite' component={Container}>
        <PageTitle
          sx={{
            mt: 7,
          }}
          title={t(TextCodes.WG6.User.ChangeUsername)}
        />
        <UsernameForm />
      </Stack>
    </Box>
  );
};

export default UsernamePage;
