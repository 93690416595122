import { Box, Container, Grid } from '@mui/material';
import StandardHeader from 'components/common/headers/StandardHeader';
import PageTitle from 'components/common/pageTitle/PageTitle';
import OrderDetailsCustomerReferenceAccordion from 'components/features/orderDetails/OrderDetailsCustomerReferenceAccordion';
import OrderDetailsAccordion from 'components/features/orderDetails/OrderDetailsAccordion';
import OrderDetailsBottomMenu from 'components/features/orderDetails/OrderDetailsBottomMenu';
import OrderDetailsMenu from 'components/features/orderDetails/OrderDetailsMenu';
import OrderDetailsSkeleton from 'components/features/orderDetails/OrderDetailsSkeleton';
import OrderDetailsTraveller from 'components/features/orderDetails/OrderDetailsTraveller';
import { useSearchParams } from 'react-router-dom';
import { routeConfig } from 'routes/config/route.config';
import { itineraryApi } from 'services/api/itinerary';
import { getOrderDetails } from 'store/orders/orderDetails.slice';
import { useAppSelector } from 'store/store';
import { useEffect, useMemo } from 'react';
import useSiteNavigation from 'hooks/navigation/useSiteNavigation';
import OrderReceiptModal from 'components/features/order/OrderReceiptModal';

const OrderDetailsPage = () => {
  const [searchParams] = useSearchParams();
  const orderNumber = searchParams.get('orderNumber');
  const navigate = useSiteNavigation();
  const { itineraryDetails, pointOfTurnaround } =
    useAppSelector(getOrderDetails);
  const [fetchDetails, fetchDetailsStatus] =
    itineraryApi.useLazyItineraryDetailsQuery();

  const loading = fetchDetailsStatus.isLoading || fetchDetailsStatus.isFetching;

  useMemo(() => {
    if (orderNumber)
      fetchDetails({ itineraryDetailsRequest: { orderNumber: orderNumber } });
  }, [orderNumber]);

  useEffect(() => {
    //Send back to travel folder if we got some invalid data
    if (!orderNumber)
      navigate(routeConfig.Orders.path, {
        replace: true,
        scrollToTop: true,
      });
  }, [orderNumber]);

  useEffect(() => {
    // @ts-ignore
    if (fetchDetailsStatus?.data?.isSuccess === false) {
      navigate(routeConfig.NotFound.path, {
        replace: true,
        scrollToTop: true,
      });
    }
  }, [fetchDetailsStatus.data]);

  return (
    <>
      <Box>
        <StandardHeader
          finalCrumbLabel={pointOfTurnaround?.city?.name ?? ''}
          amountOfBreadcrumbs={3}
          isLoading={loading}
        />
        {loading && <OrderDetailsSkeleton />}
        {!loading && itineraryDetails && (
          <Grid container gap={2}>
            <Grid container component={Container} gap={2}>
              <Grid item xs={12}>
                <PageTitle
                  sx={{ mb: 0 }}
                  title={pointOfTurnaround?.city?.name!!}
                  button={<OrderDetailsMenu orderNumber={orderNumber!!} />}
                />
              </Grid>
              <Grid item xs={12}>
                <OrderDetailsTraveller />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {itineraryDetails.map((det, i) => (
                <OrderDetailsAccordion key={i} {...det} />
              ))}
              <OrderDetailsCustomerReferenceAccordion />
              <OrderDetailsBottomMenu />
            </Grid>
          </Grid>
        )}
      </Box>
      <OrderReceiptModal />
    </>
  );
};

export default OrderDetailsPage;
