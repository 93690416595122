import { baseApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResult, LoginArgs>({
      query: (queryArg) => ({
        url: `/api/authentication/login`,
        method: 'POST',
        body: queryArg.loginRequest,
      }),
    }),
    sendPasswordResetEmail: build.mutation<
      SendPasswordResetEmailResult,
      SendPasswordResetEmailArgs
    >({
      query: (queryArg) => ({
        url: `/api/authentication/password/resetemail`,
        method: 'POST',
        body: queryArg.passwordResetEmailRequest,
      }),
    }),
    resetPassword: build.mutation<ResetPasswordResult, ResetPasswordArgs>({
      query: (queryArg) => ({
        url: `/api/authentication/password/reset`,
        method: 'PUT',
        body: queryArg.passwordResetRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as authApi };
export type LoginResult = /** status 200 Success */ IResultTokensDto;
export type LoginArgs = {
  loginRequest: LoginRequest;
};
export type SendPasswordResetEmailResult =
  /** status 200 Success */ IResultPasswordResetEmailResponse;
export type SendPasswordResetEmailArgs = {
  passwordResetEmailRequest: PasswordResetEmailRequest;
};
export type ResetPasswordResult =
  /** status 200 Success */ IResultPasswordResetResponse;
export type ResetPasswordArgs = {
  passwordResetRequest: PasswordResetRequest;
};
export type TokensDto = {
  accessToken?: string;
  refreshToken?: string;
};
export type IResultTokensDto = {
  value?: TokensDto;
  errorCode?: string | null;
  message?: string | null;
  isSuccess?: boolean;
};
export type LoginRequest = {
  username?: string | null;
  password?: string | null;
};
export type PasswordResetEmailResponse = object;
export type IResultPasswordResetEmailResponse = {
  value?: PasswordResetEmailResponse;
  errorCode?: string | null;
  message?: string | null;
  isSuccess?: boolean;
};
export type PasswordResetEmailRequest = {
  email?: string | null;
};
export type PasswordResetResponse = {
  loginID?: string | null;
  url?: string | null;
};
export type IResultPasswordResetResponse = {
  value?: PasswordResetResponse;
  errorCode?: string | null;
  message?: string | null;
  isSuccess?: boolean;
};
export type PasswordResetRequest = {
  loginId?: string | null;
  newPassword?: string | null;
};
