import Divider from '@mui/material/Divider';
import EmailValidator from 'email-validator';
import Grid from '@mui/material/Grid';
import { isEmpty, some } from 'lodash';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AddMoreInputsButton from 'components/common/forms/AddMoreInputsButton';
import useAnimatedFieldArray from 'hooks/animation/useAnimatedFieldArray';
import { TextCodes } from 'hooks';
import EmailContact from 'components/common/inputs/email/EmailContact';

interface Props {
  arrayName?: string;
}

const EmailContactList = ({ arrayName = 'emails' }: Props) => {
  // hooks 🎣
  const { t } = useTranslation();

  // Form state 📋
  const {
    clearErrors,
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove, variants, itemControls, setValue } =
    useAnimatedFieldArray(arrayName);
  const emails = useWatch({
    control,
    name: arrayName,
  });

  //  Manual validation required cause of odd form rules.
  useEffect(() => {
    let hasErrors = false;
    for (const email of emails) {
      if (
        !EmailValidator.validate(email.address!!) ||
        !some(email, (value) => value === true)
      )
        hasErrors = true;
    }
    if (!hasErrors && !isEmpty(errors['emails'])) clearErrors(arrayName);
  }, [emails]);

  const handleDelete = (index: number) => {
    remove(index);
    setValue('didDelete', true);
  };

  return (
    <Grid container gap={2}>
      {fields.map((f, i) => (
        <Grid
          container
          component={motion.div}
          variants={variants}
          animate={itemControls}
          custom={i}
          key={f.id}
          gap={2}
          item
        >
          <EmailContact
            onDelete={() => handleDelete(i)}
            showTitle={i === 0}
            name={`${arrayName}.[${i}]`}
          />
          {i !== fields.length - 1 && (
            <Divider
              component={Grid}
              item
              xs={12}
              sx={{ borderStyle: 'dashed' }}
            />
          )}
        </Grid>
      ))}
      <Grid container gap={2.5}>
        <Divider component={Grid} item xs={12} sx={{ borderStyle: 'dashed' }} />
        <AddMoreInputsButton
          sx={{ mb: 0 }}
          onClick={() =>
            append({
              address: '',
              consentUsernameAndPassword: false,
              consentItinerary: false,
              consentReceipt: false,
              consentETicket: false,
            })
          }
          label={t(TextCodes.WG6.Add.Emails)}
        />
        <Divider component={Grid} item xs={12} />
      </Grid>
    </Grid>
  );
};

export default EmailContactList;
