import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

type TextBlockProps = {
  children: React.ReactNode;
  title?: string;
  removeDivider?: boolean;
};

const TextBlock = ({ children, title, removeDivider = false }: TextBlockProps) => (
  <Box>
    {title && (
      <Typography variant='caption' color='text.secondary' sx={{ mb: 0.5 }} fontWeight={700} component='h3'>
        {title}
      </Typography>
    )}
    {children === typeof String ? (
      <Typography variant='body1' paragraph sx={{ mb: 0 }}>
        {children}
      </Typography>
    ) : (
      children
    )}
    {!removeDivider && <Divider sx={{ borderStyle: 'dashed', mb: 2, mt: 2 }} />}
  </Box>
);

export default TextBlock;
