import LanguageSelect from 'components/features/language/LanguageSelect';
import { TextCodes } from 'hooks/texts/text.codes';
import { useFormContext, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { DeleteOutline } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';

type Props = {
  name: string;
  canDelete?: boolean;
  onDelete?: () => void;
};
const OrderReceiptPhoneNumber = ({
  name,
  onDelete,
  canDelete = false,
}: Props) => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const value = watch(`${name}.phoneNumber`);
  const error = get(errors, name);

  return (
    <>
      {canDelete && (
        <IconButton color='primary' sx={{ p: 0 }} onClick={onDelete}>
          <DeleteOutline />
        </IconButton>
      )}
      <Box>
        <PhoneInput
          containerClass={error ? 'error' : ''}
          inputProps={{
            label: t(TextCodes.WG6.Contact.PhoneNumber),
            ...register(`${name}.phoneNumber`),
          }}
          country='no'
          priority={{ no: 0, se: 1, dk: 2 }}
          preferredCountries={['no', 'se', 'dk']}
          value={value}
        />
        {error?.phoneNumber?.message && (
          <>
            <FormHelperText color='error' sx={{ ml: 2 }} error>
              {t(error?.phoneNumber?.message)}
            </FormHelperText>
          </>
        )}
      </Box>
      <LanguageSelect name={`${name}.language`} size='small' />
    </>
  );
};

export default OrderReceiptPhoneNumber;
