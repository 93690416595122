import { Components, Palette } from '@mui/material';

export const getLinkStyles = (palette: Palette): Components => ({
  MuiLink: {
    styleOverrides: {
      root: {
        color: palette?.primary?.main,
        textDecorationThickness: '1px',
        textUnderlineOffset: '1px',
        textDecorationColor: palette?.primary?.main,
        '&:visited': {
          color: palette?.primary?.main,
        },
      },
    },
  },
});
