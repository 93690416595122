import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  SxProps,
  useTheme,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { ReactNode } from 'react';

interface ListProps {
  // items: string[];
  listSx?: SxProps;
  children?: ReactNode[];
  iconPosition?: 'leading' | 'trailing';
}

const HorizontalList = ({
  listSx,
  children,
  iconPosition = 'trailing',
}: ListProps) => {
  const theme = useTheme();

  return (
    <List
      container
      sx={{ py: 0, flexWrap: 'wrap', ...listSx }}
      rowGap={0.5}
      component={Grid}
      direction='row'
    >
      {children?.map((child, i) => (
        <ListItem sx={{ width: 'fit-content' }} disablePadding key={i}>
          {iconPosition === 'leading' && (
            <ListItemIcon sx={{ maxWidth: 6, minWidth: 6, mr: 1 }}>
              <CircleIcon
                sx={{
                  fill: theme.palette.text.secondary,
                  width: 6,
                  height: 6,
                }}
              />
            </ListItemIcon>
          )}
          {child}
          {iconPosition === 'trailing' && i !== children?.length - 1 && (
            <ListItemIcon sx={{ maxWidth: 6, minWidth: 6, mx: 1 }}>
              <CircleIcon
                sx={{
                  fill: theme.palette.text.secondary,
                  width: 6,
                  height: 6,
                }}
              />
            </ListItemIcon>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default HorizontalList;
