import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { TextCodes } from 'hooks';
import { useAppDispatch } from 'store/store';
import { setBaggageInfoDrawer } from 'store/app/app.slice';

interface BaggageInfoButtonProps {
  isOpen: boolean;
  departureCityName: string;
  arrivalCityName: string;
  baggageValue: number;
  baggageCode: string;
}

const BaggageInfoButton = ({
  isOpen,
  departureCityName,
  arrivalCityName,
  baggageValue,
  baggageCode,
}: BaggageInfoButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Button
      variant='text'
      sx={{ py: 1.5 }}
      onClick={() =>
        dispatch(
          setBaggageInfoDrawer({
            isOpen,
            departureCityName,
            arrivalCityName,
            baggageValue,
            baggageCode,
          })
        )
      }
    >
      <Typography>{t(TextCodes.WG6.Flight.BaggageInfo)}</Typography>
      <KeyboardArrowRightIcon sx={{ ml: 'auto' }} />
    </Button>
  );
};

export default BaggageInfoButton;
