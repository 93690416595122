import Box from '@mui/material/Box';
import { useAuthorization } from 'hooks/auth/auth.hooks';
import useSiteNavigation from 'hooks/navigation/useSiteNavigation';
import { useEffect } from 'react';

type InternalRouteProps = {
  element: JSX.Element;
  skipLogin?: boolean;
};

export const InternalRoute = ({
  element,
  skipLogin = false,
}: InternalRouteProps) => {
  const { isAuthorized } = useAuthorization();
  const navigate = useSiteNavigation();

  useEffect(() => {
    if (!isAuthorized && !skipLogin) navigate('/login', { replace: true });
  });

  if (!isAuthorized && !skipLogin) return <Box></Box>;

  return element;
};
