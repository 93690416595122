export const opacity = {
  4: '0A',
  8: '14',
  10: '1A',
  12: '1F',
  16: '29',
  17: '2B',
  20: '33',
  23: '3B',
  26: '42',
  30: '4D',
  38: '61',
  42: '6B',
  50: '80',
  54: '8A',
  60: '99',
  70: 'B3',
  87: 'DE',
};

export const pxToRem = {
  0.15: '0.009375rem',
  0.46: '0.02875rem',
  12: '0.75rem',
  13: '0.8125rem',
  14: '0.875rem',
  15: '0.9375rem',
  16: '1rem',
  17: '1.0625rem',
  18: '1.125rem',
  20: '1.25rem',
  24: '1.5rem',
  25: '1.5625rem',
  30: '1.875rem',
  35: '2.1875rem',
};
