import { Card, Skeleton, Divider, Box } from '@mui/material';

const PaymentSettingsSkeleton = () => {
  return (
    <Box>
      <Card sx={{ px: 2, py: 1 }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' gap={1}>
            <Skeleton width={40} height={40} />
            <Skeleton width={40} height={40} />
            <Skeleton width={40} height={40} />
            <Skeleton width={40} height={40} />
          </Box>
          <Skeleton width={30} height={20} />
        </Box>
        <Box>
          <Skeleton width='100%' height={60} />
          <Skeleton sx={{ mt: -1 }} width='40%' height={60} />
        </Box>
        <Box>
          <Divider sx={{ borderStyle: 'dashed', mb: 2 }} />
          <Box display='flex' justifyContent='center'>
            <Skeleton width={150} height={30} />
          </Box>
          <Divider sx={{ mt: 2, mb: 2 }} />
        </Box>
        <Box>
          <Skeleton width={80} height={20} />
        </Box>
        <Box>
          <Skeleton width='100%' height={60} />
        </Box>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Box
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
          gap={4}
        >
          <Skeleton width={60} height={30} />
          <Skeleton width={80} height={70} />
        </Box>
      </Card>
    </Box>
  );
};

export default PaymentSettingsSkeleton;
