import { CenteredLayout } from 'components/layouts';
import { FreeText } from 'components/common/text/Text';
import { LinkInternal } from 'components/common/links/LinkInternal';
import {  TextCodes } from 'hooks';
import Image from 'components/common/image/Image';
import myImage from 'assets/404.jpg';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <CenteredLayout>
      <Image source={myImage} altText={t(TextCodes.WG6.Error.NotFoundErrorAltText)} role='presentation' />
      <FreeText sx={{ mb: '8px', mt: '8px' }} variant='h1' component='h1'>
        {t(TextCodes.WG6.Error.NotFoundErrorTitle)}
      </FreeText>
      <FreeText variant='body1'>
        <p>{t(TextCodes.WG6.Error.NotFoundErrorMessage)}</p>
      </FreeText>
      <LinkInternal routeId='Home' sx={{ mt: '16px' }}>
        {t(TextCodes.WG6.Error.BackToHome)}
      </LinkInternal>
    </CenteredLayout>
  );
};

export default PageNotFound;
