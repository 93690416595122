import { FC, ReactElement } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface Props {
  icon: ReactElement;
  title: string;
  route: string;
}

const IconLinkCard: FC<Props> = ({ icon, title, route }) => (
  <Link style={{ textDecoration: 'none' }} to={route}>
    <Card sx={{ display: 'flex', padding: 2 }}>
      <Box>{icon}</Box>
      <Typography
        variant='body1'
        fontWeight='bold'
        sx={{ paddingLeft: 2, hyphens: 'auto', wordBreak: 'break-all' }}
      >
        {title}
      </Typography>
    </Card>
  </Link>
);

export default IconLinkCard;
