import { useLocalStorage } from 'hooks/storage/storage.hooks';
import { useState } from 'react';
import { itineraryApi } from 'services/api/itinerary';
import { getTac } from 'store/auth/auth.slice';
import {
  setOrderReceipt,
  closeOrderReceipt,
  getOrderReceipt,
} from 'store/orders/orderReceipt.slice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getEmails, getPhoneNumbers, getProfile } from 'store/user/user.slice';

export interface ReceiptEmail {
  mail: string;
  language: string;
}

export interface ReceiptSms {
  phoneNumber: string;
  language: string;
}

interface ReceiptData {
  phoneNumbers: ReceiptSms[];
  emails: ReceiptEmail[];
}

const useOrder = () => {
  // API
  const [sendEmail] = itineraryApi.useLazyItinerarySendByEmailQuery();
  const [sendSMS] = itineraryApi.useLazyItinerarySendBySmsQuery();

  // Selectors
  const orderReceipt = useAppSelector(getOrderReceipt);
  const { firstName, lastName } = useAppSelector(getProfile);
  const phoneNumbers = useAppSelector(getPhoneNumbers);
  const emails = useAppSelector(getEmails);
  const tac = useAppSelector(getTac);

  // Hooks
  const dispatch = useAppDispatch();
  const [storedTokenValue] = useLocalStorage('token', null);

  // Local state
  const [isSending, setIsSending] = useState(false);

  const handleSetOrderReceipt = (
    orderNo: string,
    type: 'sms' | 'email' | ''
  ) => {
    dispatch(
      setOrderReceipt({
        orderNo: orderNo,
        type: type,
        showModal: true,
      })
    );
  };

  const handleCancelOrderReceipt = () => {
    dispatch(closeOrderReceipt());
  };

  const sendTravelDetails = async (data: ReceiptData, sendBoth: boolean) => {
    setIsSending(true);
    if (orderReceipt.type === 'sms' || sendBoth) {
      await sendSMS({
        sendItinerarySmsRequest: {
          orderNo: orderReceipt.orderNo,
          travellerTacNo: tac,
          firstName: firstName,
          lastName: lastName,
          phoneNumbers: [...data?.phoneNumbers],
        },
      });
    }
    if (orderReceipt.type === 'email' || sendBoth) {
      await sendEmail({
        sendItineraryMailRequest: {
          orderNo: orderReceipt.orderNo,
          travellerTacNo: tac,
          firstName: firstName,
          lastName: lastName,
          mails: [...data?.emails],
        },
      });
    }
    setIsSending(false);
    // TODO: Right now we are not handling failing to send receipt, because it was not documented in the design.
    // Need to implement when ready
  };

  // Cannot download PDF with react query, gotta do it the old fashioned way
  const showReceipt = async (orderNo: string) => {
    const res = await fetch(
      process.env.REACT_APP_API_URL + `api/itinerary/receipt`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          authorization: `Bearer ${storedTokenValue}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderNumber: orderNo!!,
          firstName: firstName!!,
          lastName: lastName!!,
        }),
      }
    );
    const buffer = await res.arrayBuffer();
    const blob = new Blob([buffer], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  };

  return {
    sendTravelDetails,
    showReceipt,
    handleSetOrderReceipt,
    handleCancelOrderReceipt,
    orderNumber: orderReceipt.orderNo,
    isSending,
    receiptType: orderReceipt.type,
    mainEmail: emails && emails?.length > 0 ? emails[0].address : '',
    mainPhoneNumber:
      phoneNumbers && phoneNumbers?.length > 0 ? phoneNumbers[0].number : '',
  };
};

export default useOrder;
